import * as actionTypes from '../action/Homework';

const initialState = {
    homeworks: {
        data: [],
        pagination: null
    },
    addError: '',
    getError: '',
    currentPage: 1,
    isLoading: false
};

const homeworkReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOADING_HOMEWORK:
            return {
                ...state,
                isLoading: true,
                addError: '',
                getError: ''
            }

        case actionTypes.ADD_HOMEWORK:
            const total = (state.homeworks.pagination && state.homeworks.pagination.total + 1) || 1;
            let pages = (state.homeworks.pagination && state.homeworks.pagination.pages) || 1;
            if (total / 10 > pages) {
                pages += 1;
            }
            return {
                ...state,
                isLoading: false,
                homeworks: {
                    ...state.homeworks,
                    pagination: { ...state.homeworks.pagination, total, pages },
                    data: [...state.homeworks.data, action.homework.data]
                },
                addError: ''
            };

        case actionTypes.UPDATE_HOMEWORK:
            const homeworkIndex = state.homeworks.data.findIndex(a => a.id === action.homework.data.id);
            return {
                ...state,
                homeworks: { ...state.homeworks, data: Object.assign(state.homeworks.data, { [homeworkIndex]: action.homework.data }) },
                isLoading: false,
                addError: ''
            }

        case actionTypes.ADD_HOMEWORK_ERROR:
            return {
                ...state,
                isLoading: false,
                addError: action.message
            }

        case actionTypes.UPDATE_HOMEWORK_ERROR:
            return {
                ...state,
                isLoading: false,
                addError: action.message
            }

        case actionTypes.GET_HOMEWORKS:
            return {
                ...state,
                homeworks: action.homeworks,
                currentPage: action.pg || 1,
                isLoading: false,
                getError: ''
            }

        case actionTypes.GET_HOMEWORKS_ERROR:
            return {
                ...state,
                isLoading: false,
                getError: action.message,
            }
        default:
            return state;
    }
}

export default homeworkReducer;