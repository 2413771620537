import * as actionTypes from "../action/Drafts";

const initialState = {
  drafts: {
    data: [],
    pagination: null,
  },
  addError: "",
  getError: "",
  currentPage: 1,
  isLoading: false,
};

const draftsReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actionTypes.ADD_DRAFTS:
      console.log("draft====",action);
      const total = (state.drafts.pagination && state.drafts.pagination.total + 1 ) || 1;
      let pages = (state.drafts.pagination && state.drafts.pagination.pages ) || 1;
      if (total / 10 > pages) {
        pages += 1;
      }
      console.log(total, pages, state)
      return {
        ...state,
        isLoading: false,
        drafts: {
          ...state.drafts,
          pagination: { ...state.drafts.pagination, total, pages },
          data: [...state.drafts.data, action.student.data]
        },
        addError: ''
      };

    case actionTypes.GET_DRAFTS:
      return {
        ...state,
        drafts: action.drafts,
        currentPage: action.pg || 1,
        isLoading: false,
        getError: "",
      };

    case actionTypes.GET_DRAFTS_ERROR:
      return {
        ...state,
        isLoading: false,
        getError: action.message,
      };

      case actionTypes.ADD_DRAFTS_ERROR:
        return {
          ...state,
          isLoading: false,
          getError: action.message,
        };
    case actionTypes.SET_LOADING_DRAFTS:
      return {
        ...state,
        isLoading: true,
        addError: "",
        getError: "",
      };

    default:
      return state;
  }
};

export default draftsReducer;
