import * as actionTypes from '../action/Subjects';

const initialState = {
  subjects: {
    data: [],
    pagination: null,
  },
  currentPage: 1,
  addError: '', getError: '',
  isLoading: false
}

const subjectReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SUBJECT_STATUS_CHANGE:
      const index = state.subjects.data.findIndex(t => t.id === action.subjectId);
      const subject = state.subjects.data[index];
      subject.status = action.status;
      return {
        ...state,
        subjects: { ...state.subjects, data: Object.assign(state.subjects.data, { [index]: subject }) },
        isStatusChangeInProcess: false, isLoading: false,
        getError: ''
      };

    case actionTypes.GET_SUBJECTS:
      return {
        ...state,
        isLoading: false,
        subjects: action.subject,
        currentPage: action.pg || 1,
        getError: ''
      }

    case actionTypes.GET_SUBJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        getError: action.message,
      }

    case actionTypes.ADD_SUBJECT:
      const total = state.subjects.pagination && state.subjects.pagination.total + 1;
      let pages = state.subjects.pagination && state.subjects.pagination.pages;
      if (total / 10 > pages) {
        pages += 1;
      }
      return {
        ...state,
        isLoading: false,
        subjects: {
          ...state.subjects,
          pagination: { ...state.subjects.pagination, total, pages },
          data: [...state.subjects.data, action.subject.data]
          // data: Object.assign(state.currentPage)
        },
        addError: ''
      }

    case actionTypes.UPDATE_SUBJECT:
      const subjectIndex = state.subjects.data.findIndex(a => a.id === action.subject.data.id)
      return {
        ...state,
        isLoading: false,
        addError: '',
        subjects: { ...state.subjects, data: Object.assign(state.subjects.data, { [subjectIndex]: action.subject.data }) },
      }

    case actionTypes.UPDATE_SUBJECT_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }

    case actionTypes.ADD_SUBJECT_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }

    case actionTypes.SET_LOADING_SUBJECT:
      return {
        ...state,
        isLoading: true,
        addError: '', getError: ''
      }
    case actionTypes.SET_SUBJECT_STATUS_IN_PROCESS:
      return {
        ...state,
        isStatusChangeInProcess: true,
        addError: '', getError: ''
      };
    case actionTypes.SUBJECT_STATUS_CHANGE_ERROR:
      return {
        ...state,
        isStatusChangeInProcess: false,
        getError: action.message
      };
    default:
      return state
  }

}

export default subjectReducer
