import React, { Component } from 'react'
import { Alert, Card, CardBody, CardHeader, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import * as action from '../../../Store/action/Subjects';
import { ClipLoader } from "react-spinners";
import ConfirmationDialog from '../../ConfirmDialog/ConfirmDialog';
import { debounce } from '../../../utils/utils';

class Subject extends Component {
	state = {
		pg: this.props.currentPage,
		isDelete: false,
		searchName: '',
		selectedStatus: '',
		id: '',
		status: ''
	}

	componentDidMount() {
		if (!this.props.subject.data.length) {
			this.props.onGetSubject();
		}
	}

	paginationChangeHandler = (pg, value) => () => {
		this.props.onGetSubject(value, this.state.searchName);
		this.setState({ pg: value });
	}

	renderPagination(pagination) {
		const pages = [];
		for (let i = 1; i <= pagination; i++) {
			pages.push(
				<PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
					<PaginationLink tag="button">{i}</PaginationLink>
				</PaginationItem>
			);
		}
		return pages;
	}

	confirmationDialogBox = (id, status) => {
		this.setState({ id, status });
	}

	changeStatusHandler = () => {
		this.props.onSubjectStatusChange(this.state.id, this.state.status);
	}

	componentWillReceiveProps(nextProps) {
		if (!nextProps.isStatusChangeInProcess && this.props.isStatusChangeInProcess) {
			this.setState({ id: null, status: null });
		}
	}

	searchSubject = debounce((value) => {
		this.props.onGetSubject(1, value, this.state.selectedStatus);
	}, 500);

	searchSubjectHandler = (event) => {
		this.setState({ pg: 1, searchName: event.target.value })
		this.searchSubject(event.target.value);
	}

	statusChangeHandler = (event) => {
		this.setState({ pg: 1, selectedStatus: event.target.value });
		this.props.onGetSubject(1, this.state.searchName, event.target.value);
	}

	render() {
		const { subject, user, isStatusChangeInProcess } = this.props;
		const { pg, id, status, } = this.state;
		return (
			<div className="row clearfix">
				<div className="col-lg-12">
					<ConfirmationDialog
						isDelete={isStatusChangeInProcess}
						isOpen={id && status}
						deleteHandler={() => this.changeStatusHandler()}
						closeHandler={() => this.confirmationDialogBox(null, null)} />
					<Card className="c-style">
						<CardHeader className="h-style">
							<div className="row m-r-0 m-l-0">
								<div className="c-title-style">
									Subject
								</div>
								<div className="ml-auto btn-style">
									<Link class="btn btn-outline-dark" to={{ pathname: '/admin/subject/add' }}>Add</Link>
								</div>
							</div>
						</CardHeader>
						<CardBody>
							<div className="row">
								<div class="col-lg-3 col-md-6 d-flex mb-2">
									<select
										class="form-control show-tick ms select2"
										data-placeholder="Select"
										onChange={this.statusChangeHandler}
										value={this.state.selectedStatus}>
										<option value=''>Select Status</option>
										<option>ACTIVE</option>
										<option>INACTIVE</option>
									</select>
								</div>
								<div className="col-lg-6 d-flex">
									<div id="example_filter " class="dataTables_filter row">
										<label><input
											type="search"
											className="form-control"
											placeholder="Search here..."
											aria-controls="example"
											value={this.state.searchSubject}
											onChange={this.searchSubjectHandler} /></label>
									</div>
								</div>
							</div>

							<Table className="table-responsive-sm table-responsive-md " hover>
								<thead className='table-header-wrapper'>
									<tr>
										<th>#</th>
										<th width="40%">Subject Name</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{!this.props.loading && !this.props.error &&
										subject.data.map((p, index) => {
											return (
												<tr>
													<th scope="row"><strong>{(pg - 1) * 10 + index + 1}</strong></th>
													<td>{p.subjectName}</td>
													<td>{user.userRole === 3
														? null
														:
														<label className="switch">
															<input type="checkbox" checked={p.status === "ACTIVE"}
																onClick={() => this.confirmationDialogBox(p.id, p.status === 'INACTIVE' ? "ACTIVE" : "INACTIVE")} />
															<span class="slider round"></span>
														</label>
													}</td>
													<td>
														<div className="d-flex">
															<Link className="p-0" to={{ pathname: "/admin/subject/add", state: { subject: p } }}><i
																class="icon-pencil" style={{ color: 'black' }} aria-hidden="true"></i></Link>
														</div>
													</td>
												</tr>
											)
										})

									}
								</tbody>
							</Table>
							{this.props.loading
								?
								<div className="loader-wrapper">
									<ClipLoader
										size={35}
										color={"#123abc"}
										loading={true}
									/>
								</div>
								:
								<div className="d-flex justify-content-end p-2">
									{subject.data.length > 0 && subject.pagination &&
										<Pagination className="pull-right">
											<PaginationItem>
												<PaginationLink
													onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()}
													previous tag="button" />
											</PaginationItem>
											{this.renderPagination(subject.pagination.pages)}
											<PaginationItem>
												<PaginationLink
													onClick={() => subject.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()}
													next tag="button" />
											</PaginationItem>
										</Pagination>
									}
								</div>
							}
							{!this.props.loading && (this.props.error || !subject.data.length) &&
								<div className="error-style">
									<Alert color="danger">{this.props.error ? this.props.error : subject.message}</Alert>
								</div>
							}
						</CardBody>
					</Card>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		subject: state.subject.subjects,
		currentPage: state.subject.currentPage,
		error: state.subject.getError,
		isStatusChangeInProcess: state.subject.isStatusChangeInProcess,
		user: state.login.user,
		loading: state.subject.isLoading,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGetSubject: (pg, searchName, status) => dispatch(action.getSubject(pg, searchName, status)),
		onDeleteSubject: () => dispatch(action.deleteSubject()),
		onSubjectStatusChange: (id, status) => dispatch(action.subjectStatusChange(id, status)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Subject)
