import React, { Component } from 'react'
import { Card, CardHeader, CardBody, FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import * as action from '../../../Store/action/Subjects'
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';

const SignupSchema = Yup.object().shape({
  subjectName: Yup.string()
    .required('Subject is required')

});

class AddSubject extends Component {
  state = {
    subjectName: ''
  }

  componentDidMount() {
    const { subject } = this.props.location.state || {};
    if (subject) {
      this.setState({ ...subject, subjectId: subject.id })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push('/admin/subject')
      this.props.onGetSubject();
    }
    else if (nextProps.error && !this.props.error  ) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      // this.setErrors({ subjectName: nextProps.error });
    }
  }

  submitHandler = (values) => {
    if (this.state.subjectId) {
      this.props.onUpdateSubject(values)
    } else {
      this.props.onSubjectAdd(values)
    }
  }
  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{ subjectName: this.state.subjectName, subjectId: this.state.subjectId }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >{({ values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          status,
          isSubmitting, }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">{this.state.subjectName === '' ? 'Add Subject' : 'Update Subject'}</CardHeader>
              <CardBody>
                <FormGroup>
                  <Label >Subject Name<span className="required">*</span></Label>
                  <Input type="className"
                    invalid={errors.subjectName && touched.subjectName}
                    name="subjectName" onChange={handleChange} onBlur={handleBlur} value={values.subjectName} placeholder="Subject Name" />
                  <FormFeedback>
                    {errors.subjectName && touched.subjectName && errors.subjectName}
                  </FormFeedback>
                </FormGroup>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary mr-2" onClick={handleSubmit} disabled={this.props.loading}>
                    {this.props.loading ?
                      <ClipLoader
                        size={15}
                        color={"#123abc"}
                        loading={true}
                      /> : 'Save'
                    }
                  </button>
                  <Link className="btn btn-secondary" to={{ pathname: '/admin/subject' }}>Cancel</Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.subject.isLoading,
    error: state.subject.addError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubjectAdd: (subject) => dispatch(action.addSubject(subject)),
    onUpdateSubject: (subject) => dispatch(action.updateSubject(subject)),
    onGetSubject: (pg, searchName, status) => dispatch(action.getSubject(pg, searchName, status)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSubject)