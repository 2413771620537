import * as actionTypes from '../action/Attendance';

const initialState = {
    attendances: {
        data: [],
        pagination: null
    },
    addError: '',
    getError: '',
    currentPage: 1,
    isLoading: false,
    isExportAttendanceLoading:false
};

const attendanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOADING_ATTENDANCE:
            return {
                ...state,
                isLoading: true,
                addError: '',
                getError: ''
            };

            case actionTypes.SET_EXPORT_ATTENDANCE_LOADING:
                return {
                    ...state,
                    isExportAttendanceLoading:true,
                    addError: '',
                    getError: ''
                }
    


        case actionTypes.ADD_ATTENDANCE:
            const total = state.attendances.pagination && state.attendances.pagination.total + 1;
            let pages = state.attendances.pagination && state.attendances.pagination.pages;
            if (total / 10 > pages) {
                pages += 1;
            }
            return {
                ...state,
                isLoading: false,
                addError: '',
                attendances: {
                    ...state.attendances,
                    pagination: { ...state.attendances.pagination, total, pages },
                    data: [...state.attendances.data, action.attendance]
                }
            };

        case actionTypes.UPDATE_ATTENDANCE:
            const attendanceId = action.attendance[0].id;
            const attendanceIndex = state.attendances.data.findIndex(a => a.id === attendanceId);
            const attendance = state.attendances.data[attendanceIndex];
            attendance.status = action.attendance[0].status;
            return {
                ...state,
                isLoading: false,
                addError: '',
                attendances: { ...state.attendances, data: Object.assign(state.attendances.data, { [attendanceIndex]: attendance }) },
            }

        case actionTypes.ADD_ATTENDANCE_ERROR:
            return {
                ...state,
                isLoading: false,
                addError: action.message
            }

        case actionTypes.UPDATE_ATTENDANCE_ERROR:
            return {
                ...state,
                isLoading: false,
                addError: action.message
            }

        case actionTypes.GET_ATTENDANCES:
            return {
                ...state,
                attendances: action.attendance,
                currentPage: action.pg || 1,
                isLoading: false,
                isExportAttendanceLoading:false,
                getError: ''

            }

        case actionTypes.GET_ATTENDANCES_ERROR:
            return {
                ...state,
                isLoading: false,
                isExportAttendanceLoading:false,
                getError: action.message,
            }
        default:
            return state;
    }
}

export default attendanceReducer;