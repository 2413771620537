import React, { Component } from "react";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actiondrafts from "../../../Store/action/Drafts";
import { ClipLoader } from "react-spinners";
// import ConfirmationDialog from "../../ConfirmDialog/ConfirmDialog";
import { debounce } from "../../../utils/utils";
import ImageModal from "../../Modal/ImageModal";
// import { getClass } from "../../../Store/action/Classes";
import Modal from "./DraftDetails";
import Profile from "../../../assets/images/profile-avatar.png";
import "./Stdents.css";

class Drafts extends Component {
  input = React.createRef();
  state = {
    pg: this.props.currentPage,
    searchName: "",
    selectedClass: "",
    selectedStatus: "",
    studentDetails: [],
    id: "",
    status: "",
  };
  searchStudents = debounce((value) => {
    this.props.onGetDrafts(
      1,
      value.searchName,
    );
  }, 500);

  componentDidMount() {
    // this.props.onGetClass(-1);
    // if (this.props.user.userRole === 3 && !this.props.students.data.length) {
    //   this.props.onGetStudents(this.state.pg);
    // } else if (
    //   this.props.user.userRole !== 3 &&
    //   !this.props.students.data.length
    // ) {
    //   this.props.onGetStudents(this.state.pg, '');
    // }

    this.props.onGetDrafts(this.state.pg, '');
  }

  classChangeHandle = (event) => {
    this.setState({ pg: 1, selectedClass: event.target.value });
    this.props.onGetStudents(
      this.state.pg,
      event.target.value,
      this.state.searchName,
      this.state.selectedStatus
    );
  };

  renderPagination(pagination) {
    const pages = [];
    for (let i = 1; i <= pagination; i++) {
      pages.push(
        <PaginationItem
          active={this.state.pg === i}
          key={i}
          onClick={this.paginationChangeHandler("pg", i)}
        >
          <PaginationLink tag="button">{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  }

  paginationChangeHandler = (pg, value) => () => {
    console.log(this.props.students.data.length, "h");
    this.props.onGetStudents(
      value,
      this.state.selectedClass,
      this.state.searchName,
      this.state.selectedStatus
    );
    this.setState({ pg: value });
  };

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.isStatusChangeInProcess &&
      this.props.isStatusChangeInProcess
    ) {
      this.setState({ id: null, status: null });
    }
  }

  searchStudentHandler = (event) => {
    this.setState({ pg: 1, searchName: event.target.value });
    this.searchStudents({ pg: 1, searchName: event.target.value });
  };

  ModalToggler = (students) => {
    this.setState({ isOpen: !this.state.isOpen, studentDetails: students });
  };
    
  render() {

    const { drafts } = this.props;
    const { pg, studentDetails, isOpen } = this.state;

    return (
      <div className="row clearfix">
        <div className="col-lg-12">
          <Modal
            isOpen={isOpen}
            student={studentDetails}
            isClose={this.ModalToggler}
          />
          <Card className="c-style">
            <CardHeader className="h-style">
              <div className="row m-r-0 m-l-0">
                <div className="c-title-style">Draft Students</div>
                
              </div>
            </CardHeader>
            <CardBody>
              <div className="row mb-2">
                {/* <div class="col-lg-3 col-md-6 d-flex">
                  <select
                    class="form-control show-tick ms select2"
                    data-placeholder="Select"
                    onChange={this.classChangeHandle}
                    value={this.state.selectedClass}
                  >
                    <option value="">Select Class</option>
                    {user.userRole === 3
                      ? user.userclasses.map((c) => {
                          return (
                            <option
                              value={c.classId}
                            >{`${c.class.standard.standardName}-${c.class.className}, ${c.class.medium}`}</option>
                          );
                        })
                      : classes.data.map((c) => {
                          return (
                            <option
                              value={c.id}
                            >{`${c.standard.standardName}-${c.className}, ${c.medium}`}</option>
                          );
                        })}
                  </select>
                </div>

                {user.userRole === 3 ? null : (
                  <div className="row">
                    <div class="d-flex ml-3">
                      <select
                        class="form-control show-tick ms select2"
                        data-placeholder="Select"
                        onChange={this.statusChangeHandler}
                        value={this.state.selectedStatus}
                      >
                        <option value="">Select Status</option>
                        <option>ACTIVE</option>
                        <option>INACTIVE</option>
                      </select>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-12 d-flex pl-5">
                    <label>
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search here..."
                        aria-controls="example"
                        value={this.state.searchName}
                        onChange={this.searchStudentHandler}
                      />
                    </label>
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-lg-12 d-flex pl-5">
                    <label>
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search here..."
                        aria-controls="example"
                        value={this.state.searchName}
                        onChange={this.searchStudentHandler}
                      />
                    </label>
                  </div>
                </div> */}
              </div>
              <Table className="table-responsive-sm table-responsive-md " hover>
                <thead className='table-header-wrapper'>
                  <tr>
                    <th>#</th>
                    <th>Profile Pic</th>
                    <th>RollNumber</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Gender</th>
                    <th>GrNumber</th>
                    <th>Class</th>
                    <th>Medium</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.props.loading &&
                    !this.props.error &&
                    drafts.data.map((p, index) => {
                      return (
                        <tr>
                          <th>
                            <strong>{(pg - 1) * 10 + index + 1}</strong>
                          </th>
                          <td>
                            <ImageModal
                              alt="."
                              className="image img-fluid"
                              src={p.profilePic ? p.profilePic : Profile}
                            />
                          </td>
                          <td>{p.rollNumber}</td>
                          <td>{p.firstName}</td>
                          <td>{p.lastName}</td>
                          <td>{p.gender}</td>
                          <td>{p.grNumber}</td>
                          {/* <td>{`${p.class.standard.standardName}-${p.class.className}, ${p.class.medium}`}</td> */}
                          <td>{p.className}</td>
                          <td>{p.medium}</td>
                          <td>
                            <div className="d-flex">
                              <Link
                                className="p-2"
                                to={{
                                  pathname: "/admin/drafts/add",
                                  state: { students: p },
                                }}
                              >
                                <i
                                  class="icon-pencil"
                                  style={{ color: "black" }}
                                  aria-hidden="true"
                                />
                              </Link>
                              <span onClick={() => {this.ModalToggler(p)}}>
                                <i
                                  class="fa fa-info-circle"
                                  style={{
                                    color: "black",
                                    fontSize: "20px",
                                    paddingTop: "8px",
                                    paddingLeft: "10px",
                                  }}
                                  aria-hidden="true"
                                  onClick={() => this.ModalToggler(p)}
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              {this.props.loading ? (
                <div className="loader-wrapper">
                  <ClipLoader size={35} color={"#123abc"} loading={true} />
                </div>
              ) : (
                <div className="d-flex justify-content-end p-2">
                  {drafts.data.length > 0 && drafts.pagination && (
                    <Pagination className="pull-right">
                      <PaginationItem>
                        <PaginationLink
                          onClick={() =>
                            this.state.pg > 1 &&
                            this.paginationChangeHandler(
                              "pg",
                              this.state.pg - 1
                            )()
                          }
                          previous
                          tag="button"
                        />
                      </PaginationItem>
                      {this.renderPagination(drafts.pagination.pages)}
                      <PaginationItem>
                        <PaginationLink
                          onClick={() =>
                            drafts.pagination.pages > this.state.pg &&
                            this.paginationChangeHandler(
                              "pg",
                              this.state.pg + 1
                            )()
                          }
                          next
                          tag="button"
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </div>
              )}
              {!this.props.loading &&
                (this.props.error || !drafts.data.length) && (
                  <div className="error-style">
                    <Alert color="danger">
                      {this.props.error ? this.props.error : drafts.message}
                    </Alert>
                  </div>
                )}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.students.currentPage,
    error: state.students.getError,
    loading: state.students.isLoading,
    user: state.login.user,
    classes: state.class.classes,
    drafts: state.drafts.drafts,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    onGetDrafts: (pg, searchName) => dispatch(actiondrafts.getDrafts(pg, searchName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(Drafts);
