import { toast } from 'react-toastify';
import * as api from '../../api/homework';
import { getErrorMessage } from '../../utils/utils';
export const ADD_HOMEWORK = 'ADD_HOMEWORK'
export const ADD_HOMEWORK_ERROR = 'ADD_HOMEWORK_ERROR'
export const GET_HOMEWORKS = 'GET_HOMEWORKS'
export const GET_HOMEWORKS_ERROR = 'GET_HOMEWORKS_ERROR'
export const DELETE_HOMEWORK = 'DELETE_HOMEWORK'
export const DELETE_HOMEWORK_ERROR = 'DELETE_HOMEWORK_ERROR'
export const UPDATE_HOMEWORK = 'UPDATE_HOMEWORK'
export const UPDATE_HOMEWORK_ERROR = 'UPDATE_HOMEWORK_ERROR'
export const SET_LOADING_HOMEWORK = 'SET_LOADING_HOMEWORK'

export const addHomeworkSuccess = (homework) => {

    return {
        type: ADD_HOMEWORK,
        homework
    }
}

export const addHomeworkError = (message) => {
    return {
        type: ADD_HOMEWORK_ERROR,
        message
    }
}
export const addHomework = (homeworks) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_HOMEWORK });
        api.addHomework(homeworks)
            .then(response => {
                dispatch(addHomeworkSuccess(response.data))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(addHomeworkError(getErrorMessage(err)))
            })
    }
}

export const updateHomeworkError = (message) => {
    return {
        type: UPDATE_HOMEWORK_ERROR,
        message
    }
}

export const updateHomeworkSuccess = (homework) => {
    return {
        type: UPDATE_HOMEWORK,
        homework
    }
}

export const updateHomeworks = (homeworks) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_HOMEWORK });
        api.updateHomework(homeworks)
            .then(response => {
                dispatch(updateHomeworkSuccess(response.data))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(updateHomeworkError(getErrorMessage(err)))
            })
    }
}

export const getHomeworksSuccess = (homeworks, pg) => {
    return {
        type: GET_HOMEWORKS,
        homeworks, pg
    }
}

export const getHomeworksError = (message) => {
    return {
        type: GET_HOMEWORKS_ERROR,
        message
    }
}

export const getHomeworks = (pg, searchName, classId, subjectId, startDate, deadlineDate, teacherId) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_HOMEWORK });
        api.getHomeworks({ pg, searchName, classId, subjectId, startDate, deadlineDate, teacherId })
            .then(response => {
                dispatch(getHomeworksSuccess(response.data, pg))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(getHomeworksError(getErrorMessage(err)));
            })
    }
}

export const deleteHomeworksSuccess = (homeworks) => {
    return {
        type: DELETE_HOMEWORK,
        homeworks
    }
}

export const deleteHomeworksError = (message) => {
    return {
        type: DELETE_HOMEWORK_ERROR,
        message
    }
}

export const deleteHomeworks = () => {
    return dispatch => {
        // api.getTeachers()
        //     .then(response => {
        //         dispatch(deleteHomeworksSuccess(response.data))
        //     })
        //     .catch(err => {
        //     dispatch(deleteHomeworksError(err.response ? err.response.data.message:'Something Went Wrong. Please try again' ))
        //     })
    }
}
