import Classes from "./Component/View/Classes/Classes";
import Subject from "./Component/View/Subject/Subject";
import AddSubject from "./Component/View/Subject/AddSubject"
import Teachers from "./Component/View/Teachers/Teachers"
import AddClass from "./Component/View/Classes/AddClass";
import AddTeachers from "./Component/View/Teachers/AddTeachers"
import Students from "./Component/View/Students/Students"
import AddStudents from "./Component/View/Students/AddStudents"
import HomeWork from "./Component/View/HomeWork/HomeWork";
import HomeWorkDetails from "./Component/View/HomeWork/HomeworkDetail";
import AddHomeWork from "./Component/View/HomeWork/AddHomeWork"
import Standard from "./Component/View/Standard/Standard"
import AddStandard from "./Component/View/Standard/AddStandard"
import Index from "./Component/View/index"
import Event from "./Component/View/Event/Event"
import AddEvent from "./Component/View/Event/AddEvent"
import AddAttendance from "./Component/View/Attendance/AddAttendance";
import Attendance from "./Component/View/Attendance/Attendance";
import StudentLeave from "./Component/View/Student Leave/Leave"
import MyLeave from "./Component/View/MyLeave/MyLeave";
import AddMyLeave from "./Component/View/MyLeave/AddMyLeave";
import Holiday from "./Component/View/Holiday/Holiday";
import AddHoliday from "./Component/View/Holiday/AddHoliday";
import Breakfast from "./Component/View/Breakfast/Breakfast";
import AddBreakfast from "./Component/View/Breakfast/AddBreakfast";
import DailyActivityTimetable from "./Component/View/DailyActivityTimetable/DailyActivityTimetable"
import AddDailyActivityTimetable from "./Component/View/DailyActivityTimetable/AddDailyActivityTimetable"
import Result from "./Component/View/Result/Result"
import AddResult from "./Component/View/Result/AddResult"
import ExamTimeTable from "./Component/View/ExamTimeTable/ExamTimeTable"
import AddExamTimeTable from "./Component/View/ExamTimeTable/AddExamTimeTable"
import UpdateExamTimeTable from "./Component/View/ExamTimeTable/UpdateExamTimeTable"
import Profile from "./Component/View/Profile/Profile"
import AddSchool from "./Component/View/School/AddSchool";
import School from "./Component/View/School/School";
import ExamType from "./Component/View/ExamType/ExamType";
import addExamType from "./Component/View/ExamType/AddExamType";
import Drafts from "./Component/View/Drafts/Drafts"
import AddDrafts from "./Component/View/Drafts/AddDrafts"
import index from './Component/View/PrivacyPolicy/privacypolicy';
import privacypolicy from './Component/View/PrivacyPolicy/privacypolicy';

var routes = [{
	path: "/index",
	name: "Index",
	icon: "icon-user",
	component: Index,
	layout: "/admin",
	hidden: true,
},
{
	path: "/profile",
	name: "profile",
	icon: "icon-user",
	component: Profile,
	layout: "/admin",
	hidden: true,
},
{
	path: "/addSchool",
	name: "Add School  ",
	icon: "fa fa-calendar",
	component: AddSchool,
	layout: "/admin",
	userRole: [1],
	tab: 1,
	hidden: true,
},
{
	path: "/school",
	name: "School  ",
	icon: "fa fa-calendar",
	component: School,
	layout: "/admin",
	userRole: [1],
	tab: 1
	// hidden: true,
},
{
	path: "/examType",
	name: "Exam Type  ",
	icon: "fa fa-calendar",
	component: ExamType,
	layout: "/admin",
	userRole: [3],
	tab: 2
	// hidden: true,
},
{
	path: "/addExamType",
	name: "Exam Type  ",
	icon: "fa fa-calendar",
	component: addExamType,
	layout: "/admin",
	userRole: [3],
	tab: 2,
	hidden: true,
},
{
	path: "/examTimeTable",
	name: "Exam Timetable  ",
	icon: "fa fa-calendar",
	component: ExamTimeTable,
	layout: "/admin",
	userRole: [3],
	tab: 2
	// hidden: true,
},
{
	path: "/updateExamTimeTable",
	name: "Exam Timetable  ",
	icon: "fa fa-calendar",
	component: UpdateExamTimeTable,
	layout: "/admin",
	userRole: [3],
	tab: 2,
	hidden: true,
},
{
	path: "/examTimeTable/add",
	name: "addExamTimeTable  ",
	icon: "fa fa-sign-out",
	component: AddExamTimeTable,
	layout: "/admin",
	hidden: true,
	userRole: [3],
	tab: 2
},
{
	path: "/result/add",
	name: "AddResult  ",
	icon: "fa fa-sign-out",
	component: AddResult,
	layout: "/admin",
	hidden: true,
	userRole: [3],
	tab: 2
},
{
	path: "/result",
	name: "Result  ",
	icon: "fa fa-file-text",
	component: Result,
	layout: "/admin",
	userRole: [3],
	tab: 2
},
{
	path: "/dailyActivityTimetable",
	name: "Daily Activity Timetable",
	icon: "fa fa-puzzle-piece",
	component: DailyActivityTimetable,
	layout: "/admin",
	userRole: [3],
	tab: 2
},
{
	path: "/dailyActivityTimetable/add",
	name: "Add Daily Activity Timetable",
	icon: "fa fa-sign-out",
	component: AddDailyActivityTimetable,
	layout: "/admin",
	hidden: true,
	userRole: [3],
	tab: 2
},
{
	path: "/holiday/add",
	name: "AddHoliday",
	icon: "fa fa-calendar",
	component: AddHoliday,
	layout: "/admin",
	hidden: true,
	userRole: [3],
	tab: 3
},
{
	path: "/holiday",
	name: "Holiday",
	icon: "fa fa-suitcase",
	component: Holiday,
	layout: "/admin",
	userRole: [2],
	tab: 3
},
{
	path: "/breakfast/add",
	name: "AddBreakfast",
	icon: "fa fa-calendar",
	component: AddBreakfast,
	layout: "/admin",
	hidden: true,
	userRole: [3],
	tab: 3
},
{
	path: "/breakfast",
	name: "Breakfast",
	icon: "fa fa-coffee",
	component: Breakfast,
	layout: "/admin",
	userRole: [3],
	tab: 3
},
{
	path: "/myLeave",
	name: "MyLeave",
	icon: "icon-briefcase",
	component: MyLeave,
	layout: "/admin",
	userRole: [3],
	tab: 3
},
{
	path: "/teacherLeave",
	name: "Teacher Leave",
	icon: "icon-briefcase",
	component: MyLeave,
	layout: "/admin",
	userRole: [2],
	tab: 3
},
{
	path: "/myLeave/add",
	name: "MyLeave",
	icon: "fa fa-sign-out",
	component: AddMyLeave,
	layout: "/admin",
	userRole: [3],
	hidden: true,
	tab: 3
},
{
	path: "/studentleave",
	name: "Student Leave",
	icon: "fa fa-plane",
	component: StudentLeave,
	layout: "/admin",
	userRole: [3],
	// hidden: true,
},
{
	path: "/event",
	name: "Event",
	icon: "fa fa-birthday-cake",
	component: Event,
	layout: "/admin",
	userRole: [3],
	tab: 3
},
{
	path: "/event/add",
	name: "addEvent",
	icon: " icon-book-open",
	component: AddEvent,
	layout: "/admin",
	hidden: true,
	userRole: [3],
	tab: 3
},
{
	path: "/attendance",
	name: "Attendance",
	icon: "fa fa-pencil-square-o",
	component: Attendance,
	layout: "/admin",
	userRole: [2, 3],
	tab: 1
},
{
	path: "/attendance/add",
	name: "AddAttendance",
	icon: "fa fa-calendar",
	component: AddAttendance,
	layout: "/admin",
	hidden: true,
	userRole: [3],
	tab: 1
},
{
	path: "/homeWork",
	name: "Home Work",
	icon: " icon-book-open",
	component: HomeWork,
	layout: "/admin",
	userRole: [3]
},
{
	path: "/homeWork/add",
	name: "Add Home Work",
	icon: " icon-book-open",
	component: AddHomeWork,
	layout: "/admin",
	hidden: true,
	userRole: [3]
},
{
	path: "/homeWork/details",
	name: "Details",
	icon: " icon-book-open",
	component: HomeWorkDetails,
	layout: "/admin",
	hidden: true,
	userRole: [3]
},
{
	path: "/principals",
	name: "Principal",
	icon: "icon-user",
	component: Teachers,
	layout: "/admin",
	userRole: [1],
	tab: 1
},
{
	path: "/teachers",
	name: "Teachers",
	icon: "icon-user",
	component: Teachers,
	layout: "/admin",
	userRole: [2],
	tab: 1
},
{
	path: "/students",
	name: "Students",
	icon: " icon-users",
	component: Students,
	layout: "/admin",
	userRole: [3, 2],
	tab: 1
},
{
	path: "/students/add",
	name: "AddStudents",
	icon: "icon-user",
	component: AddStudents,
	layout: "/admin",
	hidden: true,
	userRole: [ 2]
},
{
	path: "/teachers/add",
	name: "AddTeachers",
	icon: "icon-user",
	component: AddTeachers,
	layout: "/admin",
	hidden: true,
	userRole: [],
	tab: 1
},
{
	path: "/standard",
	name: "Standard",
	icon: "fa fa-building-o",
	component: Standard,
	layout: "/admin",
	userRole: [ 2],
	tab: 2,
},
{
	path: "/standard/add",
	name: "AddStandard",
	icon: "icon-graduation",
	component: AddStandard,
	layout: "/admin",
	hidden: true,
	userRole: [ 2],
	tab: 2,
},
{
	path: "/classes",
	name: "Classes",
	icon: "icon-graduation",
	component: Classes,
	layout: "/admin",
	userRole: [ 2],
	tab: 2
},
{
	path: "/classes/add",
	name: "AddClass",
	icon: "icon-graduation",
	component: AddClass,
	layout: "/admin",
	hidden: true,
	tab: 2
},
{
	path: "/subject",
	name: "Subject",
	icon: " icon-book-open",
	component: Subject,
	layout: "/admin",
	userRole: [ 2],
	tab: 2
},
{
	path: "/subject/add",
	name: "AddSubject",
	icon: " icon-book-open",
	component: AddSubject,
	layout: "/admin",
	hidden: true,
	tab: 2
},
{
	path: "/drafts",
	name: "Draft Students",
	icon: " icon-users",
	component: Drafts,
	layout: "/admin",
	userRole: [2],
	tab: 1
},
{
	path: "/drafts/add",
	name: "AddDrafts",
	icon: "icon-user",
	component: AddDrafts,
	layout: "/admin",
	hidden: true,
	userRole: [2]
},
];
export default routes;
