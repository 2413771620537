import { toast } from 'react-toastify';
import * as api from '../../api/Schools';
import { getErrorMessage } from '../../utils/utils';

export const ADD_SCHOOL = 'ADD_SCHOOL'
export const ADD_SCHOOL_ERROR = 'ADD_SCHOOL_ERROR'
export const GET_SCHOOL = 'GET_SCHOOL'
export const GET_SCHOOL_ERROR = 'GET_SCHOOL_ERROR'
export const UPDATE_SCHOOL = 'UPDATE_SCHOOL'
export const UPDATE_SCHOOL_ERROR = 'UPDATE_SCHOOL_ERROR'
export const SET_LOADING_SCHOOL = 'SET_LOADING_SCHOOL'
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'

export const addSchoolSuccess = (school) => {
    return {
        type: ADD_SCHOOL,
        school
    }
}

export const addSchoolError = (message) => {
    return {
        type: ADD_SCHOOL_ERROR,
        message
    }
}
export const addSchool = (className) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_SCHOOL });
        api.addSchools(className)
            .then(response => {
                dispatch(addSchoolSuccess(response.data))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(addSchoolError(getErrorMessage(err)));
            })
    }
}

export const updateSchoolError = (message) => {
    return {
        type: UPDATE_SCHOOL_ERROR,
        message
    }
}
export const updateSchoolSuccess = (school) => {
    return {
        type: UPDATE_SCHOOL,
        school
    }
}

export const updateSchool = (school) => {
    return dispatch => {
        dispatch(school.status ? { type: SET_LOADING_STATUS } : { type: SET_LOADING_SCHOOL });
        api.updateSchools(school)
            .then(response => {
                dispatch(updateSchoolSuccess(response.data))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(updateSchoolError(getErrorMessage(err)));
            })
    }
}

export const getSchoolSuccess = (school, pg) => {
    return {
        type: GET_SCHOOL,
        school, pg
    }
}

export const getSchoolError = (message) => {
    return {
        type: GET_SCHOOL_ERROR,
        message
    }
}

export const getSchool = (pg, searchName, status) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_SCHOOL });
        api.getSchools({ pg, searchName, status })
            .then(response => {
                dispatch(getSchoolSuccess(response.data, pg))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(getSchoolError(getErrorMessage(err)))
            })
    }
}
