import * as actionTypes from '../action/breakfast';

const initialState = {
  breakfasts: {
    data: [],
    pagination: null
  },
  addError: '',
  getError: '',
  currentPage: 1,
  isLoading: false
};

const breakfastReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BREAKFAST:
      const total = state.breakfasts.pagination && state.breakfasts.pagination.total + 1;
      let pages = state.breakfasts.pagination && state.breakfasts.pagination.pages;
      if (total / 10 > pages) {
        pages += 1;
      }
      return {
        ...state,
        isLoading: false,
        addError: '',
        breakfasts: {
          ...state.breakfasts,
          pagination: { ...state.breakfasts.pagination, total, pages },
          data: [...state.breakfasts.data, action.breakfast.data]
        }
      };

    case actionTypes.ADD_BREAKFAST_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }

    case actionTypes.UPDATE_BREAKFAST:
      const breakfastIndex = state.breakfasts.data.findIndex(a => a.id === action.breakfast.data.id);
      return {
        ...state,
        isLoading: false,
        addError: '',
        breakfasts: { ...state.breakfasts, data: Object.assign(state.breakfasts.data, { [breakfastIndex]: action.breakfast.data }) }
      }

    case actionTypes.UPDATE_BREAKFAST_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }


    case actionTypes.DELETE_BREAKFAST:
      return {
        ...state,
        isLoading: false,
        getError: '',
      }

    case actionTypes.DELETE_BREAKFAST_ERROR:
      return {
        ...state,
        isLoading: false,
        getError: action.message
      }


    case actionTypes.GET_BREAKFAST:
      return {
        ...state,
        breakfasts: action.breakfast,
        currentPage: action.pg || 1,
        isLoading: false,
        getError: ''
      }

    case actionTypes.SET_LOADING_BREAKFAST:
      return {
        ...state,
        isLoading: true,
        addError: '',
        getError: ''
      }

    case actionTypes.SET_LOADING_BREAKFAST_DELETE:
      return {
        ...state,
        isLoading: true,
        addError: '',
        getError: ''
      }
    default:
      return state;
  }
}

export default breakfastReducer