import React, { Component } from 'react';
import SignIn from './Component/Auth/login';
import ForgotPassword from './Component/Auth/ForgotPassword';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from './Layout/Dashboard/Dashboard';
import ResetPassword from './Component/Auth/ResetPassword';
import privacypolicy from './Component/View/PrivacyPolicy/privacypolicy';

export default class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route  path="/admin" render={props => <AdminLayout {...props}  />} />
            {/* <Route path="/signup" component={SignUp} /> */}
            <Route path="/privacyPolicy" component={privacypolicy} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/resetPassword" component={ResetPassword} />
            <Route path="/signin" component={SignIn} onEnter={AdminLayout} />
            <Redirect from="/" to="/admin/index" />
          </Switch>
        </BrowserRouter>
      </>
    )
  }
}
