// import './auth';
// import './classes';
// import './Subject';
// import './teachers';
// import './Students';
// import './homework';
// import './Standard';
// import './Event';
// import './StudentLeave';
// import './TeacherLeave';
// import './Leave';
// import './holiday';
// import './breakfast';
// import './DailyActivityTimetable';
// import './Result';
// import './ExamTimeTable';
// import './attendance';
import { mock } from './mock';
mock.onAny().passThrough();