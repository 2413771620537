import { toast } from 'react-toastify';
import * as api from '../../api/Students';
import { getErrorMessage } from '../../utils/utils';
export const GET_STUDENTS = 'GET_STUDENTS'
export const GET_STUDENTS_ERROR = 'GET_STUDENTS_ERROR'
export const ADD_STUDENTS = 'ADD_STUDENTS'
export const ADD_STUDENTS_EXCEL = 'ADD_STUDENTS_EXCEL'
export const ADD_STUDENTS_ERROR = 'ADD_STUDENTS_ERROR'
export const ADD_STUDENTS_EXCEL_ERROR = 'ADD_STUDENTS_EXCEL_ERROR'
export const UPDATE_STUDENTS = 'UPDATE_STUDENTS'
export const UPDATE_STUDENTS_ERROR = 'UPDATE_STUDENTS_ERROR'
export const SET_LOADING_STUDENT = 'SET_LOADING_STUDENT'
export const SET_LOADING_STUDENT_EXCEL = 'SET_LOADING_STUDENT_EXCEL'
export const SET_STUDENT_STATUS_IN_PROCESS = 'SET_STUDENT_STATUS_IN_PROCESS';
export const DELETE_STUDENTS = 'DELETE_STUDENTS'
export const DELETE_STUDENTS_ERROR = 'DELETE_STUDENTS_ERROR'
export const STUDENT_STATUS_CHANGE = ' STUDENT_STATUS_CHANGE'
export const STUDENT_STATUS_CHANGE_ERROR = ' STUDENT_STATUS_CHANGE_ERROR'

export const studentStatusChangeSuccess = (status, studentId) => {
  return {
    type: STUDENT_STATUS_CHANGE,
    status,
    studentId
  }
}

export const studentStatusChangeError = (message) => {
  return {
    type: STUDENT_STATUS_CHANGE_ERROR,
    message
  }
}

export const studentStatusChange = (studentId, status) => {
  return dispatch => {
    dispatch({ type: SET_STUDENT_STATUS_IN_PROCESS });
    api.studentStatusChange({ status, studentId })
      .then(response => {
        dispatch(studentStatusChangeSuccess(status, studentId))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(studentStatusChangeError(getErrorMessage(err)))
      })
  }
}

export const addStudentsSuccess = (student) => {
  return {
    type: ADD_STUDENTS,
    student
  }
}

export const addStudentsError = (message) => {
  return {
    type: ADD_STUDENTS_ERROR,
    message
  }
}
export const addStudents = (students) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_STUDENT });
    api.addStudents(students)
      .then(response => {
        console.log("students====",response)
        dispatch(addStudentsSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addStudentsError(getErrorMessage(err)))
      })
  }
}

export const addStudentsExcelSuccess = (student) => {
  return {
    type: ADD_STUDENTS_EXCEL,
    student
  }
}

export const addStudentsExcelError = (message) => {
  return {
    type: ADD_STUDENTS_EXCEL_ERROR,
    message
  }
}

export const addStudentsExcel = (students) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_STUDENT_EXCEL });
    api.addStudentsExcel(students)
      .then(response => {
        console.log("excel====",response)
        dispatch(addStudentsExcelSuccess(response.data))
        dispatch(getStudents(1,''))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addStudentsExcelError(getErrorMessage(err)))
      })
  }
}

export const getStudentsSuccess = (students, pg) => {
  return {
    type: GET_STUDENTS,
    students,
    pg
  }
}

export const getStudentsError = (message) => {
  return {
    type: GET_STUDENTS_ERROR,
    message
  }
}

export const getStudents = (pg, classId, searchName, status) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_STUDENT });
    api.getStudents({ pg, classId, searchName, status })
      .then(response => {
        dispatch(getStudentsSuccess(response.data, pg))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getStudentsError(getErrorMessage(err)))
      })
  }
}

export const updateStudentSuccess = (students) => {
  return {
    type: UPDATE_STUDENTS,
    students
  }
}

export const updateStudentError = (message) => {
  return {
    type: UPDATE_STUDENTS_ERROR,
    message
  }
}

export const updateStudent = (students) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_STUDENT });
    api.updateStudents(students)
      .then(response => {
        dispatch(updateStudentSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateStudentError(getErrorMessage(err)))
      })

  }
}


export const deleteStudentsSuccess = (students) => {
  return {
    type: DELETE_STUDENTS,
    students
  }
}

export const deleteStudentsError = (message) => {
  return {
    type: DELETE_STUDENTS_ERROR,
    message
  }
}

export const deleteStudents = () => {
  return dispatch => {

  }
}

