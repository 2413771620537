import * as actionTypes from '../action/StudentLeave';

const initialState = {
  leave: {
    data: [],
    pagination: null
  },
  error: '',
  currentPage: 1,
  isLoading: false,
  statusLoading: false
};

const leaveReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.UPDATE_LEAVE:
      const leaveId = action.leave.leaveId;
      const leaveIndex = state.leave.data.findIndex(a => a.id === leaveId);
      const leave = state.leave.data[leaveIndex];
      leave.status = action.leave.status;
      return {
        ...state,
        leave: {
          ...state.leave, data: Object.assign(state.leave.data, { [leaveIndex]: leave })
        },
        statusLoading: false,
        error: ''
      }
    case actionTypes.UPDATE_LEAVE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.message
      }
    case actionTypes.GET_LEAVE:
      return {
        ...state,
        leave: action.leave,
        currentPage: action.pg || 1,
        isLoading: false,
        error: ''
      }

    case actionTypes.GET_LEAVE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.message,
      }

    case actionTypes.LEAVE_SET_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    case actionTypes.LEAVE_SET_STATUS_LOADING:
      return {
        ...state,
        statusLoading: true,
        error: '',
      }
    default:
      return state;
  }
}

export default leaveReducer