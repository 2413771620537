import React, { Component } from "react";
import {
  Card,
  Table,
  CardBody,
  CardHeader,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Spinner
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../../Store/action/Teachers";
import { ClipLoader } from "react-spinners";
import ConfirmationDialog from "../../ConfirmDialog/ConfirmDialog";
import ImageModal from "./../../Modal/ImageModal";
import Teacher from "../../../assets/images/teachers.png";
import Modal from "./TeacherDetail";
import { debounce } from "../../../utils/utils";
import { URLS } from "../../../api/URLS";
import Axios from "axios";

class Teachers extends Component {
  input = React.createRef();
  state = {
    pg: this.props.currentPage,
    isDelete: false,
    selectedStatus: "",
    teacherDetails: [],
    id: "",
    status: "",
    searchName: "",
    userRole: '',
    loadingTeacher:false
  };

  componentDidMount() {
    if(this.props.user.userRole !== 2){
      this.props.onGetTeachers(
        1,
       "",
       "",
       2
      );
    }else{
      this.props.onGetTeachers(
        1,
       "",
       "",
       3
      );
    }
    
  }

  paginationChangeHandler = (pg, value) => () => {
    // if(this.props.user.userRole === 1){
    // this.props.onGetTeachers(
    //   value,
    //    this.state.selectedStatus,
    //    "",
    //  2
    // ); 
    // }else{
    //   this.props.onGetTeachers(
    //     value,
    //      this.state.selectedStatus,
    //      "",
    //    3
    //   );  
    // }
    this.setState({ pg: value });
  };

  renderPagination(pagination) {
    const pages = [];
    for (let i = 1; i <= pagination; i++) {
      pages.push(
        <PaginationItem
          active={this.state.pg === i}
          key={i}
          onClick={this.paginationChangeHandler("pg", i)}
        >
          <PaginationLink tag="button">{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  }

  confirmationDialogBox = (id, status) => {
    this.setState({ id, status });
  };

  changeStatusHandler = () => {
    this.props.onTeacherStatusChange(this.state.id, this.state.status);
  };

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.isStatusChangeInProcess &&
      this.props.isStatusChangeInProcess
    ) {
      this.setState({ id: null, status: null });
    }
  }

  searchTeacher = debounce((value) => {
    console.log("User role",this.props.user.userRole) 
    this.props.onGetTeachers(
      1,
      this.state.selectedStatus,
      value.searchName,
      this.props.user.userRole===1?2:3
    );
  }, 500);

  searchTeacherHandler = (event) => {
    this.setState({ pg: 1, searchName: event.target.value });
    this.searchTeacher({ pg: 1, searchName: event.target.value });
  };

  statusChangeHandle = (event) => {
    this.setState({ pg: 1, selectedStatus: event.target.value });
    this.props.onGetTeachers(
      1,
      event.target.value,
      '',
      this.props.user.userRole===1?2:3 
    );
  };


  ModalToggler = (teacher) => {
    this.setState({ isOpen: !this.state.isOpen, teacherDetails: teacher });
  };

  handleExport = () => {
    try {
      this.setState({ loadingTeacher: true });
      let query ='user';
      Axios.get(`${URLS.TEACHER_EXPORT}?${query}`).then((response) => {
        this.setState({ loadingTeacher: false });
       // this.setState({ loadingTemplete: false });
        console.log("response teacher",response);
        window.open(`${URLS.TEACHER_DOWNLOAD}?${query}`);
      });
    } catch (error) {
      this.setState({ loadingTeacher: false });
      console.log('Error of data', error);
    }
  };

  render() {
    const { teachers, isStatusChangeInProcess, user } = this.props;
    const { pg, isOpen, teacherDetails, id, status,loadingTeacher } = this.state;
    return (
      <div className="row clearfix">
        <div className="col-lg-12">
          <Modal
            isOpen={isOpen}
            teacher={teacherDetails}
            isClose={this.ModalToggler}
          />
          <ConfirmationDialog
            isOpen={id && status}
            isDelete={isStatusChangeInProcess}
            deleteHandler={() => this.changeStatusHandler()}
            closeHandler={() => this.confirmationDialogBox(null, null)}
          />
          {/* <div className="col-lg-12"> */}
          <Card className="c-style">
            <CardHeader className="h-style">
              <div className="row m-r-0 m-l-0">
                <div className="c-title-style">
                  {this.props.user.userRole === 1 ? "Principals" : "Teachers"}
                </div>
                <div className="ml-auto btn-style">
                  <input
                    type="file"
                    ref={this.input}
                    onChange={this.onSelectFile}
                    style={{ display: "none" }}
                  />
                  {/* <Button
                    className="actionsButtons"
                    onClick={(e) =>
                      this.input.current && this.input.current.click()
                    }
                  >
                    Import
                  </Button> */}
                  {/* <Link
                    class="btn btn-outline-dark"
                    onClick={this.handleExport}
                  >
                    Export
                  </Link> */}
                  {this.props.user.userRole!==1&&
                  <>
                     <Link
                       class="btn btn-outline-dark"
                      onClick={() => this.handleExport('user')}
                      to={{ pathname: '/admin/teachers' }}
                    >
                       {loadingTeacher&&<Spinner size="sm" style={{marginRight:5}}></Spinner>}
                       Export
                         </Link><span style={{marginLeft:10}}/>
                         </>}
                  <Link
                    class="btn btn-outline-dark"
                    to={{ pathname: "/admin/teachers/add" }}
                  >
                    Add
                  </Link>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="row">
                <div class="col-lg-3 col-md-6 d-flex mb-2">
                  <select
                    class="form-control show-tick ms select2"
                    data-placeholder="Select"
                    onChange={this.statusChangeHandle}
                    value={this.state.selectedStatus}
                  >
                    <option value="">Select Status</option>
                    <option>ACTIVE</option>
                    <option>INACTIVE</option>
                  </select>
                </div>
                <div className="row">
                  <div className="col-lg-12 d-flex pl-5">
                    <label>
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search here..."
                        aria-controls="example"
                        value={this.state.searchName}
                        onChange={this.searchTeacherHandler}
                      />
                    </label>
                  </div>
                </div>

                
              </div>

              <Table
                responsive
                className="table-responsive-sm table-responsive-md  table-responsive-lg"
                hover
              >
                <thead className="table-header-wrapper">
                  <tr>
                    <th>#</th>
                    <th>Profile Pic</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Type</th>
                    <th>School Name</th>
                    <th>Mobile No</th>
                    <th>Class</th>
                    <th>Subject</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.props.loading &&
                    !this.props.error &&
                    teachers.data.map((t, index) => {
                      
                      return (
                        <tr>
                          <td>
                            <strong>{(pg - 1) * 10 + index + 1}</strong>
                          </td>
                          <td>
                            <ImageModal
                              alt="."
                              className="image img-fluid"
                              src={t.profilePic ? t.profilePic : Teacher}
                            />
                          </td>
                          <td>
                            {t.firstName} {t.lastName}
                          </td>
                          <td>{t.email}</td>
                          <td>{t.userRole === 2 ? "Principal" : "Teacher"}</td>
                          <td>{t.school.schoolName}</td>
                          <td>{t.mobileNo}</td>
                          <td>
                            {t.userclasses
                              .map(
                                (c) =>
                                  `${c.class.standard.standardName}-${c.class.className}, ${c.class.medium}`
                              )
                              .join(",")}
                          </td>
                          <td>
                            {t.userSubjects
                              .map((s) => s.subject.subjectName)
                              .join(",")}
                          </td>
                          <td>
                            <label class="switch">
                              <input
                                type="checkbox"
                                checked={t.status === "ACTIVE"}
                                onClick={() =>
                                  this.confirmationDialogBox(
                                    t.id,
                                    t.status === "INACTIVE"
                                      ? "ACTIVE"
                                      : "INACTIVE"
                                  )
                                }
                              />
                              <span class="slider round"></span>
                            </label>
                          </td>
                          <td>
                            <div className="d-flex">
                              <Link
                                className="p-2"
                                to={{
                                  pathname: "/admin/teachers/add",
                                  state: { teachers: t },
                                }}
                              >
                                <i
                                  class="icon-pencil"
                                  style={{ color: "black" }}
                                  aria-hidden="true"
                                />
                              </Link>
                              <span
                                outline
                                onClick={() => this.ModalToggler(t)}
                              >
                                <i
                                  className="fa fa-info-circle"
                                  style={{
                                    color: "black",
                                    fontSize: "20px",
                                    paddingTop: "8px",
                                    paddingLeft: "10px",
                                  }}
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              {this.props.loading ? (
                <div className="loader-wrapper">
                  <ClipLoader size={35} color={"#123abc"} loading={true} />
                </div>
              ) : (
                <div className="pagination-wrapper">
                  {teachers.data.length > 0 &&
                    teachers.pagination &&
                    !this.props.error && (
                      <Pagination className="pull-right">
                        <PaginationItem>
                          <PaginationLink
                            onClick={() =>
                              this.state.pg > 1 &&
                              this.paginationChangeHandler(
                                "pg",
                                this.state.pg - 1
                              )()
                            }
                            previous
                            tag="button"
                          />
                        </PaginationItem>
                        {this.renderPagination(teachers.pagination.pages)}
                        <PaginationItem>
                          <PaginationLink
                            onClick={() =>
                              teachers.pagination.pages > this.state.pg &&
                              this.paginationChangeHandler(
                                "pg",
                                this.state.pg + 1
                              )()
                            }
                            next
                            tag="button"
                          />
                        </PaginationItem>
                      </Pagination>
                    )}
                </div>
              )}
              {!this.props.loading &&
                (this.props.error || !teachers.data.length) && (
                  <div className="error-style">
                    <Alert color="danger">
                      {this.props.error ? this.props.error : teachers.message}
                    </Alert>
                  </div>
                )}
            </CardBody>
          </Card>
          {/* </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teachers: state.teacher.teachers,
    currentPage: state.teacher.currentPage,
    error: state.teacher.getError,
    isStatusChangeInProcess: state.teacher.isStatusChangeInProcess,
    user: state.login.user,
    loading: state.teacher.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetTeachers: (pg, status, searchName, userRole) =>
      dispatch(action.getTeachers(pg, status, searchName, userRole)),
     onDeleteTeachers: () => dispatch(action.deleteTeachers()),
     onTeacherStatusChange: (id, status) =>
      dispatch(action.teacherStatusChange(id, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);
