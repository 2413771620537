import React, { Component } from 'react';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as action from '../../../Store/action/Students';
import { ClipLoader } from 'react-spinners';
import ConfirmationDialog from '../../ConfirmDialog/ConfirmDialog';
import { debounce } from '../../../utils/utils';
import ImageModal from './../../Modal/ImageModal';
import { getClass } from '../../../Store/action/Classes';
import Modal from './StudentDetail';
import Profile from '../../../assets/images/profile-avatar.png';
import Axios from 'axios';
import { URLS } from '../../../api/URLS';
import './Stdents.css';
import { ThreeDots } from 'react-loader-spinner';

class Students extends Component {
  input = React.createRef();
  state = {
    pg: this.props.currentPage,
    searchName: '',
    selectedClass: '',
    selectedStatus: '',
    studentDetails: [],
    id: '',
    status: '',
    loadingStudent: false,
    loadingTemplete: false
  };
  searchStudents = debounce((value) => {
    this.props.onGetStudents(
      1,
      this.state.selectedClass,
      value.searchName,
      this.state.selectedStatus
    );
  }, 500);

  componentDidMount() {
    this.props.onGetClass(1);
    if (this.props.user.userRole === 3 && !this.props.students.data.length) {
      this.props.onGetStudents(this.state.pg);
    } else if (
      this.props.user.userRole !== 3 &&
      !this.props.students.data.length
    ) {
      this.props.onGetStudents(this.state.pg, '');
    }
  }

  classChangeHandle = (event) => {
    this.setState({ pg: 1, selectedClass: event.target.value });
    this.props.onGetStudents(
      this.state.pg,
      event.target.value,
      this.state.searchName,
      this.state.selectedStatus
    );
  };

  renderPagination(pagination) {
    const pages = [];
    for (let i = 1; i <= pagination; i++) {
      pages.push(
        <PaginationItem
          active={this.state.pg === i}
          key={i}
          onClick={this.paginationChangeHandler('pg', i)}
        >
          <PaginationLink tag="button">{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  }

  paginationChangeHandler = (pg, value) => () => {
    this.props.onGetStudents(
      value,
      this.state.selectedClass,
      this.state.searchName,
      this.state.selectedStatus
    );
    this.setState({ pg: value });
  };

  changeStatusHandler = () => {
    this.props.onStudentStatusChange(this.state.id, this.state.status);
  };

  confirmationDialogBox = (id, status) => {
    this.setState({ id, status });
  };

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.isStatusChangeInProcess &&
      this.props.isStatusChangeInProcess
    ) {
      this.setState({ id: null, status: null });
    }
  }

  searchStudentHandler = (event) => {
    this.setState({ pg: 1, searchName: event.target.value });
    this.searchStudents({ pg: 1, searchName: event.target.value });
  };

  statusChangeHandler = (event) => {
    this.setState({ pg: 1, selectedStatus: event.target.value });
    this.props.onGetStudents(
      1,
      this.state.selectedClass,
      this.state.searchName,
      event.target.value
    );
  };

  ModalToggler = (student) => {
    this.setState({ isOpen: !this.state.isOpen, studentDetails: student });
  };

  handleExport = (type) => {
    try {
      if (type === 'student') {
        this.setState({ loadingStudent: true });
      } else {
        this.setState({ loadingTemplete: true });
      }

      let query = type === 'student' ? 'type=student' : 'type=template';
      Axios.get(`${URLS.STUDENT_EXPORT}?${query}`).then((response) => {
        this.setState({ loadingStudent: false });
        this.setState({ loadingTemplete: false });
        console.log(response);
        window.open(`${URLS.STUDENT_DOWNLOAD}?${query}`);
      });
    } catch (error) {
      this.setState({ loadingStudent: false });
      this.setState({ loadingTemplete: false });
      console.log('Error of data', error);
    }
  };

  onSelectFile = async (e) => {
    try {
      const formData = new FormData();
      let file = e.target.files[0];
      formData.append('files', file);
      e.target.value = '';
      // // Details of the uploaded file
      this.props.onAddStudentsExcel(formData);
    } catch (e) {
      return e;
    }
  };

 
  render() {
    const { students, user, classes, isStatusChangeInProcess } = this.props;
    const {
      pg,
      studentDetails,
      isOpen,
      id,
      status,
      loadingStudent,
      loadingTemplete   
    } = this.state;
    return (
      <div className="row clearfix">
        <div className="col-lg-12">
          <Modal
            isOpen={isOpen}
            student={studentDetails}
            isClose={this.ModalToggler}
          />
          <ConfirmationDialog
            isDelete={isStatusChangeInProcess}
            isOpen={id && status}
            deleteHandler={() => this.changeStatusHandler()}
            closeHandler={() => this.confirmationDialogBox(null, null)}
          />
          <Card className="c-style">
            <CardHeader className="h-style">
              <div className="row m-r-0 m-l-0">
                <div className="c-title-style">Students</div>
                {user.userRole === 2 && (
                  <div className="ml-auto btn-style">
                    <input
                      // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      type="file"
                      ref={this.input}
                      onChange={this.onSelectFile}
                      style={{ display: 'none' }}
                    />
                    <Link
                      class="btn btn-outline-dark actionsButtons"
                      onClick={() => this.handleExport('student')}
                      to={{ pathname: '/admin/students' }}
                    >
                      {loadingStudent && (
                        <Spinner size="sm" style={{ marginRight: 5 }}></Spinner>
                      )}
                      Export Students
                    </Link>
                    <Link
                      class="btn btn-outline-dark actionsButtons"
                      onClick={() => this.handleExport('template')}
                      to={{ pathname: '/admin/students' }}
                    >
                      {loadingTemplete && (
                        <Spinner size="sm" style={{ marginRight: 5 }}></Spinner>
                      )}
                      Export Template
                    </Link>
                    <Link
                      className="btn btn-outline-dark actionsButtons"
                      onClick={(e) =>
                        this.input.current && this.input.current.click()
                      }
                      to={{ pathname: '/admin/students' }}
                    >
                      Import Students
                    </Link>
                    <Link
                      class="btn btn-outline-dark actionsButtons"
                      to={{ pathname: '/admin/students/add' }}
                    >
                      Add
                    </Link>
                  </div>
                )}
              </div>
            </CardHeader>
            <CardBody>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="row mb-2">
                  <div class="d-flex ml-3 mr-3">
                    <select
                      class="form-control show-tick ms select2"
                      data-placeholder="Select"
                      onChange={this.classChangeHandle}
                      value={this.state.selectedClass}
                    >
                      <option value="">Select Class</option>
                      {user.userRole === 3
                        ? user.userclasses.map((c) => {
                            return (
                              <option
                                value={c.classId}
                              >{`${c.class.standard.standardName}-${c.class.className}, ${c.class.medium}`}</option>
                            );
                          })
                        : classes.data.map((c) => {
                            return (
                              <option
                                value={c.id}
                              >{`${c.standard.standardName}-${c.className}, ${c.medium}`}</option>
                            );
                          })}
                    </select>
                  </div>

                  {user.userRole === 3 ? null : (
                    <div className="row">
                      <div class="d-flex ml-3">
                        <select
                          class="form-control show-tick ms select2"
                          data-placeholder="Select"
                          onChange={this.statusChangeHandler}
                          value={this.state.selectedStatus}
                        >
                          <option value="">Select Status</option>
                          <option>ACTIVE</option>
                          <option>INACTIVE</option>
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-lg-12 d-flex pl-5">
                      {/* <div style={{ marginTop: 7, marginRight: 5 }}>Search:</div> */}
                      <label>
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search here..."
                          aria-controls="example"
                          value={this.state.searchName}
                          onChange={this.searchStudentHandler}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                {this.props.excelLoading&&
                <div style={{ display: 'flex' }}>
                  <span
                    style={{
                      fontSize: 15,
                      color: 'black',
                      marginRight: '5px',
                    }}
                  >
                    {' '}
                    Importing students
                  </span>
                  <ThreeDots
                    width="40"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ marginTop: -28 }}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>}
              </div>
              <Table className="table-responsive-sm table-responsive-md " hover>
                <thead className="table-header-wrapper">
                  <tr>
                    <th>#</th>
                    <th>Profile Pic</th>
                    <th>RollNumber</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Gender</th>
                    <th>GrNumber</th>
                    <th>Class</th>
                    <th>{user.userRole === 4 ? null : 'Status'}</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.props.loading &&
                    !this.props.error &&
                    students.data.map((p, index) => {
                      return (
                        <tr>
                          <th>
                            <strong>{(pg - 1) * 10 + index + 1}</strong>
                          </th>
                          <td>
                            <ImageModal
                              alt="."
                              className="image img-fluid"
                              src={p.profilePic ? p.profilePic : Profile}
                            />
                          </td>
                          <td>{p.rollNumber}</td>
                          <td>{p.firstName}</td>
                          <td>{p.lastName}</td>
                          <td>{p.gender}</td>
                          <td>{p.grNumber}</td>
                          <td>{`${p.class.standard.standardName}-${p.class.className}, ${p.class.medium}`}</td>
                          <td>
                            {user.userRole === 4 ? null : (
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={p.status === 'ACTIVE'}
                                  onClick={() =>
                                    this.confirmationDialogBox(
                                      p.id,
                                      p.status === 'INACTIVE'
                                        ? 'ACTIVE'
                                        : 'INACTIVE'
                                    )
                                  }
                                />
                                <span class="slider round"></span>
                              </label>
                            )}
                          </td>
                          <td>
                            <div className="d-flex">
                              {user.userRole === 2 && (
                                <Link
                                  className="p-2"
                                  to={{
                                    pathname: '/admin/students/add',
                                    state: { students: p },
                                  }}
                                >
                                  <i
                                    class="icon-pencil"
                                    style={{ color: 'black' }}
                                    aria-hidden="true"
                                  />
                                </Link>
                              )}
                              <span>
                                <i
                                  class="fa fa-info-circle"
                                  style={{
                                    color: 'black',
                                    fontSize: '20px',
                                    paddingTop: '8px',
                                    paddingLeft: '10px',
                                  }}
                                  onClick={() => this.ModalToggler(p)}
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              {this.props.loading ? (
                <div className="loader-wrapper">
                  <ClipLoader size={35} color={'#123abc'} loading={true} />
                </div>
              ) : (
                <div className="d-flex justify-content-end p-2">
                  {students.data.length > 0 && students.pagination && (
                    <Pagination className="pull-right">
                      <PaginationItem>
                        <PaginationLink
                          onClick={() =>
                            this.state.pg > 1 &&
                            this.paginationChangeHandler(
                              'pg',
                              this.state.pg - 1
                            )()
                          }
                          previous
                          tag="button"
                        />
                      </PaginationItem>
                      {this.renderPagination(students.pagination.pages)}
                      <PaginationItem>
                        <PaginationLink
                          onClick={() =>
                            students.pagination.pages > this.state.pg &&
                            this.paginationChangeHandler(
                              'pg',
                              this.state.pg + 1
                            )()
                          }
                          next
                          tag="button"
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </div>
              )}
              {!this.props.loading &&
                (this.props.error || !students.data.length) && (
                  <div className="error-style">
                    <Alert color="danger">
                      {this.props.error ? this.props.error : students.message}
                    </Alert>
                  </div>
                )}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    students: state.students.students,
    currentPage: state.students.currentPage,
    error: state.students.getError,
    loading: state.students.isLoading,
    excelLoading:state.students.excelLoading,
    isStatusChangeInProcess: state.students.isStatusChangeInProcess,
    user: state.login.user,
    classes: state.class.classes,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    onAddStudentsExcel: (data) => dispatch(action.addStudentsExcel(data)),
    onGetStudents: (pg, classId, searchName, status) =>
      dispatch(action.getStudents(pg, classId, searchName, status)),
    onDeleteStudents: () => dispatch(action.deleteStudents()),
    onStudentStatusChange: (id, status) =>
      dispatch(action.studentStatusChange(id, status)),
    onGetClass: (pg) => dispatch(getClass(pg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(Students);
