import * as actionTypes from '../action/Result';


const initialState = {
  results: {
    data: [],
    pagination: null,
    filepath: '',
  },
  getError: '',
  addError: '',
  currentPage: 1,
  isLoading: false,
  sampleLoading: false,
  isExportResultLoading:false
};

const resultReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SAMPLE_RESULT:
      return {
        ...state,
        sampleLoading: false,
        results: {
          ...state.results,
          filepath: action.filepath
        },
        addError: ''
      }

      case actionTypes.SET_EXPORT_RESULT_LOADING:
        return {
            ...state,
            isExportResultLoading:true,
            addError: '',
            getError: ''
        }
    case actionTypes.SAMPLE_RESULT_ERROR:
      return {
        ...state,
        sampleLoading: false,
        addError: action.message
      }

    case actionTypes.ADD_RESULT:
      const total = state.results.pagination && state.results.pagination.total + 1;
      let pages = state.results.pagination && state.results.pagination.pages;
      if (total / 10 > pages) {
        pages += 1;
      }
      return {
        ...state,
        isLoading: false,
        results: {
          ...state.results,
          pagination: { ...state.results.pagination, total, pages },
          data: [...state.results.data, action.result]
        },
        addError: ''
      };

    case actionTypes.ADD_RESULT_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }

    case actionTypes.UPDATE_RESULT:
      const subjectId = action.result.subjectresultId;
      const resultIndex = state.results.data[0].results.findIndex(a => a.id === subjectId);
      const result = state.results.data[0].results[resultIndex].subjectresults[0];
      result.marks = action.result.marks;
      return {
        ...state,
        isLoading: false,
        addError: '',
        results: { ...state.results, data: Object.assign(state.results.data, { ...result }) },
      }

    case actionTypes.UPDATE_RESULT_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.error
      }

    case actionTypes.GET_RESULT:
      return {
        ...state,
        results: action.result,
        currentPage: action.pg || 1,
        isLoading: false,
        isExportResultLoading:false,
        getError: ''
      }

    case actionTypes.GET_RESULT_ERROR:
      return {
        ...state,
        isLoading: false,
        getError: action.message,
        isExportResultLoading:false,
      }

    case actionTypes.DELETE_RESULT:
      return {
        ...state,
        isLoading: false,
        getError: '',
      }

    case actionTypes.DELETE_RESULT_ERROR:
      return {
        ...state,
        isLoading: false,
        getError: action.message
      }

    case actionTypes.SET_LOADING_RESULT:
      return {
        ...state,
        isLoading: true, getError: '', addError: ''
      }

    case actionTypes.SET_LOADING_SAMPLE:
      return {
        ...state,
        sampleLoading: true, getError: '', addError: ''
      }
    default:
      return state;
  }
}

export default resultReducer