import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
} from "reactstrap";
import * as action from "../../../Store/action/Attendance";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import * as Yup from "yup";
import { Formik } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";

const SignupSchema = Yup.object().shape({
  // studentName: Yup.string()
  //   .matches(/^[A-Za-z]+$/, 'Enter Only Alphabetic')
  //   .required('studentName is Required'),
  // rollNo: Yup.string()
  //   .required('rollNo is required'),
  status: Yup.string().required("attendance is required"),
});

class AddAttendance extends Component {
  state = {
    studentName: "",
    rollNo: "",
    attendance: "",
    classId: "",
  };

  componentDidMount() {
    const { attendances } = this.props.location.state || {};
    if (attendances) {
      this.setState({
        classId: attendances.id,
        studentName: attendances.student.firstName,
        rollNo: attendances.student.rollNumber,
        attendance: attendances.status,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push("/admin/attendance");
    } else if (nextProps.error && !this.props.error) {
      console.log("Error",nextProps.error)
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  }

  submitHandler = (values) => {
    if (this.state.classId) {
      this.props.onUpdateAttendance([values]);
    }
  };

  render() {
    const attendanceOptions = [
      { label: "P", value: "P" },
      { label: "A", value: "A" },
      { label: "L", value: "L" },
    ];
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            // studentName: this.state.studentName,
            // rollNo: this.state.rollNo,
            status: this.state.attendance,
            id: this.state.classId,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">
                {"Update Attendance"}
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>studentName</Label>
                  <Input
                    type="studentName"
                    readOnly={true}
                    invalid={errors.studentName && touched.studentName}
                    name="studentName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={this.state.studentName}
                    placeholder="Enter studentName"
                  />
                  <FormFeedback>
                    {errors.studentName &&
                      touched.studentName &&
                      errors.studentName}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>rollNo</Label>
                  <Input
                    type="rollNo"
                    readOnly={true}
                    invalid={errors.rollNo && touched.rollNo}
                    name="rollNo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={this.state.rollNo}
                    placeholder="Enter rollNo"
                  />
                  <FormFeedback>
                    {errors.rollNo && touched.rollNo && errors.rollNo}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>Attendance</Label>
                  <Select
                    onChange={(value) => setFieldValue("status", value.value)}
                    onBlur={handleBlur}
                    className="is-invalid"
                    value={
                      attendanceOptions
                        ? attendanceOptions.find(
                            (s) => s.value === values.status
                          )
                        : ""
                    }
                    options={attendanceOptions}
                  />
                  <FormFeedback>
                    {errors.attendance &&
                      touched.attendance &&
                      errors.attendance}
                  </FormFeedback>
                </FormGroup>

                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                    disabled={this.props.loading}
                  >
                    {this.props.loading ? (
                      <ClipLoader size={15} color={"#123abc"} loading={true} />
                    ) : (
                      "Save"
                    )}
                  </button>
                  <Link
                    className="btn btn-secondary"
                    to={{ pathname: "/admin/attendance" }}
                  >
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.attendance.isLoading,
    error: state.attendance.addError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateAttendance: (attendance) =>
      dispatch(action.updateAttendance(attendance)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAttendance);
