import Axios from "axios";
import { URLS } from "./URLS";

export const getHomeworks = data => Axios.post(URLS.GET_HOMEWORK, data);

// export const addHomework = data => Axios.post(URLS.ADD_HOMEWORK, data)

export const addHomework = data => {
  const form = new FormData();
  form.append('title', data.title);
  form.append('classId', data.classId);
  form.append('subjectId', data.subjectId);
  form.append('description', data.description);
  form.append('startDate', data.startDate);
  form.append('deadlineDate', data.deadlineDate);
  data.document.forEach((p, i) => {
    form.append(`document[${i}]`, p)
  });
  // form.append('document', data.document)
  return Axios.post(URLS.ADD_HOMEWORK, form);
}

export const updateHomework = data => {
  const form = new FormData();
  form.append('classId', data.classId);
  form.append('subjectId', data.subjectId);
  form.append('homeworkId', data.homeworkId);
  form.append('description', data.description);
  form.append('title', data.title);
  form.append('startDate', data.startDate);
  form.append('deadlineDate', data.deadlineDate);
  data.document.forEach((p, i) => {
    form.append(`document[${i}]`, p)
  });
  // form.append('document', data.document)
  return Axios.post(URLS.UPDATE_HOMEWORK, form)
}