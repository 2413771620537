import React, { Component } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { connect } from 'react-redux';
import './Navbar.css'
import Logo from '../../assets/images/logo/ic_launcher.png';
import { getSchool } from '../../Store/action/School';
import * as authActions from '../../Store/action/Auth/login'
import Profile from "../../assets/images/user-small.png"

class Navbar extends Component {
	state = {
		prevState: false
	}

	logoutHandler = () => {
		localStorage.clear();
		this.props.onLogout();
		this.props.history.push('/signin');
	}

	toggle = () => {
		this.setState({ prevState: !this.state.prevState })
	}

	onMouseEnter = () => {
		this.setState({ prevState: true });
	}

	onMouseLeave = () => {
		this.setState({ prevState: false });
	}

	menuClickHandler = () => {
		document.body.classList.toggle('offcanvas-active');
	}

	componentDidMount() {
		// this.props.getSchools();
	}

	render() {
		const { user } = this.props;
		return (
			<div id="wrapper">
				<nav className="navbar navbar-fixed-top">
					<div className="container-fluid">
						<div className="navbar-btn">
							<button
								type="button"
								className="btn-toggle-offcanvas"
								onClick={this.menuClickHandler}
							>
								<i className="lnr lnr-menu fa fa-bars"></i>
							</button>
						</div>
						<div className="row m-r-0 m-l-0">
							<div>
								<img
									src={Logo}
									alt="School Diary"
									style={{ width: "51px", height: "51px" }}
								/>
							</div>
							<div className="d-flex justify-content-center align-items-center ">
								<div className="row">
									<div className="col-12">
										<h6 className="school-title-style mb-0 ml-1">{`School Diary - ${user.userRole === 1
												? "Super Admin"
												: user.userRole === 2
													? "Principal Admin"
													: "Teacher Admin"
											}`}</h6>
									</div>
									{(user.userRole === 3 || user.userRole === 2) && (
										<div className="col-12">
											<h1 className="school-sub-title-style mb-0 ml-1">
												{user.school.schoolName}
											</h1>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="navbar-right align-self-baseline">
							<div id="navbar-menu">
								<ul className="nav navbar-nav">
									<Dropdown isOpen={this.state.prevState} toggle={this.toggle}>
										<DropdownToggle tag="button" type="button" className="btn">
											<img
												src={user.profilePic ? user.profilePic : Profile}
												style={{ width: "40px", height: "40px" }}
												// className="rounded-circle user-photo"
												alt=""
											/>
										</DropdownToggle>
										<DropdownMenu className="p-1">
											<DropdownItem
												onClick={() =>
													this.props.history.push("/admin/profile", { user })
												}
											>
												{this.props.profileName}
											</DropdownItem>
											<DropdownItem divider />
											<DropdownItem onClick={this.logoutHandler}>
												logout
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</ul>
							</div>
						</div>
					</div>
				</nav>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		logout: state.login.user,
		profileName: state.login.user.firstName + " " + state.login.user.lastName,
		user: state.login.user,
		schools: state.school.schools,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLogout: () => dispatch(authActions.logout()),
		getSchools: () => dispatch(getSchool()),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
