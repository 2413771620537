import Axios from "axios";
import { URLS } from "./URLS";

export const getResult = data => Axios.post(URLS.GET_RESULT, data);
export const addResult = data => {
    const form = new FormData();
    form.append('examTimeTableId', data.examTimeTableId);
    data.document.forEach((p, i) => {
        form.append(`result`, p)
    });
    return Axios.post(URLS.ADD_RESULT, form)
};
export const updateResult = data => {
    const form = new FormData();
    form.append('subjectresultsId', data.subjectresultId);
    form.append('examTimeTableId', data.examTimeTableId);
    form.append('marks', data.marks);
    return Axios.post(URLS.UPDATE_RESULT, form)
};
export const deleteResult = data => Axios.post(URLS.DELETE_RESULT, data);
export const sampleResult = data => Axios.post(URLS.SAMPLE_RESULT, data);