import * as actionTypes from '../action/School';

const initialState = {
    schools: {
        data: [],
        pagination: null
    },
    addError: '',
    getError: '',
    currentPage: 1,
    isLoading: false
};

const schoolReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.ADD_SCHOOL:
            const total = state.schools.pagination && state.schools.pagination.total + 1;
            let pages = state.schools.pagination && state.schools.pagination.pages;
            if (total / 10 > pages) {
                pages += 1;
            }
            return {
                ...state,
                isLoading: false,
                addError: '',
                schools: {
                    ...state.schools,
                    pagination: { ...state.schools.pagination, total, pages },
                    data: [...state.schools.data, action.school.data]
                }
            };

        case actionTypes.ADD_SCHOOL_ERROR:
            return {
                ...state,
                isLoading: false,
                addError: action.message
            }

        case actionTypes.UPDATE_SCHOOL:
            const schoolId = action.school.data.id;
            const schoolIndex = state.schools.data.findIndex(a => a.id === schoolId);
            const school = state.schools.data[schoolIndex];
            school.status = action.school.data.status;
            // const classIndex = state.schools.data.findIndex(a => a.id === action.schools.data.id)
            return {
                ...state,
                isLoading: false,
                isStatusChangeInProcess: false,
                addError: '',
                schools: { ...state.schools, data: Object.assign(state.schools.data, { [schoolIndex]: school ? school : action.school.data }) }
            }

        case actionTypes.UPDATE_SCHOOL_ERROR:
            return {
                ...state,
                isLoading: false,
                addError: action.message,
                isStatusChangeInProcess: false,
            }

        case actionTypes.SET_LOADING_STATUS:
            return {
                ...state,
                isStatusChangeInProcess: true,
                isLoading: false,
                addError: '',
                getError: '',
            }

        case actionTypes.GET_SCHOOL:
            return {
                ...state,
                schools: action.school,
                currentPage: action.pg || 1,
                isLoading: false,
                getError: ''
            }

        case actionTypes.SET_LOADING_SCHOOL:
            return {
                ...state,
                isLoading: true,
                getError: '',
                addError: ''
            }

        case actionTypes.GET_SCHOOL_ERROR:
            return {
                ...state,
                isLoading: false,
                getError: action.message,
            }
        default:
            return state;
    }
}

export default schoolReducer
