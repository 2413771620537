import * as authActions from '../../action/Auth/forgotPassword'


const initialState = {
    error: '',
    forgotLoading: false
}

const forgotPassword = (state = initialState, action) => {
  
    switch (action.type) {
        case authActions.FORGOT_PASSWORD_ERROR:
            return{
                ...state,
                forgotLoading: false,
                error: action.error
            }
        case authActions.FORGOT_PASSWORD:
            return{
                ...state,
                forgotLoading: false,
                message: action.forgotPassword
            }
        case authActions.SET_LOADING_FORGOT_PASSWORD:
            return {
                ...state,
                forgotLoading: true,
                error: ''
            };   
        default:
            return state;

    }
}

export default forgotPassword;