import * as actionTypes from '../action/Event';

const initialState = {
  events: {
    data: [],
    pagination: null
  },
  addError: '',
  getError: '',
  uploadError: '',
  currentPage: 1,
  isLoading: false,
  isPhotosUploading: false
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.ADD_EVENT:
      const total = state.events.pagination && state.events.pagination.total + 1;
      let pages = state.events.pagination && state.events.pagination.pages;
      if (total / 10 > pages) {
        pages += 1;
      }
      return {
        ...state,
        isLoading: false,
        addError: '',
        events: {
          ...state.events,
          pagination: { ...state.events.pagination, total, pages },
          data: [...state.events.data, action.event.data]
        }
      };

    case actionTypes.ADD_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }

    case actionTypes.UPDATE_EVENT:
      const eventIndex = state.events.data.findIndex(a => a.id === action.event.eventId);
      return {
        ...state,
        isLoading: false,
        addError: '',
        events: { ...state.events, data: Object.assign(state.events.data, { [eventIndex]: action.event }) }
      }

    case actionTypes.UPDATE_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }

    case actionTypes.GET_EVENT:
      return {
        ...state,
        events: action.event,
        currentPage: action.pg || 1,
        isLoading: false,
        getError: ''
      }

    case actionTypes.UPLOAD_EVENT_FILE:
      return {
        ...state,
        isPhotosUploading: false,
        uploadError: ''
      }

    case actionTypes.SET_LOADING_EVENT:
      return {
        ...state,
        isLoading: true,
        getError: '',
        addError: '',
        uploadError: ''
      }
    case actionTypes.SET_PHOTO_UPLOADING_EVENT:
      return {
        ...state,
        isPhotosUploading: true,
        uploadError: ''
      }
    default:
      return state;
  }
}

export default eventReducer