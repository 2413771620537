import * as actionTypes from '../action/holiday';

const initialState = {
  holidays: {
    data: [],
    pagination: null
  },
  addError: '',
  getError: '',
  currentPage: 1,
  isLoading: false
};

const holidaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_HOLIDAY:
      const total = state.holidays.pagination && state.holidays.pagination.total + 1;
      let pages = state.holidays.pagination && state.holidays.pagination.pages;
      if (total / 10 > pages) {
        pages += 1;
      }
      return {
        ...state,
        isLoading: false,
        holidays: {
          ...state.holidays,
          pagination: { ...state.holidays.pagination, total, pages },
          data: [...state.holidays.data, action.holiday.data]
        },
        addError: ''
      };

    case actionTypes.ADD_HOLIDAY_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }

    case actionTypes.UPDATE_HOLIDAY:
      const holidayIndex = state.holidays.data.findIndex(a => a.id === action.holiday.data.id);
      return {
        ...state,
        isLoading: false,
        addError: '',
        holidays: { ...state.holidays, data: Object.assign(state.holidays.data, { [holidayIndex]: action.holiday.data }) }
      }

    case actionTypes.UPDATE_HOLIDAY_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }


    case actionTypes.DELETE_HOLIDAY:
      return {
        ...state,
        isLoading: false,
        addError: '',
      }

    case actionTypes.DELETE_HOLIDAY_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }


    case actionTypes.GET_HOLIDAY:
      return {
        ...state,
        holidays: action.holiday,
        currentPage: action.pg ||1, 
        isLoading: false,
        getError: ''
      }

    case actionTypes.SET_LOADING_HOLIDAY:
      return {
        ...state,
        isLoading: true, getError: '', addError: ''
      }
    default:
      return state;
  }
}

export default holidaysReducer