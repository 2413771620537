const BASE_URL = 'https://schooldiarybackend-production.up.railway.app/api/v1';
// const BASE_URL = 'https://schooldiarybackend-development.up.railway.app/api/v1';
// const BASE_URL = 'http://localhost:8081/api/v1';
export const URLS = {
  AUTH: BASE_URL + '/user/adminLogin',
  RESET_PASSWORD: BASE_URL + '/user/resetpassword',

  TEACHER_AUTH: BASE_URL + '/user/login',
  TEACHER_UPDATE: BASE_URL + '/user/update',
  USER_PROFILE: BASE_URL + '/user/profile ',

  CLASS: BASE_URL + '/class/create',
  GET_CLASS: BASE_URL + '/class/classList',
  UPDATE_CLASS: BASE_URL + '/class/update',
  CLASS_STATUS_CHANGE: BASE_URL + '/class/statusChange',

  GET_SUBJECT: BASE_URL + '/subject/subjectList',
  ADD_SUBJECT: BASE_URL + '/subject/create',
  UPDATE_SUBJECT: BASE_URL + '/subject/update',
  SUBJECT_STATUS_CHANGE: BASE_URL + '/subject/statusChange',

  TEACHER: BASE_URL + '/user/create',
  UPDATE_TEACHER: BASE_URL + '/user/update',
  GET_TEACHER: BASE_URL + '/user/userList',
  TEACHER_STATUS_CHANGE: BASE_URL + '/user/statusChange',

  ADD_STUDENTS: BASE_URL + '/student/create',
  ADD_STUDENTS_EXCEL: BASE_URL + '/student/excelcreate',
  GET_STUDENTS: BASE_URL + '/student/list',
  UPDATE_STUDENTS: BASE_URL + '/student/update',
  STUDENT_STATUS_CHANGE: BASE_URL + '/student/statusChange',

  GET_HOMEWORK: BASE_URL + '/homework/list',
  ADD_HOMEWORK: BASE_URL + '/homework/create',
  UPDATE_HOMEWORK: BASE_URL + '/homework/update',

  ADD_STANDARD: BASE_URL + '/standard/create',
  GET_STANDARD: BASE_URL + '/standard/standardList',
  UPDATE_STANDARD: BASE_URL + '/standard/update',
  STANDARD_STATUS_CHANGE: BASE_URL + '/standard/statusChange',

  ADD_EVENT: BASE_URL + '/event/create',
  GET_EVENT: BASE_URL + '/event/list',
  UPDATE_EVENT: BASE_URL + '/event/update',
  UPLOAD_EVENT_FILE: BASE_URL + '/event/uploadeventfile',

  GET_ATTENDANCE: BASE_URL + '/attendance/list',
  UPDATE_ATTENDANCE: BASE_URL + '/attendance/update',

  GET_LEAVE: BASE_URL + '/leave/list',
  UPDATE_LEAVE: BASE_URL + '/leave/update',

  GET_TEACHER_LEAVE: BASE_URL + '/leave/list',
  ADD_TEACHER_LEAVE: BASE_URL + '/leave/teacherapplyleave',
  UPDATE_TEACHER_LEAVE: BASE_URL + '/leave/update',

  PRINCIPAL_GET_TEACHER_LEAVE: BASE_URL + '/leave/list',
  PRINCIPAL_UPDATE_TEACHER_LEAVE: BASE_URL + '/leave/update',

  GET_HOLIDAY: BASE_URL + '/holiday/list',
  ADD_HOLIDAY: BASE_URL + '/holiday/create',
  UPDATE_HOLIDAY: BASE_URL + '/holiday/update',
  DELETE_HOLIDAY: BASE_URL + '/holiday/delete',

  GET_BREAKFAST: BASE_URL + '/breakfast/list',
  ADD_BREAKFAST: BASE_URL + '/breakfast/create',
  UPDATE_BREAKFAST: BASE_URL + '/breakfast/update',
  DELETE_BREAKFAST: BASE_URL + '/breakfast/delete',

  GET_DAILY_ACTIVITY_TIME_TABLE: BASE_URL + '/dailytimetable/list',
  ADD_DAILY_ACTIVITY_TIME_TABLE: BASE_URL + '/dailytimetable/create',
  UPDATE_DAILY_ACTIVITY_TIME_TABLE: BASE_URL + '/dailytimetable/update',
  DELETE_DAILY_ACTIVITY_TIME_TABLE: BASE_URL + '/dailytimetable/delete',

  GET_RESULT: BASE_URL + '/student/getresult',
  ADD_RESULT: BASE_URL + '/student/addstudentresult',
  DELETE_RESULT: BASE_URL + '/student/deleteStudentResult',
  UPDATE_RESULT: BASE_URL + '/student/updateStudentResult',
  SAMPLE_RESULT: BASE_URL + '/student/sampleresult',

  GET_EXAM_TYPE: BASE_URL + '/examtype/list',
  ADD_EXAM_TYPE: BASE_URL + '/examtype/create',
  UPDATE_EXAM_TYPE: BASE_URL + '/examtype/update',

  GET_EXAM_TIMETABLE: BASE_URL + '/examtimetable/list',
  ADD_EXAM_TIMETABLE: BASE_URL + '/examtimetable/create',
  UPDATE_EXAM_TIMETABLE: BASE_URL + '/examtimetable/update',
  DELETE_EXAM_TIMETABLE: BASE_URL + '/examtimetable/delete',

  FORGOT_PASSWORD: BASE_URL + '/user/forgot-password',

  GET_SCHOOL: BASE_URL + '/school/schoolList',
  ADD_SCHOOL: BASE_URL + '/school/create',
  UPDATE_SCHOOL: BASE_URL + '/school/update',

  STUDENT_EXPORT: BASE_URL + '/student/exportdata',
  STUDENT_DOWNLOAD: BASE_URL + '/student/download',

  GET_DRAFTS: BASE_URL + '/draftUser/drafts',
  ADD_DRAFTS: BASE_URL + '/draftUser/create',

  TEACHER_EXPORT: BASE_URL + '/user/exportdata',
  TEACHER_DOWNLOAD: BASE_URL + '/user/download',

  ATTENDANCE_DOWNLOAD:BASE_URL + '/attendance/download',
  RESULT_DOWNLOAD:BASE_URL + '/student/result/download',
  EXAMTIMETABLE_DOWNLOAD:BASE_URL + '/examtimetable/download',
  DAILYACTIVITYTIMETABLE_DOWNLOAD:BASE_URL + '/dailytimetable/download',
};



