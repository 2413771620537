import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class ImageModal extends Component {
	state = {
		isOpen: false
	}

  render() {
    const { src } = this.props;
    return (
      <React.Fragment>
        <img
          alt="."
          className="image img-fluid"
          style={{ width: 30, height: 30 }}
          src={src}
          onClick={() => this.setState({ isOpen: true })}
        />
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader>Image</ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <img alt="" src={src} style={{ height: "50vh", width: "100%" }} />
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button
              color="secondary"
              onClick={() => this.setState({ isOpen: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ImageModal;
