import {
  forgotPassword
} from '../../../api/auth'
import {
  getErrorMessage
} from '../../../utils/utils';
import { toast } from 'react-toastify';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_LOADING_FORGOT_PASSWORD = 'SET_LOADING_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const forgotPasswordSuccess = (forgotPassword) => {
  return {
    type: FORGOT_PASSWORD,
    forgotPassword
  }
}

export const forgotPasswordError = (error) => {
  return {
    type: FORGOT_PASSWORD_ERROR,
    error
  }
}

export const forgotPasswords = (data) => {
  return dispatch => {
    dispatch({
      type: SET_LOADING_FORGOT_PASSWORD
    });
    forgotPassword(data)
      .then(response => {
        dispatch(forgotPasswordSuccess(response.data.data));
        toast.POSITION.BOTTOM_RIGHT('a')
      })
      .catch(err => {
        // toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
				// 	position: toast.POSITION.TOP_RIGHT,
				// });
        dispatch(forgotPasswordError(getErrorMessage(err)))
      })
  }
}