import React, { Component } from 'react'
import { Card, Table, CardBody, CardHeader, Alert, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from "react-spinners";
import { getExamType} from '../../../Store/action/ExamTimeTable';
import ConfirmationDialog from '../../ConfirmDialog/DeleteConfirmDialog';
import { Link } from 'react-router-dom';

class ExamType extends Component {
  state = {
    pg: 1,
  }

  componentDidMount() {
    if (!this.props.examType.data.length) {
      this.props.onGetExamType(1);
    }
  }

  // confirmationDialogBox = (deleteEvent) => {
  //   this.setState({
  //     deleteEvent,
  //   });
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.deleteLoading && !nextProps.deleteLoading && !nextProps.error) {
  //     this.props.onGetExamTimeTable(this.state.pg, 'Active');
  //   }
  // }

  // deleteHandler = (ec) => {
  //   this.setState({ deleteEvent: null });
  //   this.props.onUpdateExamTimeTable({ examTypeId: ec.examTypeId, examTimeTableId: ec.id, subjectId: ec.subjectId, classId: ec.classId, status: 'Inactive' });
  // }

  paginationChangeHandler = (pg, value) => () => {
    this.props.onGetExamType(value);
    this.setState({ pg: value });
  }

  renderPagination(pagination) {
    const pages = [];
    for (let i = 1; i <= pagination; i++) {
      pages.push(
        <PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
          <PaginationLink tag="button">{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  }

  render() {
    const { examType, loading, error } = this.props;
    const { pg } = this.state;
    return (
      <div className="row clearfix">
        <div className="col-lg-12">

          <ConfirmationDialog
            isOpen={!!this.state.deleteEvent}
            deleteHandler={() => this.deleteHandler(this.state.deleteEvent)}
            closeHandler={() => this.confirmationDialogBox(null)} />
          <Card className="c-style">
            <CardHeader className="h-style">
              <div className="row m-r-0 m-l-0">
                <div className="c-title-style">
                  Exam Type
                      </div>
                <div className="ml-auto btn-style">
                  <Link class="btn btn-outline-dark" to={{ pathname: '/admin/addExamType' }}>Add</Link>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Table className="table-responsive-sm table-responsive-md " hover>
                <thead className='table-header-wrapper'>
                  <tr>
                    <th>#</th>
                    <th>Exam Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && !error &&
                    examType.data.map((p, index) => {
                      return (
                        <tr>
                          <td><strong>{(pg - 1) * 10 + index + 1}</strong></td>
                          <td>{p.name}</td>
                          <td>
                            <div className="row">
                              <Link className="p-2" to={{ pathname: "/admin/addExamType", state: { examType: p } }}><i class="icon-pencil" style={{ color: 'black' }} aria-hidden="true"></i></Link>
                              {/* <span className="p-2 ml-3" onClick={() => this.confirmationDialogBox(p)}><i class="icon-trash" aria-hidden="true"></i></span> */}
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>

              {loading
                ?
                <div className="loader-wrapper">
                  <ClipLoader
                    size={35}
                    color={"#123abc"}
                    loading={true}
                  />
                </div>
                :
                <div className="d-flex justify-content-end p-2">
                  {examType.data.length > 0 && examType.pagination &&
                    <Pagination className="pull-right">
                      <PaginationItem>
                        <PaginationLink
                          onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()} previous tag="button" />
                      </PaginationItem>
                      {this.renderPagination(examType.pagination.pages)}
                      <PaginationItem>
                        <PaginationLink
                          onClick={() => examType.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()} next tag="button" />
                      </PaginationItem>
                    </Pagination>
                  }
                </div>
              }
              {!this.props.loading && (this.props.error || !examType.data.length) &&
                <div className="error-style">
                  <Alert color="danger">{this.props.error ? this.props.error : examType.message}</Alert>
                </div>}
            </CardBody>
          </Card>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPage: state.examTimeTable.currentPage,
    error: state.examTimeTable.getError,
    examType: state.examTimeTable.examTypes,
    loading: state.examTimeTable.isGetExamLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetExamType: (pg) => dispatch(getExamType(pg)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExamType)
