import * as actionTypes from '../action/userProfile';

const initialState = {
	userProfile: {
		data: [],
		pagination: null,
	},
	error: '',
	isLoading: false,
};

const userProfile = (state = initialState, action) => {
	switch (action.type) {

		case actionTypes.GET_USER_PROFILE:
			return {
				...state,
				isLoading: false,
				userProfile: action.userProfile,
				error: '',
			};

		case actionTypes.GET_USER_PROFILE_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.message,
			};
		default:
			return state;
	}

};

export default userProfile;
