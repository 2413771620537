import Axios from "axios";
import { URLS } from "./URLS";

export const getSchools = (data) => Axios.post(URLS.GET_SCHOOL, data);
export const addSchools = (data) => {
    const form = new FormData();
    form.append('schoolName', data.schoolName);
    form.append('address', data.address);
    form.append('city', data.city);
    form.append('logo', data.logo);
    return Axios.post(URLS.ADD_SCHOOL, form)
};
export const updateSchools = (data) => {
    const form = new FormData();
    form.append('schoolId', data.schoolId);
    form.append('status', data.status);
    form.append('schoolName', data.schoolName);
    form.append('address', data.address);
    form.append('city', data.city);
    form.append('logo', data.logo);
    return Axios.post(URLS.UPDATE_SCHOOL, form)
};