import { toast } from 'react-toastify';
import * as api from '../../api/Result';
import { URLS } from '../../api/URLS';
import { getErrorMessage } from '../../utils/utils';
export const GET_RESULT = 'GET_RESULT'
export const GET_RESULT_ERROR = 'GET_RESULT_ERROR'
export const ADD_RESULT = 'ADD_RESULT'
export const ADD_RESULT_ERROR = 'ADD_RESULT_ERROR'
export const UPDATE_RESULT = 'UPDATE_RESULT'
export const UPDATE_RESULT_ERROR = 'UPDATE_RESULT_ERROR'
export const DELETE_RESULT = 'DELETE_RESULT'
export const DELETE_RESULT_ERROR = 'DELETE_RESULT_ERROR'
export const SAMPLE_RESULT = 'SAMPLE_RESULT'
export const SAMPLE_RESULT_ERROR = 'SAMPLE_RESULT_ERROR'
export const SET_LOADING_RESULT = 'SET_LOADING_RESULT'
export const SET_LOADING_SAMPLE = 'SET_LOADING_SAMPLE'
export const SET_EXPORT_RESULT_LOADING='SET_EXPORT_RESULT_LOADING'

export const getResultSuccess = (result, pg) => {
  return {
    type: GET_RESULT,
    result, pg
  }
}

export const getResultError = (message) => {
  return {
    type: GET_RESULT_ERROR,
    message
  }
}

export const getResult = (pg, examTimeTableId,exportData) => {
  return dispatch => {
    if(exportData===true){
      dispatch({ type: SET_EXPORT_RESULT_LOADING });
  }else{
      dispatch({ type: SET_LOADING_RESULT });
  }
    api.getResult({ pg, examTimeTableId:[examTimeTableId],exportData })
      .then(response => {
        dispatch(getResultSuccess(response.data))
        if(exportData===true){
          window.open(`${URLS.RESULT_DOWNLOAD}`);
        }
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getResultError(getErrorMessage(err)))
      })
  }
}

export const addResultError = (message) => {
  return {
    type: ADD_RESULT_ERROR,
    message
  }
}
export const addResultSuccess = (result) => {
  return {
    type: ADD_RESULT,
    result
  }
}

export const addResult = (result) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_RESULT });
    api.addResult(result)
      .then(response => {
        dispatch(addResultSuccess(result));
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addResultError(getErrorMessage(err)))
      })
  }
}

export const updateResultError = (error) => {
  return {
    type: UPDATE_RESULT_ERROR,
    error
  }
}
export const updateResultSuccess = (result) => {
  return {
    type: UPDATE_RESULT,
    result
  }
}

export const updateResults = (result) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_RESULT });
    api.updateResult(result)
      .then(response => {
        dispatch(updateResultSuccess(result));
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateResultError(getErrorMessage(err)))
      })
  }
}

export const deleteResultError = (message) => {
  return {
    type: DELETE_RESULT_ERROR,
    message
  }
}
export const deleteResultSuccess = (result) => {
  return {
    type: DELETE_RESULT,
    result
  }
}

export const deleteResults = (result) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_RESULT });
    api.deleteResult(result)
      .then(response => {
        dispatch(deleteResultSuccess(result));
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(deleteResultError(getErrorMessage(err)))
      })
  }
}


export const sampleResultError = (message) => {
  return {
    type: SAMPLE_RESULT_ERROR,
    message
  }
}

export const sampleResultSuccess = (filepath) => {
  return {
    type: SAMPLE_RESULT,
    filepath
  }
}

export const sampleResults = (examTimeTableId) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_SAMPLE });
    api.sampleResult({ examTimeTableId })
      .then(response => {
        dispatch(sampleResultSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(sampleResultError(getErrorMessage(err)))
      })
  }
}