import { toast } from 'react-toastify';
import * as api from '../../api/Leave';
import { getErrorMessage } from '../../utils/utils';
export const PRINCIPAL_GET_TEACHER_LEAVE = 'PRINCIPAL_GET_TEACHER_LEAVE'
export const PRINCIPAL_GET_TEACHER_LEAVE_ERROR = 'PRINCIPAL_GET_TEACHER_LEAVE_ERROR'
export const PRINCIPAL_UPDATE_TEACHER_LEAVE = 'PRINCIPAL_UPDATE_TEACHER_LEAVE'
export const PRINCIPAL_UPDATE_TEACHER_LEAVE_ERROR = 'PRINCIPAL_UPDATE_TEACHER_LEAVE_ERROR'
export const PRINCIPAL_LEAVE_SET_LOADING = 'PRINCIPAL_LEAVE_SET_LOADING'

export const principalGetTeacherLeaveSuccess = (leave) => {
  return {
    type: PRINCIPAL_GET_TEACHER_LEAVE,
    leave
  }
}

export const principalGetTeacherLeaveError = (message) => {
  return {
    type: PRINCIPAL_GET_TEACHER_LEAVE_ERROR,
    message
  }
}

export const principalGetTeacherLeave = () => {
  return dispatch => {
    dispatch({ type: PRINCIPAL_LEAVE_SET_LOADING });
    api.principalGetTeacherLeave()
      .then(response => {
        dispatch(principalGetTeacherLeaveSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(principalGetTeacherLeaveError(getErrorMessage(err)))
      })
  }
}

export const principalUpdateLeaveError = (message) => {
  return {
    type: PRINCIPAL_UPDATE_TEACHER_LEAVE_ERROR,
    message
  }
}
export const principalUpdateLeaveSuccess = (id, status) => {
  return {
    type: PRINCIPAL_UPDATE_TEACHER_LEAVE,
    id, status

  }
}

export const principalUpdateLeave = (id, status) => {
  return dispatch => {
    dispatch({ type: PRINCIPAL_LEAVE_SET_LOADING });
    api.principalUpdateTeacherLeave({ id, status })
      .then(response => {
        dispatch(principalUpdateLeaveSuccess(id, status))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(principalUpdateLeaveError(getErrorMessage(err)))
      })
  }
}


