import { toast } from 'react-toastify';
import * as api from '../../api/holiday';
import { getErrorMessage } from '../../utils/utils';
export const GET_HOLIDAY = 'GET_HOLIDAY'
export const GET_HOLIDAY_ERROR = 'GET_HOLIDAY_ERROR'
export const ADD_HOLIDAY = 'ADD_HOLIDAY'
export const ADD_HOLIDAY_ERROR = 'ADD_HOLIDAY_ERROR'
export const UPDATE_HOLIDAY = 'UPDATE_HOLIDAY'
export const UPDATE_HOLIDAY_ERROR = 'UPDATE_HOLIDAY_ERROR'
export const DELETE_HOLIDAY = 'DELETE_HOLIDAY'
export const DELETE_HOLIDAY_ERROR = 'DELETE_HOLIDAY_ERROR'
export const SET_LOADING_HOLIDAY = 'SET_LOADING_HOLIDAY'

export const getHolidaySuccess = (holiday, pg) => {
  return {
    type: GET_HOLIDAY,
    holiday, pg
  }
}

export const getHolidayError = (message) => {
  return {
    type: GET_HOLIDAY_ERROR,
    message
  }
}

export const getHolidays = (pg) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_HOLIDAY });
    api.getHoliday({pg})
      .then(response => {
        dispatch(getHolidaySuccess(response.data, pg))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getHolidayError(getErrorMessage(err)))
      })
  }
}

export const addHolidayError = (message) => {
  return {
    type: ADD_HOLIDAY_ERROR,
    message
  }
}
export const addHolidaySuccess = (holiday) => {
  return {
    type: ADD_HOLIDAY,
    holiday
  }
}

export const addHoliday = (holiday) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_HOLIDAY });
    api.addHoliday(holiday)
      .then(response => {
        dispatch(addHolidaySuccess(response.data));
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addHolidayError(getErrorMessage(err)))
      })
  }
}

export const updateHolidayError = (message) => {
  return {
    type: UPDATE_HOLIDAY_ERROR,
    message
  }
}
export const updateHolidaySuccess = (holiday) => {
  return {
    type: UPDATE_HOLIDAY,
    holiday
  }
}

export const updateHolidays = (holiday) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_HOLIDAY });
    api.updateHoliday(holiday)
      .then(response => {
        dispatch(updateHolidaySuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateHolidayError(getErrorMessage(err)))
      })
  }
}


export const deleteHolidaySuccess = (deleteEvent) => {
  return {
    type: DELETE_HOLIDAY,
    deleteEvent
  }
}

export const deleteHolidayError = (message) => {
  return {
    type: DELETE_HOLIDAY_ERROR,
    message
  }
}

export const deleteHoliday = (holidayId) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_HOLIDAY });
    api.deleteHoliday(holidayId)
      .then(response => {
        dispatch(deleteHolidaySuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(deleteHolidayError(getErrorMessage(err)))
      })
  }
}

