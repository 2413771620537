import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
  Button,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as action from "../../../Store/action/Result";
import { getExamTimeTable } from "../../../Store/action/ExamTimeTable";
import { trim } from "../../../utils/utils";
import document from "../../../assets/icon/doc.svg";
import close from "../../../assets/icon/close.svg";
import Select from "react-select";
import moment from 'moment';

const SignupSchema = Yup.object().shape({
  examTimeTableId:Yup.string().required("Please select Examtimetable")
});

class AddResult extends Component {
  input = React.createRef();
  state = {
    exam: "",
    classes: "",
    subjectName: "",
    marks: "",
    id: "",
    examTimeTables: "",
    examTimeTableId: "",
    document: [],
    imageUrls: [],
    subjectresultId: "",
  };

  componentDidMount() {
    this.props.onGetExamTimeTable(1, "Active");
    const { result, examTimeTableId } = this.props.location.state || {};
    if (result && examTimeTableId) {
      this.setState({
        marks: result.subjectresults[0].marks,
        subjectresultId: result.id,
        examTimeTableId,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.sampleLoading &&
      this.props.sampleLoading &&
      !nextProps.error
    ) {
      this.props.onGetResult(1,'')
      // this.props.onGetResult(1,this.state.examTimeTableId);
      this.props.history.push('/admin/result');
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // this.setErrors({ subjectName: nextProps.error });
    }
  }

  downloadSampleRequest = (examTimeTableId) => {
    this.setState({ examTimeTableId });
    this.props.onGetSampleResult(examTimeTableId);
  };

  submitHandler = (values) => {
    console.log("Values of data",values.examTimeTableId)
    if (this.state.subjectresultId) {
      this.props.onUpdateResult(values);
    } else {
      this.props.onAddResult(values);
      // setTimeout(() => {
      //   this.props.onGetResult(1,values.examTimeTableId);
      // }, 1000);
     
    }
  };

  onSelectFile = (documents, setFieldValue) => (e) => {
    const files = e.target.files;
    const { imageUrls } = this.state;
    for (let file of files) {
      documents.push(file);
      if (file.type.startsWith("image")) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          imageUrls.push(reader.result);
          this.setState({ imageUrls });
        });
        reader.readAsDataURL(file);
      }
    }
    setFieldValue("document", documents);
  };

  imageRemoveHandler = (doc, image) => {
    this.setState((s) => ({
      imageUrls: s.imageUrls.filter((p) => p !== image),
      document: s.document.filter((p) => p !== doc),
    }));
  };

  documentRemoveHandler = (document) => {
    this.setState((s) => ({
      document: s.document.filter((p) => p !== document),
    }));
  };

  render() {
    const { examTimeTable, results } = this.props;
    const examTimeTables = examTimeTable.data.map((a) => ({
      label:`${a.subject.subjectName} - ${moment(a.examDate).format('YYYY-MM-DD')}`,
      value: a.id,
    }));
   
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            marks: this.state.marks,
            subjectresultId: this.state.subjectresultId,
            examTimeTableId: this.state.examTimeTableId,
            document: this.state.document,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">
                {this.state.marks === "" ? "Add Result" : "Update Result"}
              </CardHeader>
              <CardBody>
                {values.subjectresultId ? (
                  <div className="col-12">
                    <FormGroup>
                      <Label>Marks</Label>
                      <Input
                        type="text"
                        invalid={errors.marks && touched.marks}
                        name="marks"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.marks}
                        placeholder="Enter Marks"
                      />
                      <FormFeedback>
                        {errors.marks && touched.marks && errors.marks}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                ) : (
                  <div className="col-12">
                    <FormGroup>
                      <Label>Exam Time Table</Label>
                      <Select
                        name="examTimeTableId"
                        onChange={(value) => {
                          setFieldValue("examTimeTableId", value.value);
                          this.downloadSampleRequest(value.value);
                        }}
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={
                          examTimeTables
                            ? examTimeTables.find(
                                (s) => s.value === values.examTimeTableId
                              )
                            : ""
                        }
                        options={examTimeTables}
                      />
                      <FormFeedback>
                        {errors.examTimeTableId &&
                          touched.examTimeTableId &&
                          errors.examTimeTableId}
                      </FormFeedback>
                    </FormGroup>

                    {results.filepath && (
                      <FormGroup>
                        <Label>Download Sample</Label>
                        <FormGroup>
                          <Button
                            href={results.filepath.filepath}
                            className="btn-add"
                          >
                            Download
                          </Button>
                        </FormGroup>
                      </FormGroup>
                    )}

                    <FormGroup>
                      <label className="form-control-label">Document</label>
                      <FormGroup>
                        <input
                          accept="application/msword, application/excel,.docx, .csv, .xlsx"
                          hidden
                          ref={this.input}
                          type="file"
                          multiple
                          onChange={this.onSelectFile(
                            values.document,
                            setFieldValue
                          )}
                        />
                        <FormFeedback>
                          {errors.document &&
                            touched.document &&
                            errors.document}
                        </FormFeedback>
                        <button
                          className="btn btn-primary"
                          onClick={(e) =>
                            this.input.current && this.input.current.click()
                          }
                        >
                          Browse
                        </button>
                      </FormGroup>

                      <div className="d-flex overflow-auto">
                        {values.document
                          .filter(
                            (p) =>
                              !p.type.startsWith("image") &&
                              !p.type.startsWith("application/pdf")
                          )
                          .map((d, i) => (
                            <div className="d-flex flex-column m-2">
                              <div
                                style={{ position: "relative", left: "20px" }}
                              >
                                <button
                                  className="btn btn-link"
                                  onClick={() => this.documentRemoveHandler(d)}
                                  style={{
                                    position: "absolute",
                                    paddingLeft: "40px",
                                    alignSelf: "center",
                                  }}
                                >
                                  <img src={close} alt="" />
                                </button>
                              </div>
                              <img
                                alt="Crop"
                                style={{ width: "100px", height: "100px" }}
                                src={document}
                              />
                              <span classes="mt-2 text-wrap">
                                {trim(d.name, 13)}
                              </span>
                            </div>
                          ))}
                      </div>
                    </FormGroup>
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                    disabled={this.props.sampleLoading}
                  >
                    {this.props.sampleLoading ? (
                      <ClipLoader size={15} color={"#123abc"} loading={true} />
                    ) : (
                      "Save"
                    )}
                  </button>
                  <Link
                    className="btn btn-secondary"
                    to={{ pathname: "/admin/result" }}
                  >
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.result.addError,
    sampleLoading: state.result.isLoading,
    examTimeTable: state.examTimeTable.examTimeTable,
    results: state.result.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetResult: (pg, examTimeTableId,exportData) => dispatch(action.getResult(pg, examTimeTableId,exportData)),
    onAddResult: (values) => dispatch(action.addResult(values)),
    onGetSampleResult: (id) => dispatch(action.sampleResults(id)),
    onGetExamTimeTable: (pg) => dispatch(getExamTimeTable(pg)),
    onUpdateResult: (values) => dispatch(action.updateResults(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddResult);
