import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import * as action from "../../../Store/action/Teachers";
import { getClass } from "../../../Store/action/Classes";
import { getSubject } from "../../../Store/action/Subjects";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import "./Teachers.css";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Select from "react-select";
import Crop from "../../Crop/Crop";
import { getSchool } from "../../../Store/action/School";
import Profile from "../../../assets/images/profile-avatar.png";
import moment from 'moment'
// import DatePicker from 'react-datepicker2';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Enter Only Alphabetic")
    .required("First Name is required"),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Enter Only Alphabetic")
    .required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  // classId: Yup.string().required("Class Name is Required"),
  // subjectId:Yup.string().required("Subject is Required"),
 schoolId:Yup.string().required("Please select school name"),
  gender:Yup.string().required("Gender is Required"),
  birthOfDate:Yup.string().nullable().required("Birthdate is required"),
    // function (value) {
    //   console.log('parent', value)
    //   if (this.parent.userRole === 2) return true;
    //   // else if (!value) return false;
    //   else return true;
    // // }
    // birthOfDate: Yup.date()
    // .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
    // .required("Please select birth date"),

  mobileNo: Yup.string()
    .min(10, "Enter valid phone number")
    .max(10, "Enter valid phone number")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Enter valid phone number"
    )
    .required("Mobile number is required"),
});

const SignupTeacherSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Enter Only Alphabetic")
    .required("First Name is required"),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Enter Only Alphabetic")
    .required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  classId: Yup.string().required("Class Name is Required"),
  subjectId:Yup.string().required("Subject is Required"),
 //schoolId:Yup.string().required("School name is Required"),
  gender:Yup.string().required("Gender is Required"),
  birthOfDate:Yup.string().nullable().required("Birthdate is required"),
    // function (value) {
    //   console.log('parent', value)
    //   if (this.parent.userRole === 2) return true;
    //   // else if (!value) return false;
    //   else return true;
    // // }
    // birthOfDate: Yup.date()
    // .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
    // .required("Please select birth date"),

  mobileNo: Yup.string()
    .min(10, "Enter valid phone number")
    .max(10, "Enter valid phone number")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Enter valid phone number"
    )
    .required("Mobile number is required"),
});


const GenderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const userOptions = [
  { value: 2, label: "Principal" }
];

class AddTeacher extends Component {
  input = React.createRef();
  state = {
    birthOfDate: '',
    firstName: "",
    lastName: "",
    email: "",
    schoolId: "",
    mobileNo: "",
    userRole: null,
    address: "",
    experience: "",
    gender: "",
    subjectId: [],
    oldSubjectId: [],
    classId: [],
    oldClassId: [],
    profilePic: "",
    userId: "",
    src: null,
  };

  componentDidMount() {
    this.props.onGetClass();
    this.props.onGetSubject();
    this.props.getSchools(-1);
    const { teachers } = this.props.location.state || {};
    console.log("teachers of data",teachers)
    if (teachers) {
      const classId = teachers.userclasses.map((c) => c.classId);
      const subjectId = teachers.userSubjects.map((s) => s.subjectId);
      this.setState({
        ...teachers,
        userId: teachers.id,
        classId,
        subjectId,
        birthOfDate:new Date(teachers.birthOfDate),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log("Props of data",nextProps.loading,this.props.loading ,nextProps.error)
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      console.log("Hello")
       this.props.history.push("/admin/teachers");
       this.props.onGetTeachers(
        1,
       "",
       "",
       this.props.user.userRole===1?2:3
      );
       //this.props.onGetTeachers("", "", "", 1);
    } 
    else if (nextProps.error && !this.props.error) {
      console.log("Next props of error",nextProps.error)
if(nextProps.error==`Cannot read properties of null (reading 'pages')`){
  this.props.history.push("/admin/teachers");
  this.props.onGetTeachers(
   1,
  "",
  "",
  this.props.user.userRole===1?2:3
 );

}else{
  toast.error(nextProps.error, {
    position: toast.POSITION.TOP_RIGHT,
  });
}
    
    }
  }

  submitHandler = (values) => {
    if (this.state.userId) {
      this.props.onUpdateTeacher(values);
    } else {
      this.props.onAddTeacher(values);
      //this.props.onGetTeachers("", "", "", 2);
    }
  };

  onSelectFile = (key) => (e) => {
    this.setState({ image: e.target.files[0] });
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ [key]: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  render() {
    const { croppedImageUrl, src } = this.state;
    const { classes, subjects, user, schools } = this.props;
    const classOption = classes.data.map((c, i) => ({
      label: `${c.standard.standardName}-${c.className}, ${c.medium}`,
      value: c.id,
      status: c.status,
    }));

    const classOptions = classOption.filter((op) => op.status === "ACTIVE");
    const subjectOption = subjects.data.map((c, i) => ({
      label: c.subjectName,
      value: c.id,
      status: c.status,
    }));
    const subjectOptions = subjectOption.filter((op) => op.status === "ACTIVE");
    const schoolOption = schools.data
      .filter((s) => {
        return s.status === "ACTIVE";
      })
      .map((s) => {
        return { label: s.schoolName, value: s.id };
      });
    // const schoolOption = schools.data.map((s) => ({
    //   label: s.schoolName,
    //   value: s.id,
    //   status: s.status
    // }));

    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            userRole:  this.props.user.userRole === 1 && this.state.firstName === "" ? 2 : this.props.user.userRole === 1 && this.state.firstName !== "" ? 2 : this.state.firstName === "" ? 3 :3,
            mobileNo: this.state.mobileNo,
            address: this.state.address,
            classId: this.state.classId,
            subjectId: this.state.subjectId,
            oldClassId: this.state.classId,
            oldSubjectId: this.state.subjectId,
            experience: this.state.experience,
            gender: this.state.gender,
            userId: this.state.userId,
            schoolId: this.state.schoolId,
            birthOfDate: this.state.birthOfDate,
            profilePic: this.state.profilePic,
          }}
          validationSchema={this.props.user.userRole === 1 && this.state.firstName === "" ? SignupSchema : this.props.user.userRole === 1 && this.state.firstName !== "" ? SignupSchema : this.state.firstName === "" ? SignupTeacherSchema : SignupTeacherSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
           
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              { console.log("values of birthdate",values.birthOfDate,errors)}
              <Crop
                src={src}
                name={this.state.image && this.state.image.name}
                onCrop={(imageUrl, blob) => {
                  this.setState({ croppedImageUrl: imageUrl, src: null });
                  setFieldValue("profilePic", blob);
                }}
                onCancel={() => this.setState({ src: null })}
              />
              {console.log("Props of user role", this.props.user.userRole)}
              <CardHeader className="h-style title-style">
                {/* {this.state.firstName === "" ? "Add Principal" : "Update Principal"} */}
                {this.props.user.userRole === 1 && this.state.firstName === "" ? "Add Principal" : this.props.user.userRole === 1 && this.state.firstName !== "" ? "Update Principal" : this.state.firstName === "" ? "Add Teacher" : "update Teacher" }
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <label className="form-control-label">Profile Picture</label>
                  <FormGroup>
                    <input
                      accept="image/jpg, image/jpeg, image/png"
                      hidden
                      ref={this.input}
                      type="file"
                      onChange={this.onSelectFile("src")}
                    />
                    <FormFeedback>
                      {errors.profilePic &&
                        touched.profilePic &&
                        errors.profilePic}
                    </FormFeedback>
                    {!croppedImageUrl && !this.state.profilePic && (
                      <img
                        alt="profile"
                        style={{ height: 100 }}
                        src={Profile}
                        onClick={(e) =>
                          this.input.current && this.input.current.click()
                        }
                      />
                    )}
                  </FormGroup>
                  <div>
                    {(croppedImageUrl || this.state.profilePic) && (
                      <img
                        alt="Crop"
                        style={{
                          height: "auto",
                          width: "auto",
                          maxHeight: 100,
                          maxWidth: 100,
                        }}
                        onClick={(e) =>
                          this.input.current && this.input.current.click()
                        }
                        src={croppedImageUrl || this.state.profilePic}
                      />
                    )}
                  </div>
                </FormGroup>
                <Row>
                  <Col xs="5">
                    <Label>Basic Details</Label>
                    <hr className="mx-0  mt-0" />
                    <FormGroup>
                      <Label>First Name<span className="required">*</span></Label>
                      <Input
                        type="firstName"
                        invalid={errors.firstName && touched.firstName}
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        placeholder="First Name"
                      />
                      <FormFeedback>
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Last Name<span className="required">*</span></Label>
                      <Input
                        type="lastName"
                        invalid={errors.lastName && touched.lastName}
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        placeholder="Last Name"
                      />
                      <FormFeedback>
                        {errors.lastName && touched.lastName && errors.lastName}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>MobileNo<span className="required">*</span></Label>
                      <Input
                        type="number"
                        invalid={errors.mobileNo && touched.mobileNo}
                        name="mobileNo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobileNo}
                        placeholder="Mobile Number"
                      />
                      <FormFeedback>
                        {errors.mobileNo && touched.mobileNo && errors.mobileNo}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Email<span className="required">*</span></Label>
                      <Input
                        type="email"
                        invalid={errors.email && touched.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="Email"
                      />
                      <FormFeedback>
                        {errors.email && touched.email && errors.email}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Gender</Label>
                      <Select
                        name="GenderOptions"
                        onChange={(option) =>
                          setFieldValue("gender", option.value)
                        }
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={GenderOptions.find(
                          (option) => option.value === values.gender
                        )}
                        options={GenderOptions}
                      />
                      <FormFeedback>
                        {errors.gender && touched.gender && errors.gender}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>BirthDate</Label>
                      <div>
                        <DatePicker
                         maxDate={moment().subtract(18, "years")._d}
                          isClearable={values.birthOfDate?true:false}
                          placeholderText='Select birthdate'
                          timePicker={false}
                          className="form-control"
                          wrapperClassName="w-100"
                          selected={values.birthOfDate}
                          showYearDropdown
                          scrollableYearDropdown
                          showMonthDropdown
                          onChange={(date) =>
                            setFieldValue("birthOfDate", date)
                          }
                        />
                      </div>
                    
                    </FormGroup>
                    <div style={{color:'#dc3545',marginTop:-12,fontSize:12,marginBottom:20}}>
                        {errors.birthOfDate && touched.birthOfDate && errors.birthOfDate}
                      </div>
                    <FormGroup>
                      <Label>Address</Label>
                      <Input
                        type="textarea"
                        invalid={errors.address && touched.address}
                        name="address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        placeholder="Address"
                        rows={3}
                      />
                      <FormFeedback>
                        {errors.address && touched.address && errors.address}
                      </FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col xs={{ size: 5, order: 2, offset: 1 }}>
                    <Label>Academic Details</Label>
                    <hr className="mx-0 mt-0" />
                    {this.props.user.userRole !== 1 && <FormGroup>
                    
                    <Label>Experience</Label>
                    <Input
                      type="experience"
                      invalid={errors.experience && touched.experience}
                      name="experience"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.experience}
                      placeholder="Experience"
                    />
                    <FormFeedback>
                      {errors.experience &&
                        touched.experience &&
                        errors.experience}
                    </FormFeedback>
                  </FormGroup> }

                        {this.props.user.userRole !== 1 && <FormGroup>
                      <Label>Class Name</Label>
                      <Select
                        name="classId"
                        isMulti={true}
                        onChange={(option) =>
                          setFieldValue(
                            "classId",
                            option ? option.map((p) => p.value) : ""
                          )
                        }
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={
                          values.classId &&
                          classOptions.filter((option) =>
                            values.classId.includes(option.value)
                          )
                        }
                        options={classOptions}
                      />
                      <FormFeedback>
                        {errors.classId && touched.classId && errors.classId}
                      </FormFeedback>
                    </FormGroup>}

                    {/* {user.userRole === 1 && (
                      <FormGroup>
                        <Label>Type</Label>
                        <Select
                          name="userRole"
                          onChange={(option) =>
                            setFieldValue("userRole", option.value)
                          }
                          onBlur={handleBlur}
                          className="is-invalid"
                          value={userOptions.find(
                            (option) => option.value === values.userRole
                          )}
                          options={userOptions}
                        />
                        <FormFeedback>
                          {errors.userRole &&
                            touched.userRole &&
                            errors.userRole}
                        </FormFeedback>
                      </FormGroup>
                    )} */}

                    {this.props.user.userRole !== 1 && 
                    <FormGroup>
                        <Label>Subject Name</Label>
                        <Select
                          name="subjectId"
                          isMulti={true}
                          onChange={(option) =>
                            setFieldValue(
                              "subjectId",
                              option && option.map((p) => p.value)
                            )
                          }
                          onBlur={handleBlur}
                          className="is-invalid"
                          value={
                            values.subjectId &&
                            subjectOptions.filter((option) =>
                              values.subjectId.includes(option.value)
                            )
                          }
                          options={subjectOptions}
                          menuPlacement="auto"
                        />
                        <FormFeedback>
                          {errors.subjectId &&
                            touched.subjectId &&
                            errors.subjectId}
                        </FormFeedback>
                      </FormGroup>
                      }

                    {!user.schoolId && (
                      <FormGroup>
                        <Label>School Name<span className="required">*</span></Label>
                        <Select
                          name="schoolId"
                          onChange={(option) =>
                            setFieldValue("schoolId", option.value)
                          }
                          onBlur={handleBlur}
                          className="is-invalid"
                          value={schoolOption.filter(
                            (option) => values.schoolId === option.value
                          )}
                          options={schoolOption}
                          menuPlacement="auto"
                        />
                        <FormFeedback>
                          {errors.schoolId &&
                            touched.schoolId &&
                            errors.schoolId}
                        </FormFeedback>
                      </FormGroup>
                    )}

                   
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <button
                        disabled={this.props.loading}
                        type="button"
                        className="btn btn-primary mr-2"
                        onClick={handleSubmit}
                      >
                        {this.props.loading ? (
                          <ClipLoader
                            size={15}
                            color={"#123abc"}
                            lsoading={true}
                          />
                        ) : (
                          "Save"
                        )}
                      </button>
                      <Link
                        className="btn btn-secondary"
                        to={{ pathname: "/admin/teachers" }}
                      >
                        Cancel
                      </Link>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teachers: state.teacher.teachers,
    loading: state.teacher.isLoading,
    error: state.teacher.addError,
    subjects: state.subject.subjects,
    classes: state.class.classes,
    user: state.login.user,
    schools: state.school.schools,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddTeacher: (teachers) => dispatch(action.addTeacher(teachers)),
    onGetClass: () => dispatch(getClass()),
    onGetSubject: () => dispatch(getSubject()),
    getSchools: (pg) => dispatch(getSchool(pg)),
    onUpdateTeacher: (teachers) => dispatch(action.updateTeacher(teachers)),
    onGetTeachers: (pg, status, searchName, userRole) =>
    dispatch(action.getTeachers(pg, status, searchName, userRole)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTeacher);
