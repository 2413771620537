import React, { Component } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { addTeacherLeave } from '../../../Store/action/TeacherLeave';
import moment from 'moment';

const SignupSchema = Yup.object().shape({
  startDate: Yup.string().required('start Date is required'),
  endDate: Yup.string().required('End Date is required'),
  reason: Yup.string().required('Reason is required'),
});

class AddMyLeave extends Component {
  input = React.createRef();
  state = {
    startDate: new Date(),
    endDate: new Date(),
    reason: '',
  };

  componentDidMount() {
    // this.props.onGetClass();
    // this.props.onGetSubject();
    // this.props.getSchools();
    // const { teachers } = this.props.location.state || {};
    // if (teachers) {
    //     const classId = teachers.userclasses.map(c => c.classId)
    //     const subjectId = teachers.userSubjects.map(s => s.subjectId)
    //     this.setState({ ...teachers, userId: teachers.id, classId, subjectId, birthOfDate: new Date(teachers.birthOfDate) });
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push('/admin/myLeave');
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // this.setErrors({ email: nextProps.error });
    }
  }

  submitHandler = (values) => {
    const data = {
      ...values,
      startDate: moment(values.startDate).format('YYYY-MM-DDT00:00:00.000[Z]'),
      endDate: moment(values.endDate).format('YYYY-MM-DDT00:00:00.000[Z]'),
    };
    this.props.onAddTeacherLeave(data);
  };

  render() {
    const { user } = this.props;
    const classId = user.userclasses.map((a) => a.classId);
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            reason: this.state.reason,
            classId: classId[0],
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">
                {this.state.reason === '' ? 'Add MyLeave' : 'Update MyLeave'}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="3">
                    <FormGroup>
                      <Label>StartDate</Label>
                      <div>
                        <DatePicker
                          isClearable
                          minDate={new Date()}
                          className="form-control"
                          wrapperClassName="w-100"
                          selected={values.startDate}
                          onChange={(date) => setFieldValue('startDate', date)}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="3">
                    <FormGroup>
                      <Label>EndDate</Label>
                      <div>
                        <DatePicker
                          minDate={new Date()}
                          isClearable
                          className="form-control"
                          wrapperClassName="w-100"
                          selected={values.endDate}
                          onChange={(date) => setFieldValue('endDate', date)}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Reason<span className="required">*</span></Label>
                      <Input
                        type="text"
                        invalid={errors.reason && touched.reason}
                        name="reason"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reason}
                        placeholder="Reason"
                      />
                      <FormFeedback>
                        {errors.reason && touched.reason && errors.reason}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="d-flex justify-content-start">
                  <button
                    disabled={this.props.loading}
                    type="button"
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                  >
                    {this.props.loading ? (
                      <ClipLoader size={15} color={'#123abc'} loading={true} />
                    ) : (
                        'Save'
                      )}
                  </button>
                  <Link
                    className="btn btn-secondary"
                    to={{ pathname: '/admin/myLeave' }}
                  >
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.myLeave.isLoading,
    error: state.myLeave.addError,
    user: state.login.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddTeacherLeave: (leave) => dispatch(addTeacherLeave(leave)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMyLeave);
