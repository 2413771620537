import React, { Component, createRef } from "react";
import ReactCrop from "react-image-crop";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col
} from "reactstrap";

class Crop extends Component {
    input = createRef();
    state = {
        src: null,
        crop: {
            unit: "%",
            width: 30,
        },
        msg:"Please set name"
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClienCrop(crop);
    };

    onCropChange = (crop) => {
        this.setState({ crop });
    };

    async makeClienCrop(crop) {
        if (!this.props.name) {
            throw this.state.msg
        }
        if (this.imageRef && crop.width && crop.height) {
            const { url, blob } = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.props.name
            );
            this.setState({ url, blob });
        }
    };

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error("Canvas is Empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve({ url: this.fileUrl, blob });
            }, "image/jpeg");
        })
    };

    render() {
        const { crop, url, blob } = this.state;
        const { src } = this.props;
        return (
            <div className="animated fadeIn">
                <Row className="justify-content-center">
                    <Col xs="12" sm="6">
                        <Modal isOpen={!!src} toggle={this.props.onCancel} className={this.props.className} >
                            <ModalHeader>Image</ModalHeader>
                            <ModalBody>
                                {src && (
                                    <ReactCrop
                                        src={src}
                                        crop={crop}
                                        imageStyle={{ maxHeight: '100%' }}
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                )}
                            </ModalBody>
                            <ModalFooter className="justify-content-center">
                                <Button color="success" disabled={!url} onClick={() => this.props.onCrop(url, blob)}> Save </Button>
                                <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Crop;
