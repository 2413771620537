import React from 'react';
import { Component } from 'react';
import { Card, Table, CardBody, CardHeader, Alert, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as action from '../../../Store/action/School'
import ImageModal from './../../Modal/ImageModal';
import Teacher from '../../../assets/images/teachers.png';
import { ClipLoader } from "react-spinners";
import { debounce } from '../../../utils/utils';
import ConfirmationDialog from '../../ConfirmDialog/ConfirmDialog';

class School extends Component {
    state = {
        pg: 1,
        searchName: '',
        selectedStatus: '',
        id: '',
        status: ''

    }
    componentDidMount() {
        if (!this.props.schools.data.length) {
            this.props.onGetSchools(1);
        }
        this.props.onGetSchools(1);
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.isStatusChangeInProcess && this.props.isStatusChangeInProcess) {
            this.setState({ id: null, status: null });
        }
    }

    paginationChangeHandler = (pg, value) => () => {
        this.props.onGetSchools(
            value,
        "",
            this.state.selectedStatus,
          );
         this.setState({ pg: value });
    }

    renderPagination(pagination) {
        const pages = [];
        for (let i = 1; i <= pagination; i++) {
            pages.push(
                <PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
                    <PaginationLink tag="button">{i}</PaginationLink>
                </PaginationItem>
            );
        }
        return pages;
    }

    searchSchool = debounce((value) => {
        this.props.onGetSchools(1, value.searchName, this.state.selectedStatus);
    }, 500);

    searchSchoolHandler = (event) => {
        this.setState({ pg: 1, searchName: event.target.value });
        this.searchSchool({ pg: 1, searchName: event.target.value });
    }

    statusChangeHandler = (event) => {
        this.setState({ pg: 1, selectedStatus: event.target.value });
        this.props.onGetSchools(1, this.state.searchName, event.target.value);
    }

    confirmationDialogBox = (id, status) => {
        this.setState({ id, status });
    }

    changeStatusHandler = () => {
        this.props.onUpdateSchoolStatus({ schoolId: this.state.id, status: this.state.status });
    }

    render() {
        const { schools, isStatusChangeInProcess } = this.props;
        const { pg, id, status } = this.state;
        return (
            <div className="row clearfix">
                <div className="col-lg-12">
                    <ConfirmationDialog
                        isOpen={id && status}
                        isDelete={isStatusChangeInProcess}
                        deleteHandler={() => this.changeStatusHandler()}
                        closeHandler={() => this.confirmationDialogBox(null, null)} />
                    <Card className="c-style">
                        <CardHeader className="h-style">
                            <div className="row m-r-0 m-l-0">
                                <div className="c-title-style">
                                    Schools
                                </div>
                                <div className="ml-auto btn-style">
                                    <Link class="btn btn-outline-dark" to={{ pathname: '/admin/addSchool' }}>Add</Link>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="row">
                                <div class="col-lg-3 col-md-6 d-flex mb-2">
                                    <select
                                        class="form-control show-tick ms select2"
                                        data-placeholder="Select"
                                        onChange={this.statusChangeHandler}
                                        value={this.state.selectedStatus}>
                                        <option value=''>Select Status</option>
                                        <option>ACTIVE</option>
                                        <option>INACTIVE</option>
                                    </select>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 d-flex pl-5">
                                        <label><input
                                            type="search"
                                            className="form-control"
                                            placeholder="Search here..."
                                            aria-controls="example"
                                            value={this.state.searchName}
                                            onChange={this.searchSchoolHandler} /></label>
                                    </div>
                                </div>
                            </div>
                            <Table responsive className="table-responsive-sm  table-responsive-md  table-responsive-lg" hover>
                                <thead className='table-header-wrapper'>
                                    <tr>
                                        <th>#</th>
                                        <th>Logo</th>
                                        <th>School Name</th>
                                        <th>Address</th>
                                        <th>City</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!this.props.loading && !this.props.error &&
                                        schools.data.map((t, index) => {
                                            return (
                                                <tr>
                                                    <td><strong>{(pg - 1) * 10 + index + 1}</strong></td>
                                                    <td><ImageModal alt="." className="image img-fluid" src={t.logo ? t.logo : Teacher} /></td>
                                                    <td>{t.schoolName}</td>
                                                    <td>{t.address}</td>
                                                    <td>{t.city}</td>
                                                    <td>
                                                        <label class="switch">
                                                            <input type="checkbox" checked={t.status === "ACTIVE"} onClick={() => this.confirmationDialogBox(t.id, t.status === 'INACTIVE' ? "ACTIVE" : "INACTIVE")} />
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <Link className="p-2" to={{ pathname: "/admin/addSchool", state: { schools: t, } }}><i class="icon-pencil" style={{ color: 'black' }} aria-hidden="true" /></Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </Table>

                            {this.props.loading ?
                                <div className="loader-wrapper">
                                    <ClipLoader
                                        size={35}
                                        color={"#123abc"}
                                        loading={true}
                                    />
                                </div>
                                :
                                <div className="pagination-wrapper">
                                    {schools.data.length > 0 && schools.pagination && !this.props.error &&
                                        <Pagination className="pull-right">
                                            <PaginationItem>
                                                <PaginationLink
                                                    onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()} previous tag="button" />
                                            </PaginationItem>
                                            {this.renderPagination(schools.pagination.pages)}
                                            <PaginationItem>
                                                <PaginationLink
                                                    onClick={() => schools.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()} next tag="button" />
                                            </PaginationItem>
                                        </Pagination>
                                    }
                                </div>
                            }
                            {!this.props.loading && (this.props.error || !schools.data.length) &&
                                <div className="error-style">
                                    <Alert color="danger">{this.props.error ? this.props.error : schools.message}</Alert>
                                </div>}
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        schools: state.school.schools,
        currentPage: state.school.currentPage,
        error: state.school.getError,
        user: state.login.user,
        loading: state.school.isLoading,
        isStatusChangeInProcess: state.school.isStatusChangeInProcess,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetSchools: (pg, searchName, status) => dispatch(action.getSchool(pg, searchName, status)),
        onUpdateSchoolStatus: (teachers) => dispatch(action.updateSchool(teachers)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(School);