import * as actionTypes from '../action/ExamTimeTable';

const initialState = {
  examTimeTable: {
    data: [],
    pagination: null
  },
  examTypes: {
    data: [],
    pagination: null
  },
  addError: '',
  getError: '',
  currentPage: 1,
  isLoading: false,
  isGetExamLoading: false,
  isAddExamLoading: false,
  deleteLoading: false
};

const examTimeTableReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.UPDATE_EXAM_TIMETABLE:
      const examTimeIndex = state.examTimeTable.data.findIndex(a => a.id === action.examTime.data.id);
      return {
        ...state,
        isLoading: false,
        deleteLoading: false,
        isAddExamLoading: false,
        addError: '',
        examTimeTable: { ...state.examTimeTable, data: Object.assign(state.examTimeTable.data, { [examTimeIndex]: action.examTime.data }) }
      }

    case actionTypes.UPDATE_EXAM_TIMETABLE_ERROR:
      return {
        ...state,
        isLoading: false,
        deleteLoading: false,
        addError: action.message
      }

    case actionTypes.ADD_EXAM_TIMETABLE:
      const total = state.examTimeTable.pagination && state.examTimeTable.pagination.total + 1;
      let pages = state.examTimeTable.pagination && state.examTimeTable.pagination.pages;
      if (total / 10 > pages) {
        pages += 1;
      }
      return {
        ...state,
        isLoading: false,
        isAddExamLoading: false,
        examTimeTable: {
          ...state.examTimeTable,
          pagination: { ...state.examTimeTable.pagination, total, pages },
          data: [...state.examTimeTable.data, action.examTime.data[0]]
        },
        addError: ''
      };

    case actionTypes.ADD_EXAM_TIMETABLE_ERROR:
      return {
        ...state,
        isLoading: false,
     
        addError: action.message
      }

    case actionTypes.GET_EXAM_TIMETABLE:
      return {
        ...state,
        examTimeTable: action.data,
        currentPage: action.pg || 1,
        isLoading: false,
        getError: ''
      }

    case actionTypes.GET_EXAM_TIMETABLE_ERROR:
      return {
        ...state,
        isLoading: false,
        getError: action.message,
      }

    case actionTypes.SET_GET_LOADING_EXAM_TYPE:
      return {
        ...state,
        isGetExamLoading: true,
        addError: '',
        getError: ''
      }

    case actionTypes.SET_ADD_LOADING_EXAM_TYPE:
      return {
        ...state,
        isAddExamLoading: true,
        addError: '',
        getError: ''
      }

    case actionTypes.GET_EXAM_TYPE:
      return {
        ...state,
        examTypes: action.examType,
        currentPage: action.pg || 1,
        isGetExamLoading: false,
        getError: ''
      }
    case actionTypes.ADD_EXAM_TYPE_ERROR:
      return {
        ...state,
        isAddExamLoading: false,
        addError: action.message,
      }
    case actionTypes.ADD_EXAM_TYPE:
      const examTotal = state.examTypes.pagination && state.examTypes.pagination.total + 1;
      let examPages = state.examTypes.pagination && state.examTypes.pagination.pages;
      if (examTotal / 10 > examPages) {
        examPages += 1;
      }
      return {
        ...state,
        isAddExamLoading: false,
        examTypes: {
          ...state.examTypes,
          pagination: { ...state.examTypes.pagination, examTotal, examPages },
          data: [...state.examTypes.data, action.examType.data]
        },
        addError: ''
      };

    case actionTypes.UPDATE_EXAM_TYPE:
      const examTypeIndex = state.examTypes.data.findIndex(a => a.id === action.examType.data.id);
      return {
        ...state,
        isAddExamLoading: false,
        deleteLoading: false,
        addError: '',
        examTypes: { ...state.examTypes, data: Object.assign(state.examTypes.data, { [examTypeIndex]: action.examType.data }) }
      }

    case actionTypes.UPDATE_EXAM_TYPE_ERROR:
      return {
        ...state,
        isAddExamLoading: false,
        deleteLoading: false,
        addError: action.message
      }


      case actionTypes.DELETE_EXAM_TYPE_ERROR:
        return {
          ...state,
          isAddExamLoading: false,
          deleteLoading: false,
          addError: action.message
        }

    default:
      return state;
  }
}

export default examTimeTableReducer