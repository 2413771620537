import { toast } from 'react-toastify';
import * as api from '../../api/StudentLeave';
import { getErrorMessage } from '../../utils/utils';
export const GET_LEAVE = 'GET_LEAVE'
export const GET_LEAVE_ERROR = 'GET_LEAVE_ERROR'
export const UPDATE_LEAVE = 'UPDATE_LEAVE'
export const UPDATE_LEAVE_ERROR = 'UPDATE_LEAVE_ERROR'
export const LEAVE_SET_LOADING = 'LEAVE_SET_LOADING'
export const LEAVE_SET_STATUS_LOADING = 'LEAVE_SET_STATUS_LOADING'

export const updateLeaveError = (message) => {
  return {
    type: UPDATE_LEAVE_ERROR,
    message
  }
}
export const updateLeaveSuccess = (leave) => {
  return {
    type: UPDATE_LEAVE,
    leave
  }
}

export const updateLeave = (leave) => {
  return dispatch => {
    dispatch({ type: LEAVE_SET_STATUS_LOADING });
    api.updateLeave(leave)
      .then(response => {
        dispatch(updateLeaveSuccess(leave))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateLeaveError(getErrorMessage(err)))
      })
  }
}

export const getLeaveSuccess = (leave, pg) => {
  return {
    type: GET_LEAVE,
    leave,
    pg
  }
}

export const getLeaveError = (message) => {
  return {
    type: GET_LEAVE_ERROR,
    message
  }
}

export const getLeave = (pg,startDate, endDate,) => {
  return dispatch => {
    dispatch({ type: LEAVE_SET_LOADING });
    api.getLeave({ pg, startDate, endDate, })
      .then(response => {
        dispatch(getLeaveSuccess(response.data, pg))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getLeaveError(getErrorMessage(err)))
      })
  }
}



