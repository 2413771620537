import { toast } from 'react-toastify';
import * as api from '../../api/Standard';
import { getErrorMessage } from '../../utils/utils';
export const GET_STANDARD = 'GET_STANDARD'
export const GET_STANDARD_ERROR = 'GET_STANDARD_ERROR'
export const ADD_STANDARD = 'ADD_STANDARD'
export const ADD_STANDARD_ERROR = 'ADD_STANDARD_ERROR'
export const UPDATE_STANDARD = 'UPDATE_STANDARD'
export const UPDATE_STANDARD_ERROR = 'UPDATE_STANDARD_ERROR'
export const DELETE_STANDARD = 'DELETE_STANDARD'
export const DELETE_STANDARD_ERROR = 'DELETE_STANDARD_ERROR'
export const SET_LOADING = 'SET_LOADING'
export const STANDARD_STATUS_CHANGE = ' STANDARD_STATUS_CHANGE'
export const STANDARD_STATUS_CHANGE_ERROR = ' STANDARD_STATUS_CHANGE_ERROR'
export const SET_STANDARD_STATUS_IN_PROCESS = 'SET_STANDARD_STATUS_IN_PROCESS';

export const getStandardSuccess = (standard, pg) => {
  return {
    type: GET_STANDARD,
    standard, pg
  }
}

export const getSubjectError = (message) => {
  return {
    type: GET_STANDARD_ERROR,
    message
  }
}

export const getStandard = (pg, searchName, status) => {
  return dispatch => {
    dispatch({ type: SET_LOADING });
    api.getStandard({ pg, searchName, status })
      .then(response => {
        
        dispatch(getStandardSuccess(response.data, pg))
      })
  }
}


export const addStandardSuccess = (standard) => {
  return {
    type: ADD_STANDARD,
    standard
  }
}

export const addStandardError = (message) => {
  return {
    type: ADD_STANDARD_ERROR,
    message
  }
}

export const addStandard = (standard) => {
  return dispatch => {
    dispatch({ type: SET_LOADING });
    api.addStandard(standard)
      .then(response => {
        dispatch(addStandardSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addStandardError(getErrorMessage(err)))
      })
  }
}

export const updateStandardError = (message) => {
  return {
    type: UPDATE_STANDARD_ERROR,
    message
  }
}

export const updateStandardSuccess = (standard) => {
  return {
    type: UPDATE_STANDARD,
    standard
  }
}

export const updateStandard = (standard) => {
  return dispatch => {
    dispatch({ type: SET_LOADING });
    api.updateStandard(standard)
      .then(response => {
        dispatch(updateStandardSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateStandardError(getErrorMessage(err)))
      })
  }
}

export const standardStatusChangeSuccess = (standardId, status) => {
  return {
    type: STANDARD_STATUS_CHANGE,
    standardId, status
  }
}

export const standardStatusChangeError = (message) => {
  return {
    type: STANDARD_STATUS_CHANGE_ERROR,
    message
  }
}

export const standardStatusChange = (standardId, status) => {
  return dispatch => {
    dispatch({ type: SET_STANDARD_STATUS_IN_PROCESS });
    api.standardStatusChange({ standardId, status })
      .then(response => {
        dispatch(standardStatusChangeSuccess(standardId, status))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(standardStatusChangeError(getErrorMessage(err)))
      })
  }
}
