import * as authActions from '../../action/TeachersAuth/login'


const initialState = {
  user: null,
  error: '',
  isLoading: false
}

const teachersLoginReducer = (state = initialState, action) => {

  switch (action.type) {
    case authActions.LOGIN:
      return {
        isLoading: false,
        user: action.user
      };

    case authActions.LOGOUT:
      return {
        user: null,
      }

    case authActions.LOGIN_ERROR:
      return {
        isLoading: false,
        error: action.message
      }
    case authActions.SET_LOADING_TEACHER_LOGIN:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    default:
      return state;

  }
}

export default teachersLoginReducer