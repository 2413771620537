import { toast } from 'react-toastify';
import * as api from '../../api/DailyActivityTimetable';
import { URLS } from '../../api/URLS';
import { getErrorMessage } from '../../utils/utils';

export const GET_DAILY_ACTIVITY_TIME_TABLE = 'GET_DAILY_ACTIVITY_TIME_TABLE'
export const GET_DAILY_ACTIVITY_TIME_TABLE_ERROR = 'GET_DAILY_ACTIVITY_TIME_TABLE_ERROR'
export const ADD_DAILY_ACTIVITY_TIME_TABLE = 'ADD_DAILY_ACTIVITY_TIME_TABLE'
export const ADD_DAILY_ACTIVITY_TIME_TABLE_ERROR = 'ADD_DAILY_ACTIVITY_TIME_TABLE_ERROR'
export const UPDATE_DAILY_ACTIVITY_TIME_TABLE = 'UPDATE_DAILY_ACTIVITY_TIME_TABLE'
export const UPDATE_DAILY_ACTIVITY_TIME_TABLE_ERROR = 'UPDATE_DAILY_ACTIVITY_TIME_TABLE_ERROR'
export const DELETE_DAILY_ACTIVITY_TIME_TABLE = 'DELETE_DAILY_ACTIVITY_TIME_TABLE'
export const DELETE_DAILY_ACTIVITY_TIME_TABLE_ERROR = 'DELETE_DAILY_ACTIVITY_TIME_TABLE_ERROR'
export const SET_LOADING_DAILY_ACTIVITY_TIME_TABLE = 'SET_LOADING_DAILY_ACTIVITY_TIME_TABLE'
export const SET_LOADING_DAILY_ACTIVITY_TIME_TABLE_DELETE = 'SET_LOADING_DAILY_ACTIVITY_TIME_TABLE_DELETE'
export const SET_LOADING_EXORT_ACTITY_TIME_TABLE='SET_LOADING_EXORT_ACTITY_TIME_TABLE'

export const getDailyActivitySuccess = (data, pg) => {
	return {
		type: GET_DAILY_ACTIVITY_TIME_TABLE,
		data, pg
	}
}

export const getDailyActivityError = (message) => {
	return {
		type: GET_DAILY_ACTIVITY_TIME_TABLE_ERROR,
		message
	}
}

export const getDailyActivity = (pg,exportData) => {
	return dispatch => {
		if(exportData===true){
			dispatch({type:SET_LOADING_EXORT_ACTITY_TIME_TABLE})
		}else{
			dispatch({ type: SET_LOADING_DAILY_ACTIVITY_TIME_TABLE });
		}
		api.getDailyActivityTimetable({ pg,exportData })
			.then(response => {
				dispatch(getDailyActivitySuccess(response.data, pg))
				if(exportData===true){
					window.open(`${URLS.DAILYACTIVITYTIMETABLE_DOWNLOAD}`);
				}
			})
			.catch(err => {
				toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
				dispatch(getDailyActivityError(getErrorMessage(err)))
			})
	}
}


export const addDailyActivityError = (message) => {
	return {
		type: ADD_DAILY_ACTIVITY_TIME_TABLE_ERROR,
		message
	}
}
export const addDailyActivitySuccess = (timeTable) => {
	return {
		type: ADD_DAILY_ACTIVITY_TIME_TABLE,
		timeTable
	}
}

export const addDailyActivity = (timeTable) => {
	return dispatch => {
		dispatch({ type: SET_LOADING_DAILY_ACTIVITY_TIME_TABLE });
		api.addDailyActivityTimetable(timeTable)
			.then(response => {
				dispatch(addDailyActivitySuccess(response.data));
				dispatch(getDailyActivity(-1));
			})
			.catch(err => {
				toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
				dispatch(addDailyActivityError(getErrorMessage(err)))
			})
	}
}


export const updateDailyActivityError = (message) => {
	return {
		type: UPDATE_DAILY_ACTIVITY_TIME_TABLE_ERROR,
		message
	}
}

export const updateDailyActivitySuccess = (timeTable) => {
	return {
		type: UPDATE_DAILY_ACTIVITY_TIME_TABLE,
		timeTable
	}
}

export const updateDailyActivity = (timeTable) => {
	return dispatch => {
		dispatch({ type: SET_LOADING_DAILY_ACTIVITY_TIME_TABLE });
		api.updateDailyActivityTimetable(timeTable)
			.then(response => {
				dispatch(updateDailyActivitySuccess(response.data))
				dispatch(getDailyActivity(-1));
			})
			.catch(err => {
				toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
				dispatch(updateDailyActivityError(getErrorMessage(err)))
			})
	}
}


export const deleteDailyActivityTimeTableSuccess = (dailyTimeTableId) => {
	return {
		type: DELETE_DAILY_ACTIVITY_TIME_TABLE,
		dailyTimeTableId
	}
}

export const deleteDailyActivityTimeTableError = (message) => {
	return {
		type: DELETE_DAILY_ACTIVITY_TIME_TABLE_ERROR,
		message
	}
}

export const deleteDailyActivityTimeTable = (dailyTimeTableId) => {
	return dispatch => {
		dispatch({ type: SET_LOADING_DAILY_ACTIVITY_TIME_TABLE });
		api.deleteDailyActivityTimetable({ dailyTimeTableId })
			.then(response => {
				dispatch(deleteDailyActivityTimeTableSuccess(dailyTimeTableId))
			})
			.catch(err => {
				toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
				dispatch(deleteDailyActivityTimeTableError(getErrorMessage(err)))
			})
	}
}
