import React, { Component } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import { addHomework, updateHomeworks } from '../../../Store/action/Homework';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { getSubject } from '../../../Store/action/Subjects';
import { getClass } from '../../../Store/action/Classes';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Editor } from 'react-draft-wysiwyg';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import pdf from '../../../assets/icon/pdf (4).svg';
import document from '../../../assets/icon/doc.svg';
import close from '../../../assets/icon/close.svg';
import moment from 'moment';
import './style.css';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { trim } from '../../../utils/utils';
import * as action from '../../../Store/action/Homework';

const SignupSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  startDate: Yup.string().required('Required'),
  deadlineDate: Yup.string().required('Required'),
  classId: Yup.string().required('Class is required'),
  subjectId: Yup.string().required('Subject is required'),
});

class AddHomeWork extends Component {
  input = React.createRef();
  state = {
    classId: '',
    subjectId: '',
    title: '',
    startDate: new Date(),
    deadlineDate: new Date(),
    description: EditorState.createEmpty(),
    src: null,
    document: [],
    imageUrls: [],
    homeworkId: '',
  };

  componentDidMount() {
    const { homework } = this.props.location.state || {};
    this.props.onGetSubjects(-1, '', 'ACTIVE');
    this.props.onGetClass();
    if (homework) {
      const blocksFromHTML = convertFromHTML(
        homework.description && homework.description
      );
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      this.setState({
        ...homework,
        document: homework.homeworkdocuments,
        homeworkId: homework.id,
        startDate: new Date(homework.startDate),
        deadlineDate: new Date(homework.deadlineDate),
        description: EditorState.createWithContent(content),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push('/admin/homeWork');
     // this.props.onGetHomeworks(1, '', '', '', moment(new Date()).format('YYYY-MM-DDT00:00:00.000[Z]'), '');
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // this.setErrors({ subjectName: nextProps.error });
    }
  }

  submitHandler = (values) => {
    const data = {
      ...values,
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      deadlineDate: moment(values.deadlineDate).format(
        'YYYY-MM-DD'
      ),
    };
    if (this.state.homeworkId) {
      this.props.onUpdateHomework(data);
    } else {
      this.props.onAddHomework(data);
    }
  };

  onSelectFile = (documents, setFieldValue) => (e) => {
    const files = e.target.files;
    const { imageUrls } = this.state;
    for (let file of files) {
      documents.push(file);
      if (file.type.startsWith('image')) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          imageUrls.push(reader.result);
          this.setState({ imageUrls });
        });
        reader.readAsDataURL(file);
      }
    }
    setFieldValue('document', documents);
    this.setState({});
  };

  imageRemoveHandler = (doc, image) => {
    this.setState((s) => ({
      imageUrls: s.imageUrls.filter((p) => p !== image),
      document: s.document.filter((p) => p !== doc),
    }));
  };

  documentRemoveHandler = (document) => {
    this.setState((s) => ({
      document: s.document.filter((p) => p !== document),
    }));
  };

  render() {
    const { subject, classes,user } = this.props;
    const { imageUrls } = this.state;
    const subjects = subject.data.map((a) => ({
      label: a.subjectName,
      value: a.id,
    }));

    const classOptions = classes.data.map((a) => ({
      label: `${a.standard.standardName}-${a.className}, ${a.medium}`,
      value: a.id,
    }));

    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            classId: this.state.classId,
            document: this.state.document,
            description: this.state.description,
            subjectId: this.state.subjectId,
            title: this.state.title,
            startDate: this.state.startDate,
            deadlineDate: this.state.deadlineDate,
            homeworkId: this.state.homeworkId,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler({
              ...values,
              description: draftToHtml(
                convertToRaw(values.description.getCurrentContent())
              ),
              startDate: moment(values.startDate).format('YYYY-MM-DD'),
              deadlineDate: moment(values.deadlineDate).format('YYYY-MM-DD'),
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">
                {this.state.title === '' ? 'Add Homework' : 'Update Homework'}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="6">
                    <FormGroup>
                      <Label>Title<span className="required">*</span></Label>
                      <Input
                        type="title"
                        invalid={errors.title && touched.title}
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        placeholder="Title"
                      />
                      <FormFeedback>
                        {errors.title && touched.title && errors.title}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs="3">
                    <FormGroup>
                      <Label>Start Date</Label>
                      <div>
                        <DatePicker
                          isClearable
                          minDate={new Date()}
                          className="form-control"
                          wrapperClassName="w-100"
                          selected={values.startDate}
                          onChange={(date) => {
                            setFieldValue('startDate', date);
                            if (date > new Date(values.deadlineDate))
                              setFieldValue('deadlineDate', date);
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col xs="3">
                    <FormGroup>
                      <Label>Deadline Date</Label>
                      <div>
                        <DatePicker
                          isClearable
                          minDate={new Date(values.startDate)}
                          // maxDate={values.startDate}
                          className="form-control"
                          wrapperClassName="w-100"
                          selected={values.deadlineDate}
                          onChange={(date) =>
                            setFieldValue('deadlineDate', date)
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">
                    <FormGroup>
                      <Label>Select Class<span className="required">*</span></Label>
                      <Select
                        name="classId"
                        onChange={(value) =>
                          setFieldValue('classId', value.value)
                        }
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={
                          classOptions
                            ? classOptions.find(
                              (p) => p.value === values.classId
                            )
                            : ''
                        }
                        options={classOptions}
                      />
                      <FormFeedback>
                        {errors.classId && touched.classId && errors.classId}
                      </FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col xs="4">
                    <FormGroup>
                      <Label>Subject<span className="required">*</span></Label>
                      <Select
                        name="subjectId"
                        onChange={(value) =>
                          setFieldValue('subjectId', value.value)
                        }
                        onBlur={handleBlur}
                        className="is-invalid"
                       
                        value={
                          subjects
                            ? subjects.find((s) => s.value === values.subjectId)
                            : ''
                        }
                        options={subjects}
                      />
                      <FormFeedback>
                        {errors.subjectId &&
                          touched.subjectId &&
                          errors.subjectId}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label>Description</Label>
                  <Editor
                    editorState={values.description}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={(value) =>
                      setFieldValue('description', value)
                    }
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col xs="1">
                      <label className="form-control-label">Document</label>
                      <FormGroup>
                        <input
                          accept="application/pdf,application/msword, .jpg, .jpeg, .docx"
                          hidden
                          ref={this.input}
                          type="file"
                          multiple
                          onChange={this.onSelectFile(
                            values.document,
                            setFieldValue
                          )}
                        />
                        <FormFeedback>
                          {errors.document &&
                            touched.document &&
                            errors.document}
                        </FormFeedback>
                        <button
                          className="btn btn-primary"
                          onClick={(e) =>
                            this.input.current && this.input.current.click()
                          }
                        >
                          Browse
                        </button>
                      </FormGroup>
                    </Col>

                    <Col xs="4">
                      <div className="d-flex overflow-auto">
                        {values.document
                          .filter((p) => p.type.startsWith('image'))
                          .map((d, i) => (
                            <div className="d-flex flex-column m-2">
                              <div
                                style={{ position: 'relative', left: '20px' }}
                              >
                                <button
                                  className="btn btn-link"
                                  onClick={() =>
                                    this.imageRemoveHandler(d, imageUrls[i])
                                  }
                                  style={{
                                    position: 'absolute',
                                    paddingLeft: '40px',
                                    alignSelf: 'center',
                                  }}
                                >
                                  <img src={close} alt="" />
                                </button>
                              </div>
                              <img
                                alt="Crop"
                                style={{
                                  width: '100px',
                                  height: '100px',
                                  border: '2px solid #b3b3b3',
                                }}
                                src={imageUrls[i]}
                              />
                              <span classes="mt-2">{trim(d.name, 13)}</span>
                            </div>
                          ))}
                      </div>
                    </Col>
                    <div className="d-flex overflow-auto">
                      {values.document
                        .filter((p) => p.type.startsWith('application/pdf'))
                        .map((d, j) => (
                          <div className="d-flex flex-column m-2">
                            <div style={{ position: 'relative', left: '20px' }}>
                              <button
                                className="btn btn-link"
                                onClick={() => this.documentRemoveHandler(d)}
                                style={{
                                  position: 'absolute',
                                  paddingLeft: '40px',
                                  alignSelf: 'center',
                                }}
                              >
                                <img src={close} alt="" />
                              </button>
                            </div>
                            <img
                              alt="Crop"
                              style={{ width: '100px', height: '100px' }}
                              src={pdf}
                            />
                            <span classes="mt-2 text-wrap">
                              {trim(d.name, 13)}
                            </span>
                          </div>
                        ))}
                    </div>

                    <div className="d-flex overflow-auto">
                      {values.document
                        .filter(
                          (p) =>
                            !p.type.startsWith('image') &&
                            !p.type.startsWith('application/pdf')
                        )
                        .map((d, i) => (
                          <div className="d-flex flex-column m-2">
                            <div style={{ position: 'relative', left: '20px' }}>
                              <button
                                className="btn btn-link"
                                onClick={() => this.documentRemoveHandler(d)}
                                style={{
                                  position: 'absolute',
                                  paddingLeft: '40px',
                                  alignSelf: 'center',
                                }}
                              >
                                <img src={close} alt="" />
                              </button>
                            </div>
                            <img
                              alt="Crop"
                              style={{ width: '100px', height: '100px' }}
                              src={document}
                            />
                            <span classes="mt-2 text-wrap">
                              {trim(d.name, 13)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </Row>
                </FormGroup>

                <div className="d-flex justify-content-start">
                  <button
                    disabled={this.props.loading}
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                  >
                    {this.props.loading ? (
                      <ClipLoader size={15} color={'#123abc'} loading={true} />
                    ) : (
                        'Save'
                      )}
                  </button>
                  <Link
                    className="btn btn-secondary"
                    to={{ pathname: '/admin/homeWork' }}
                  >
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.homework.addError,
    loading: state.homework.isLoading,
    subject: state.subject.subjects,
    classes: state.class.classes,
    user: state.login.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddHomework: (homeWorks) => dispatch(addHomework(homeWorks)),
    onUpdateHomework: (homeWorks) => dispatch(updateHomeworks(homeWorks)),
    onGetSubjects: (pg, a, status) => dispatch(getSubject(pg, a, status)),
    onGetClass: () => dispatch(getClass()),
    onGetHomeworks: (pg, searchName, selectedClass, selectedSubject, startDate, deadlineDate, teacherId) => dispatch(action.getHomeworks(pg, searchName, selectedClass, selectedSubject, startDate, deadlineDate, teacherId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddHomeWork);
