import React, { Component } from 'react'
import routes from "../../routes";
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserProfile } from '../../Store/action/userProfile';

class Dashboard extends Component {

    componentDidMount() {
        this.props.getUserProfile();
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        exact
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    menuClickHandler = () => {
        document.body.classList.toggle('layout-fullwidth');
    }

    render() {
        if (!this.props.user) {
            return <Redirect from="/" to="/signin" />
        }
        return (
            <div style={{ width: '100%' }}>
                <ToastContainer />
                <Navbar {...this.props} />
                <Sidebar
                    {...this.props}
                    routes={routes}
                />
                <div id="wrapper">
                    <div id="main-content" >
                        <div className="container-fluid">
                            <div className="block-header">
                                <div className="row ">
                                    <div className="col-lg-6 col-md-8 col-sm-12">
                                        <span onClick={this.menuClickHandler} >
                                            {/* <h2><a href="javascript:void(0);" className="btn btn-xs btn-link btn-toggle-fullwidth" ><i className="fa fa-arrow-left"></i></a> Dashboard</h2> */}
                                        </span>
                                        {/* <ul className="breadcrumb">
                                            <li className="breadcrumb-item"> <Link to={{ pathname: '/' }}> <i className="icon-home"></i></Link></li>
                                            <li className="breadcrumb-item active">Dashboard</li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            <Switch>{this.getRoutes(routes)}</Switch>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapSateToProps = state => {
    return {
        user: state.login.user
    }
}
const mapDispatchToProps = dispatch => ({
    getUserProfile: () => dispatch(getUserProfile()),
});

export default connect(mapSateToProps, mapDispatchToProps)(Dashboard)
