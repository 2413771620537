import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import "react-image-crop/dist/ReactCrop.css";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Classes from './Store/reducer/Classes';
import TeacherReducer from './Store/reducer/Teachers';
import Login from './Store/reducer/Auth/login'
import Axios from 'axios';
import './api/mocks'
import { loginSuccess } from './Store/action/Auth/login';
import Subjects from './Store/reducer/Subjects';
import Students from './Store/reducer/Students';
import HomeworkReducer from './Store/reducer/Homework';
import TeachersLogin from './Store/reducer/TeachersAuth/login';
import Standards from "./Store/reducer/Standard";
import Event from "./Store/reducer/Event";
import AttendanceReducer from "./Store/reducer/Attendance";
import Leave from "./Store/reducer/StudentLeave"
import MyLeave from "./Store/reducer/MyLeave"
import principalGetTeacherLeave from "./Store/reducer/Leave";
import holidaysReducer from './Store/reducer/holiday';
import breakfastReducer from './Store/reducer/breakfast';
import DailyActivityTimeTable from './Store/reducer/DailyActivityTimetable'
import Result from "./Store/reducer/Result"
import ExamTimeTable from "./Store/reducer/ExamTimeTable"
import Profile from "./Store/reducer/Profile";
import forgotPassword from './Store/reducer/Auth/forgotPassword';
import resetPassword from './Store/reducer/Auth/resetPassowrd';
import userProfileReducer from './Store/reducer/userProfile';
import SchoolReducer from './Store/reducer/School';
import Drafts from './Store/reducer/Drafts';
import { RESET_STORE } from './Store/action/actions';

const appReducer = combineReducers({
    class: Classes,
    login: Login,
    teacherLogin: TeachersLogin,
    subject: Subjects,
    teacher: TeacherReducer,
    students: Students,
    homework: HomeworkReducer,
    standard: Standards,
    school: SchoolReducer,
    event: Event,
    attendance: AttendanceReducer,
    leave: Leave,
    myLeave: MyLeave,
    principalGetTeacherLeave: principalGetTeacherLeave,
    holiday: holidaysReducer,
    breakfast: breakfastReducer,
    dailyActivityTimeTable: DailyActivityTimeTable,
    result: Result,
    examTimeTable: ExamTimeTable,
    profile: Profile,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    userProfile: userProfileReducer,
    drafts:Drafts
});

const rootReducer = (state, action) => {
    if (action.type === RESET_STORE)
        state = undefined;
    return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
const user = JSON.parse(localStorage.getItem('auth'));
if (user) {
    store.dispatch(loginSuccess(user));
    Axios.defaults.headers = { 'auth-token': user.secretToken };
}
ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
