import { toast } from 'react-toastify';
import {userProfile} from '../../api/userProfile';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';


export const getUserProfileSuccess = (userProfile) => {
	return {
		type: GET_USER_PROFILE,
		userProfile,
	};
};

export const getUserProfileError = (message) => {
	return {
		type: GET_USER_PROFILE_ERROR,
		message,
	};
};

export const getUserProfile = () => {
	return dispatch => {
		userProfile()
			.then(response => {
				dispatch(getUserProfileSuccess(response.data));
			})
			.catch(err => {
				// toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
				// 	position: toast.POSITION.TOP_RIGHT,
				// });
				dispatch(getUserProfileError(err.response ? err.response.data.message : 'Something Went Wrong. Please try again'));
			});
	};
};
