import * as actionTypes from '../action/Students'

const initialState = {
  students: {
    data: [],
    pagination: null
  },
  addError: '', getError: '',
  currentPage: 1,
  isLoading: false,
  excelLoading:false
}

const studentReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.STUDENT_STATUS_CHANGE:
      const index = state.students.data.findIndex(t => t.id === action.studentId);
      const student = state.students.data[index];
      student.status = action.status;
      return {
        ...state,
        students: { ...state.students, data: Object.assign(state.students.data, { [index]: student }) },
        isStatusChangeInProcess: false,
        isLoading: false,
        getError: ''
      };

    case actionTypes.ADD_STUDENTS:
      const total = (state.students.pagination && state.students.pagination.total + 1) || 1;
      let pages = (state.students.pagination && state.students.pagination.pages) || 1;
      if (total / 10 > pages) {
        pages += 1;
      }
      console.log(total, pages, state)
      return {
        ...state,
        isLoading: false,
        students: {
          ...state.students,
          pagination: { ...state.students.pagination, total, pages },
          data: [...state.students.data, action.student.data]
        },
        addError: ''
      };

      case actionTypes.ADD_STUDENTS_EXCEL:
        console.log("====", state)
        return {
          ...state,
          excelLoading: false,
          // studentsExcel: {
          //   ...state.studentsExcel,
          //   data: [...state.studentsExcel.data, action.studentExcel.data]
          // },
          addError: ''
        };

    case actionTypes.UPDATE_STUDENTS:
      const studentIndex = state.students.data.findIndex(a => a.id === action.students.data.id);
      return {
        ...state,
        isLoading: false,
        addError: '',
        students: { ...state.students, data: Object.assign(state.students.data, { [studentIndex]: action.students.data }) }
      }

    case actionTypes.UPDATE_STUDENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }

    case actionTypes.ADD_STUDENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }

      case actionTypes.ADD_STUDENTS_EXCEL_ERROR:
        return {
          ...state,
          excelLoading: false,
          addError: action.message
        }
    case actionTypes.GET_STUDENTS:
      return {
        ...state,
        students: action.students,
        currentPage: action.pg || 1,
        isLoading: false,
        getError: ''
      }

    case actionTypes.GET_STUDENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        getError: action.message,
      }
    case actionTypes.SET_LOADING_STUDENT:
      return {
        ...state,
        isLoading: true,
        addError: '', getError: ''
      }

      case actionTypes.SET_LOADING_STUDENT_EXCEL:
        return {
          ...state,
          excelLoading : true,
          addError: '', getError: ''
        }
    case actionTypes.SET_STUDENT_STATUS_IN_PROCESS:
      return {
        ...state,
        isStatusChangeInProcess: true,
        addError: '', getError: ''
      };
    case actionTypes.STUDENT_STATUS_CHANGE_ERROR:
      return {
        ...state,
        isStatusChangeInProcess: false,
        getError: action.message
      };
    default:
      return state;
  }
}

export default studentReducer
