import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import * as action from '../../../Store/action/Standard';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const SignupSchema = Yup.object().shape({
  standardName: Yup.string().required('Standard is required'),
});

class AddStandard extends Component {
  state = {
    standardName: '',
  };

  componentDidMount() {
    const { standard } = this.props.location.state || {};
    if (standard) {
      this.setState({ ...standard, standardId: standard.id });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.addError) {
      this.props.history.push('/admin/standard');
      this.props.onGetStandard();
    } else if (nextProps.addError && !this.props.addError) {
      // toast.error(nextProps.addError, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // this.setErrors({ subjectName: nextProps.error });
    }
  }

  submitHandler = (values) => {
    if (this.state.standardId) {
      this.props.onUpdateStandard(values);
    } else {
      this.props.onStandardAdd(values);
    }
  };

  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            standardName: this.state.standardName,
            standardId: this.state.standardId,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">
                {this.state.standardName === ''
                  ? 'Add Standard'
                  : 'Update Standard'}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="3">
                    <FormGroup>
                      <Label>Standard Name<span className="required">*</span></Label>
                      <Input
                        type="className"
                        invalid={errors.standardName && touched.standardName}
                        name="standardName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.standardName}
                        placeholder="Standard Name"
                      />
                      <FormFeedback>
                        {errors.standardName &&
                          touched.standardName &&
                          errors.standardName}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="d-flex justify-content-start">
                  <button
                    disabled={this.props.loading}
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                  >
                    {this.props.loading ? (
                      <ClipLoader size={15} color={'#123abc'} loading={true} />
                    ) : (
                        'Save'
                      )}
                  </button>
                  <Link
                    className="btn btn-secondary"
                    to={{ pathname: '/admin/standard' }}
                  >
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.standard.isLoading,
    addError: state.standard.addError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onStandardAdd: (standard) => dispatch(action.addStandard(standard)),
    onUpdateStandard: (standard) => dispatch(action.updateStandard(standard)),
    onGetStandard: (pg, searchName, status) => dispatch(action.getStandard(pg, searchName, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStandard);
