import React, { Component } from 'react'
import { Alert, Card, CardBody, CardHeader, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from "react-spinners";
import * as action from '../../../Store/action/StudentLeave'
import ConfirmationDialog from '../../ConfirmDialog/ConfirmDialog';
import ApproveButton from "../../../assets/icon/Approve-btn.svg";
import RejectButton from "../../../assets/icon/Reject-btn.svg";
import moment from 'moment';
import './leave.css';

class Leave extends Component {
	state = {
		pg: this.props.currentPage,
		leaveId: ''
	}

	componentDidMount() {
		if (!this.props.leave.data.length) {
			this.props.onGetLeave('', moment(new Date()).format('YYYY-MM-DDT00:00:00.000[Z]'), moment(new Date()).format('YYYY-MM-DDT00:00:00.000[Z]'));
		}
	}

	confirmationDialogBox = (deleteEvent) => {
		this.setState({
			deleteEvent,
		});
	}

	deleteHandler = (ec) => {
		this.setState({ deleteEvent: null });
	}

	paginationChangeHandler = (pg, value) => () => {
		this.props.onGetLeave(value);
		this.setState({ pg: value });
	}

	renderPagination(pagination) {
		const pages = [];
		for (let i = 1; i <= pagination; i++) {
			pages.push(
				<PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
					<PaginationLink tag="button">{i}</PaginationLink>
				</PaginationItem>
			);
		}
		return pages;
	}

	approvedStatusChangeHandler = (p) => {
		this.setState({ leaveId: p.id });
		this.props.onUpdateLeave({ leaveId: p.id, status: 'Approve' })
	}

	rejectStatusChangeHandler = (p) => {
		this.setState({ leaveId: p.id });
		this.props.onUpdateLeave({ leaveId: p.id, status: 'Reject' })
	}

	render() {
		const { pg } = this.state;
		const { loading, error, leave, statusLoading } = this.props;
		// const leave = leave.data.map(p => p);
		return (
			<div className="row clearfix">
				<div className="col-lg-12">
					<ConfirmationDialog
						isOpen={!!this.state.deleteEvent}
						deleteHandler={() => this.deleteHandler(this.state.deleteEvent)}
						closeHandler={() => this.confirmationDialogBox(null)} />
					<Card className="c-style">
						<CardHeader className="h-style">
							<div className="row m-r-0 m-l-0">
								<div className="c-title-style">
									Leave
								</div>
								<div className="ml-auto btn-style">
								</div>
							</div>
						</CardHeader>
						<CardBody>
							<Table className="table-responsive-sm table-responsive-md " hover>
								<thead className='table-header-wrapper'>
									<tr>
										<th>#</th>
										<th>Start Date</th>
										<th>End Date</th>
										<th>Student Name</th>
										<th>Roll No.</th>
										<th>class Name</th>
										<th>Reason</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{!loading && !error &&
										leave.data.map((p, index) => {
											return (
												<tr>
													<td><strong>{(pg - 1) * 10 + index + 1}</strong></td>
													<td>{moment(p.startDate).format('DD/MM/YYYY')}</td>
													<td>{moment(p.endDate).format('DD/MM/YYYY')}</td>
													<td>{p.student && p.student.firstName} {p.student && p.student.lastName}</td>
													<td>{p.student && p.student.rollNumber}</td>
													<td>{p.student && `${p.student.class.standard.standardName}-${p.student.class.className}, ${p.student.class.medium}`}</td>
													<td className="reason">{p.reason}</td>
													<td>{p.status === 'Pending' ?
														statusLoading && this.state.leaveId === p.id ?
															<ClipLoader
																size={30}
																color={"#123abc"}
																loading={true}
															/> :
															<div className="row">
																<button className="btn btn-link p-1 m-1" value={this.state.Approved}
																	onClick={() => this.approvedStatusChangeHandler(p)}><img src={ApproveButton} alt='#'/>
																</button>
																<button className="btn btn-link p-1 m-1" value={this.state.Reject}
																	onClick={() => this.rejectStatusChangeHandler(p)}><img src={RejectButton} alt='#'/></button>
															</div> : p.status === 'Approve' ?
															<span class="badge badge-success ">Approved</span>
															:
															p.status === 'Reject' ?
																<span class="badge badge-danger ">Rejected</span>
																: p.status === 'Cancel' ?
																	<span class="badge badge-warning ">Canceled</span> : null}</td>
												</tr>
											)
										})}
								</tbody>
							</Table>

							{this.props.loading
								?
								<div className="loader-wrapper">
									<ClipLoader
										size={35}
										color={"#123abc"}
										loading={true}
									/>
								</div>
								:
								<div className="d-flex justify-content-end p-2">
									{this.props.leave.data.length > 0 && this.props.leave.pagination &&
										<Pagination className="pull-right">
											<PaginationItem>
												<PaginationLink
													onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()}
													previous tag="button" />
											</PaginationItem>
											{this.renderPagination(this.props.leave.pagination.pages)}
											<PaginationItem>
												<PaginationLink
													onClick={() => this.props.leave.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()}
													next tag="button" />
											</PaginationItem>
										</Pagination>
									}
								</div>
							}
							{!this.props.loading && (this.props.error || !this.props.leave.data.length) &&
								<div className="error-style">
									<Alert color="danger">{this.props.error ? this.props.error : this.props.leave.message}</Alert>
								</div>}
						</CardBody>
					</Card>
				</div>
			</div>
		)
	}
}


const mapStateToProps = state => {
	return {
		leave: state.leave.leave,
		currentPage: state.leave.currentPage,
		error: state.leave.error,
		loading: state.leave.isLoading,
		statusLoading: state.leave.statusLoading,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGetLeave: (pg, startDate, endDate,) => dispatch(action.getLeave(pg, startDate, endDate,)),
		onUpdateLeave: (leave) => dispatch(action.updateLeave(leave)),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Leave)
