import * as actionTypes from '../action/MyLeave';

const initialState = {
  teacherLeave: {
    data: [],
    pagination: null
  },
  addError: '',
  getError: '',
  currentPage: 1,
  isLoading: false,
  statusLoading: false,
};

const teacherLeaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TEACHER_LEAVE:
      const total = state.teacherLeave.pagination && state.teacherLeave.pagination.total + 1;
      let pages = state.teacherLeave.pagination && state.teacherLeave.pagination.pages;
      if (total / 10 > pages) {
        pages += 1;
      }
      return {
        ...state,
        isLoading: false,
        addError: '',
        teacherLeave: {
          ...state.teacherLeave,
          pagination: { ...state.teacherLeave.pagination, total, pages },
          data: [...state.teacherLeave.data, action.leave]
        }
      };

    case actionTypes.ADD_TEACHER_LEAVE_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }
    case actionTypes.GET_TEACHER_LEAVE:
      return {
        ...state,
        teacherLeave: action.leave,
        currentPage: action.pg || 1,
        isLoading: false,
        getError: ''
      }

    case actionTypes.GET_TEACHER_LEAVE_ERROR:
      return {
        ...state,
        isLoading: false,
        getError: action.message,
      }

    case actionTypes.TEACHER_LEAVE_SET_LOADING:
      return {
        ...state,
        isLoading: true,
        getError: '',
        addError: ''
      }

    case actionTypes.TEACHER_LEAVE_STATUS_LOADING:
      return {
        ...state,
        statusLoading: true,
        getError: '',
        addError: ''
      }

    case actionTypes.UPDATE_TEACHER_LEAVE:
      const leaveIndex = state.teacherLeave.data.findIndex(a => a.id === action.id);
      const leave = state.teacherLeave.data[leaveIndex];
      leave.status = action.status;
      return {
        ...state,
        statusLoading: false,
        addError: '',
        teacherLeave: Object.assign(state.teacherLeave, { [leaveIndex]: leave }),
      }

    case actionTypes.UPDATE_TEACHER_LEAVE_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }
    default:
      return state;
  }
}

export default teacherLeaveReducer