import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, FormGroup, FormFeedback, } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import close from '../../../assets/icon/close.svg'
import { ClipLoader } from "react-spinners";
import { connect } from 'react-redux';
import { uploadEventFile } from '../../../Store/action/Event';
import { getEvent } from '../../../Store/action/Event';

const SignupSchema = Yup.object().shape({
});

class UploadEventFiles extends Component {
    input = React.createRef();
    state = {
        imageUrls: [],
        photos: [],
        eventId: ''
    }

    imageRemoveHandler = (doc, image) => {
        this.setState(s => ({ photos: s.photos.filter(p => p !== doc), imageUrls: s.imageUrls.filter(a => a !== image) }));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen && !this.props.isOpen) {
            this.setState({
                imageUrls: [],
                photos: [],
            });
        }
        const { event } = nextProps;
        this.setState({ eventId: event });
        if (!nextProps.isLoading && this.props.isLoading && !nextProps.error) {
            this.props.isClose();
            this.props.onGetEvent();
        }
    }

    onSelectFile = (documents, setFieldValue) => e => {
        const files = e.target.files;
        const { imageUrls } = this.state;
        for (let file of files) {
            documents.push(file);
            if (file.type.startsWith('image')) {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    imageUrls.push(reader.result)
                    this.setState({ imageUrls })
                });
                reader.readAsDataURL(file);
            }
        }
        setFieldValue('photos', documents);
    };

    submitHandler = (values) => {
        this.props.onUploadEventFiles(values)
    }

    render() {
        const { imageUrls, eventId } = this.state;
        const { events } = this.props;
        const photos = events.data.filter(a => a.id === eventId);
        return (
            <Formik
                enableReinitialize
                initialValues={{ photos: this.state.photos, eventId }}
                validationSchema={SignupSchema}
                onSubmit={(values) => {
                    this.submitHandler(values);
                }}
            >{({ values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
                status,
                isSubmitting, }) => (
                    <Modal isOpen={this.props.isOpen}>
                        <div className="modal-header">
                            <h5 className="modal-title h2">Upload Photos</h5>
                            <Button className="close" onClick=""></Button>
                        </div>
                        <ModalBody>
                            <div className="col-12">
                                <FormGroup>
                                    <label className="form-control-label">Photos</label>
                                    <FormGroup>
                                        <input accept="image/jpg, image/jpeg, image/png" hidden ref={this.input} type="file" multiple
                                            onChange={this.onSelectFile(values.photos, setFieldValue)} />
                                        <FormFeedback>
                                            {errors.photos && touched.photos && errors.photos}
                                        </FormFeedback>
                                        <button className="btn btn-primary" onClick={e => this.input.current && this.input.current.click()}>Browse</button>
                                    </FormGroup>

                                    <div className="d-flex overflow-auto column">
                                        <div className="row">
                                            <div className="col-12 d-flex overflow-auto column">
                                                {values.photos.filter(p => p.type.startsWith('image')).map((d, i) =>
                                                    <div className="d-flex flex-column m-2">
                                                        <div style={{ position: "relative", left: "20px" }}>
                                                            <button className="btn btn-link" onClick={() => this.imageRemoveHandler(d, imageUrls[i])} style={{ position: "absolute", paddingLeft: "40px", alignSelf: "center" }}> <img src={close} alt="" /></button>
                                                        </div>

                                                        <img alt="Crop" style={{ width: "100px", height: "100px", border: "2px solid #b3b3b3" }}
                                                            src={imageUrls[i]} />
                                                        <span classes="mt-2">{d.name}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-12">
                                                {photos.map((d, i) =>
                                                    <div className="d-flex flex-row m-2">
                                                        {d.eventFiles.map(a => {
                                                            return (
                                                                <>
                                                                    <div className="d-flex flex-column">
                                                                        <img alt="Crop" style={{ width: "100px", height: "100px", border: "2px solid #b3b3b3", marginLeft: '10px' }}
                                                                            src={a.photo} />
                                                                        {/* <span classes="mt-2">{a.name}</span> */}
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center">
                            <Button color="primary" style={{ color: "white" }} className="mr-2" onClick={handleSubmit}>
                                {this.props.isLoading ? <ClipLoader
                                    sizeUnit={"px"}
                                    size={20}
                                    color={'#fff'}
                                    loading={true}
                                /> : 'Save'}
                            </Button>
                            <Button className="btn-outline-primary" style={{ background: "none", color: "#4FC1E9" }}
                                onClick={this.props.isClose}>Close</Button>
                        </ModalFooter>
                    </Modal>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.event.uploadError,
        isLoading: state.event.isPhotosUploading,
        events: state.event.events
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUploadEventFiles: (photos) => dispatch(uploadEventFile(photos)),
        onGetEvent: (pg) => dispatch(getEvent(pg)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadEventFiles);