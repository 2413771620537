import { toast } from 'react-toastify';
import { teachersLogin } from '../../../api/teachersAuth'
import Axios from 'axios';
export const LOGIN = 'LOGIN';
export const SET_LOADING_TEACHER_LOGIN = 'SET_LOADING_TEACHER_LOGIN'
export const LOGOUT = 'LOGOUT'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const loginSuccess = (user) => {
  return {
    type: LOGIN,
    user
  }
}

export const login = (data) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_TEACHER_LOGIN });
    teachersLogin(data)
      .then(response => {
       localStorage.setItem("auth", JSON.stringify(response.data.data[0]));
        const token = response.data.data[0].secretToken;
        Axios.defaults.headers = { 'auth-token': token };
        dispatch(loginSuccess(response.data.data[0]))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
          dispatch(loginError(err.response.data.message));
      })
  }
}


export const loginError = (message) => {
  return {
    type: LOGIN_ERROR,
    message
  }
}

export const logoutSuccess = () => {
  return {
    type: LOGOUT,
  }
}

export const logout = () => {
  return dispatch => {
    dispatch(logoutSuccess())
  }
}
