import React, { Component } from 'react'
import { Alert, Card, CardBody, CardHeader, Pagination, PaginationItem, PaginationLink, Table ,Spinner} from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from "react-spinners";
import * as action from '../../../Store/action/DailyActivityTimetable';
import ConfirmationDialog from '../../ConfirmDialog/DeleteConfirmDialog';
import { Link } from 'react-router-dom';
import img from '../../../assets/images/user-small.png'
import { URLS } from '../../../api/URLS';

class DailyActivityTimetable extends Component {
	state = {
		pg: this.props.currentPage,
		dailyTimeTableId: '',
		hover: {},
		export:true
	}

	componentDidMount() {
		if (!this.props.dailyActivityTimeTable.data.length) {
			this.props.onGetDailyActivityTimeTable(-1);
		}
	}

	confirmationDialogBox = (deleteEvent) => {
		this.setState({
			deleteEvent,
		});
	}


	deleteHandler = (ec) => {
		this.setState({ deleteEvent: null });
		this.props.onDeleteActivityTimeTable(ec.id);
	}

	paginationChangeHandler = (pg, value) => () => {
		// this.props.onGetDailyActivityTimeTable(value);
		this.setState({ pg: value });
	}

	renderPagination(pagination) {
		const pages = [];
		for (let i = 1; i <= pagination; i++) {
			pages.push(
				<PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
					<PaginationLink tag="button">{i}</PaginationLink>
				</PaginationItem>
			);
		}
		return pages;
	}


	onMouseEnterHandler = id => () => {
		this.setState(s => ({ hover: { ...s.hover, [id]: true } }))
	}

	onMouseLeaveHandler = id => () => {
		this.setState(s => ({ hover: { ...s.hover, [id]: false } }))
	}

	handleExport = () => {
		this.props.onGetDailyActivityTimeTable(this.state.pg, this.state.export)
		
  };

	render() {
		const { dailyActivityTimeTable, timetable, loading, error } = this.props;

		return (
			<div className="row clearfix">
				<div className="col-lg-12">
					<ConfirmationDialog
						isOpen={!!this.state.deleteEvent}
						deleteHandler={() => this.deleteHandler(this.state.deleteEvent)}
						closeHandler={() => this.confirmationDialogBox(null)} />
					<Card className="c-style">
						<CardHeader className="h-style">
							<div className="row m-r-0 m-l-0">
								<div className="c-title-style">
									Daily Activity Timetable
								</div>
								<div className="ml-auto btn-style">
							{dailyActivityTimeTable.data.length > 0&&
										<span>
								<Link
                       class="btn btn-outline-dark"
                      onClick={() => this.handleExport()}
                      to={{ pathname: '/admin/dailyActivityTimetable' }}
                    >
                       {this.props.exportLoading&&<Spinner size="sm" style={{marginRight:5}}></Spinner>}
                        Export
                         </Link><span style={{marginLeft:10}}/></span>}
									<Link class="btn btn-outline-dark" to={{ pathname: '/admin/dailyActivityTimetable/add' }}>Add</Link>
								</div>
							</div>
						</CardHeader>
						<CardBody>
							<Table>
								<thead className='table-header-wrapper'>
									<tr>
										<td>Monday</td>
										<td>Tuesday</td>
										<td>Wednesday</td>
										<td>Thursday</td>
										<td>Friday</td>
										<td>Saturday</td>
									</tr>
								</thead>
								<tbody>
									{!loading && !error &&
										timetable.map((day, index) => {
											return (
												<tr>
													{day.map(p => <td onMouseEnter={this.onMouseEnterHandler(p.id)}
														onMouseLeave={this.onMouseLeaveHandler(p.id)}>
														{p ? <div className="row">
															<div>
																<div>{p.subject}</div>
																<div>{p.duration}</div>
																<div><img src={!p.user.profilePic ? p.user.profilePic : img} alt="" class="rounded-circle" width="20px" height="20px" />{p.user.firstName} {p.user.lastName}</div>
															</div>
															<div className="pt-3">
																{this.state.hover[p.id] &&
																	<div>
																		<Link className="p-2"
																			to={{ pathname: "/admin/dailyActivityTimetable/add", state: { dailyActivity: p } }}><i
																				class="icon-pencil" style={{ color: 'black' }} aria-hidden="true" /></Link>
																		<span className="p-2 " onClick={() => this.confirmationDialogBox(p)}>
																			<i class="icon-trash"
																				aria-hidden="true" /></span>
																	</div>
																}
															</div>
														</div> : '-'}
													</td>)}
												</tr>
											)
										})}
								</tbody>
							</Table>

							{loading
								?
								<div className="loader-wrapper">
									<ClipLoader
										size={35}
										color={"#123abc"}
										loading={true}
									/>
								</div>
								:
								<div className="d-flex justify-content-end p-2 ">
									{dailyActivityTimeTable.data.length > 0 && dailyActivityTimeTable.pagination &&
										<Pagination className="pull-right">
											<PaginationItem>
												<PaginationLink
													onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()}
													previous tag="button" />
											</PaginationItem>
											{this.renderPagination(dailyActivityTimeTable.pagination.pages)}
											<PaginationItem>
												<PaginationLink
													onClick={() => dailyActivityTimeTable.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()}
													next tag="button" />
											</PaginationItem>
										</Pagination>
									}
								</div>
							}
							{!loading && error &&
								<div className="error-style">
									<Alert color="danger">{error}</Alert>
								</div>}
						</CardBody>
					</Card>
				</div>
			</div>
		)
	}
}


const mapStateToProps = state => {
	return {
		dailyActivityTimeTable: state.dailyActivityTimeTable.dailyActivityTimeTable,
		timetable: state.dailyActivityTimeTable.timetable,
		error: state.dailyActivityTimeTable.getError,
		loading: state.dailyActivityTimeTable.isLoading,
		exportLoading:state.dailyActivityTimeTable.isExportActivityLoading,
		currentPage: state.dailyActivityTimeTable.currentPage,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGetDailyActivityTimeTable: (pg,exportData) => dispatch(action.getDailyActivity(pg,exportData)),
		onDeleteActivityTimeTable: (values) => dispatch(action.deleteDailyActivityTimeTable(values))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DailyActivityTimetable)
