import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';
import ImageModal from '../../Modal/ImageModal';
import User from '../../../assets/images/user.png';

class ResultDetails extends Component {
  state = {
    student: {},
    subjectresults: [],
  };

  componentWillReceiveProps(nextProps) {
    const { student } = nextProps;
    this.setState({ ...student });
  }

  render() {
    const { student, subjectresults } = this.props;
    if (!student && !subjectresults) return null;
    return (
      <div style={{width:'900px'}}>
        <Modal isOpen={this.props.isOpen} size="lg">
          <div className="modal-header">
            <h5 className="modal-title">Student's Result</h5>
            <Button className="close" onClick=""></Button>
          </div>
          <ModalBody >
            <div style={{  overflowY: 'auto' ,height:'300px'}}>
              <Table   hover>
                <thead className="table-header-wrapper">
                  <tr>
                    <th>#</th>
                    <th>Profile Pic</th>
                    <th>Name</th>
                    <th>Marks</th>
                  </tr>
                </thead>

                <tbody>
                  {student?.results?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <th>
                            <strong>{index + 1}</strong>
                          </th>
                          <td>
                            <ImageModal
                              alt="."
                              className="image img-fluid"
                              src={item?.student?.profilePic?item?.student?.profilePic:User}
                            />
                          </td>
                          <td>
                            {item.student.firstName} {item.student.lastName}
                          </td>
                          <td>{item.subjectresults.map((s) => s.marks)}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button
              className="btn-outline-primary"
              style={{ background: 'none', color: '#4FC1E9' }}
              onClick={this.props.isClose}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ResultDetails;
