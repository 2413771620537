import { toast } from 'react-toastify';
import * as api from '../../api/Event';
import { getErrorMessage } from '../../utils/utils';
export const ADD_EVENT = 'ADD_EVENT'
export const ADD_EVENT_ERROR = 'ADD_EVENT_ERROR'
export const GET_EVENT = 'GET_EVENT'
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR'
export const SET_LOADING_EVENT = 'SET_LOADING_EVENT'
export const SET_PHOTO_UPLOADING_EVENT = 'SET_PHOTO_UPLOADING_EVENT'
export const UPLOAD_EVENT_FILE = ' UPLOAD_EVENT_FILE'
export const UPLOAD_EVENT_FILE_ERROR = ' UPLOAD_EVENT_FILE_ERROR'

export const addEventSuccess = (event) => {
  return {
    type: ADD_EVENT,
    event
  }
}

export const addEventError = (message) => {
  return {
    type: ADD_EVENT_ERROR,
    message
  }
}

export const addEvent = (event) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_EVENT });
    api.addEvent(event)
      .then(response => {
        dispatch(addEventSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addEventError(getErrorMessage(err)))
      })
  }
}

export const getEventSuccess = (event, pg) => {
  return {
    type: GET_EVENT,
    event, pg
  }
}

export const getEventError = (message) => {
  return {
    type: GET_EVENT_ERROR,
    message
  }
}

export const getEvent = (pg, action) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_EVENT });
    api.getEvent({ pg, action })
      .then(response => {
        dispatch(getEventSuccess(response.data, pg))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getEventError(getErrorMessage(err)))
      })
  }
}

export const updateEventError = (message) => {
  return {
    type: UPDATE_EVENT_ERROR,
    message
  }
}
export const updateEventSuccess = (event) => {
  return {
    type: UPDATE_EVENT,
    event
  }
}

export const updateEvent = (event) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_EVENT });
    api.updateEvent(event)
      .then(response => {
        dispatch(updateEventSuccess(event))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateEventError(getErrorMessage(err)))
      })
  }
}

export const uploadEventFileError = (message) => {
  return {
    type: UPLOAD_EVENT_FILE_ERROR,
    message
  }
}
export const uploadEventFileSuccess = (photos) => {
  return {
    type: UPLOAD_EVENT_FILE,
    photos
  }
}

export const uploadEventFile = (photos) => {
  return dispatch => {
    dispatch({ type: SET_PHOTO_UPLOADING_EVENT });
    api.uploadEventFile(photos)
      .then(response => {
        dispatch(uploadEventFileSuccess(response.data));
        // dispatch(getEventSuccess());
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(uploadEventFileError(getErrorMessage(err)))
      })
  }
}