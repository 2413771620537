import { toast } from 'react-toastify';
import * as api from '../../api/breakfast';
import { getErrorMessage } from '../../utils/utils';
export const GET_BREAKFAST = 'GET_BREAKFAST'
export const GET_BREAKFAST_ERROR = 'GET_BREAKFAST_ERROR'
export const ADD_BREAKFAST = 'ADD_BREAKFAST'
export const ADD_BREAKFAST_ERROR = 'ADD_BREAKFAST_ERROR'
export const UPDATE_BREAKFAST = 'UPDATE_BREAKFAST'
export const UPDATE_BREAKFAST_ERROR = 'UPDATE_BREAKFAST_ERROR'
export const DELETE_BREAKFAST = 'DELETE_BREAKFAST'
export const DELETE_BREAKFAST_ERROR = 'DELETE_BREAKFAST_ERROR'
export const SET_LOADING_BREAKFAST = 'SET_LOADING_BREAKFAST'
export const SET_LOADING_BREAKFAST_DELETE = 'SET_LOADING_BREAKFAST_DELETE'

export const getBreakfastSuccess = (breakfast, pg) => {
  return {
    type: GET_BREAKFAST,
    breakfast, pg
  }
}

export const getBreakfastError = (message) => {
  return {
    type: GET_BREAKFAST_ERROR,
    message
  }
}

export const getBreakfasts = (pg) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_BREAKFAST });
    api.getBreakfast({pg})
      .then(response => {
        dispatch(getBreakfastSuccess(response.data, pg))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getBreakfastError(getErrorMessage(err)))
      })
  }
}

export const addBreakfastError = (message) => {
  return {
    type: ADD_BREAKFAST_ERROR,
    message
  }
}
export const addBreakfastSuccess = (breakfast) => {
  return {
    type: ADD_BREAKFAST,
    breakfast
  }
}

export const addBreakfast = (breakfast) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_BREAKFAST });
    api.addBreakfast(breakfast)
      .then(response => {
        dispatch(addBreakfastSuccess(response.data));
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addBreakfastError(getErrorMessage(err)))
      })
  }
}

export const updateBreakfastError = (message) => {
  return {
    type: UPDATE_BREAKFAST_ERROR,
    message
  }
}
export const updateBreakfastSuccess = (breakfast) => {
  return {
    type: UPDATE_BREAKFAST,
    breakfast
  }
}

export const updateBreakfast = (breakfast) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_BREAKFAST });
    api.updateBreakfast(breakfast)
      .then(response => {
        dispatch(updateBreakfastSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateBreakfastError(getErrorMessage(err)))
      })
  }
}


export const deleteBreakfastSuccess = (breakfastId) => {
  return {
    type: DELETE_BREAKFAST,
    breakfastId
  }
}

export const deleteBreakfastError = (message) => {
  return {
    type: DELETE_BREAKFAST_ERROR,
    message
  }
}

export const deleteBreakfast = (breakfastId) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_BREAKFAST_DELETE });
    api.deleteBreakfast(breakfastId)
      .then(response => {
        dispatch(deleteBreakfastSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(deleteBreakfastError(getErrorMessage(err)))
      })
  }
}



