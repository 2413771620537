import { toast } from 'react-toastify';
import * as api from '../../api/TeacherLeave';
import {
  getErrorMessage
} from '../../utils/utils';
export const GET_TEACHER_LEAVE = 'GET_TEACHER_LEAVE'
export const GET_TEACHER_LEAVE_ERROR = 'GET_TEACHER_LEAVE_ERROR'
export const ADD_TEACHER_LEAVE = 'ADD_TEACHER_LEAVE'
export const ADD_TEACHER_LEAVE_ERROR = 'ADD_TEACHER_LEAVE_ERROR'
export const UPDATE_TEACHER_LEAVE = 'UPDATE_TEACHER_LEAVE'
export const UPDATE_TEACHER_LEAVE_ERROR = 'UPDATE_TEACHER_LEAVE_ERROR'
export const TEACHER_LEAVE_SET_LOADING = 'TEACHER_LEAVE_SET_LOADING'
export const TEACHER_LEAVE_STATUS_LOADING = 'TEACHER_LEAVE_STATUS_LOADING'

export const addTeacherLeaveSuccess = (leave) => {
  return {
    type: ADD_TEACHER_LEAVE,
    leave
  }
}

export const addTeacherLeaveError = (message) => {
  return {
    type: ADD_TEACHER_LEAVE_ERROR,
    message
  }
}

export const addTeacherLeave = (leave) => {
  return dispatch => {
    dispatch({
      type: TEACHER_LEAVE_SET_LOADING
    });
    api.addTeacherLeave(leave)
      .then(response => {
        dispatch(addTeacherLeaveSuccess({
          ...leave,
          ...response.data.data
        }))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addTeacherLeaveError(err.response.data.message))
      })
  }
}

export const getTeacherLeaveSuccess = (leave, pg) => {
  return {
    type: GET_TEACHER_LEAVE,
    leave, pg
  }
}

export const getTeacherLeaveError = (message) => {
  return {
    type: GET_TEACHER_LEAVE_ERROR,
    message
  }
}

export const getTeacherLeave = (pg, classId, status, startDate, endDate, myleave) => {
  return dispatch => {
    dispatch({
      type: TEACHER_LEAVE_SET_LOADING
    });
    api.getTeacherLeave({
      pg,
      classId,
      status,
      startDate,
      endDate,
      myleave
    })
      .then(response => {
        dispatch(getTeacherLeaveSuccess(response.data, pg))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getTeacherLeaveError(getErrorMessage(err)))
      })
  }
}

export const updateTeacherLeaveSuccess = (id, status) => {
  return {
    type: UPDATE_TEACHER_LEAVE,
    id,
    status
  }
}

export const updateTeacherLeaveError = (message) => {
  return {
    type: UPDATE_TEACHER_LEAVE_ERROR,
    message
  }
}

export const updateTeacherLeave = (leaveId, status) => {
  return dispatch => {
    dispatch({
      type: TEACHER_LEAVE_STATUS_LOADING
    });
    api.updateTeacherLeave({
      leaveId,
      status
    })
      .then(response => {
        dispatch(updateTeacherLeaveSuccess(leaveId, status))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateTeacherLeaveError(getErrorMessage(err)));
      })
  }
}