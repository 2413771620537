import * as actionTypes from '../action/Classes';

const initialState = {
    classes: {
        data: [],
        pagination: null
    },
    addError: '',
    getError: '',
    currentPage: 1,
    isLoading: false
};

const classReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CLASS_STATUS_CHANGE:
            const index = state.classes.data.findIndex(t => t.id === action.classId);
            const classes = state.classes.data[index];
            classes.status = action.status;
            return {
                ...state,
                classes: { ...state.classes, data: Object.assign(state.classes.data, { [index]: classes }) },
                isStatusChangeInProcess: false, isLoading: false,
                getError: ''
            };

        case actionTypes.ADD_CLASS:
            const total = state.classes.pagination && state.classes.pagination.total + 1;
            let pages = state.classes.pagination && state.classes.pagination.pages;
            if (total / 10 > pages) {
                pages += 1;
            }
            return {
                ...state,
                isLoading: false,
                addError: '',
                classes: {
                    ...state.classes,
                    pagination: { ...state.classes.pagination, total, pages },
                    data: [...state.classes.data, action.classes.data]
                }
            };

        case actionTypes.ADD_CLASS_ERROR:
            return {
                ...state,
                isLoading: false,
                addError: action.message
            }

        case actionTypes.UPDATE_CLASS:
            const classIndex = state.classes.data.findIndex(a => a.id === action.classes.data.id)
            return {
                ...state,
                isLoading: false,
                addError: '',
                classes: { ...state.classes, data: Object.assign(state.classes.data, { [classIndex]: action.classes.data }) }
            }

        case actionTypes.UPDATE_CLASS_ERROR:
            return {
                ...state,
                isLoading: false,
                addError: action.message
            }
        case actionTypes.GET_CLASS:
            return {
                ...state,
                classes: action.classes,
                currentPage: action.pg || 1,
                isLoading: false,
                getError: ''
            }

        case actionTypes.SET_LOADING_CLASS:
            return {
                ...state,
                isLoading: true,
                getError: '',
                addError: ''
            }

        case actionTypes.GET_CLASS_ERROR:
            return {
                ...state,
                isLoading: false,
                getError: action.message,
            }
        case actionTypes.SET_CLASS_STATUS_IN_PROCESS:
            return {
                ...state,
                isStatusChangeInProcess: true,
                getError: '',
                addError: ''
            };
        case actionTypes.CLASS_STATUS_CHANGE_ERROR:
            return {
                ...state,
                isStatusChangeInProcess: false,
                getError: '',
                addError: ''
            };
        default:
            return state;
    }
}

export default classReducer
