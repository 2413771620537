import React, { Component } from 'react'
import { FormGroup, Label, Input, Card, CardHeader, CardBody, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from "react-spinners";
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { addExamTimeTable, updateExamTimeTable } from '../../../Store/action/ExamTimeTable';
import DatePicker from "react-datepicker";

const SignupSchema = Yup.object().shape({

});

class AddExamTimeTable extends Component {
    input = React.createRef();
    state = {
        subject: '',
        examType: '',
        date: new Date(),
        time: '',
        id: ''
    }

    componentDidMount() {
        const { examTimeTable } = this.props.location.state || {};
        if (examTimeTable) {
            this.setState({ ...examTimeTable, id: examTimeTable.id, date: new Date(examTimeTable.date) });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.loading && this.props.loading && !nextProps.error) {
            this.props.history.push('/admin/examTimeTable')
        } else if (nextProps.error && !this.props.error) {
            // toast.error(nextProps.error, {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            // this.setErrors({ subjectName: nextProps.error });
        }
    }

    submitHandler = (values) => {
        if (this.state.id) {
            this.props.onUpdateExamTimetable(this.state.id, values)
        }
        else {
            this.props.onAddExamTimetable(values);
        }
    }

    render() {
        return (
            <div>
                <Formik
                    enableReinitialize
                    initialValues={{ subject: this.state.subject, date: this.state.date, examType: this.state.examType, time: this.state.time }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        this.setErrors = setErrors;
                        this.submitHandler(values);
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    status,
                    isSubmitting, }) => (
                        <Card className="c-style">
                            <CardHeader className="h-style title-style">{this.state.subjectName === '' ? 'Add Exam TimeTable' : 'Update Exam TimeTable'}</CardHeader>
                            <CardBody>

                                <div className="col-12">
                                    <FormGroup>
                                        <Label >Subject Name</Label>
                                        <Input type="text"
                                            invalid={errors.subject && touched.subject}
                                            name="subject" onChange={handleChange} onBlur={handleBlur} value={values.subject} placeholder="Subject" />
                                        <FormFeedback>
                                            {errors.subject && touched.subject && errors.subject}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label >Exam Date</Label>
                                        <div>
                                            <DatePicker
                                             isClearable   minDate={new Date()}
                                                className="form-control"
                                                wrapperClassName="w-100"
                                                selected={values.date}
                                                onChange={date => setFieldValue('date', date)}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label >Exam Type</Label>
                                        <Input type="text"
                                            invalid={errors.examType && touched.examType}
                                            name="examType" onChange={handleChange} onBlur={handleBlur} value={values.examType} placeholder="ExamType" />
                                        <FormFeedback>
                                            {errors.examType && touched.examType && errors.examType}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label >Time</Label>
                                        <Input type="text"
                                            invalid={errors.time && touched.time}
                                            name="time" onChange={handleChange} onBlur={handleBlur} value={values.time} placeholder="Time" />
                                        <FormFeedback>
                                            {errors.time && touched.time && errors.time}
                                        </FormFeedback>
                                    </FormGroup>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <button type="submit" className="btn btn-primary mr-2" onClick={handleSubmit}>
                                        {this.props.loading ?
                                            <ClipLoader
                                                size={15}
                                                color={"#123abc"}
                                                loading={true}
                                            /> : 'Save'
                                        }
                                    </button>
                                    <Link className="btn btn-secondary" to={{ pathname: '/admin/examTimeTable' }}>Cancel</Link>
                                </div>
                            </CardBody>
                        </Card>
                    )}
                </Formik>
            </div >
        )
    }
}


const mapStateToProps = state => {
    return {
        error: state.examTimeTable.eaddError,
        loading: state.examTimeTable.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddExamTimetable: (values) => dispatch(addExamTimeTable(values)),
        onUpdateExamTimetable: (id, values) => dispatch(updateExamTimeTable(id, values)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExamTimeTable)
