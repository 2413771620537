import React, { Component } from 'react'
import { Card, Table, CardBody, CardHeader, Alert, Pagination, PaginationItem, PaginationLink,Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from "react-spinners";
import * as action from '../../../Store/action/ExamTimeTable';
import ConfirmationDialog from '../../ConfirmDialog/DeleteConfirmDialog';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { URLS } from '../../../api/URLS';


class ExamTimeTable extends Component {
  state = {
    pg: this.props.currentPage,
    export:true
  }

  componentDidMount() {
    if (!this.props.examTimeTable.data.length) {
      this.props.onGetExamTimeTable(1);
    }
  }



  confirmationDialogBox = (deleteEvent) => {
    this.setState({
      deleteEvent,
    });
  }

  deleteHandler = (ec) => {
    this.setState({ deleteEvent: null });
    this.props.onDeleteExamTime(ec.id)
    this.props.onGetExamTimeTable();
  }

  paginationChangeHandler = (pg, value) => () => {
    this.setState({ pg: value });
    this.props.onGetExamTimeTable(value);
  }

  renderPagination(pagination) {
    const pages = [];
    for (let i = 1; i <= pagination; i++) {
      pages.push(
        <PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
          <PaginationLink tag="button">{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  }

  handleExport = () => {
		this.props.onGetExamTimeTable(this.state.pg, this.state.export)
  };

  render() {
    const { examTimeTable, loading, error } = this.props;
    const { pg } = this.state;
    return (
      <div className="row clearfix">
        <div className="col-lg-12">

          <ConfirmationDialog
            isOpen={!!this.state.deleteEvent}
            deleteHandler={() => this.deleteHandler(this.state.deleteEvent)}
            closeHandler={() => this.confirmationDialogBox(null)} />

          <Card className="c-style">
            <CardHeader className="h-style">
              <div className="row m-r-0 m-l-0">
                <div className="c-title-style">
                  Exam Timetable
                      </div>
                <div className="ml-auto btn-style">
                  {examTimeTable.data.length > 0&&
                  <span>
                   <Link
                       class="btn btn-outline-dark"
                      onClick={() => this.handleExport()}
                      to={{ pathname: '/admin/examTimeTable' }}
                    >
                       {loading&&<Spinner size="sm" style={{marginRight:5}}></Spinner>}
                       Export
                         </Link><span style={{marginLeft:10}}/></span>}
                         <Link class="btn btn-outline-dark" to={{ pathname: '/admin/examTimeTable/add' }}>Add</Link>
                </div>
               
              </div>
            </CardHeader>
            <CardBody>
              <Table className="table-responsive-sm table-responsive-md " hover>
                <thead className='table-header-wrapper'>
                  <tr>
                    <th>#</th>
                    <th>Subject Name</th>
                    <th>Date</th>
                    <th>Exam Type</th>
                    <th>Duration</th>
                    <th>Exam Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && !error &&
                    examTimeTable.data.map((p, index) => {
                      return (
                        <tr>
                          <td><strong>{(pg - 1) * 10 + index + 1}</strong></td>
                          <td>{p.subject.subjectName}</td>
                          <td>{moment(p.examDate).format('DD-MMM,YYYY')}</td>
                          <td>{p.examType.name}</td>
                          <td>{p.duration}</td>
                          <td>{p.examTime}</td>
                          <td>
                            <div className="row">
                              <Link className="p-2" to={{ pathname: "/admin/examTimeTable/add", state: { values: p } }}><i class="icon-pencil" style={{ color: 'black' }} aria-hidden="true"></i></Link>
                              <span className="p-2 ml-3" onClick={() => this.confirmationDialogBox(p)}><i class="icon-trash" aria-hidden="true"></i></span>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>

              {loading
                ?
                <div className="loader-wrapper">
                  <ClipLoader
                    size={35}
                    color={"#123abc"}
                    loading={true}
                  />
                </div>
                :
                <div className="d-flex justify-content-end p-2">
                  {examTimeTable.data.length > 0 && examTimeTable.pagination &&
                    <Pagination className="pull-right">
                      <PaginationItem>
                        <PaginationLink
                          onClick={() => examTimeTable.pagination.pages < this.state.pg && this.paginationChangeHandler('pg', this.state.pg - 1)} previous tag="button" />
                      </PaginationItem>
                      {this.renderPagination(examTimeTable.pagination.pages)}
                      <PaginationItem>
                        <PaginationLink
                          onClick={() => examTimeTable.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)} next tag="button" />
                      </PaginationItem>
                    </Pagination>
                  }
                </div>
              }
              {!this.props.loading && (this.props.error || !examTimeTable.data.length) &&
                <div className="error-style">
                  <Alert color="danger">{this.props.error ? this.props.error : examTimeTable.message}</Alert>
                </div>}
            </CardBody>
          </Card>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => {
  return {
    examTimeTable: state.examTimeTable.examTimeTable,
    currentPage: state.examTimeTable.currentPage,
    error: state.examTimeTable.error,
    loading: state.examTimeTable.isLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetExamTimeTable: (pg,exportData) => dispatch(action.getExamTimeTable(pg,exportData)),
    onDeleteExamTime: (id) =>
    dispatch(action.deleteExamTime(id)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExamTimeTable)
