import { toast } from 'react-toastify';
import { resetPassword } from '../../../api/auth'
import {
    getErrorMessage
} from '../../../utils/utils';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_LOADING_RESET_PASSWORD = 'SET_LOADING_RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const resetPasswordSuccess = reset => {
    return {
        type: RESET_PASSWORD,
        reset,
    };
};

export const resetPasswordError = error => {
    return {
        type: RESET_PASSWORD_ERROR,
        error,
    };
};

export const resetPasswords = (data) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_RESET_PASSWORD });
        // const reset = { oldPassword, newPassword };
        resetPassword(data)
            .then(response => {
                dispatch(resetPasswordSuccess(response.data));
                // ToastAndroid.show(
                //     response ? response.data.message : 'Please check your Email! sent...',
                //     ToastAndroid.SHORT,
                // );
            })
            .catch(err => {
                console.log("Error of response data",err.response)
                // toast.error(err.response.data.message, {
				// 	position: toast.POSITION.TOP_RIGHT,
				// });
                dispatch(resetPasswordError(getErrorMessage(err)));
            });
    };
};