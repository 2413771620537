import * as authActions from '../../action/Auth/resetPassword'


const initialState = {
    error: '',
    resetLoading: false
}

const resetPassword = (state = initialState, action) => {
    switch (action.type) {
        case authActions.RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetLoading: false,
                error: action.error
            }
        case authActions.RESET_PASSWORD:
            return {
                ...state,
                resetLoading: false,
                message: action.forgotPassword
            }
        case authActions.SET_LOADING_RESET_PASSWORD:
            return {
                ...state,
                resetLoading: true,
                error: ''
            };
        default:
            return state;

    }
}

export default resetPassword;