import * as actionTypes from '../action/Teachers';

const initialState = {
	teachers: {
		data: [],
		pagination: null
	},
	addError: '',
	getError: '',
	currentPage: 1,
	isLoading: false
};

const teacherReducer = (state = initialState, action) => {
	console.log("State of teacher",state.teacher)
	switch (action.type) {
		case actionTypes.SET_LOADING_TEACHER:
			return {
				...state,
				isLoading: true,
				addError: '',
				getError: '',
			}

		case actionTypes.TEACHER_STATUS_CHANGE:
			const index = state.teachers.data.findIndex(t => t.id === action.userId);
			const teacher = state.teachers.data[index];
			teacher.status = action.status;
			return {
				...state,
				teachers: { ...state.teachers, data: Object.assign(state.teachers.data, { [index]: teacher }) },
				isStatusChangeInProcess: false, isLoading: false,
				getError: ''
			};

		case actionTypes.ADD_TEACHER:
			const total = (state.teachers.pagination && state.teachers.pagination.total+ 1)||1;
			let pages = (state.teachers.pagination.pages & state.teachers.pagination.pages)||1;
		
			if (total / 10 > pages) {
				pages += 1;
			}
			console.log("===",total, pages, state)
			return {
				...state,
				isLoading: false,
				addError: '',
				teachers: {
					...state.teachers,
					pagination: { ...state.teachers.pagination, total, pages },
					data: [...state.teachers.data, action.teacher.data[0]]
				}
			};


		case actionTypes.UPDATE_TEACHER:
			const teacherIndex = state.teachers.data.findIndex(a => a.id === action.teacher.data[0].id)
			return {
				...state,
				teachers: { ...state.teachers, data: Object.assign(state.teachers.data, { [teacherIndex]: action.teacher.data[0] }) },
				isLoading: false,
				addError: ''
			}

		case actionTypes.ADD_TEACHER_ERROR:
			return {
				...state,
				isLoading: false,
				addError: action.message
			}

		case actionTypes.UPDATE_TEACHER_ERROR:
			return {
				...state,
				isLoading: false,
				addError: action.message
			}

		case actionTypes.GET_TEACHER:
			return {
				...state,
				teachers: action.teachers,
				currentPage: action.pg || 1,
				isLoading: false,
				getError: ''
			}

		case actionTypes.GET_TEACHER_ERROR:
			return {
				...state,
				isLoading: false,
				getError: action.message,
			}
		case actionTypes.SET_TEACHER_STATUS_IN_PROCESS:
			return {
				...state,
				isStatusChangeInProcess: true,
				addError: '',
				getError: '',
			};
		case actionTypes.TEACHER_STATUS_CHANGE_ERROR:
			return {
				...state,
				isStatusChangeInProcess: false,
				addError: '',
				getError: '',
			};
		default:
			return state;
	}
}

export default teacherReducer;
