import { toast } from 'react-toastify';
import * as api from '../../api/teachersAuth';
import {getErrorMessage} from '../../utils/utils';

export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'
export const SET_LOADING_PROFILE = 'SET_LOADING_PROFILE'
export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR'

export const updateProfileSuccess = (values) => {
	return {
		type: UPDATE_PROFILE,
		values
	}
}

export const updateProfileError = (message) => {
	return {
		type: UPDATE_PROFILE_ERROR,
		message
	}
}

export const updateProfile = (values) => {
	return dispatch => {
		dispatch({type: SET_LOADING_PROFILE});
		api.teacherUpdate(values)
			.then(response => {
				const auth = JSON.parse(localStorage.getItem('auth'));
				localStorage.setItem("auth", JSON.stringify({...auth, ...response.data.data[0]}));
				dispatch(updateProfileSuccess(response.data.data[0]))
			})
			.catch(err => {
				toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
				dispatch(updateProfileError(getErrorMessage(err)))
				console.log(err)
			})

	}
}

export const getProfileSuccess = (values) => {
	return {
		type: GET_PROFILE,
		values
	}
}

export const getProfileError = (message) => {
	return {
		type: GET_PROFILE_ERROR,
		message
	}
}

export const getProfile = (values) => {
	return dispatch => {
		dispatch({type: SET_LOADING_PROFILE});
		api.teacherUpdate(values)
			.then(response => {
				localStorage.getItem("auth", JSON.stringify(response.data));
				dispatch(updateProfileSuccess(values))
			})
			.catch(err => {
				toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
				// dispatch(updateProfileError(err.response.data.message))
				console.log(err)
			})

	}
}
