import React, { Component } from 'react'
import '../../assets/css/color_skins.css'
import '../../assets/css/main.css'
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { FormFeedback, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as authActions from '../../Store/action/Auth/login';
import * as authTeachersAction from '../../Store/action/TeachersAuth/login';
import { getSchool } from '../../Store/action/School';
import { ClipLoader } from "react-spinners";
import { TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Label } from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import { Redirect } from "react-router-dom";
import Logo from '../../assets/images/logo/ic_launcher.png';

const SignupSchema = Yup.object().shape({
    password: Yup.string()
        .min(5, 'Too Short!')
        .max(10, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
});

const SignupSchemaForTeachers = Yup.object().shape({
    password: Yup.string()
        .max(10, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
});

// const options = [
//     { value: 'test', label: 'test' },
//     { value: 'test1', label: 'test1' }
// ];

// const optionsForCity = [
//     { value: 'test', label: 'test' },
//     { value: 'test1', label: 'test1' }
// ];

class Login extends Component {
    state = {
        isLoading: true,
        activeTab: '1',
        selectedSchool: '',
        selectedCity: ''
    }

    toggle = activeTab => {
        this.setState(s => ({ activeTab }))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.teachers && !this.props.teachers && !nextProps.teachersError) {
             if(nextProps.teachers.email==='admin@gmail.com'&&nextProps.teachers.isPasswordReset===false){
                this.props.history.push('/admin/index') 
             }else if(nextProps.teachers.isPasswordReset===false){
                this.props.history.push('/resetPassword')  
             }else{
                this.props.history.push('/admin/index')
             }
        } else if (nextProps.error && !this.props.teachersError) {
            this.setErrors({ email: nextProps.error });
        }

        if (nextProps.user && !this.props.user && !nextProps.error) {
        } else if (nextProps.error && !this.props.error) {
           // this.setErrors({ email: nextProps.error });
        }
    }

    componentDidMount() {
        this.props.getSchools(-1);
        this.props.onLogout();
        this.props.onResetStore();
    }
    submitHandler = (values) => {
        this.props.onLogin(values);
    }

    submitHandlerForTeachers = (values) => {
        this.props.onTeachersLogin(values)
    }

    schoolPickerChange(selectedSchool) {
        this.setState({ selectedSchool });
    }

    cityPickerChange(selectedCity) {
        this.setState({ selectedCity });
    }

    keyPress = (e, values) => {
        if (e.keyCode === 13 && values) {
            this.submitHandlerForTeachers(values)
        }
    }
    render() {
        const { activeTab, selectedSchool, selectedCity } = this.state;
        const { schools } = this.props;
        const cities = [...new Set(schools.data.map(s => s.city))];
        const cityOptions = cities.map(value => ({ value, label: value }));
        const schoolOption = selectedCity ? schools.data.filter(f => f.city === selectedCity.value).map(p => ({ label: p.schoolName, value: p.id })) : [];
        return (
            <div id="wrapper">
                <div class="vertical-align-wrap">
                    <div class="vertical-align-middle auth-main">
                        <div class="auth-box">
                            <div class="top row pl-2">
                                <img className="" src={Logo} alt="School Diary" height="50px" />
                                <h3 className="align-self-center text-white pl-2 pt-1">School Diary</h3>
                            </div>
                            <div class="card">
                                <div class="header">
                                    <p class="lead">Login to your account</p>
                                </div>
                                <Nav tabs className="nav-tabs">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { this.toggle('1'); }}>
                                            Login
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { this.toggle('2'); }}>
                                            Teachers
                                        </NavLink>
                                    </NavItem> */}
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Formik
                                            ref={this.form}
                                            initialValues={{ email: '', password: '', }}
                                            validationSchema={SignupSchema}
                                            onSubmit={(values, { setSubmitting, setErrors }) => {
                                                this.setErrors = setErrors;
                                                this.submitHandler(values);
                                            }}>
                                            {({ values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleSubmit,
                                                setFieldValue,
                                                handleBlur,
                                                isSubmitting, }) => (
                                                    <Form onSubmit={handleSubmit}>
                                                        <div class="body">
                                                            <form class="form-auth-small" action="http://www.wrraptheme.com/templates/lucid/hr/html/light/index.html" />
                                                            <div class="form-group">
                                                                <label for="signin-email" class="control-label sr-only">Email</label>
                                                                <Input type="email"
                                                                    invalid={errors.email && touched.email}
                                                                    name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} placeholder="Username" />
                                                                <FormFeedback>
                                                                    {errors.email && touched.email && errors.email}
                                                                </FormFeedback>
                                                            </div>

                                                            <div class="form-group">
                                                                <label for="signin-password" class="control-label sr-only">Password</label>
                                                                <Input type="password"
                                                                    invalid={errors.password && touched.password}
                                                                    name="password" onChange={handleChange} onBlur={handleBlur} value={values.password} placeholder="Password" />
                                                                <FormFeedback>
                                                                    {errors.password && touched.password && errors.password}
                                                                </FormFeedback>
                                                            </div>

                                                            <button type="submit" onClick={handleSubmit} class="btn btn-primary btn-lg btn-block mt-4">
                                                                {this.props.isLoading ?
                                                                    <ClipLoader
                                                                        size={15}
                                                                        color={"#123abc"}
                                                                        loading={true}
                                                                    /> : 'LOGIN'
                                                                }
                                                            </button>
                                                            <div class="bottom mt-3 text-center">
                                                                <span class="helper-text m-b-10"><Link to={{ pathname: "/forgotPassword", state: { schoolId: selectedSchool } }}><i class="fa fa-lock pr-2"></i>Forgot password?</Link> </span>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                        </Formik>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Formik
                                            enableReinitialize
                                            ref={this.form}
                                            initialValues={{ email: '', password: '', schoolId: selectedSchool.value }}
                                            validationSchema={SignupSchemaForTeachers}
                                            onSubmit={(values, { setSubmitting, setErrors }) => {
                                                this.setErrors = setErrors;
                                                this.submitHandlerForTeachers(values);
                                                // (values) => this.keyPress(values));
                                                // this.keyPress(values);
                                            }}>
                                            {({ values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleSubmit,
                                                setFieldValue,
                                                handleBlur,
                                                isSubmitting, }) => (
                                                    <Form onSubmit={handleSubmit}>
                                                        <div class="body">
                                                            <form class="form-auth-small" action="http://www.wrraptheme.com/templates/lucid/hr/html/light/index.html" />
                                                            <div class="form-group">
                                                                <FormGroup>
                                                                    <Label >Select City</Label>
                                                                    <Select
                                                                        name="city"
                                                                        onChange={(option) => this.cityPickerChange(option)}
                                                                        onBlur={handleBlur}
                                                                        className="is-invalid"
                                                                        value={this.state.selectedCity}
                                                                        options={cityOptions} />
                                                                    <FormFeedback>
                                                                        {errors.selectedCity && touched.selectedCity && errors.selectedCity}
                                                                    </FormFeedback>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Label >Select school</Label>
                                                                    <Select
                                                                        name="school"
                                                                        onChange={(option) => this.schoolPickerChange(option)}
                                                                        onBlur={handleBlur}
                                                                        className="is-invalid"
                                                                        value={this.state.selectedSchool}
                                                                        options={schoolOption} />
                                                                    <FormFeedback>
                                                                        {errors.selectedSchool && touched.selectedSchool && errors.selectedSchool}
                                                                    </FormFeedback>
                                                                </FormGroup>

                                                                <label for="signin-email" class="control-label sr-only">Email</label>
                                                                <Input type="email"
                                                                    invalid={errors.email && touched.email}
                                                                    name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} placeholder="Username" />
                                                                <FormFeedback>
                                                                    {errors.email && touched.email && errors.email}
                                                                </FormFeedback>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="signin-password" class="control-label sr-only">Password</label>
                                                                <Input type="password"
                                                                    onKeyDown={this.keyPress}
                                                                    invalid={errors.password && touched.password}
                                                                    name="password" onChange={handleChange} onBlur={handleBlur} value={values.password} placeholder="Password" />
                                                                <FormFeedback>
                                                                    {errors.password && touched.password && errors.password}
                                                                </FormFeedback>
                                                            </div>

                                                            {/* <button type="submit" onClick={handleSubmit} class="btn btn-primary btn-lg btn-block mt-4">
                                                                {this.props.loading ?
                                                                    <ClipLoader
                                                                        // css={override}
                                                                        size={15}
                                                                        //size={"150px"} this also works
                                                                        color={"#123abc"}
                                                                        loading={true}
                                                                    /> : 'LOGIN'
                                                                }
                                                            </button> */}
                                                            {/* <div class="bottom mt-3 text-center">
                                                                <span class="helper-text m-b-10"><Link to={{ pathname: "/forgotPassword", state: { schoolId: selectedSchool } }}><i class="fa fa-lock pr-2"></i>Forgot password?</Link> </span>
                                                            </div> */}
                                                        </div>
                                                    </Form>
                                                )}
                                        </Formik>
                                    </TabPane>
                                </TabContent>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.login.user,
        isLoading: state.login.isLoading,
        error: state.login.error,
        teachers: state.teacherLogin.user,
        loading: state.teacherLogin.isLoading,
        teachersError: state.teacherLogin.error,
        schools: state.school.schools
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (values) => dispatch(authActions.login(values)),
        onTeachersLogin: (values) => dispatch(authTeachersAction.login(values)),
        getSchools: (pg) => dispatch(getSchool(pg)),
        onLogout: () => dispatch(authActions.logout()),
        onResetStore: () => dispatch(authActions.resetStore()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

