import React, { Component } from 'react'
import {
	Alert,
	Card,
	CardBody,
	CardHeader,
	FormGroup,
	Pagination,
	PaginationItem,
	PaginationLink,
	Table
} from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from "react-spinners";
import { getTeacherLeave, updateTeacherLeave } from '../../../Store/action/MyLeave';
import ConfirmationDialog from '../../ConfirmDialog/ConfirmDialog';
import ApproveButton from "../../../assets/icon/Approve-btn.svg"
import RejectButton from "../../../assets/icon/Reject-btn.svg"
import { Link } from 'react-router-dom';
import moment from 'moment';
import DatePicker from "react-datepicker";
import './leave.css';

class MyLeave extends Component {
	state = {
		pg: this.props.currentPage,
		selectedClass: '',
		selectedStatus: '',
		startDate: moment(new Date()).startOf('month').toDate(),
		endDate: moment(new Date()).endOf('month').toDate(),
		id: '',
		status: '',
		leaveId: ''
	}

	componentDidMount() {
		this.props.onGetTeacherLeave('', '', '', moment(new Date()).format('YYYY-MM-DDT00:00:00.000[Z]'), moment(new Date()).format('YYYY-MM-DDT00:00:00.000[Z]'), 1);
	}

	confirmationDialogBox = (id, status) => {
		this.setState({ id, status });
	}

	changeStatusHandler = () => {
		this.props.onSubjectStatusChange(this.state.id, this.state.status);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.loading && !this.props.loading) {
			this.setState({ id: null, status: null });
		}
	}

	paginationChangeHandler = (pg, value) => () => {
		this.props.onGetTeacherLeave(value, '', '', '', '', 1);
		this.setState({ pg: value });
	}

	renderPagination(pagination) {
		const pages = [];
		for (let i = 1; i <= pagination; i++) {
			pages.push(
				<PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
					<PaginationLink tag="button">{i}</PaginationLink>
				</PaginationItem>
			);
		}
		return pages;
	}

	approvedStatusChangeHandler = (p) => {
		this.setState({ leaveId: p.id });
		this.props.onUpdateTeacherLeave(p.id, 'Approve')
	}

	rejectStatusChangeHandler = (p) => {
		this.setState({ leaveId: p.id });
		this.props.onUpdateTeacherLeave(p.id, 'Reject')
	}

	cancelStatusChangeHandler = (p) => {
		this.setState({ leaveId: p.id });
		this.props.onUpdateTeacherLeave(p.id, 'Cancel')
	};

	classChangeHandler = (event) => {
		const {
			selectedStatus,
			startDate,
			endDate
		} = this.state;
		this.setState({ pg: 1, selectedClass: event.target.value });
		this.props.onGetTeacherLeave(1, event.target.value, selectedStatus, startDate, endDate, 1);
	};

	statusChangeHandler = (event) => {
		const {
			selectedClass,
			startDate,
			endDate
		} = this.state;
		this.props.onGetTeacherLeave(1, selectedClass, event.target.value, startDate, endDate, 1);
		this.setState({ pg: 1, selectedStatus: event.target.value });
	};

	startDateHandleChange = (date, event) => {
		this.setState({ pg: 1, startDate: date });
		const {
			selectedClass,
			selectedStatus,
			endDate
		} = this.state;
		this.props.onGetTeacherLeave(1, selectedClass, selectedStatus, moment(date).format('YYYY-MM-DDT00:00:00.000[Z]'), moment(endDate).format('YYYY-MM-DDT00:00:00.000[Z]'), 1);
	};

	endDateHandleChange = (date, event) => {
		this.setState({ pg: 1, endDate: date });
		const {
			selectedClass,
			selectedStatus,
			startDate
		} = this.state;
		this.props.onGetTeacherLeave(1, selectedClass, selectedStatus, moment(startDate).format('YYYY-MM-DDT00:00:00.000[Z]'), moment(date).format('YYYY-MM-DDT00:00:00.000[Z]'), 1);
	};

	render() {
		const { teacherLeave, user, statusLoading, loading, error } = this.props;
		const { pg, selectedStatus, id, status } = this.state;
		return (
			<div className="row clearfix">
				<div className="col-lg-12">

					<ConfirmationDialog
						isOpen={id && status}
						deleteHandler={() => this.changeStatusHandler()}
						closeHandler={() => this.confirmationDialogBox(null, null)} />
					<Card className="c-style">
						<CardHeader className="h-style">
							<div className="row m-r-0 m-l-0">
								<div className="c-title-style">
									Teacher Leave
								</div>
								{user.userRole === 3 &&
									<div className="ml-auto btn-style">
										<Link class="btn btn-outline-dark" to={{ pathname: '/admin/myLeave/add' }}>Add</Link>
									</div>
								}
							</div>
						</CardHeader>
						<CardBody>
							<div className="row">
								<div className="col-3">
									<select
										class="form-control show-tick ms select2"
										data-placeholder="Select"
										onChange={this.statusChangeHandler}
										value={selectedStatus}>
										<option value="">Select Status</option>
										<option>Pending</option>
										<option>Approve</option>
										<option>Reject</option>
									</select>
								</div>
								<div className="pr-3">
									<FormGroup>
										<DatePicker
											isClearable
											placeholderText="Start Date"
											className="form-control"
											selected={this.state.startDate}
											onChange={this.startDateHandleChange}
										/>
									</FormGroup>
								</div>
								<div >
									<FormGroup>
										<DatePicker
											isClearable
											placeholderText="End Date"
											className="form-control"
											selected={this.state.endDate}
											onChange={this.endDateHandleChange}
										/>
									</FormGroup>
								</div>
							</div>
							<Table className="table-responsive-sm table-responsive-md " hover>
								<thead className='table-header-wrapper'>
									<tr>
										<th>#</th>
										<th>Start Date</th>
										<th>End Date</th>
										<th>Reason</th>
										{user.userRole !== 3 && <th>Teacher Name</th>}
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{!loading && !error &&
										teacherLeave.data.map((p, index) => {
											return (
												<tr>
													<td><strong>{(pg - 1) * 10 + index + 1}</strong></td>
													<td>{moment(p.startDate).format('MM/DD/YYYY')}</td>
													<td>{moment(p.endDate).format('MM/DD/YYYY')}</td>
													<td className="reason">{p.reason}</td>
													{user.userRole !== 3 && <td>{p.user.firstName} {p.user.lastName}</td>}
													<td>{user.userRole === 3 ? p.status === 'Pending' ?
														statusLoading && this.state.leaveId === p.id ?
															<ClipLoader
																size={30}
																color={"#123abc"}
																loading={true}
															/> :
															<div className="row d-flex align-items-center">
																<span class="badge badge-warning ml-4">Pending</span>
																<button className="btn btn-link p-1 ml-2" value={this.state.Reject}
																	onClick={() => this.cancelStatusChangeHandler(p)}><img style={{ height: 22, width: 30 }}
																		src={RejectButton} alt='' />
																</button>
															</div> : p.status === 'Approve' ?
															<span class="badge badge-success ">Approved</span>
															:
															p.status === 'Reject' ?
																<span class="badge badge-danger ">Rejected</span>
																: p.status === 'Pending' ?
																	<span class="badge badge-warning ">Pending</span>
																	: p.status === 'Cancel' ?
																		<span class="badge badge-warning ">Canceled</span> : null
														: p.status === 'Approve' ?
															<span class="badge badge-success ">Approved</span> :
															p.status === 'Cancel' ?
																<span class="badge badge-danger ">Canceled</span> :
																p.status === 'Reject' ?
																	<span class="badge badge-danger ">Rejected</span> :
																	p.status === 'Pending' ?
																		statusLoading && this.state.leaveId === p.id ?
																			<ClipLoader
																				size={30}
																				color={"#123abc"}
																				loading={true}
																			/> :
																			<div className="row d-flex align-items-center">
																				<button className="btn btn-link p-1 ml-2" value={this.state.Approve}
																					onClick={() => this.approvedStatusChangeHandler(p)}><img src={ApproveButton} alt='' />
																				</button>
																				<button className="btn btn-link p-1 ml-2" value={this.state.Reject}
																					onClick={() => this.rejectStatusChangeHandler(p)}><img src={RejectButton} alt='' />
																				</button>
																			</div> : null}</td>
												</tr>
											)
										})}
								</tbody>
							</Table>

							{loading
								?
								<div className="loader-wrapper">
									<ClipLoader
										size={35}
										color={"#123abc"}
										loading={true}
									/>
								</div>
								:
								<div className="d-flex justify-content-end p-2">
									{this.props.teacherLeave.data.length > 0 && this.props.teacherLeave.pagination &&
										<Pagination className="pull-right">
											<PaginationItem>
												<PaginationLink
													onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()}
													previous tag="button" />
											</PaginationItem>
											{this.renderPagination(this.props.teacherLeave.pagination.pages)}
											<PaginationItem>
												<PaginationLink
													onClick={() => this.props.teacherLeave.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()}
													next tag="button" />
											</PaginationItem>
										</Pagination>
									}
								</div>
							}
							{!loading && (this.props.error || !teacherLeave.data.length) &&
								<div className="error-style">
									<Alert color="danger">{this.props.error ? this.props.error : teacherLeave.message}</Alert>
								</div>}
						</CardBody>
					</Card>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		teacherLeave: state.myLeave.teacherLeave,
		currentPage: state.myLeave.currentPage,
		error: state.myLeave.getError,
		loading: state.myLeave.isLoading,
		statusLoading: state.myLeave.statusLoading,
		user: state.login.user,
		classes: state.class.classes,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGetTeacherLeave: (pg, classId, status, startDate, endDate, myleave) => dispatch(getTeacherLeave(pg, classId, status, startDate, endDate, myleave)),
		onUpdateTeacherLeave: (leaveId, status) => dispatch(updateTeacherLeave(leaveId, status))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(MyLeave)
