export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export function trim(str, length = 50, append = '...') {
	return str && str.length > length ? str.substr(0, length - 3) + append : str
}

export function toFormData(data) {
	const formData = new FormData();
	Object.keys(data)
		.forEach(key => {
			if (Array.isArray(data[key])) {
				data[key].forEach(v => formData.append(key + '[]', v));
			} else if (data[key] instanceof Blob) {
				formData.append(key, data[key], data[key].name);
			} else {
				formData.append(key, data[key]);
			}
		})
	return formData;
}

export function getErrorMessage(err) {
	let message = 'Something Went Wrong. Please try again';
	if (!err) {
		return message;
	} else if (err.response) {
		if (err.response.data && err.response.data.message) {
			return err.response.data.message;
		}
		if (err.response.message) {
			return err.response.message;
		}
	} else if (err.message) {
		return err.message;
	}
	return message;
}

export const groupBy = function (xs, key) {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};
