import React, { Component } from 'react';
import {
	Alert,
	Card,
	CardBody,
	CardHeader,
	FormGroup,
	Pagination,
	PaginationItem,
	PaginationLink,
	Table,
	Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import * as action from '../../../Store/action/Attendance'
import { Link } from 'react-router-dom'
import { ClipLoader } from "react-spinners";
import ConfirmationDialog from '../../ConfirmDialog/ConfirmDialog';
import { debounce } from '../../../utils/utils';
import { getClass } from '../../../Store/action/Classes';
import DatePicker from "react-datepicker";
import moment from 'moment';
import ImageModal from './../../Modal/ImageModal';
import User from '../../../assets/images/user.png';
import { URLS } from '../../../api/URLS';

class Attendance extends Component {
	state = {
		pg: this.props.currentPage,
		status: false,
		isDelete: false,
		searchName: '',
		selectedClass: '',
		startDate: new Date(),
		export:true
	}

	componentDidMount() {
		if(this.props.user.userRole===3){
			this.setState({selectedClass:this.props.user.userclasses[0].classId})
			 this.props.onGetAttendance(1, this.props.user.userclasses[0].classId, moment(this.state.startDate).format('YYYY-MM-DDT00:00:00.000[Z]'), this.state.searchName);
		}
		
		if (!this.props.attendance.data.length) {
			this.props.onGetClass(-1);
		}
	}

	classChangeHandle = (event) => {
		this.setState({ pg: 1, selectedClass: event.target.value });
		this.props.onGetAttendance(1, event.target.value, moment(this.state.startDate).format('YYYY-MM-DDT00:00:00.000[Z]'), this.state.searchName);
	}

	handleChange = (date, event) => {
		this.setState({ pg: 1, startDate: date });
		this.props.onGetAttendance(1, this.state.selectedClass, moment(date).format('YYYY-MM-DDT00:00:00.000[Z]'), this.state.searchName);
	};

	paginationChangeHandler = (pg, value) => () => {
		if (this.state.startDate) {
			this.props.onGetAttendance(value, this.state.selectedClass, moment(this.state.startDate).format('YYYY-MM-DDT00:00:00.000[Z]'), this.state.searchName);
		}
		this.props.onGetAttendance(value, this.state.selectedClass, '', this.state.searchName);
		this.setState({ pg: value });
	}

	searchAttendance = debounce((value) => {
	if (this.state.startDate) {
			console.log(this.state.startDate)
			this.props.onGetAttendance(1, this.state.selectedClass, moment(this.state.startDate).format('YYYY-MM-DDT00:00:00.000[Z]'), value.searchName);
		} else {
			this.props.onGetAttendance(1, this.state.selectedClass, '', value.searchName);
		}
	}, 500);

	searchAttendanceHandler = (event) => {
		this.setState({ pg: 1, searchName: event.target.value })
		this.searchAttendance({ pg: 1, searchName: event.target.value })
	}

	renderPagination(pagination) {
		const pages = [];
		for (let i = 1; i <= pagination; i++) {
			pages.push(
				<PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
					<PaginationLink tag="button">{i}</PaginationLink>
				</PaginationItem>
			);
		}
		return pages;
	}

	confirmationDialogBox = (deleteClass) => {
		this.setState({
			deleteClass,
		});
	}

	deleteHandler = (ec) => {
		// this.setState({isDelete: true});
		// this.setState({ deleteClass: null });
		// this.props.onDeleteClass(ec);
		// this.setState({isDelete: false});
	}

	// searchClasses = debounce((value) => {
	//     this.props.onGetClass(1, value);
	// }, 500);

	searchClassHandler = (event) => {
		// this.setState({ pg: 1, searchName: event.target.value })
		// this.searchClasses(event.target.value);
	}

	handleExport = () => {
		this.props.onGetAttendance(1, this.state.selectedClass, moment(this.state.startDate).format('YYYY-MM-DDT00:00:00.000[Z]'), this.state.searchName,this.state.export)
		
  };


	render() {
		const { attendance, classes, user } = this.props;
		const { pg } = this.state;
		console.log("Props of export loading",this.props.exportLoading)
		return (
			<div className="row clearfix">
				<div className="col-lg-12">
					<ConfirmationDialog
						isOpen={!!this.state.deleteClass}
						deleteHandler={() => this.deleteHandler(this.state.deleteClass)}
						closeHandler={() => this.confirmationDialogBox(null)} />
					<Card className="c-style">
						<CardHeader className="h-style">
							<div className="row m-r-0 m-l-0">
								<div className="c-title-style">
									Attendance
								</div>
								{this.state.selectedClass&&attendance.data.length>0&&
								<div className="ml-auto btn-style">
								<Link
                      class="btn btn-outline-dark actionsButtons"
                     onClick={() => this.handleExport()}
                      to={{ pathname: '/admin/attendance' }}
                    >
                       {this.props.exportLoading&&<Spinner size="sm" style={{marginRight:5}}></Spinner>}
                        Export
                         </Link>
								</div>}
							</div>
						</CardHeader>
						<CardBody>
							<div className="row">
								<div className="col-3">
									<select
										className="form-control show-tick ms select2"
										data-placeholder="Select"
										onChange={this.classChangeHandle}
										value={this.state.selectedClass}>
										<option value="">Select Class</option>
										{user.userRole === 3 ?
											user.userclasses.map(c => {
												return (
													<option key={c.id} value={c.classId}>{`${c.class.standard.standardName}-${c.class.className}, ${c.class.medium}`}</option>
												)
											})
											: classes.data.map(c => {
												return (
													<option key={c.id} value={c.id}>{`${c.standard.standardName}-${c.className}, ${c.medium}`}</option>
												)
											})}
									</select>
								</div>
								<div>
									<FormGroup>
										<DatePicker
											isClearable
											maxDate={new Date()}
											className="form-control"
											placeholderText="Select Date"
											selected={this.state.startDate}
											onChange={this.handleChange}
										/>
									</FormGroup>
								</div>
								<div className="col-3">
									<label><input
										type="search"
										className="form-control"
										placeholder="Search here..."
										aria-controls="example"
										value={this.state.searchName}
										onChange={this.searchAttendanceHandler} /></label>
								</div>
							</div>
							<Table className="table-responsive-sm table-responsive-md " hover>
								<thead className='table-header-wrapper'>
									<tr>
										<th>#</th>
										<th>Profile</th>
										<th>Student Name</th>
										<th>Date</th>
										<th>Roll No.</th>
										<th>Attendance</th>
										{this.props.user.userRole === 3 &&
											<th>Action</th>
										}
									</tr>
								</thead>
								<tbody>
									{this.props.user.userRole === 3 && !this.props.loading && !this.props.error &&
										attendance.data.map((p, index) => {
											return (
												<tr key={p.student.firstName}>
													<td><strong>{(pg - 1) * 10 + index + 1}</strong></td>
													<td><ImageModal alt="." className="image img-fluid"
														src={p.student.profilePic ? p.student.profilePic : User} /></td>
													<td>{p.student.firstName} {p.student.lastName}</td>
													<td>{moment(p.attendanceDate).format('YYYY-MM-DD')}</td>
													<td>{p.student.rollNumber}</td>
													<td>{p.status}</td>
													<td>
														<div className="row">
															<Link className="btn-style" to={{ pathname: "/admin/attendance/add", state: { attendances: p } }}><i
																className="icon-pencil" style={{ color: 'black' }} aria-hidden="true"></i></Link>
															{/* <span className="btn-style-1" onClick={() => this.confirmationDialogBox(p)}><i className="icon-trash" aria-hidden="true"></i></span> */}
														</div>
													</td>
												</tr>
											)
										})
									}
									{(this.props.user.userRole === 1 || this.props.user.userRole === 2) && !this.props.loading && !this.props.error &&
										attendance.data.map((p, index) => {
											return (
												<tr>
													<td><strong>{(pg - 1) * 10 + index + 1}</strong></td>
													<td><ImageModal alt="." className="image img-fluid"
														src={p.student.profilePic ? p.student.profilePic : User} /></td>
													<td>{p.student.firstName}</td>
													<td>{moment(p.attendanceDate).format('YYYY-MM-DD')}</td>
													<td>{p.student.rollNumber}</td>
													<td>{p.status}</td>
												</tr>
											)
										})
									}
								</tbody>
							</Table>

							{this.props.loading
								?
								<div className="loader-wrapper">
									<ClipLoader
										size={35}
										color={"#123abc"}
										loading={true}
									/>
								</div>
								:
								<div className="d-flex justify-content-end p-2">
									{attendance.data.length > 0 && attendance.pagination &&
										<Pagination className="pull-right">
											<PaginationItem>
												<PaginationLink
													onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()}
													previous tag="button" />
											</PaginationItem>
											{this.renderPagination(attendance.pagination.pages)}
											<PaginationItem>
												<PaginationLink
													onClick={() => attendance.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()}
													next tag="button" />
											</PaginationItem>
										</Pagination>
									}
								</div>
							}
							{!this.state.selectedClass && !attendance.data.length &&
								<div className="error-style">
									<Alert color="danger">Please select Class</Alert>
								</div>
							}
							{!this.props.loading && (this.props.error || !attendance.data.length) && this.state.selectedClass &&
								<div className="error-style">
									<Alert color="danger">{this.props.error ? this.props.error : attendance.message}</Alert>
								</div>}
						</CardBody>
					</Card>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		attendance: state.attendance.attendances,
		currentPage: state.attendance.currentPage,
		classes: state.class.classes,
		error: state.attendance.getError,
		loading: state.attendance.isLoading,
		exportLoading:state.attendance.isExportAttendanceLoading,
		user: state.login.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGetAttendance: (pg, selectedClass, startDate, searchName,exportData) => dispatch(action.getAttendances(pg, selectedClass, startDate, searchName,exportData)),
		onGetClass: (pg) => dispatch(getClass(pg)),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Attendance)
