import React, { Component } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import * as action from '../../../Store/action/Profile';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Crop from '../../Crop/Crop';
import moment from 'moment';
import UserProfile from '../../../assets/images/profile-avatar.png';
import close from '../../../assets/icon/close.svg';

const SignupSchema = Yup.object().shape({
  // subjectName: Yup.string()
  //     .required('Subject is required')
});

class Profile extends Component {
  input = React.createRef();
  state = {
    firstName: '',
    lastName: '',
    profilePic: '',
    mobileNo: '',
    email: '',
    birthOfDate: '',
    address: '',
    experience: '',
    gender: '',
    medium: '',
    className: '',
    subjectName: '',
    src: null,
  };

  componentDidMount() {
    const { user } = this.props.location.state || {};
    if (user) {
      this.setState({
        ...user,
        className:
          user.userclasses.length &&
          user.userclasses.map(
            (p) =>
              `${p.class.standard.standardName}-${p.class.className}, ${p.class.medium}`
          ),
        subjectName: user.userSubjects.map((x) => x.subject.subjectName),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
     // this.props.history.push('/admin/index');
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // this.setErrors({ classId: nextProps.error });
    }
  }

  onSelectFile = (key) => (e) => {
    this.setState({ image: e.target.files[0] });
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ [key]: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  submitHandler = (values) => {
    this.props.onProfileUpdate(values);
  };

  imageRemoveHandler = (image) => {
    this.setState((s) => ({
      profilePic: null,
    }));
  };

  render() {
    const { src, croppedImageUrl } = this.state;
    const { user } = this.props;
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            profilePic: this.state.profilePic,
            mobileNo: this.state.mobileNo,
            email: this.state.email,
            birthOfDate: this.state.birthOfDate,
            address: this.state.address,
            experience: this.state.experience,
            gender: this.state.gender,
            medium: this.state.medium,
            className: this.state.className,
            subjectName: this.state.subjectName,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <Crop
                src={src}
                name={this.state.image && this.state.image.name}
                onCrop={(imageUrl, blob) => {
                  this.setState({ croppedImageUrl: imageUrl, src: null });
                  setFieldValue('profilePic', blob);
                }}
                onCancel={() => this.setState({ src: null })}
              />
              <CardHeader className="h-style title-style">
                Update Profile
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label className="form-control-label">Profile Picture</Label>
                  <FormGroup>
                    <input
                      accept="image/jpg, image/jpeg, image/png"
                      hidden
                      ref={this.input}
                      type="file"
                      onChange={this.onSelectFile('src')}
                    />
                    <FormFeedback>
                      {errors.profilePic &&
                        touched.profilePic &&
                        errors.profilePic}
                    </FormFeedback>
                    {!croppedImageUrl && !this.state.profilePic && (
                      <img
                        alt="profile"
                        style={{ height: 100, width: 'auto' }}
                        src={UserProfile}
                        onClick={(e) =>
                          this.input.current && this.input.current.click()
                        }
                      />
                    )}
                  </FormGroup>
                  <div>
                    {(croppedImageUrl || this.state.profilePic) && (
                      <div className="d-flex flex-column">
                        <div style={{ position: 'relative', left: '20px' }}>
                          <button
                            className="btn btn-link"
                            onClick={() =>
                              this.imageRemoveHandler(this.state.profilePic)
                            }
                            style={{
                              position: 'absolute',
                              paddingLeft: '47px',
                              paddingTop: 0,
                              alignSelf: 'center',
                            }}
                          >
                            <img src={close} alt="" />
                          </button>
                          <img
                            alt="Crop"
                            style={{ maxWidth: '85px', height: '100px' }}
                            onClick={(e) =>
                              this.input.current && this.input.current.click()
                            }
                            src={croppedImageUrl || user.profilePic}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </FormGroup>

                {(this.props.user.userRole === 1 ||
                  this.props.user.userRole === 2) && (
                    <React.Fragment>
                      <Row>
                        <Col>
                          <Label>Basic Details</Label>
                          <hr className="mx-0 mt-0" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="3">
                          <FormGroup>
                            <Label>First Name</Label>
                            <Input
                              type="text"
                              invalid={errors.firstName && touched.firstName}
                              name="firstName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                              placeholder="First Name"
                            />
                            <FormFeedback>
                              {errors.firstName &&
                                touched.firstName &&
                                errors.firstName}
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col xs="2">
                          <FormGroup>
                            <Label>Last Name</Label>
                            <Input
                              type="text"
                              invalid={errors.lastName && touched.lastName}
                              name="lastName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName}
                              placeholder="Last Name"
                            />
                            <FormFeedback>
                              {errors.lastName &&
                                touched.lastName &&
                                errors.lastName}
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col xs="2">
                          <FormGroup>
                            <Label>Phone Number</Label>
                            <Input
                              type="text"
                              maxLength={10}
                              invalid={errors.mobileNo && touched.mobileNo}
                              name="mobileNo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mobileNo}
                              placeholder="Mobile Number"
                            />
                            <FormFeedback>
                              {errors.mobileNo &&
                                touched.mobileNo &&
                                errors.mobileNo}
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col xs="3">
                          <FormGroup>
                            <Label>Teacher Email Id</Label>
                            <Input
                              type="text"
                              readOnly
                              invalid={errors.email && touched.email}
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              placeholder="Teacher Email"
                            />
                            <FormFeedback>
                              {errors.email && touched.email && errors.email}
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col xs="2">
                          <FormGroup>
                            <Label>Birth Date</Label>
                            <Input
                              type="text"
                              invalid={errors.birthOfDate && touched.birthOfDate}
                              name="birthOfDate"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={moment(values.birthOfDate).format(
                                'DD-MM-YYYY'
                              )}
                              placeholder="Birth Date"
                            />
                            <FormFeedback>
                              {errors.birthOfDate &&
                                touched.birthOfDate &&
                                errors.birthOfDate}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="5">
                          <FormGroup>
                            <Label>Address</Label>
                            <Input
                              type="textarea"
                              rows={5}
                              invalid={errors.address && touched.address}
                              name="address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address}
                              placeholder="Address"
                            />
                            <FormFeedback>
                              {errors.address &&
                                touched.address &&
                                errors.address}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}

                {this.props.user.userRole === 3 && (
                  <Row>
                    <Col xs="4">
                      <Label>Basic Details</Label>
                      <hr className="mx-0 mt-0" />
                      <FormGroup>
                        <Label>First Name</Label>
                        <Input
                          type="text"
                          invalid={errors.firstName && touched.firstName}
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          placeholder="First Name"
                        />
                        <FormFeedback>
                          {errors.firstName &&
                            touched.firstName &&
                            errors.firstName}
                        </FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                          type="text"
                          invalid={errors.lastName && touched.lastName}
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          placeholder="Last Name"
                        />
                        <FormFeedback>
                          {errors.lastName &&
                            touched.lastName &&
                            errors.lastName}
                        </FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label>Phone Number</Label>
                        <Input
                          type="text"
                          maxLength={10}
                          invalid={errors.mobileNo && touched.mobileNo}
                          name="mobileNo"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobileNo}
                          placeholder="Mobile Number"
                        />
                        <FormFeedback>
                          {errors.mobileNo &&
                            touched.mobileNo &&
                            errors.mobileNo}
                        </FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label>Teacher Email Id</Label>
                        <Input
                          type="text"
                          readOnly
                          invalid={errors.email && touched.email}
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="Teacher Email"
                        />
                        <FormFeedback>
                          {errors.email && touched.email && errors.email}
                        </FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label>Gender</Label>
                        <Input
                          type="text"
                          invalid={errors.gender && touched.gender}
                          name="gender"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.gender}
                          placeholder="Gender"
                        />
                        <FormFeedback>
                          {errors.gender && touched.gender && errors.gender}
                        </FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label>Birth Date</Label>
                        <Input
                          type="text"
                          invalid={errors.birthOfDate && touched.birthOfDate}
                          name="birthOfDate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={moment(values.birthOfDate).format(
                            'DD-MM-YYYY'
                          )}
                          placeholder="Birth Date"
                        />
                        <FormFeedback>
                          {errors.birthOfDate &&
                            touched.birthOfDate &&
                            errors.birthOfDate}
                        </FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label>Address</Label>
                        <Input
                          type="textarea"
                          rows={5}
                          invalid={errors.address && touched.address}
                          name="address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          placeholder="Address"
                        />
                        <FormFeedback>
                          {errors.address && touched.address && errors.address}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    {this.props.user.userRole === 3 && (
                      <React.Fragment>
                        <Col xs={{ size: 5, offset: 1 }}>
                          <Label>Teacher's Details</Label>
                          <hr className="mx-0 mt-0" />
                          <FormGroup>
                            <Label>Experience</Label>
                            <Input
                              type="text"
                              readOnly
                              invalid={errors.experience && touched.experience}
                              name="experience"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.experience}
                              placeholder="Experience"
                            />
                            <FormFeedback>
                              {errors.experience &&
                                touched.experience &&
                                errors.experience}
                            </FormFeedback>
                          </FormGroup>

                          <FormGroup>
                            <Label>Class</Label>
                            <Input
                              type="text"
                              readOnly
                              invalid={errors.className && touched.className}
                              name="className"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.className}
                              placeholder="Class"
                            />
                            <FormFeedback>
                              {errors.className &&
                                touched.className &&
                                errors.className}
                            </FormFeedback>
                          </FormGroup>

                          <FormGroup>
                            <Label>Medium</Label>
                            <Input
                              type="text"
                              readOnly
                              invalid={errors.medium && touched.medium}
                              name="medium"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.medium}
                              placeholder="Medium"
                            />
                            <FormFeedback>
                              {errors.medium && touched.medium && errors.medium}
                            </FormFeedback>
                          </FormGroup>

                          <FormGroup>
                            <Label>Subject</Label>
                            <Input
                              type="text"
                              readOnly
                              invalid={
                                errors.subjectName && touched.subjectName
                              }
                              name="subjectName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.subjectName}
                              placeholder="Subject"
                            />
                            <FormFeedback>
                              {errors.subjectName &&
                                touched.subjectName &&
                                errors.subjectName}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                )}

                <div className="d-flex justify-content-start">
                  <button
                    disabled={this.props.loading}
                    type="submit"
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                  >
                    {this.props.loading ? (
                      <ClipLoader size={15} color={'#123abc'} loading={true} />
                    ) : (
                        'Save'
                      )}
                  </button>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    loading: state.profile.isLoading,
    error: state.profile.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onProfileUpdate: (values) => dispatch(action.updateProfile(values)),
    onGetProfile: () => dispatch(action.getProfile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
