import { toast } from 'react-toastify';
import * as api from '../../api/ExamTimeTable';
import { URLS } from '../../api/URLS';
import { getErrorMessage } from '../../utils/utils';
export const GET_EXAM_TIMETABLE = 'GET_EXAM_TIMETABLE'
export const GET_EXAM_TIMETABLE_ERROR = 'GET_EXAM_TIMETABLE_ERROR'
export const ADD_EXAM_TIMETABLE = 'ADD_EXAM_TIMETABLE'
export const ADD_EXAM_TIMETABLE_ERROR = 'ADD_EXAM_TIMETABLE_ERROR'
export const UPDATE_EXAM_TIMETABLE = 'UPDATE_EXAM_TIMETABLE'
export const UPDATE_EXAM_TIMETABLE_ERROR = 'UPDATE_EXAM_TIMETABLE_ERROR'
export const SET_LOADING_EXAM_TIMETABLE = 'SET_LOADING_EXAM_TIMETABLE'
export const SET_GET_LOADING_EXAM_TYPE = 'SET_GET_LOADING_EXAM_TYPE'
export const SET_ADD_LOADING_EXAM_TYPE = 'SET_ADD_LOADING_EXAM_TYPE'
export const GET_EXAM_TYPE = 'GET_EXAM_TYPE'
export const GET_EXAM_TYPE_ERROR = 'GET_EXAM_TYPE_ERROR'
export const ADD_EXAM_TYPE = 'ADD_EXAM_TYPE'
export const ADD_EXAM_TYPE_ERROR = 'ADD_EXAM_TYPE_ERROR'
export const UPDATE_EXAM_TYPE = 'UPDATE_EXAM_TYPE'
export const UPDATE_EXAM_TYPE_ERROR = 'UPDATE_EXAM_TYPE_ERROR'


export const DELETE_EXAM_TYPE = 'DELETE_EXAM_TYPE'
export const DELETE_EXAM_TYPE_ERROR = 'DELETE_EXAM_TYPE_ERROR'
export const DELETE_EXAM_TYPE_LOADING = 'DELETE_EXAM_TYPE_LOADING'




export const getExamTimeTableSuccess = (data, pg) => {
  return {
    type: GET_EXAM_TIMETABLE,
    data, pg
  }
}

export const getExamTimeTableError = (message) => {
  return {
    type: GET_EXAM_TIMETABLE_ERROR,
    message
  }
}

export const getExamTimeTable = (pg,exportData) => {
  return dispatch => {
    dispatch({ type: SET_GET_LOADING_EXAM_TYPE });
    api.getExamTimeTable({ pg,exportData })
      .then(response => {
        dispatch(getExamTimeTableSuccess(response.data, pg))
        if(exportData===true){
        window.open(`${URLS.EXAMTIMETABLE_DOWNLOAD}`);
        }
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getExamTimeTableError(getErrorMessage(err)))
      })
  }
}

export const addExamTimeTableError = (message) => {
  return {
    type: ADD_EXAM_TIMETABLE_ERROR,
    message
  }
}
export const addExamTimeTableSuccess = (examTime) => {
  return {
    type: ADD_EXAM_TIMETABLE,
    examTime
  }
}

export const addExamTimeTable = (examTimeTable) => {
  return dispatch => {
    dispatch({ type: SET_ADD_LOADING_EXAM_TYPE });
    api.addExamTimeTable(examTimeTable)
      .then(response => {
        dispatch(addExamTimeTableSuccess(response.data));
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addExamTimeTableError(getErrorMessage(err)))
      })
  }
}


export const updateExamTimeTableError = (message) => {
  return {
    type: UPDATE_EXAM_TIMETABLE_ERROR,
    message
  }
}

export const updateExamTimeTableSuccess = (examTime) => {
  return {
    type: UPDATE_EXAM_TIMETABLE,
    examTime
  }
}

export const updateExamTimeTable = (values) => {
  return dispatch => {
    dispatch({ type: SET_ADD_LOADING_EXAM_TYPE });
    api.updateExamTimeTable(values)
      .then(response => {
        dispatch(updateExamTimeTableSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateExamTimeTableError(getErrorMessage(err)))
      })
  }
}

// Exam Type Actions

export const getExamTypeSuccess = (examType, pg) => {
  return {
    type: GET_EXAM_TYPE,
    examType, pg
  }
}

export const getExamTypeError = (message) => {
  return {
    type: ADD_EXAM_TYPE_ERROR,
    message
  }
}

export const getExamType = (pg) => {
  return dispatch => {
    dispatch({ type: SET_GET_LOADING_EXAM_TYPE });
    api.getExamType({ pg })
      .then(response => {
        dispatch(getExamTypeSuccess(response.data, pg))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getExamTypeError(getErrorMessage(err)))
      })
  }
}

export const addExamTypeError = (message) => {
  return {
    type: ADD_EXAM_TYPE_ERROR,
    message
  }
}
export const addExamTypeSuccess = (examType) => {
  return {
    type: ADD_EXAM_TYPE,
    examType
  }
}

export const addExamType = (examTimeTable) => {
  return dispatch => {
    dispatch({ type: SET_ADD_LOADING_EXAM_TYPE });
    api.addExamType(examTimeTable)
      .then(response => {
        dispatch(addExamTypeSuccess(response.data));
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addExamTypeError(getErrorMessage(err)))
      })
  }
}


export const updateExamTypeError = (message) => {
  return {
    type: UPDATE_EXAM_TYPE_ERROR,
    message
  }
}

export const updateExamTypeSuccess = (examType) => {
  return {
    type: UPDATE_EXAM_TYPE,
    examType
  }
}

export const updateExamType = (values) => {
  return dispatch => {
    dispatch({ type: SET_ADD_LOADING_EXAM_TYPE });
    api.updateExamType(values)
      .then(response => {
        dispatch(updateExamTypeSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateExamTypeError(getErrorMessage(err)))
      })
  }
}






export const deleteExamTimeSuccess = (studentId) => {
  return {
    type: DELETE_EXAM_TYPE,
    studentId
  }
}

export const deleteExamTimeError = (message) => {
  return {
    type: DELETE_EXAM_TYPE_ERROR,
    message
  }
}

export const deleteExamTime = (studentId) => {
  console.log("inside ac",studentId)
  return dispatch => {
    dispatch({ type: DELETE_EXAM_TYPE_LOADING });
    api.deleteExamTimeTable({examTimeTableId:studentId })
      .then(response => {
        dispatch(deleteExamTimeSuccess(studentId))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        console.log("Error of  message",err.response.data.message)
        dispatch(deleteExamTimeError(getErrorMessage(err.message)))
      })
  }
}
