import Axios from "axios";
import {URLS} from "./URLS";

export const getExamType = data => Axios.post(URLS.GET_EXAM_TYPE, data);
export const addExamType = data => Axios.post(URLS.ADD_EXAM_TYPE, data);
export const updateExamType = data => Axios.post(URLS.UPDATE_EXAM_TYPE, data);

export const getExamTimeTable = data => Axios.post(URLS.GET_EXAM_TIMETABLE, data);
export const addExamTimeTable = examTimeTable => {
	// const form = new FormData();
	// let i = 0;
	// for (let d of data) {
	//     form.append(`examTimeTable[${i}][day]`, d.day);
	//     form.append(`examTimeTable[${i}][duration]`, d.duration);
	//     form.append(`examTimeTable[${i}][examDate]`, d.examDate);
	//     form.append(`examTimeTable[${i}][examTime]`, d.examTime);
	//     form.append(`examTimeTable[${i}][examTypeId]`, d.examTypeId);
	//     form.append(`examTimeTable[${i}][subjectId]`, d.subjectId);
	// }
	return Axios.post(URLS.ADD_EXAM_TIMETABLE, {examTimeTable})
};
export const updateExamTimeTable = data => Axios.post(URLS.UPDATE_EXAM_TIMETABLE, data);

export const deleteExamTimeTable = data => Axios.post(URLS.DELETE_EXAM_TIMETABLE, data);
