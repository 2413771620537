import React, { Component } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import { addBreakfast, updateBreakfast } from '../../../Store/action/breakfast';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Select from 'react-select';
import * as action from '../../../Store/action/breakfast';

const SignupSchema = Yup.object().shape({
  day: Yup.string().required('Day is required'),
  breakfastName: Yup.string().required('Breakfast name is required'),
  description: Yup.string().required('Description name is required'),
});

class AddBreakfast extends Component {
  input = React.createRef();
  state = {
    day: '',
    breakfastName: '',
    description: '',
    breakfastId: '',
  };

  componentDidMount() {
    const { breakfast } = this.props.location.state || {};
    if (breakfast) {
      // this.setState(breakfast);
      this.setState({ ...breakfast, breakfastId: breakfast.id });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push('/admin/breakfast');
      this.props.onGetBreakfast(1);
    } else if (nextProps.error && !this.props.error) {
      console.log("Error",nextProps.error)
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // this.setErrors({ subjectName: nextProps.error });
    }
  }

  submitHandler = (values) => {
    if (this.state.breakfastId) {
      this.props.onUpdateBreakfast(values);
    } else {
      this.props.onAddBreakfast(values);
    }
  };

  render() {
    const dayOptions = [
      { label: 'Monday', value: 'Monday' },
      { label: 'Tuesday', value: 'Tuesday' },
      { label: 'Wednesday', value: 'Wednesday' },
      { label: 'Thursday', value: 'Thursday' },
      { label: 'Friday', value: 'Friday' },
      { label: 'Saturday', value: 'Saturday' },
      { label: 'Sunday', value: 'Sunday' },
    ];
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            breakfastName: this.state.breakfastName,
            day: this.state.day,
            description: this.state.description,
            breakfastId: this.state.breakfastId,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">
                {this.state.breakfastName === ''
                  ? 'Add Breakfast'
                  : 'Update Breakfast'}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="5">
                    <FormGroup>
                      <Label>Breakfast<span className="required">*</span></Label>
                      <Input
                        type="text"
                        invalid={errors.breakfastName && touched.breakfastName}
                        name="breakfastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.breakfastName}
                        placeholder="Breakfast Name"
                      />
                      <FormFeedback>
                        {errors.breakfastName &&
                          touched.breakfastName &&
                          errors.breakfastName}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label>Day<span className="required">*</span></Label>
                      <Select
                        onChange={(value) => setFieldValue('day', value.value)}
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={
                          dayOptions
                            ? dayOptions.find((s) => s.value === values.day)
                            : ''
                        }
                        options={dayOptions}
                      />
                      <FormFeedback>
                        {errors.day && touched.day && errors.day}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="5">
                    <FormGroup>
                      <Label>Description<span className="required">*</span></Label>
                      <Input
                        type="textarea"
                        invalid={errors.description && touched.description}
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        placeholder="Description"
                      />
                      <FormFeedback>
                        {errors.description &&
                          touched.description &&
                          errors.description}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="d-flex justify-content-start">
                  <button
                    disabled={this.props.loading}
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                  >
                    {this.props.loading ? (
                      <ClipLoader size={15} color={'#123abc'} loading={true} />
                    ) : (
                        'Save'
                      )}
                  </button>
                  <Link
                    className="btn btn-secondary"
                    to={{ pathname: '/admin/breakfast' }}
                  >
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.breakfast.addError,
    loading: state.breakfast.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetBreakfast: (pg) => dispatch(action.getBreakfasts(pg)),
    onAddBreakfast: (holiday) => dispatch(addBreakfast(holiday)),
    onUpdateBreakfast: (breakfast) => dispatch(updateBreakfast(breakfast)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBreakfast);
