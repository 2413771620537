import { toast } from 'react-toastify';
import * as api from '../../api/classes';
import { getErrorMessage } from '../../utils/utils';
export const ADD_CLASS = 'ADD_CLASS'
export const ADD_CLASS_ERROR = 'ADD_CLASS_ERROR'
export const GET_CLASS = 'GET_CLASS'
export const GET_CLASS_ERROR = 'GET_CLASS_ERROR'
export const UPDATE_CLASS = 'UPDATE_CLASS'
export const UPDATE_CLASS_ERROR = 'UPDATE_CLASS_ERROR'
export const SET_LOADING_CLASS = 'SET_LOADING_CLASS'
export const DELETE_CLASS = 'DELETE_CLASS'
export const DELETE_CLASS_ERROR = 'DELETE_CLASS_ERROR'
export const CLASS_STATUS_CHANGE = ' CLASS_STATUS_CHANGE'
export const CLASS_STATUS_CHANGE_ERROR = ' CLASS_STATUS_CHANGE_ERROR'
export const SET_CLASS_STATUS_IN_PROCESS= 'SET_CLASS_STATUS_IN_PROCESS';

export const addClassSuccess = (classes) => {
    return {
        type: ADD_CLASS,
        classes
    }
}

export const addClassError = (message) => {
    return {
        type: ADD_CLASS_ERROR,
        message
    }
}
export const addClass = (className) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_CLASS });
        api.addClass(className)
            .then(response => {
                dispatch(addClassSuccess(response.data))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(addClassError(getErrorMessage(err)));
            })
    }
}

export const updateClassError = (message) => {
    return {
        type: UPDATE_CLASS_ERROR,
        message
    }
}
export const updateClassSuccess = (classes) => {
    return {
        type: UPDATE_CLASS,
        classes
    }
}

export const updateClass = (classes) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_CLASS });
        api.updateClass(classes)
            .then(response => {
                dispatch(updateClassSuccess(response.data))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(updateClassError(getErrorMessage(err)));
            })
    }
}

export const getClassSuccess = (classes, pg) => {
    return {
        type: GET_CLASS,
        classes, pg
    }
}

export const getClassError = (message) => {
    return {
        type: GET_CLASS_ERROR,
        message
    }
}

export const getClass = (pg, searchName, status) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_CLASS });
        api.getClasses({ pg, searchName, status })
            .then(response => {
                dispatch(getClassSuccess(response.data, pg))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(getClassError(getErrorMessage(err)))
            })
    }
}

export const classStatusChangeSuccess = (classId, status) => {
    return {
        type: CLASS_STATUS_CHANGE,
        classId, status
    }
}

export const classStatusChangeError = (message) => {
    return {
        type: CLASS_STATUS_CHANGE_ERROR,
        message
    }
}

export const classStatusChange = (classId, status) => {
    return dispatch => {
        dispatch({ type: SET_CLASS_STATUS_IN_PROCESS });
        api.classStatusChange({ classId, status })
            .then(response => {
                dispatch(classStatusChangeSuccess(classId, status))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(classStatusChangeError(getErrorMessage(err)))
            })
    }
}

export const deleteClassSuccess = () => {
    return {
        type: DELETE_CLASS,
    }
}

export const deleteClassError = (message) => {
    return {
        type: DELETE_CLASS_ERROR,
        message
    }
}

export const deleteClass = () => {
    return dispatch => {

    }
}
