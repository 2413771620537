import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import {
  addExamType,
  updateExamType,
} from '../../../Store/action/ExamTimeTable';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { getExamType} from '../../../Store/action/ExamTimeTable';

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Subject is required'),
});

class AddExamType extends Component {
  state = {
    name: '',
  };

  componentDidMount() {
    const { examType } = this.props.location.state || {};
    if (examType) {
      this.setState({ ...examType, examTypeId: examType.id });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push('/admin/examType');
      this.props.onGetExamType(1);
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // this.setErrors({ subjectName: nextProps.error });
    }
  }

  submitHandler = (values) => {
    if (this.state.examTypeId) {
      this.props.onUpdateExamType(values);
    } else {
      this.props.onExamTypeAdd(values);
    }
  };
  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            name: this.state.name,
            examTypeId: this.state.examTypeId,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">
                {this.state.name === '' ? 'Add Exam Type' : 'Update Exam Type'}
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>Exam Type Name<span className="required">*</span></Label>
                  <Row>
                    <Col xs={6}>
                      <Input
                        type="className"
                        invalid={errors.name && touched.name}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        placeholder="Enter Exam Type"
                      />
                    </Col>
                  </Row>
                  <FormFeedback>
                    {errors.name && touched.name && errors.name}
                  </FormFeedback>
                </FormGroup>
                <div className="d-flex justify-content-start">
                  <button
                    disabled={this.props.loading}
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}>
                    {this.props.loading ? (
                      <ClipLoader size={15} color={'#123abc'} loading={true} />
                    ) : (
                        'Save'
                      )}
                  </button>
                  <Link
                    className="btn btn-secondary"
                    to={{ pathname: '/admin/examType' }}
                  >
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.examTimeTable.isAddExamLoading,
    error: state.examTimeTable.addError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onExamTypeAdd: (examType) => dispatch(addExamType(examType)),
    onUpdateExamType: (examType) => dispatch(updateExamType(examType)),
    onGetExamType: (pg) => dispatch(getExamType(pg)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddExamType);
