import React, { Component } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  addDailyActivity,
  updateDailyActivity,
} from '../../../Store/action/DailyActivityTimetable';
import Select from 'react-select';
import { getSubject } from '../../../Store/action/Subjects';
import TimeKeeper from 'react-timekeeper';
const SignupSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  durationFrom: Yup.string().required('Duration From is required'),
  durationTo: Yup.string().required('Duration To is required'),
  day: Yup.string().required('Day is required'),
});

class AddDailyActivityTimetable extends Component {
  input = React.createRef();
  state = {
    subject: '',
    durationFrom: '10:00 am',
    durationTo: '11:00 am',
    day: '',
    teacherId: '',
    dailyTimeTableId: '',
    showFromTimePicker: false,
    showToTimePicker: false,
  };

  componentDidMount() {
    this.props.onGetSubjects();
    const { dailyActivity } = this.props.location.state || {};
    if (dailyActivity) {
      this.setState({
        ...dailyActivity,
        dailyTimeTableId: dailyActivity.id,
        durationFrom: dailyActivity.duration.split('-')[0].trim(''),
        durationTo: dailyActivity.duration.split('-').pop().trim(''),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push('/admin/dailyActivityTimetable');
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // this.setErrors({ subjectName: nextProps.error });
    }
  }

  submitHandler = (values) => {
    if (this.state.dailyTimeTableId) {
      this.props.onUpdateDailyActivityTimetable(values);
    } else {
      this.props.onAddDailyActivityTimetable(values);
    }
  };

  render() {
    const { subject } = this.props;
    const { showFromTimePicker, showToTimePicker } = this.state;
    const subjects = subject.data.map((s) => ({
      value: s.subjectName,
      label: s.subjectName,
    }));
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ].map((s) => ({
      value: s,
      label: s,
    }));
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            subject: this.state.subject,
            durationFrom: this.state.durationFrom,
            durationTo: this.state.durationTo,
            day: this.state.day,
            teacherId: this.props.teacherId.id,
            dailyTimeTableId: this.state.dailyTimeTableId
          }}
          validationSchema={SignupSchema}
          onSubmit={(
            { durationFrom, durationTo,...values },
            { setSubmitting, setErrors }
          ) => {
            this.setErrors = setErrors;
            this.submitHandler({
              ...values,
              duration: `${durationFrom} - ${durationTo}`,
              startTime:durationTo,
              endTime:durationFrom
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">
                {this.state.subjectName === ''
                  ? 'Add Daily Activity TimeTable'
                  : 'Update Daily Activity TimeTable'}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="4">
                    <FormGroup>
                      <Label>Subject Name<span className="required">*</span></Label>
                      <Select
                        name="subjectId"
                        onChange={(value) =>
                          setFieldValue('subject', value.value)
                        }
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={
                          subjects
                            ? subjects.find((s) => s.value === values.subject)
                            : ''
                        }
                        options={subjects}
                      />
                      <FormFeedback>
                        {errors.subject && touched.subject && errors.subject}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <FormGroup>
                    <Label>Start Time</Label>
                    <Row style={{marginRight:5}}>
                      <div className="col-lg-12">
                        <Input
                          type="text"
                          onClick={() =>
                            this.setState({
                              showFromTimePicker: true,
                              showToTimePicker: false,
                            })
                          }
                          invalid={errors.durationFrom && touched.durationFrom}
                          name="durationFrom"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.durationFrom}
                          placeholder="Duration From"
                        />
                        {showFromTimePicker && (
                          <div
                            className="position-absolute"
                            style={{ zIndex: 9 }}
                          >
                            <TimeKeeper
                              time={values.durationFrom}
                              onChange={({ formatted12 }) =>
                                setFieldValue('durationFrom', formatted12)
                              }
                              onDoneClick={() =>
                                this.setState({ showFromTimePicker: false })
                              }
                              switchToMinuteOnHourSelect
                            />
                          </div>
                        )}
                        <FormFeedback>
                          {errors.durationFrom &&
                            touched.durationFrom &&
                            errors.durationFrom}
                        </FormFeedback>
                      </div>
                    
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label>End Time</Label>
                    <Row>
                      <div className="col-lg-12">
                        <Input
                          type="text"
                          invalid={errors.durationTo && touched.durationTo}
                          onClick={() =>
                            this.setState({
                              showToTimePicker: true,
                              showFromTimePicker: false,
                            })
                          }
                          name="durationTo"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.durationTo}
                          placeholder="Duration"
                        />
                        {showToTimePicker && (
                          <div
                            className="position-absolute"
                            style={{ zIndex: 9 }}
                          >
                            <TimeKeeper
                              time={values.durationTo}
                              onChange={({ formatted12 }) =>
                                setFieldValue('durationTo', formatted12)
                              }
                              onDoneClick={() =>
                                this.setState({ showToTimePicker: false })
                              }
                              switchToMinuteOnHourSelect
                            />
                          </div>
                        )}
                        <FormFeedback>
                          {errors.durationTo &&
                            touched.durationTo &&
                            errors.durationTo}
                        </FormFeedback>
                      </div>
                    </Row>
                  </FormGroup>

                  <Col>
                    <FormGroup>
                      <Label>Day<span className="required">*</span></Label>
                      <Select
                        name="subjectId"
                        onChange={(value) => setFieldValue('day', value.value)}
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={
                          days ? days.find((s) => s.value === values.day) : ''
                        }
                        options={days}
                      />
                      <FormFeedback>
                        {errors.day && touched.day && errors.day}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="d-flex justify-content-start">
                  <button
                    disabled={this.props.loading}
                    type="submit"
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                  >
                    {this.props.loading ? (
                      <ClipLoader size={15} color={'#123abc'} loading={true} />
                    ) : (
                        'Save'
                      )}
                  </button>
                  <Link
                    className="btn btn-secondary"
                    to={{ pathname: '/admin/dailyActivityTimetable' }}
                  >
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.dailyActivityTimeTable.addError,
    subject: state.subject.subjects,
    loading: state.dailyActivityTimeTable.isLoading,
    teacherId: state.teacherLogin.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDailyActivityTimetable: (timeTable) =>
      dispatch(addDailyActivity(timeTable)),
    onGetSubjects: () => dispatch(getSubject()),
    onUpdateDailyActivityTimetable: (timeTable) =>
      dispatch(updateDailyActivity(timeTable)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDailyActivityTimetable);
