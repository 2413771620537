import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import * as action from "../../../Store/action/Classes";
import { getStandard } from "../../../Store/action/Standard";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import "./Classes.css";
import * as Yup from "yup";
import { Formik } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";

const SignupSchema = Yup.object().shape({
  // className: Yup.string()
  //   .required("class Name is required")
  //   .test("alphabets", "Class Name must only contain alphabets", (value) => {
  //     return /^[A-Za-z]+$/.test(value);
  //   }),
    
  className: Yup.string().required("Classname  is required"),
  standardId: Yup.string().required("Standard is required"),
  medium: Yup.string().required("Medium is required"),
});

const options = [
  { value: "GUJ", label: "Guj" },
  { value: "ENG", label: "Eng" },
];
class AddClass extends Component {
  state = {
    className: "",
    standard: "",
    medium: "",
  };

  componentDidMount() {
    this.props.onGetStandard();
    const { className } = this.props.location.state || {};
    if (className) {
      this.setState({ ...className, classId: className.id });
    }
  }
 
  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
       this.props.history.push("/admin/classes");
      this.props.onGetClass();
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // this.setErrors({ medium: nextProps.error });
    }
  }

  submitHandler = (values) => {
    if (this.state.classId) {
      this.props.onUpdateClass(values);
    } else {
      this.props.onAddClass(values);
    }
  };
  render() {
    let alphbate=  Array.from({ length: 11 }, (_, index) => ({
      label: String.fromCharCode(65 + index),
      value: index + 1,
    }));
    console.log("Alphabate",alphbate)
    const { standard } = this.props;
    const standardOption = standard.data.map((p, x) => ({
      label: p.standardName,
      value: p.id,
      status: p.status,
    }));

    const standardOptions = standardOption.filter(
      (op) => op.status === "ACTIVE"
    );
  

    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            className: this.state.className,
            standardId: this.state.standardId,
            medium: this.state.medium,
            classId: this.state.classId,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">
                {this.state.className === "" ? "Add Class" : "Update Class"}
              </CardHeader>
              <CardBody>
                <Row>
                  
                  <Col xs="3">
                    <FormGroup>
                      <Label>Standard<span className="required">*</span></Label>
                      <Select
                        name="medium"
                        onChange={(option) =>
                          setFieldValue(
                            "standardId",
                            option ? option.value : ""
                          )
                        }
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={
                          standardOptions
                            ? standardOptions.find(
                                (option) => option.value === values.standardId
                              )
                            : ""
                        }
                        options={standardOptions}
                      />
                      {/* <Input type="standard"
                    invalid={errors.standard && touched.standard}
                    name="standard" onChange={handleChange} onBlur={handleBlur} value={values.standard} placeholder="Standard" /> */}
                      <FormFeedback>
                        {errors.standardId &&
                          touched.standardId &&
                          errors.standardId}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs="3">
                  <FormGroup>
                      <Label>Class Name<span className="required">*</span></Label>
                      <Select
                        name="medium"
                        onChange={(option) =>
                          setFieldValue(
                            "className",
                            option ? option.label: ""
                          )
                        }
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={
                          alphbate
                            ? alphbate.find(
                                (option) => option.value === values.className
                              )
                            : ""
                        }
                        options={alphbate}
                      />
                      <FormFeedback>
                        {errors.className &&
                          touched.className &&
                          errors.className}
                      </FormFeedback>
                    </FormGroup>
                    {/* <FormGroup>
                      <Label>Class Name<span className="required">*</span></Label>
                      <Input
                        type="className"
                        invalid={errors.className && touched.className}
                        name="className"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.className}
                        placeholder="Class Name"
                      />
                      <FormFeedback>
                        {errors.className &&
                          touched.className &&
                          errors.className}
                      </FormFeedback>
                    </FormGroup> */}
                  </Col>

                  <Col xs="3">
                    <FormGroup>
                      <Label>Medium<span className="required">*</span></Label>
                      <Select
                        name="medium"
                        onChange={(option) =>
                          setFieldValue("medium", option.value)
                        }
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={
                          options
                            ? options.find(
                                (option) => option.value === values.medium
                              )
                            : ""
                        }
                        options={options}
                      />
                      <FormFeedback>
                        {errors.medium && touched.medium && errors.medium}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <button
                      disabled={this.props.loading}
                      className="btn btn-primary mr-2"
                      onClick={handleSubmit}
                    >
                      {this.props.loading ? (
                        <ClipLoader
                          size={15}
                          color={"#123abc"}
                          loading={true}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                    <Link
                      className="btn btn-secondary"
                      to={{ pathname: "/admin/classes" }}
                    >
                      Cancel
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.class.isLoading,
    error: state.class.addError,
    standard: state.standard.standard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddClass: (className) => dispatch(action.addClass(className)),
    onUpdateClass: (className) => dispatch(action.updateClass(className)),
    onGetStandard: () => dispatch(getStandard()),
    onGetClass: (pg, searchName, status) => dispatch(action.getClass(pg, searchName, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClass);
