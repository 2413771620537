import { toast } from 'react-toastify';
import { URLS } from '../../api/URLS';
import * as api from '../../api/attendance';
import { getErrorMessage } from '../../utils/utils';
export const ADD_ATTENDANCE = 'ADD_ATTENDANCE'
export const ADD_ATTENDANCE_ERROR = 'ADD_ATTENDANCE_ERROR'
export const GET_ATTENDANCES = 'GET_ATTENDANCES'
export const GET_ATTENDANCES_ERROR = 'GET_ATTENDANCES_ERROR'
export const DELETE_ATTENDANCE = 'DELETE_ATTENDANCE'
export const DELETE_ATTENDANCE_ERROR = 'DELETE_ATTENDANCE_ERROR'
export const UPDATE_ATTENDANCE = 'UPDATE_ATTENDANCE'
export const UPDATE_ATTENDANCE_ERROR = 'UPDATE_ATTENDANCE_ERROR'
export const SET_LOADING_ATTENDANCE = 'SET_LOADING_ATTENDANCE'
export const SET_EXPORT_ATTENDANCE_LOADING='SET_EXPORT_ATTENDANCE_LOADING'

export const addAttendanceSuccess = (attendance) => {

    return {
        type: ADD_ATTENDANCE,
        attendance
    }
}

export const addAttendanceError = (message) => {
    return {
        type: ADD_ATTENDANCE_ERROR,
        message
    }
}
export const addAttendance = (attendance) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_ATTENDANCE });
        api.addAttendance(attendance)
            .then(response => {
                dispatch(addAttendanceSuccess(response.data))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(addAttendanceError(getErrorMessage(err)))
            })
    }
}

export const updateAttendanceError = (message) => {
    return {
        type: UPDATE_ATTENDANCE_ERROR,
        message
    }
}

export const updateAttendanceSuccess = (attendance) => {
    return {
        type: UPDATE_ATTENDANCE,
        attendance
    }
}

export const updateAttendance = (attendance) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_ATTENDANCE });
        api.updateAttendance(attendance)
            .then(response => {
                dispatch(updateAttendanceSuccess(attendance))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(updateAttendanceError(getErrorMessage(err)))
            })
    }
}

export const getAttendancesSuccess = (attendance, pg) => {
    return {
        type: GET_ATTENDANCES,
        attendance, pg
    }
}

export const getAttendancesError = (message) => {
    return {
        type: GET_ATTENDANCES_ERROR,
        message
    }
}

export const getAttendances = (pg, classId, attendanceDate, searchName,exportData) => {
    console.log("Export data",exportData)
    return dispatch => {
       
        if(exportData===true){
            dispatch({ type: SET_EXPORT_ATTENDANCE_LOADING });
        }else{
            dispatch({ type: SET_LOADING_ATTENDANCE });
        }
       
        api.geAttendances({ pg, classId, attendanceDate, searchName,exportData })
            .then(response => {
               
                console.log("res",response.data)
                dispatch(getAttendancesSuccess(response.data, pg))
                if(exportData===true){
                    window.open(`${URLS.ATTENDANCE_DOWNLOAD}`);
                }
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(getAttendancesError(getErrorMessage(err)))
            })
    }
}

export const deleteAttendancesSuccess = (attendance) => {
    return {
        type: DELETE_ATTENDANCE,
        attendance
    }
}

export const deleteAttendancesError = (message) => {
    return {
        type: DELETE_ATTENDANCE_ERROR,
        message
    }
}

export const deleteAttendances = () => {
    return dispatch => {
        // api.getTeachers()
        //     .then(response => {
        //         dispatch(deleteHomeworksSuccess(response.data))
        //     })
        //     .catch(err => {
        //     dispatch(deleteHomeworksError(err.response ? err.response.data.message:'Something Went Wrong. Please try again' ))
        //     })
    }
}
