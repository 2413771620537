import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';
import moment from 'moment';
import ImageModal from "../../Modal/ImageModal";
import Teacher from "../../../assets/images/teacher.png";
import { connect } from 'react-redux';
import { forgotPasswords } from '../../../Store/action/Auth/forgotPassword';
import { toast } from 'react-toastify';
import { ClipLoader } from "react-spinners";

class TeacherDetails extends Component {
	state = {
		teacher: ''
	}

	componentWillReceiveProps(nextProps) {
		const { teacher } = nextProps;
		this.setState({ teacher });
		if (this.props.loading && !nextProps.loading && !nextProps.error) {
			toast.info('Please check your Email! sent...', {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}

	resetPasswordHandler = () => {
		const { teacher } = this.state;
		// const schoolId = this.props.user.schoolId;
		this.props.onForgotPassword({ email: teacher.email, schoolId: teacher.schoolId });
		// this.props.isClose();
	}

	render() {
		const { teacher } = this.state;
		console.log("Teacher of data",teacher)
		return (
			<>
				<Modal isOpen={this.props.isOpen}>
					<div className="modal-header">
						<h5 className="modal-title">{teacher.userRole===3?`Teacher's Details`:'Principal Details'}</h5>
						<Button className="close" onClick=""></Button>
					</div>
					<ModalBody>
						<Table className="borderless" hover>
							<tr>
								<td><ImageModal alt="." className="image img-fluid" src={teacher.profilePic ? teacher.profilePic : Teacher} /></td>
							</tr>
							<tr>
								<th>Name</th>
								<td>{teacher.firstName} {teacher.lastName}</td>
							</tr>
							<tr>
								<th>Email</th>
								<td>{teacher.email}</td>
							</tr>
							<tr>
								<th>BirthDate</th>
								<td>{moment(teacher.birthOfDate).format('DD-MM-YYYY')}</td>
							</tr>
							<tr>
								<th>Experience</th>
								<td>{teacher.experience}</td>
							</tr>
							<tr>
								<th>Gender</th>
								<td>{teacher.gender}</td>
							</tr>
							<tr>
								<th>Address</th>
								<td>{teacher.address}</td>
							</tr>
							<tr>
								<th>Class</th>
								<td>{teacher.userclasses && teacher.userclasses.map(p => `${p.class.standard.standardName}-${p.class.className}, ${p.class.medium}`).join(',')}</td>
							</tr>
							<tr>
								<th>Subjects</th>
								<td>{teacher.userSubjects && teacher.userSubjects.map(p => p.subject.subjectName).join(',')}</td>
							</tr>
						</Table>
					</ModalBody>
					<ModalFooter className="justify-content-center">
						<Button className="btn-outline-primary" disabled={this.props.loading} style={{ background: "none", color: "#4FC1E9" }}
							onClick={this.resetPasswordHandler}>{this.props.loading ?
								<ClipLoader
								  size={15}
								  color={"#123abc"}
								  loading={true}
								/> : 'Reset Password'
							  }</Button>
						<Button className="btn-outline-primary" style={{ background: "none", color: "#4FC1E9" }}
							onClick={this.props.isClose}>Close</Button>
					</ModalFooter>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.forgotPassword.forgotLoading,
		error: state.forgotPassword.error,
		user: state.login.user,
	}
};

const mapDispatchToProps = dispatch => ({
	onForgotPassword: (data) => dispatch(forgotPasswords(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDetails);
