import React, { Component } from 'react'
import { Alert, Card, CardBody, CardHeader, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from "react-spinners";
import * as action from '../../../Store/action/holiday';
import ConfirmationDialog from '../../ConfirmDialog/DeleteConfirmDialog';
import { Link } from 'react-router-dom';
import moment from 'moment'

class Holiday extends Component {
	state = {
		pg: this.props.currentPage,
		holidayId: ''
	}

	componentDidMount() {
		if (!this.props.holidays.data.length) {
			this.props.onGetHoliday(1);
		}
	}


	confirmationDialogBox = (deleteEvent) => {
		this.setState({ deleteEvent });
	}

	deleteHandler = (ec) => {
		this.setState({ deleteEvent: null });
		this.props.onDeleteHoliday({ holidayId: ec.id })
		this.props.onGetHoliday();
	}

	paginationChangeHandler = (pg, value) => () => {
		this.setState({ pg: value });
		this.props.onGetHoliday(value);
	}

	renderPagination(pagination) {
		const pages = [];
		for (let i = 1; i <= pagination; i++) {
			pages.push(
				<PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
					<PaginationLink tag="button">{i}</PaginationLink>
				</PaginationItem>
			);
		}
		return pages;
	}

	render() {

		const { holidays, loading, error, user } = this.props;
		const { pg } = this.state;
		return (
			<div className="row clearfix">
				<div className="col-lg-12">

					<ConfirmationDialog
						isOpen={!!this.state.deleteEvent}
						deleteHandler={() => this.deleteHandler(this.state.deleteEvent)}
						closeHandler={() => this.confirmationDialogBox(null)} />
					<Card className="c-style">
						<CardHeader className="h-style">
							<div className="row m-r-0 m-l-0">
								<div className="c-title-style">
									Holiday
								</div>
								{user.userRole === 2 &&
									<div className="ml-auto btn-style">
										<Link class="btn btn-outline-dark" to={{ pathname: '/admin/holiday/add' }}>Add</Link>
									</div>
								}
							</div>
						</CardHeader>
						<CardBody>
							<Table className="table-responsive-sm table-responsive-md " hover>
								<thead className='table-header-wrapper'>
									<tr>
										<th>#</th>
										<th>Date</th>
										<th>Holiday Name</th>
										<th>{user.userRole === 2 && 'Action'}</th>
									</tr>
								</thead>
								<tbody>
									{!loading && !error &&
										holidays.data.map((p, index) => {
											return (
												<tr>
													<td><strong>{(pg - 1) * 10 + index + 1}</strong></td>
													<td>{moment(p.holidayDate).format('DD-MM-YYYY')}</td>
													<td>{p.holidayName}</td>
													<td>{user.userRole === 2 &&
														<div className="row">
															<Link className="p-2" to={{ pathname: "/admin/holiday/add", state: { holiday: p } }}><i
																class="icon-pencil" style={{ color: 'black' }} aria-hidden="true"></i></Link>
															<span className="p-2" onClick={() => this.confirmationDialogBox(p)}><i class="icon-trash"
																aria-hidden="true"></i></span>
														</div>}
													</td>
												</tr>
											)
										})}
								</tbody>
							</Table>

							{loading
								?
								<div className="loader-wrapper">
									<ClipLoader
										size={35}
										color={"#123abc"}
										loading={true}
									/>
								</div>
								:
								<div className="d-flex justify-content-end p-2">
									{holidays.data.length > 0 && holidays.pagination &&
										<Pagination className="pull-right">
											<PaginationItem>
												<PaginationLink
													onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()}
													previous tag="button" />
											</PaginationItem>
											{this.renderPagination(holidays.pagination.pages)}
											<PaginationItem>
												<PaginationLink
													onClick={() => holidays.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()}
													next tag="button" />
											</PaginationItem>
										</Pagination>
									}
								</div>
							}
							{!loading && error &&
								<div className="error-style">
									<Alert color="danger">{error}</Alert>
								</div>}
						</CardBody>
					</Card>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		holidays: state.holiday.holidays,
		currentPage: state.holiday.currentPage,
		error: state.holiday.getError,
		loading: state.holiday.isLoading,
		user: state.login.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGetHoliday: (pg) => dispatch(action.getHolidays(pg)),
		onDeleteHoliday: (deleteEvent) => dispatch(action.deleteHoliday(deleteEvent))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Holiday)
