import { toast } from 'react-toastify';
import * as api from '../../api/teachers';
import { getErrorMessage } from '../../utils/utils';
export const ADD_TEACHER = 'ADD_TEACHER'
export const ADD_TEACHER_ERROR = 'ADD_TEACHER_ERROR'
export const GET_TEACHER = 'GET_TEACHER'
export const GET_TEACHER_ERROR = 'GET_TEACHER_ERROR'
export const DELETE_TEACHER = 'DELETE_TEACHER'
export const DELETE_TEACHER_ERROR = 'DELETE_TEACHER_ERROR'
export const UPDATE_TEACHER = 'UPDATE_TEACHER'
export const UPDATE_TEACHER_ERROR = 'UPDATE_TEACHER_ERROR'
export const TEACHER_STATUS_CHANGE = ' TEACHER_STATUS_CHANGE'
export const TEACHER_STATUS_CHANGE_ERROR = ' TEACHER_STATUS_CHANGE_ERROR'
export const SET_LOADING_TEACHER = 'SET_LOADING_TEACHER'
export const SET_TEACHER_STATUS_IN_PROCESS = 'SET_TEACHER_STATUS_IN_PROCESS';

export const TeacherStatusChangeSuccess = (status, userId) => {
    return {
        type: TEACHER_STATUS_CHANGE,
        status, userId
    }
}

export const TeacherStatusChangeError = (message) => {
    return {
        type: TEACHER_STATUS_CHANGE_ERROR,
        message
    }
}
export const teacherStatusChange = (userId, status) => {
    return dispatch => {
        dispatch({ type: SET_TEACHER_STATUS_IN_PROCESS });
        api.teacherStatusChange({ status, userId })
            .then(response => {
                dispatch(TeacherStatusChangeSuccess(status, userId))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(TeacherStatusChangeError(getErrorMessage(err)))
            })
    }
}

export const addTeacherSuccess = (teacher) => {
    console.log("TEacher of data",teacher)
    return {
        type: ADD_TEACHER,
        teacher
    }
}

export const addTeacherError = (message) => {
    return {
        type: ADD_TEACHER_ERROR,
        message
    }
}

export const addTeacher = (teachers) => {
    console.log("Add teacher data",teachers)
    return dispatch => {
        dispatch({ type: SET_LOADING_TEACHER });
        api.addTeacher(teachers)
            .then(response => {
                dispatch(addTeacherSuccess(response.data))
            })
            .catch(err => {
                console.log("Error of teacher",err)
                // if(err==='Cannot read properties of null (reading 'pages')')
                // toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
				// 	position: toast.POSITION.TOP_RIGHT,
				// });
                dispatch(addTeacherError(getErrorMessage(err)))
            })
    }
}

export const updateTeacherError = (message) => {
    return {
        type: UPDATE_TEACHER_ERROR,
        message
    }
}

export const updateTeacherSuccess = (teacher) => {
    return {
        type: UPDATE_TEACHER,
        teacher
    }
}

export const updateTeacher = (teachers) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_TEACHER });
        api.updateTeacher(teachers)
            .then(response => {
                dispatch(updateTeacherSuccess(response.data))
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
                dispatch(updateTeacherError(getErrorMessage(err)))
            })
    }
}

export const getTeachersSuccess = (teachers, pg) => {
    return {
        type: GET_TEACHER,
        teachers, pg
    }
}

export const getTeachersError = (message) => {
    return {
        type: GET_TEACHER_ERROR,
        message
    }
}

export const getTeachers = (pg, status, searchName, userRole) => {
    return dispatch => {
        dispatch({ type: SET_LOADING_TEACHER });
        api.getTeachers({ pg, status, searchName, userRole })
            .then(response => {
                console.log("Respinse of data",response)
                dispatch(getTeachersSuccess(response.data, pg))
            })
            .catch(err => {
                console.log("Error of response",err.response.data.data)
                dispatch(getTeachersError(getErrorMessage(err)))
            })
    }
}

export const deleteTeachersSuccess = (teachers) => {
    return {
        type: DELETE_TEACHER,
        teachers
    }
}

export const deleteTeachersError = (message) => {
    return {
        type: DELETE_TEACHER_ERROR,
        message
    }
}

export const deleteTeachers = () => {
    return dispatch => {
        // api.getTeachers()
        //     .then(response => {
        //         dispatch(deleteTeachersSuccess(response.data))
        //     })
        //     .catch(err => {
        //     dispatch(deleteTeachersError(err.response ? err.response.data.message:'Something Went Wrong. Please try again' ))
        //     })
    }
}
