import React, { Component } from 'react'
import {
    Card, Table, CardBody, CardHeader, Alert, Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from "react-spinners";
import * as action from '../../../Store/action/breakfast';
import ConfirmationDialog from '../../ConfirmDialog/DeleteConfirmDialog';
import { Link } from 'react-router-dom';

class Holiday extends Component {
    state = {
        pg: this.props.currentPage,
        breakfastId: ''
    }

    componentDidMount() {
        if (!this.props.breakfasts.data.length) {
            this.props.onGetBreakfast(1);
        }
    }

    confirmationDialogBox = (deleteEvent) => {
        this.setState({
            deleteEvent,
        });
    }

    deleteHandler = (ec) => {
        this.setState({ deleteEvent: null });
        this.props.onDeleteBreakfast({ breakfastId: ec.id })
        this.props.onGetBreakfast();
    }

    paginationChangeHandler = (pg, value) => () => {
        this.setState({ pg: value });
        this.props.onGetBreakfast(value);
    }

    renderPagination(pagination) {
        const pages = [];
        for (let i = 1; i <= pagination; i++) {
            pages.push(
                <PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
                    <PaginationLink tag="button">{i}</PaginationLink>
                </PaginationItem>
            );
        }
        return pages;
    }

    render() {
        const { breakfasts, loading, error } = this.props;
        return (
            <div className="row clearfix">
                <div className="col-lg-12">

                    <ConfirmationDialog
                        isOpen={!!this.state.deleteEvent}
                        deleteHandler={() => this.deleteHandler(this.state.deleteEvent)}
                        closeHandler={() => this.confirmationDialogBox(null)} />
                    <Card className="c-style">
                        <CardHeader className="h-style">
                            <div className="row m-r-0 m-l-0">
                                <div className="c-title-style">
                                    Breakfast
                                </div>
                                <div className="ml-auto btn-style">
                                    <Link class="btn btn-outline-dark" to={{ pathname: '/admin/breakfast/add' }}>Add</Link>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Table className="table-responsive-sm table-responsive-md " hover>
                                <thead className='table-header-wrapper'>
                                    <tr>
                                        <th>#</th>
                                        <th>Day</th>
                                        <th>Breakfast Name</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading && !error &&
                                        breakfasts.data.map((p, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{p.day}</td>
                                                    <td>{p.breakfastName}</td>
                                                    <td>{p.description}</td>
                                                    <td>
                                                        <div className="row">
                                                            <Link className="p-2" to={{ pathname: "/admin/breakfast/add", state: { breakfast: p } }}><i class="icon-pencil" style={{ color: 'black' }} aria-hidden="true"></i></Link>
                                                            <span className="p-2 ml-3" onClick={() => this.confirmationDialogBox(p)}><i class="icon-trash" aria-hidden="true"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </Table>

                            {loading
                                ?
                                <div className="loader-wrapper">
                                    <ClipLoader
                                        size={35}
                                        color={"#123abc"}
                                        loading={true}
                                    />
                                </div>
                                :
                                <div className="d-flex justify-content-end p-2">
                                    {breakfasts.data.length > 0 && breakfasts.pagination &&
                                        <Pagination className="pull-right">
                                            <PaginationItem>
                                                <PaginationLink
                                                    onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()} previous tag="button" />
                                            </PaginationItem>
                                            {this.renderPagination(breakfasts.pagination.pages)}
                                            <PaginationItem>
                                                <PaginationLink
                                                    onClick={() => breakfasts.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()} next tag="button" />
                                            </PaginationItem>
                                        </Pagination>
                                    }
                                </div>
                            }
                            {!loading && error &&
                                <div className="error-style">
                                    <Alert color="danger">{error}</Alert>
                                </div>}
                        </CardBody>
                    </Card>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        breakfasts: state.breakfast.breakfasts,
        currentPage: state.breakfast.currentPage,
        error: state.breakfast.getError,
        loading: state.breakfast.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetBreakfast: (pg) => dispatch(action.getBreakfasts(pg)),
        onDeleteBreakfast: (breakfastId) => dispatch(action.deleteBreakfast(breakfastId))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Holiday)
