import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { forgotPasswords } from '../../../Store/action/Auth/forgotPassword';
import { toast } from 'react-toastify';
import { ClipLoader } from "react-spinners";

class StudentDetails extends Component {
	state = {
		student: ''
	}

	componentWillReceiveProps(nextProps) {
		const { student } = nextProps;
		this.setState({ student });
		if (this.props.loading && !nextProps.loading && !nextProps.error) {
			toast.info('Please check your Email! sent...', {
				position: toast.POSITION.TOP_RIGHT
			});
		}
		// else if (nextProps.error && !this.props.error) {
		// 	toast.error(nextProps.error, {
		// 		position: toast.POSITION.TOP_RIGHT
		// 	});
		// }
	}

	resetPasswordHandler = () => {
		const { student } = this.state;
		const schoolId = this.props.user.schoolId;
		this.props.onForgotPassword({ email: student.parent.email, grNumber: student.grNumber, schoolId });
		// this.props.isClose();
	}

	render() {
		const { student } = this.state;
		if (!student || !student.parent) return null;
		return (
			<>
				<Modal isOpen={this.props.isOpen}>
					<div className="modal-header">
						<h5 className="modal-title">Student's Details</h5>
						<Button className="close" onClick=""></Button>
					</div>
					<ModalBody>
						<Table className="borderless" hover>
							<tr>
								<th>Name</th>
								<td>{student.firstName} {student.lastName}</td>
							</tr>
							<tr>
								<th>GR Number</th>
								<td>{student.grNumber}</td>
							</tr>
							<tr>
								<th>Roll Number</th>
								<td>{student.rollNumber}</td>
							</tr>
							<tr>
								<th>Class</th>
								<td>{`${student.class.className}, ${student.class.medium}`}</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<h5>Parent's Details</h5>
									<hr />
								</td>
							</tr>
							<tr>
								<th>Name</th>
								<td>{student.parent.firstName} {student.parent.lastName}</td>
							</tr>
							<tr>
								<th>Email</th>
								<td>{student.parent.email}</td>
							</tr>
							<tr>
								<th>Mobile</th>
								<td>{student.parent.mobileNo}</td>
							</tr>
							<tr>
								<th>Address</th>
								<td>{student.parent.address}</td>
							</tr>
							<tr>
								<th>Joining Date</th>
								<td>{moment(student.birthOfDate).format('DD-MM-YYYY')}</td>
							</tr>
							<tr>
								<th>Status</th>
								<td>{student.status}</td>
							</tr>
						</Table>
					</ModalBody>
					<ModalFooter className="justify-content-center">
						<Button className="btn-outline-primary" style={{ background: "none", color: "#4FC1E9" }}
							onClick={this.resetPasswordHandler}>{this.props.loading ?
								<ClipLoader
									size={15}
									color={"#123abc"}
									loading={true}
								/> : 'Reset Password'
							}</Button>
						<Button className="btn-outline-primary" style={{ background: "none", color: "#4FC1E9" }}
							onClick={this.props.isClose}>Close</Button>
					</ModalFooter>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.forgotPassword.forgotLoading,
		error: state.forgotPassword.error,
		user: state.login.user,
	}
};

const mapDispatchToProps = dispatch => ({
	onForgotPassword: (data) => dispatch(forgotPasswords(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentDetails);
