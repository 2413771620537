import React, { Component } from 'react';

export default class privacypolicy extends Component {
  render() {
    return (
      <div
        style={{
        marginLeft:120,
          marginTop: 20,
        }}
      >
        <h3 style={{textAlign:'center'}}>SchoolDiary - School Management System</h3>
        <h4 style={{textAlign:'center'}}>Privacy Policy</h4>
        <h5 style={{textAlign:'center'}}>Effective Date: 18 Jan 2024</h5>
        <div style={{color:'black',fontSize:15,marginTop:50}}>
          Thank you for choosing SchoolDiary, a comprehensive school management
          system designed to enhance the efficiency and communication within
          educational institutions. This Privacy Policy outlines how we collect,
          use, disclose, and protect your information when you use our
          application.
        </div>
        <div  style={{color:'black',fontSize:15}}>
          By using SchoolDiary, you agree to the terms of this Privacy Policy.
          If you do not agree with these terms, please refrain from using our
          services.
        </div>
        <div  style={{color:'black',fontSize:15}}>1. Information We Collect:</div>
        <div  style={{color:'black',fontSize:15}}>1.1 User Information:</div>
        <div  style={{color:'black',fontSize:15}}>
          When you register for SchoolDiary, we may collect personal information
          such as your name, email address, contact details, and other relevant
          details. 1.2 Student Information:
        </div>
        <div  style={{color:'black',fontSize:15}}>
          Users, particularly school administrators and teachers, may input and
          store student information within SchoolDiary. This may include names,
          class details, attendance records, grades, and other educational data.
          1.3 Usage Data:
        </div>
        <div  style={{color:'black',fontSize:15}}>
          We may collect data on how you interact with SchoolDiary, such as log
          files, device information, and usage patterns. This helps us improve
          our services and troubleshoot issues. 2. How We Use Your Information:
        </div>
        <div  style={{color:'black',fontSize:15}}>2.1 Providing Services:</div>
        <div  style={{color:'black',fontSize:15}}>
          We use your information to provide and improve our services, including
          managing student records, facilitating communication, and optimizing
          the user experience. 2.2 Communication:
        </div>
        <div  style={{color:'black',fontSize:15}}>
          We may use your contact information to send important updates,
          announcements, and information related to SchoolDiary. You can opt-out
          of non-essential communications. 2.3 Analytics:
        </div>
        <div  style={{color:'black',fontSize:15}}>
          We analyze usage data to understand how SchoolDiary is used, identify
          areas for improvement, and enhance our features. 3. Information
          Sharing:
        </div>
        <div  style={{color:'black',fontSize:15}}>3.1 School Personnel:</div>
        <div  style={{color:'black',fontSize:15}}>
          Student information is accessible to authorized school personnel, such
          as administrators and teachers, for educational purposes within the
          scope of their roles. 3.2 Service Providers:
        </div>
        <div  style={{color:'black',fontSize:15}}>
          We may engage third-party service providers to assist with technical
          support, analytics, and other services. These providers are bound by
          confidentiality and data protection obligations. 3.3 Legal Compliance:
        </div>
        <div  style={{color:'black',fontSize:15}}>
          We may disclose information if required by law or in response to a
          valid legal request. 4. Data Security:
        </div>
        <div  style={{color:'black',fontSize:15}}>
          We implement industry-standard security measures to protect your
          information from unauthorized access, disclosure, alteration, and
          destruction.
        </div>
        <div  style={{color:'black',fontSize:15}}>5. Data Retention:</div>
        <div  style={{color:'black',fontSize:15}}>
          We retain user and student information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy. Users can
          request the deletion of their account and associated data.
        </div>
        <div  style={{color:'black',fontSize:15}}>6. Updates to Privacy Policy:</div>
        <div  style={{color:'black',fontSize:15}}>
          We may update this Privacy Policy from time to time. We will notify
          users of any material changes. It is advisable to review this policy
          periodically.
        </div>
        <div  style={{color:'black',fontSize:15}}>7. Contact Us:</div>
        <div  style={{color:'black',fontSize:15}}>
          If you have any questions, concerns, or requests regarding your
          privacy or this Privacy Policy, please contact us at
          info@mayorainfotech.com.
        </div>
        <div  style={{color:'black',fontSize:15}}>
          By using SchoolDiary, you acknowledge that you have read and
          understood this Privacy Policy.
        </div>
      </div>
    );
  }
}
