import { toast } from 'react-toastify';
import * as api from "../../api/Drafts";
import { getErrorMessage } from "../../utils/utils";
export const GET_DRAFTS = "GET_DRAFTS";
export const GET_DRAFTS_ERROR = "GET_DRAFTS_ERROR";
export const SET_LOADING_DRAFTS = "SET_LOADING_DRAFTS";
export const ADD_DRAFTS = 'ADD_DRAFTS';
export const ADD_DRAFTS_ERROR = 'ADD_DRAFTS_ERROR';

export const getDraftsSuccess = (drafts) => {
  return {
    type: GET_DRAFTS,
    drafts,
  };
};

export const getDraftsError = (message) => {
  return {
    type: GET_DRAFTS_ERROR,
    message,
  };
};

export const addDraftsSuccess = (student) => {
  return {
    type: ADD_DRAFTS,
    student
  }
}

export const addDraftsError = (message) => {
  return {
    type: ADD_DRAFTS_ERROR,
    message,
  };
};

export const getDrafts = (pg, classId, searchName, status) => {
  
  return (dispatch) => {
    dispatch({ type: SET_LOADING_DRAFTS });
    api
      .getDrafts({pg, classId, searchName, status})
      .then((response) => {
        dispatch(getDraftsSuccess(response.data));
      })
      .catch((err) => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getDraftsError(getErrorMessage(err)));
      });
  };
};

export const addDrafts = (students) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_DRAFTS });
    api.addDrafts(students)
      .then(response => {
        console.log("dsafsfasf")
        dispatch(addDraftsSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        console.log("error of data",err.response.data)
        dispatch(addDraftsError(getErrorMessage(err)))
      })
  }
}
