import {
  loginUser,
} from '../../../api/auth'
import Axios from 'axios';
import {
  getErrorMessage
} from '../../../utils/utils';
import { RESET_STORE } from '../actions';
import { toast } from 'react-toastify';
export const LOGIN = 'LOGIN';
export const SET_LOADING_LOGIN = 'SET_LOADING_LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const loginSuccess = (user) => {
  return {
    type: LOGIN,
    user
  }
}


export const login = (data) => {
  return dispatch => {
    dispatch({
      type: SET_LOADING_LOGIN
    });
    loginUser(data)
      .then(response => {
      localStorage.setItem("auth", JSON.stringify(response.data.data[0]));
        const token = response.data.data[0].secretToken;
        Axios.defaults.headers = {
          'auth-token': token
        };
        dispatch(loginSuccess(response.data.data[0]))
      })
      .catch(err => {
        console.log("Error of response of data",err.response.data.message)
        // toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
				// 	position: toast.POSITION.TOP_RIGHT,
				// });
        dispatch(loginError(getErrorMessage(err)));
      })
  }
}

export const loginError = (message) => {
  return {
    type: LOGIN_ERROR,
    message
  }
}

export const logoutSuccess = () => {
  return {
    type: LOGOUT,
  }
}

export const resetStore = () => {
  return {
    type: RESET_STORE
  }
}

export const logout = () => {
  return dispatch => {
    dispatch(resetStore())
    dispatch(logoutSuccess())
  }
}