import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { FormGroup, Label } from 'reactstrap';
import { FormFeedback, Input } from 'reactstrap';
import { ClipLoader } from "react-spinners";
import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { forgotPasswords } from '../../Store/action/Auth/forgotPassword';
import { getSchool } from '../../Store/action/School';
import Select from 'react-select';
import Logo from '../../assets/images/logo/ic_launcher.png';
import { resetPasswords } from '../../Store/action/Auth/resetPassword';


 const ResetPassWordValidationScheme = Yup.object({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string().required('Required'),
  setNewPassWord: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match with new password')
    .required('Required'),
});

class ResetPassword extends Component {
    state = {
        selectedSchool: '',
        selectedCity: ''
    }

    componentDidMount() {
        this.props.getSchools();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.loading && !nextProps.loading && !nextProps.error) {
            this.props.history.push('/signin');
        } else if (nextProps.error && !this.props.error) {
            this.setErrors({ email: nextProps.error });
        }
    }
    schoolPickerChange(selectedSchool) {
        this.setState({ selectedSchool });
    }

    cityPickerChange(selectedCity) {
        this.setState({ selectedCity });
    }

    resetPasswordsHandler = (values) => {
      let passwordData={
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      }
        this.props.onResetPassword(passwordData);
    }

    render() {
        const { selectedSchool, selectedCity } = this.state;
        const { schools, loading } = this.props;
        const cities = [...new Set(schools.data.map(s => s.city))];
        const cityOptions = cities.map(value => ({ value, label: value }));
        const schoolOption = selectedCity ? schools.data.filter(f => f.city === selectedCity.value).map(p => ({ label: p.schoolName, value: p.id })) : [];
        return (
            <div id="wrapper">
                <div class="vertical-align-wrap">
                    <div class="vertical-align-middle auth-main">
                        <div class="auth-box">
                            <div class="top">
                                <div class="top row pl-2">
                                    {/* <img src="http://www.wrraptheme.com/templates/lucid/hr/html/assets/images/logo-white.svg" alt="Lucid" /> */}
                                    <img className="" src={Logo} alt="School Diary" height="50px" />
                                    <h3 className="align-self-center text-white pl-2 pt-1">School Diary</h3>
                                </div>
                            </div>
                            <div class="card">
                                <div class="header">
                                    <p class="lead">Reset Password</p>
                                </div>
                                <Formik
                                    enableReinitialize
                                    ref={this.form}
                                    initialValues={{oldPassword:'',newPassword:'',setNewPassWord:'' }}
                                    validationSchema={ResetPassWordValidationScheme}
                                    onSubmit={(values, { setSubmitting, setErrors }) => {
                                        this.setErrors = setErrors;
                                        this.resetPasswordsHandler(values);
                                    }}>
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        handleBlur,
                                        isSubmitting, }) => (
                                            <div class="body">
                                                <form class="form-auth-small" action="http://www.wrraptheme.com/templates/lucid/hr/html/light/index.html" />
                                                <div class="form-group">               
                                                    <label for="signin-email" class="control-label sr-only">Old Password</label>
                                                    <Input type="text"
                                                       invalid={errors.oldPassword && touched.oldPassword}
                                                        name="oldPassword" onChange={handleChange} onBlur={handleBlur} value={values.oldPassword} placeholder="Old Password" />
                                                    <FormFeedback>
                                                        {errors.oldPassword && touched.oldPassword && errors.oldPassword}
                                                    </FormFeedback>
                                                </div>
                                                <div class="form-group">               
                                                    <label for="signin-email" class="control-label sr-only">New Password</label>
                                                    <Input type="text"
                                                        invalid={errors.newPassword && touched.newPassword}
                                                        name="newPassword" onChange={handleChange} onBlur={handleBlur} value={values.newPassword} placeholder="New Password" />
                                                    <FormFeedback>
                                                        {errors.newPassword && touched.newPassword && errors.newPassword}
                                                    </FormFeedback>
                                                </div>
                                                <div class="form-group">               
                                                    <label for="signin-email" class="control-label sr-only">Confirm Password</label>
                                                    <Input type="text"
                                                        invalid={errors.setNewPassWord && touched.setNewPassWord}
                                                        name="setNewPassWord" onChange={handleChange} onBlur={handleBlur} value={values.confirmPassword} placeholder="Confirm Password" />
                                                    <FormFeedback>
                                                        {errors.setNewPassWord && touched.setNewPassWord && errors.setNewPassWord}
                                                    </FormFeedback>
                                                </div>
                                                <button type="submit" onClick={handleSubmit} class="btn btn-primary btn-lg btn-block mt-4">
                                                  RESET PASSWORD
                                                    {/* {loading ?
                                                        <ClipLoader
                                                            // css={override}
                                                            size={15}
                                                            //size={"150px"} this also works
                                                            color={"#123abc"}
                                                            loading={true}
                                                        /> : 'RESET PASSWORD'
                                                    } */}
                                                </button>
                                                <div class="bottom">
                                                    {/* <span class="helper-text m-b-10"><i class="fa fa-lock"></i> <L>Forgot password?</L></span>
                                                            <span>Don't have an account?<a href="">Register</a></span> */}
                                                    {/* <span>Don't have an account? <Link to={{ pathname: '/signup' }}> <a href="">Register</a> </Link></span> */}
                                                </div>
                                            </div>
                                        )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.resetPassword.resetLoading,
        error: state.resetPassword.error,
        schools: state.school.schools
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResetPassword: (values) => dispatch(resetPasswords(values)),
        getSchools: () => dispatch(getSchool()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);