import React, { Component } from 'react'

export default class index extends Component {
  render() {
    return (
      <div>
        <h2>Dashboard</h2>
      </div>
    )
  }
}
