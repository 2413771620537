import * as actionTypes from '../action/Standard';

const initialState = {
  standard: {
    data: [],
    pagination: null
  },
  addError: '',
  getError: '',
  currentPage: 1,
  isLoading: false
}

const standardReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.STANDARD_STATUS_CHANGE:
      const index = state.standard.data.findIndex(t => t.id === action.standardId);
      const standard = state.standard.data[index];
      standard.status = action.status;
      return {
        ...state,
        standard: { ...state.standard, data: Object.assign(state.standard.data, { [index]: standard }) },
        isStatusChangeInProcess: false, isLoading: false,
        getError: ''
      };

    case actionTypes.GET_STANDARD:
      return {
        ...state,
        isLoading: false,
        standard: action.standard,
        currentPage: action.pg || 1,
        getError: ''
      }

    case actionTypes.GET_STANDARD_ERROR:
      return {
        ...state,
        isLoading: false,
        getError: action.message,
      }

    case actionTypes.ADD_STANDARD:
      const total = state.standard.pagination && state.standard.pagination.total + 1;
      let pages = state.standard.pagination && state.standard.pagination.pages;
      if (total / 10 > pages) {
        pages += 1;
      }
      return {
        ...state,
        isLoading: false,
        standard: {
          ...state.standard,
          pagination: { ...state.standard.pagination, total, pages },
          data: [...state.standard.data, action.standard.data]
        },
        addError: ''
      }

    case actionTypes.ADD_STANDARD_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }

    case actionTypes.UPDATE_STANDARD:
      const standardIndex = state.standard.data.findIndex(a => a.id === action.standard.data.id);
      return {
        ...state,
        isLoading: false,
        addError: '',
        standard: { ...state.standard, data: Object.assign(state.standard.data, { [standardIndex]: action.standard.data }) },
      }

    case actionTypes.UPDATE_STANDARD_ERROR:
      return {
        ...state,
        isLoading: false,
        addError: action.message
      }
    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: true,
        addError: '', getError: ''
      }
    case actionTypes.SET_STANDARD_STATUS_IN_PROCESS:
      return {
        ...state,
        isStatusChangeInProcess: true,
        addError: '', getError: ''
      };
    case actionTypes.STANDARD_STATUS_CHANGE_ERROR:
      return {
        ...state,
        isStatusChangeInProcess: false,
        getError: action.message
      };
    default:
      return state
  }

}

export default standardReducer
