import React, { Component } from 'react';
import { Button, Table, Modal, ModalBody, ModalFooter } from 'reactstrap';

class StudentsStatus extends Component {
    state = {
        participatedStudents: ''
    }
    
    componentWillReceiveProps(nextProps) {
        const { participatedStudents } = nextProps;
        this.setState({ participatedStudents })
    }

    render() {
        const { participatedStudents } = this.state;
        return (
            <>
                <Modal isOpen={this.props.isOpen}>
                    <div className="modal-header">
                        <h5 className="modal-title h2">View Students Status</h5>
                        <Button className="close" onClick=""></Button>
                    </div>
                    <ModalBody>
                        <Table className="table-responsive-sm table-responsive-md " hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Class Name</th>
                                    <th>Standard</th>
                                    <th>Roll No.</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {participatedStudents && participatedStudents.map((e, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{e.parent.students.map(s => s.firstName)} {e.parent.students.map(s => s.lastName)}</td>
                                            <td>{'8-(A)'}</td>
                                            <td>{'8th'}</td>
                                            <td>{e.parent.students.map(s => s.rollNumber)}</td>
                                            <td>{e.status === 'Interested' ?
                                                <span class="badge badge-success ">Interested</span>
                                                : e.status === 'Ignore' ?
                                                    <span class="badge badge-danger ">Ignore</span>
                                                    : <span class="badge badge-warning ">Going</span>
                                            }</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        {/* <Button color="primary" style={{ color: "white" }} className="mr-2" onClick={{}}>
                            {this.props.isLoading ? <ClipLoader
                                sizeUnit={"px"}
                                size={20}
                                color={'#fff'}
                                loading={true}
                            /> : 'Save'}
                        </Button> */}
                        <Button className="btn-outline-primary" style={{ background: "none", color: "#4FC1E9" }}
                            onClick={this.props.isClose}>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default StudentsStatus;