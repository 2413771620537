import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import Crop from '../../Crop/Crop';
import Profile from '../../../assets/images/profile-avatar.png';
import { ClipLoader } from 'react-spinners';
import * as action from '../../../Store/action/School';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const schoolSchema = Yup.object().shape({
  schoolName: Yup.string()
    // .matches(/^[A-Za-z]+$/, 'Enter Only Alphabetic')
    .required('School Name is required'),
  city: Yup.string()
    .matches(/^[A-Za-z]+$/, 'Enter Only Alphabetic')
    .required('City is required'),
  address: Yup.string().required('Address is required'),
});

class AddSchool extends Component {
  input = React.createRef();
  state = {
    schoolName: '',
    city: '',
    address: '',
    id: '',
    logo: '',
    src: null,
  };

  componentDidMount() {
    const { schools } = this.props.location.state || {};
    if (schools) {
      this.setState({ ...schools, id: schools.id });
    }
  }

  onSelectFile = (key) => (e) => {
    this.setState({ image: e.target.files[0] });
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ [key]: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push('/admin/school');
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  }

  submitHandler = (values) => {
    if (this.state.id) {
      this.props.onUpdateSchool(values);
    } else {
      this.props.onAddSchool(values);
    }
  };

  render() {
    const { src, croppedImageUrl } = this.state;
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            schoolName: this.state.schoolName,
            schoolId: this.state.id,
            city: this.state.city,
            address: this.state.address,
            logo: this.state.logo,
          }}
          validationSchema={schoolSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <Crop
                src={src}
                name={this.state.image && this.state.image.name}
                onCrop={(imageUrl, blob) => {
                  this.setState({ croppedImageUrl: imageUrl, src: null });
                  setFieldValue('logo', blob);
                }}
                onCancel={() => this.setState({ src: null })}
              />
              <CardHeader className="h-style title-style">
                {this.state.schoolName === '' ? 'Add School' : 'Update School'}
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <label className="form-control-label">Logo</label>
                      <FormGroup>
                        <input
                          accept="image/jpg, image/jpeg, image/png"
                          hidden
                          ref={this.input}
                          type="file"
                          onChange={this.onSelectFile('src')}
                        />
                        <FormFeedback>
                          {errors.logo && touched.logo && errors.logo}
                        </FormFeedback>
                        {!croppedImageUrl && !this.state.logo && (
                          <img
                            alt="profile"
                            style={{ height: 100 }}
                            src={Profile}
                            onClick={(e) =>
                              this.input.current && this.input.current.click()
                            }
                          />
                        )}
                      </FormGroup>
                      <div>
                        {(croppedImageUrl || this.state.logo) && (
                          <img
                            alt="Crop"
                            style={{ maxWidth: '100%' }}
                            onClick={(e) =>
                              this.input.current && this.input.current.click()
                            }
                            src={croppedImageUrl || this.state.logo}
                          />
                        )}
                      </div>
                    </FormGroup>
                  </div>
                </div>

                <Row>
                  <Col xs="5">
                    <FormGroup>
                      <Label>School Name<span className="required">*</span></Label>
                      <Input
                        type="schoolName"
                        invalid={errors.schoolName && touched.schoolName}
                        name="schoolName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schoolName}
                        placeholder="Enter School Name"
                      />
                      <FormFeedback>
                        {errors.schoolName &&
                          touched.schoolName &&
                          errors.schoolName}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label>City<span className="required">*</span></Label>
                      <Input
                        type="city"
                        invalid={errors.city && touched.city}
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        placeholder="Enter City"
                      />
                      <FormFeedback>
                        {errors.city && touched.city && errors.city}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="5">
                    <FormGroup>
                      <Label>Address<span className="required">*</span></Label>
                      <Input
                        type="textarea"
                        invalid={errors.address && touched.address}
                        name="address"
                        rows={3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        placeholder="Enter Address"
                      />
                      <FormFeedback>
                        {errors.address && touched.address && errors.address}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <button
                      disabled={this.props.loading}
                      type="button"
                      className="btn btn-primary mr-2"
                      onClick={handleSubmit}
                    >
                      {this.props.loading ? (
                        <ClipLoader
                          size={15}
                          color={'#123abc'}
                          loading={true}
                        />
                      ) : (
                          'Save'
                        )}
                    </button>
                    <Link
                      className="btn btn-secondary"
                      to={{ pathname: '/admin/school' }}
                    >
                      Cancel
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.school.isLoading,
    error: state.school.addError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddSchool: (teachers) => dispatch(action.addSchool(teachers)),
    onUpdateSchool: (teachers) => dispatch(action.updateSchool(teachers)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSchool);
