import React, { Component } from 'react';
import {
  Card, Table, CardBody, CardHeader, Alert, Pagination, PaginationItem, PaginationLink,Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import * as action from '../../../Store/action/Result';
import ConfirmationDialog from '../../ConfirmDialog/DeleteConfirmDialog';
import { Link } from 'react-router-dom';
import { getExamTimeTable } from '../../../Store/action/ExamTimeTable';
import Modal from './ResultDetail';
import moment from 'moment';
import { URLS } from '../../../api/URLS';
import { withRouter } from 'react-router-dom';

class Result extends Component {
  state = {
    pg: this.props.currentPage,
    selectedExam: '',
    isOpen: false,
    studentDetails: '',
    export:true,
  }

  componentDidMount() {
     this.props.onGetResult(1,'');
     this.props.onGetExamTimeTable(1, 'Active');
  }

  confirmationDialogBox = (deleteResult) => {
    this.setState({
      deleteResult,
    });
  };

  deleteHandler = (ec) => {
    this.setState({ deleteResult: null });
    this.props.onDeleteResult({ subjectResultId: ec.id,studentId:ec.student.id });
    this.props.onGetResult(1, this.state.selectedExam);
  };

  paginationChangeHandler = (pg, value) => () => {
    this.setState({ pg: value });
  };

  renderPagination(pagination) {
    console.log("Pagination of data",pagination)
    const pages = [];
    for (let i = 1; i <= pagination; i++) {
      pages.push(
        <PaginationItem
          active={this.state.pg === i}
          key={i}
          onClick={this.paginationChangeHandler('pg', i)}
        >
          <PaginationLink tag="button">{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  }

  examChangeHandle = (event) => {
    this.setState({ pg: 1, selectedExam: event.target.value });
    this.props.onGetResult(1, event.target.value);
  };

  ModalToggler = (student) => {
    this.setState({ isOpen: !this.state.isOpen, studentDetails: student });
  };

  handleExport = () => {
      this.props.onGetResult(1, this.state.selectedExam,this.state.export);
      
  };

  render() {
    const { result, loading, error, examTimeTable } = this.props;
    const { pg, studentDetails, isOpen } = this.state;
    const examTimeTables = examTimeTable.data.map((a) => ({
      label: a.subject.subjectName,
      value: a.id,
      examDate: moment(a.examDate).format('YYYY-MM-DD')
    }));
  console.log("Result of data",result.data)
    return (
      <div className="row clearfix">
        <div className="col-lg-12">
          <Modal
            isOpen={isOpen}
            student={studentDetails}
            isClose={this.ModalToggler}
          />
          <ConfirmationDialog
            isOpen={!!this.state.deleteResult}
            deleteHandler={() => this.deleteHandler(this.state.deleteResult)}
            closeHandler={() => this.confirmationDialogBox(null)}
          />
          <Card className="c-style">
            <CardHeader className="h-style">
              <div className="row m-r-0 m-l-0">
                <div className="c-title-style">
                  Result
                      </div>

                <div className="ml-auto btn-style">
                  {result.data.length>0&&result?.data[0]?.results?.length!==0&&
               <span>
                <Link
                       class="btn btn-outline-dark"
                      onClick={() => this.handleExport()}
                      to={{ pathname: '/admin/result' }}
                    >
                       {this.props.exportLoading&&<Spinner size="sm" style={{marginRight:5}}></Spinner>}
                        Export
                         </Link><span style={{marginLeft:10}}/>
                         </span>
                         }
                  <Link class="btn btn-outline-dark" to={{ pathname: '/admin/result/add' }}>Add</Link>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="row mb-2">
                <div className="col-3">
                  <select
                    class="form-control show-tick ms select2"
                    data-placeholder="Select"
                    onChange={this.examChangeHandle}
                    value={this.state.selectedExam}
                  >
                    <option value="">Select Exam</option>
                    {examTimeTables.map((c) => {
                      return <option value={c.value}>{`${c.label} - ${c.examDate}`}</option>;
                    })}
                  </select>
                </div>
              </div>
              <Table className="table-responsive-sm table-responsive-md " hover>
                <thead className="table-header-wrapper">
                  <tr>
                    <th>#</th>
                    <th>Roll No</th>
                    <th>Student Name</th>
                    <th>Subject Name</th>
                    <th>Exam</th>
                    <th>Total Marks</th>
                    <th>Obtain Marks</th>
                    <th>Exam Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !loading &&
                      !error&& result?.data && result.data[0]?.results?.length !== 0&&
                      result?.data[0]?.results.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <strong>{(pg - 1) * 10 + index + 1}</strong>
                            </td>
                            <td>{item.student.rollNumber}</td>
                            <td>{item.student.firstName}</td>
                            <td>
                              {result?.data.map(
                                (item, index) => item?.subject?.subjectName
                              )}
                            </td>
                            <td>
                              {result?.data.map(
                                (item, index) => item.examType.name
                              )}
                            </td>
                            <td>
                              {result?.data.map(
                                (item, index) => item.totalmarks
                              )}
                            </td>
                            <td>
                              {item?.subjectresults?.map(
                                (result, index) => result.marks
                              )}
                            </td>
                            <td>
                              {result?.data.map((item, index) =>
                                moment(item.examDate).format('YYYY-MM-DD')
                              )}
                            </td>
                            <td>
                              <div className="row d-flex">
                                <Link
                                  className="p-2"
                                  to={{
                                    pathname: '/admin/result/add',
                                    state: {
                                      result: item,
                                      examTimeTableId: item.id,
                                    },
                                  }}
                                >
                                  <i
                                    class="icon-pencil"
                                    style={{ color: 'black' }}
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <span
                                  className="p-2"
                                  onClick={() =>
                                    this.confirmationDialogBox(item)
                                  }
                                >
                                  <i class="icon-trash" aria-hidden="true"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>

              {loading ? (
                <div className="loader-wrapper">
                  <ClipLoader size={35} color={'#123abc'} loading={true} />
                </div>
              ) : (
                <div className="d-flex justify-content-end p-2">
                  {result?.data&& result.pagination && !error && (
                    <Pagination className="pull-right">
                      <PaginationItem>
                        <PaginationLink
                          onClick={() =>
                            this.state.pg > 1 &&
                            this.paginationChangeHandler(
                              'pg',
                              this.state.pg - 1
                            )()
                          }
                          previous
                          tag="button"
                        />
                      </PaginationItem>
                      {this.renderPagination(result?.data &&result?.pagination&&result?.pagination.pages)}
                      {result?.data && result?.pagination &&  (
  <PaginationItem>
    <PaginationLink
      onClick={() =>
        this.state.pg < result.pagination.pages &&
        this.paginationChangeHandler('pg', this.state.pg + 1)()
      }
      next
      tag="button"
    />
  </PaginationItem>
)}
                    </Pagination>
                  )}
                </div>
              )}
            {!result?.data || (result?.data[0]?.results && result?.data[0]?.results.length === 0) && (
    <div className="error-style">
        <Alert color="danger">Result not found</Alert>
    </div>
)}
              {!loading && (error || !result.data.length) && (
                <div className="error-style">
                  <Alert color="danger">{error ? error : result.message}</Alert>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    result: state.result.results,
    currentPage: state.result.currentPage,
    error: state.result.getError,
    loading: state.result.isLoading,
    examTimeTable: state.examTimeTable.examTimeTable,
    exportLoading:state.result.isExportResultLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetResult: (pg, examTimeTableId,exportData) => dispatch(action.getResult(pg, examTimeTableId,exportData)),
    onDeleteResult: (deleteResult) => dispatch(action.deleteResults(deleteResult)),
    onGetExamTimeTable: (pg) => dispatch(getExamTimeTable(pg)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Result);
