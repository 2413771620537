import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { NavLink as NavLinkRRD } from "react-router-dom";
import '../../assets/css/main.css'
import '../../assets/css/color_skins.css'
import './Sidebar.css';
import { connect } from 'react-redux';

class Sidebar extends Component {
  
  state = {
    activeTab:  this.props.user.userRole===1?'1':this.props.user.userRole===3?'1':'2',
    isOpen: false
  }

  toggle = activeTab => {
    this.setState(s => ({ activeTab }))
  }

  toggles = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  closeCollapse = () => document.body.classList.toggle('offcanvas-active');

  createLinks = routes => {
    return routes.filter(p => !p.hidden && (!p.userRole.length || p.userRole.includes(this.props.user.userRole))).map((prop, key) => {
      return (
        <NavItem key={key} >
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active-link"
            activeStyle={{ background: "#f1f1f1", borderLeft: "5px solid #f68c1f" }} >
            <i className={prop.icon} />
            <span className="pl-2 sidebar-font-change ">
              {prop.name}
            </span>
          </NavLink>
        </NavItem>
      );
    });
  };

  render() {
    const { routes, user } = this.props;
    const { activeTab } = this.state;
    const tab1 = routes.filter(a => a.userRole && a.userRole.includes(this.props.user.userRole)).filter(a => a.tab !== 2 && a.tab !== 3);
    const tab2 = routes.filter(a => a.userRole && a.userRole.includes(this.props.user.userRole)).filter(a => a.tab === 2);
    const tab3 = routes.filter(a => a.userRole && a.userRole.includes(this.props.user.userRole)).filter(a => a.tab === 3);
    return (
      <div id="wrapper">
        <div id="left-sidebar" className="sidebar">
          <div className="sidebar-scroll">
            {user.userRole===3?
          <Nav tabs style={{ cursor: "pointer", marginTop: "32px" }}>
                <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}>
                  {user.userRole === 3
                    ?
                    <span>Teacher</span>
                    :
                    <span>Principal</span>
                  }
                </NavLink>
              </NavItem>
            {user.userRole !== 1 &&
                <>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}>
                      Exam
                    </NavLink>
                  </NavItem>
                 
                </>}
             
              {user.userRole !== 1 &&
              <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { this.toggle('3'); }}>
                      Other
                    </NavLink>
                  </NavItem>}
            </Nav>:
            <Nav tabs style={{ cursor: "pointer", marginTop: "32px" }}>
            {user.userRole !== 1 &&
                <>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}>
                      Exam
                    </NavLink>
                  </NavItem>
                 
                </>}
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}>
                  {user.userRole === 3
                    ?
                    <span>Teacher</span>
                    :
                    <span>Principal</span>
                  }
                </NavLink>
              </NavItem>
              {user.userRole !== 1 &&
              <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { this.toggle('3'); }}>
                      Other
                    </NavLink>
                  </NavItem>}
            </Nav>}

            <TabContent activeTab={activeTab} className="p-l-0 p-r-0">
              <TabPane tabId="1">
                <nav id="sidebar" className="sidebar-nav">
                  <Nav className="metismenu  ">{this.createLinks(tab1)}</Nav>
                </nav>
              </TabPane>
              <TabPane tabId="2">
                <nav id="sidebar" className="sidebar-nav">
                  <Nav className="metismenu  ">{this.createLinks(tab2)}</Nav>
                </nav>
              </TabPane>
              <TabPane tabId="3">
                <nav id="sidebar" className="sidebar-nav">
                  <Nav className="metismenu  ">{this.createLinks(tab3)}</Nav>
                </nav>
              </TabPane>
            </TabContent>
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    profileName: state.login.user.firstName + ' ' + state.login.user.lastName,
    user: state.login.user
  }
}
export default connect(mapStateToProps)(Sidebar)
