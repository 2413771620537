import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
  Button,
  Row,
  Col,
} from "reactstrap";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import * as action from "../../../Store/action/Event";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import TimeKeeper from "react-timekeeper";
import moment from "moment";
import Crop from "../../Crop/Crop";
import { getEvent } from '../../../Store/action/Event';



const SignupSchema = Yup.object().shape({
  title: Yup.string().required("Event Name is required"),
  description: Yup.string().required("Description is required"),
  eventDate: Yup.string().required("Date is required"),
  eventTime: Yup.string().required("Time is required"),
  location: Yup.string().required("Place is required"),
});

class AddEvent extends Component {
  input = React.createRef();
  state = {
    title: "",
    description: "",
    eventDate: new Date(),
    eventTime: "10:00",
    search: "",
    location: "",
    eventId: "",
    eventPoster: "",
    src: null,
  };

  componentDidMount() {
    const { eventName } = this.props.location.state || {};
    if (eventName) {
      this.setState({
        ...eventName,
        eventId: eventName.id,
        eventTime: moment(eventName.eventTime, ["HH:mm"]).format("hh:mm"),
        eventDate: new Date(eventName.eventDate),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push("/admin/event");
      this.props.onGetEvent(1);
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  }

  submitHandler = (values) => {
    const data = {
      ...values,
      eventDate: moment(values.eventDate).format('YYYY-MM-DD'),
    };
    if (this.state.eventId) {
      this.props.onUpdateEvent(data);
    } else {
      this.props.onAddEvent(data);
    }
  };

  onSelectFile = (key) => (e) => {
    this.setState({ image: e.target.files[0] });
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ [key]: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  render() {
    const {
      showTimePicker,
      title,
      description,
      eventDate,
      eventTime,
      location,
      eventId,
      src,
      croppedImageUrl,
      eventPoster,
    } = this.state;
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            title: title,
            description: description,
            eventId: eventId,
            eventDate: eventDate,
            eventTime: eventTime,
            location: location,
            eventPoster: eventPoster?.name,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <Crop
                src={src}
                name={this.state.image && this.state.image.name}
                onCrop={(imageUrl, blob) => {
                  this.setState({ croppedImageUrl: imageUrl, src: null });
                  setFieldValue("eventPoster", blob);
                }}
                onCancel={() => this.setState({ src: null })}
              />
              <CardHeader className="h-style title-style">Add Event</CardHeader>
              <CardBody>
                <Row>
                  <Col xs="4">
                    <FormGroup>
                      <Label>Event Name<span className="required">*</span></Label>
                      <Input
                        type="text"
                        invalid={errors.title && touched.title}
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        placeholder="Title"
                      />
                      <FormFeedback>
                        {errors.title && touched.title && errors.title}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs="2">
                    <FormGroup>
                      <Label>Event Date</Label>
                      <div>
                        <DatePicker
                          isClearable
                          minDate={new Date()}
                          className="form-control"
                          wrapperClassName="w-100"
                          selected={values.eventDate}
                          onChange={(eventDate) =>
                            setFieldValue("eventDate", eventDate)
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="2">
                    <FormGroup>
                      <Label>Event Time</Label>
                      <Input
                        type="text"
                        onClick={() => this.setState({ showTimePicker: true })}
                        invalid={errors.eventTime && touched.eventTime}
                        name="eventTime"
                        onBlur={handleBlur}
                        value={moment(values.eventTime, ["HH:mm"]).format("hh:mm a")}
                        placeholder="Select time"
                      />
                      {showTimePicker && (
                        <div
                          className="position-absolute"
                          style={{ zIndex: 9 }}
                        >
                          <TimeKeeper
                            time={values.eventTime}
                            onChange={({ formatted24 }) =>
                              setFieldValue('eventTime', formatted24)
                            }
                            onDoneClick={() =>
                              this.setState({ showTimePicker: false })
                            }
                            switchToMinuteOnHourSelect
                          />
                        </div>
                      )}
                      <FormFeedback>
                        {errors.eventTime &&
                          touched.eventTime &&
                          errors.eventTime}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label>Event Place<span className="required">*</span></Label>
                      <Input
                        type="text"
                        invalid={errors.location && touched.location}
                        name="location"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.location}
                        placeholder="Enter Location"
                      />
                      <FormFeedback>
                        {errors.location && touched.location && errors.location}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs="6">
                    <FormGroup>
                      <Label>Event Description<span className="required">*</span></Label>
                      <Input
                        type="text"
                        invalid={errors.description && touched.description}
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        placeholder="Description"
                      />
                      <FormFeedback>
                        {errors.description &&
                          touched.description &&
                          errors.description}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs="2">
                    <FormGroup>
                      <Label className="form-control-label">Event Poster</Label>
                      <FormGroup>
                        <input
                          accept="image/jpg, image/jpeg, image/png"
                          hidden
                          ref={this.input}
                          type="file"
                          onChange={this.onSelectFile("src")}
                        />
                        <FormFeedback>
                          {errors.eventPoster &&
                            touched.eventPoster &&
                            errors.eventPoster}
                        </FormFeedback>
                        {!croppedImageUrl && !this.state.eventPoster && (
                          <Button
                            className="form-control col-lg-12"
                            onClick={(e) =>
                              this.input.current && this.input.current.click()
                            }
                          >
                            Browse
                          </Button>
                        )}
                      </FormGroup>
                      <div>
                        {(croppedImageUrl || this.state.eventPoster) && (
                          <img
                            alt="Crop"
                            style={{ maxWidth: "100%" }}
                            onClick={(e) =>
                              this.input.current && this.input.current.click()
                            }
                            src={croppedImageUrl || this.state.eventPoster}
                          />
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="d-flex justify-content-start">
                  <button
                    disabled={this.props.loading}
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                  >
                    {this.props.loading ? (
                      <ClipLoader size={15} color={"#123abc"} loading={true} />
                    ) : (
                      "Save"
                    )}
                  </button>
                  <Link
                    className="btn btn-secondary"
                    to={{ pathname: "/admin/event" }}
                  >
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.event.isLoading,
    error: state.event.addError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddEvent: (EventName) => dispatch(action.addEvent(EventName)),
    onUpdateEvent: (EventName) => dispatch(action.updateEvent(EventName)),
    onGetEvent: (pg, action) => dispatch(getEvent(pg, action)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
