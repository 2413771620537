import * as actionTypes from '../action/Profile'

const initialState = {
    user: null,
    isError: '',
    getError: '',
    isLoading: false
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.UPDATE_PROFILE:
            // const userIndex = state.user.data.findIndex(a => a.id === action.values.id);
            // const user = state.user.data[userIndex];
            // user.firstName = action.values.firstName;
            // user.lastName = action.values.lastName;
            return {
                ...state,
                // user: Object.assign(state.user, { [userIndex]: user }),
                isLoading: false,
                isError: ''
            }

        case actionTypes.UPDATE_PROFILE_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: action.message
            }

        case actionTypes.GET_PROFILE:
            return {
                ...state,
                students: action.values,
                isLoading: false,
                isError: ''
            }


        case actionTypes.SET_LOADING_PROFILE:
            return {
                ...state,
                isLoading: true,
                isError: '',
            }
        default:
            return state;
    }
}

export default profileReducer
