import Axios from "axios";
import { URLS } from "./URLS";

export const geAttendances = data => Axios.post(URLS.GET_ATTENDANCE, data);
export const addAttendance = data => Axios.post(URLS.ADD_ATTENDANCE, data)
// export const updateAttendance = data => {
//   const form = new FormData();
//   data.forEach((d, i) => Object.keys(d).forEach(k => form.append(`student[${i}][${k}]`, d[k])))
//   return Axios.post(URLS.UPDATE_ATTENDANCE, form)
// }


export const updateAttendance = data => {
  const form = new FormData();
  data.forEach((d, i) => Object.keys(d).forEach(k => form.append(`student[${i}][${k}]`, d[k])));
  return Axios.post(URLS.UPDATE_ATTENDANCE, form)
  }