import React, { Component } from 'react'
import { Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { Field, FieldArray, Form, Formik, getIn } from 'formik'
import DatePicker from "react-datepicker";
import { ClipLoader } from "react-spinners";
import { getSubject } from '../../../Store/action/Subjects';
import { getClass } from '../../../Store/action/Classes';
import { getStandard } from '../../../Store/action/Standard';
import { addExamTimeTable, getExamType, updateExamTimeTable } from '../../../Store/action/ExamTimeTable';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as action from '../../../Store/action/ExamTimeTable';

const SignupSchema = Yup.object().shape({
	examTimeTable: Yup.array()
		.of(
			Yup.object().shape({
				examTypeId: Yup.string()
					.required('Exam Type is required'),
				subjectId: Yup.string()
					.required('Subject is required'),
				duration: Yup.string()
					.required('Duration is required'),
				day: Yup.string()
					.required('Day is required'),
				examTime: Yup.string()
					.required('Exam Time is required'),
				examDate: Yup.mixed()
					.test('Exam Date', 'Exam Date is required', value => {
						if (!value) return false
						else return true
					}),
			})
		)
});

class AddExamTimeTable extends Component {
	input = React.createRef();
	state = {
		values: [{
			examTypeId: '',
			subjectId: '',
			classId: '',
			duration: '',
			day: '',
			examTime: '',
			examDate: new Date(),
			examId: ''
		}],
		index: 0
	}

	componentDidMount() {
		this.props.onGetSubjects();
		this.props.onGetClass();
		this.props.onGetExamType(1)
		const { values } = this.props.location.state || {};
		if (values) {
			this.setState({ values: [{ ...values, examDate: new Date(values.examDate), examTimeTableId: values.id }] });
		}
	}

	addClick = (array) => {
		array.insert(1, {
			examTypeId: '',
			subjectId: '',
			classId: '',
			duration: '',
			day: '',
			examTime: '',
			examDate: '',
		})
	}

	removeOnClick = (array, index) => {
		array.remove(index)
	}

	submitHandler = (values) => {
		if (values.examTimeTable[0] && values.examTimeTable[0].id) {
			this.props.onUpdateExamTimetable(values.examTimeTable[0])
		} else {
			this.props.onAddExamTimetable(values.examTimeTable);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!nextProps.isAddLoading && this.props.isAddLoading && !nextProps.error) {
			this.props.onGetExamTimeTable(1);
			// connect.log(nextProps, this.props.isAddLoading);
			this.props.history.push('/admin/examTimeTable')
		} else if (nextProps.error && !this.props.error) {
			// toast.error(nextProps.error, {
			// 	position: toast.POSITION.TOP_RIGHT
			// });
		}
	}

	render() {
		const { subject, examType, classes } = this.props;
		const subjects = subject.data.map(a => ({ label: a.subjectName, value: a.id }));
		const classOptions = classes.data.map(a => ({ label: `${a.standard.standardName}-${a.className}, ${a.medium}`, value: a.id }));
		const examTypeOption = examType.data.map(a => ({ label: a.name, value: a.id }));
		const dayOptions = [
			{ label: 'Monday', value: 'Monday' },
			{ label: 'Tuesday', value: 'Tuesday' },
			{ label: 'Wednesday', value: 'Wednesday' },
			{ label: 'Thursday', value: 'Thursday' },
			{ label: 'Friday', value: 'Friday' },
			{ label: 'Saturday', value: 'Saturday' },
			{ label: 'Sunday', value: 'Sunday' },
		]
		const ErrorMessage = ({ name }) => (
			<Field
				name={name}
				render={({ form }) => {
					const error = getIn(form.errors, name);
					const touch = getIn(form.touched, name);
					return touch && error ? error : null;
				}}
			/>
		);
		return (
			<div>
				<Formik
					enableReinitialize
					initialValues={{ examTimeTable: this.state.values }}
					validationSchema={SignupSchema}
					onSubmit={(values, { setSubmitting, setErrors }) => {
						this.setErrors = setErrors;
						this.submitHandler(values);
					}}
				>{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					handleBlur,
					setFieldValue,
					setFieldTouched,
				}) => (
					<Form>
						<FieldArray name="examTimeTable" render={arrayHelpers => (
							<Card className="c-style">
								<CardHeader className="h-style title-style">Add Exam TimeTable</CardHeader>
								<CardBody>
									{values.examTimeTable.map((exam, index) => {
										return (
											<div className="row" key={index}>
												<div className="col-12 col-sm-6 col-md col-lg- col-xl">
													<FormGroup>
														<Label>Exam Type<span className="required">*</span></Label>
														<Select
															name={`examTimeTable[${index}].examTypeId`}
															onChange={value => setFieldValue(`examTimeTable[${index}].examTypeId`, value.value)}
															onBlur={handleBlur}
															className="is-invalid"
															value={examTypeOption ? examTypeOption.find(s => s.value === exam.examTypeId) : ''}
															options={examTypeOption}
														/>
														<FormFeedback>
															<ErrorMessage name={`examTimeTable[${index}].examTypeId`} />
														</FormFeedback>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-6 col-md col-lg- col-xl">
													<FormGroup>
														<Label>Class<span className="required">*</span></Label>
														<Select
															name={`examTimeTable[${index}].classId`}
															onChange={value => setFieldValue(`examTimeTable[${index}].classId`, value.value)}
															onBlur={handleBlur}
															className="is-invalid"
															value={classOptions ? classOptions.find(s => s.value === exam.classId) : ''}
															options={classOptions}
														/>
														<FormFeedback>
															<ErrorMessage name={`examTimeTable[${index}].subjectId`} />
														</FormFeedback>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-6 col-md col-lg- col-xl">
													<FormGroup>
														<Label>Subject<span className="required">*</span></Label>
														<Select
															name={`examTimeTable[${index}].subjectId`}
															onChange={value => setFieldValue(`examTimeTable[${index}].subjectId`, value.value)}
															onBlur={handleBlur}
															className="is-invalid"
															value={subjects ? subjects.find(s => s.value === exam.subjectId) : ''}
															options={subjects}
														/>
														<FormFeedback>
															<ErrorMessage name={`examTimeTable[${index}].subjectId`} />
														</FormFeedback>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-6 col-md col-lg- col-xl">
													<FormGroup>
														<Label>Duration<span className="required">*</span></Label>
														<Input type="text"
															invalid={getIn(touched, `examTimeTable[${index}].duration`) && getIn(errors, `examTimeTable[${index}].duration`)}
															name={`examTimeTable[${index}].duration`} onChange={handleChange} onBlur={handleBlur}
															value={exam.duration} placeholder="Duration" />
														<FormFeedback>
															<ErrorMessage name={`examTimeTable[${index}].duration`} />
														</FormFeedback>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-6 col-md col-lg- col-xl">
													<FormGroup>
														<Label>Day<span className="required">*</span></Label>
														<Select
															name={`examTimeTable[${index}].day`}
															onChange={value => setFieldValue(`examTimeTable[${index}].day`, value.value)}
															onBlur={handleBlur}
															className="is-invalid"
															value={dayOptions.find(s => s.value === exam.day)}
															options={dayOptions}
														/>
														<FormFeedback>
															<ErrorMessage name={`examTimeTable[${index}].day`} />
														</FormFeedback>
													</FormGroup>
												</div>
												<div className="col-12 col-sm-6 col-md col-lg- col-xl">
													<FormGroup>
														<Label>Exam Date</Label>
														<div>
															<DatePicker
																isClearable
																minDate={new Date()}
																timePicker={false}
																className="form-control"
																wrapperClassName="w-100"
																selected={(exam.examDate && new Date(exam.examDate)) || null}
																onChange={date => setFieldValue(`examTimeTable[${index}].examDate`, date)} />
														</div>
														<FormFeedback style={{ display: "block" }}>
															<ErrorMessage name={`examTimeTable[${index}].examDate`} />
														</FormFeedback>
													</FormGroup>
												</div>

												<div className="col-12 col-sm-6 col-md col-lg- col-xl">
													<FormGroup>
														<Label>Exam Time<span className="required">*</span></Label>
														<Input type="text"
															invalid={getIn(touched, `examTimeTable[${index}].examTime`) && getIn(errors, `examTimeTable[${index}].examTime`)}
															name={`examTimeTable[${index}].examTime`} onChange={handleChange} onBlur={handleBlur}
															value={exam.examTime} placeholder="Exam Time" />
														<FormFeedback>
															<ErrorMessage name={`examTimeTable[${index}].examTime`} />
														</FormFeedback>
													</FormGroup>
												</div>
												{
													values.examTimeTable.length > 1 && <div
														className="col-12 col-sm-6 col-md col-lg- col-xl d-flex justify-content-center align-items-center">
														<button type="button" disabled={!index} className="btn btn-outline-dark"
															onClick={() => this.removeOnClick(arrayHelpers, index)}>Remove
														</button>
													</div>
												}
											</div>
										)
									})}
									{values.length && !values[0].id && <div
										className="col-12 col-sm-6 col-md col-lg- col-xl d-flex justify-content-center align-items-center">
										<button type="button" className="btn btn-outline-dark"
											onClick={() => this.addClick(arrayHelpers)}>Add
										</button>
									</div>}
									<div className="d-flex justify-content-center pt-5">
										<button type="submit" className="btn btn-primary mr-2" onClick={handleSubmit} disabled={this.props.isAddLoading}>
											{this.props.isAddLoading?
												<ClipLoader
													size={15}
													color={"#123abc"}
													loading={true}
												/> : 'Save'
											}
										</button>
										<Link className="btn btn-secondary" to={{ pathname: '/admin/examTimeTable' }}>Cancel</Link>
									</div>
								</CardBody>
							</Card>
						)} />
					</Form>
				)}
				</Formik>
			</div>
		)
	}
}



const mapStateToProps = state => {
	return {
		subject: state.subject.subjects,
		classes: state.class.classes,
		standard: state.standard.standard,
		examType: state.examTimeTable.examTypes,
		isAddLoading: state.examTimeTable.isAddExamLoading,
		isLoading: state.examTimeTable.isLoading,
		// isUpdateLoading:state.examTimeTable.isUpdateLoading,
		error: state.examTimeTable.addError,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGetSubjects: () => dispatch(getSubject()),
		onGetClass: () => dispatch(getClass()),
		onGetStandard: (pg) => dispatch(getStandard(pg)),
		onGetExamType: (pg) => dispatch(getExamType(pg)),
		onAddExamTimetable: (values) => dispatch(addExamTimeTable(values)),
		onUpdateExamTimetable: (values) => dispatch(updateExamTimeTable(values)),
		onGetExamTimeTable: (pg) => dispatch(action.getExamTimeTable(pg)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExamTimeTable)