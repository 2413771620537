import React, { Component } from 'react';
import { Card, Table, CardBody, CardHeader, Alert, Pagination, PaginationItem, PaginationLink, Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import { ClipLoader } from "react-spinners";
import { connect } from 'react-redux';
import { getEvent } from '../../../Store/action/Event';
import ConfirmationDialog from '../../ConfirmDialog/ConfirmDialog';
import moment from 'moment';
import Modal from './StudentStatus';
import UploadFileModal from './UploadPictures';
import ImageModal from '../../Modal/ImageModal';
class Event extends Component {
  state = {
    pg: this.props.currentPage,
    upload: false,
    selectAction: '',
    participatedStudents: [],
    eventId: ''
  }

  componentDidMount() {
    if (!this.props.events.data.length) {
      this.props.onGetEvent(1);
    }
  }

  confirmationDialogBox = (deleteEvent) => {
    this.setState({
      deleteEvent,
    });
  }


  deleteHandler = (ec) => {
    // this.setState({isDelete: true});
    this.setState({ deleteEvent: null });
    // this.props.onDeleteEvent(ec);
    // this.setState({isDelete: false});
  }

  paginationChangeHandler = (pg, value) => () => {
    this.props.onGetEvent(value, this.state.selectAction);
    this.setState({ pg: value });
  }

  renderPagination(pagination) {
    const pages = [];
    for (let i = 1; i <= pagination; i++) {
      pages.push(
        <PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
          <PaginationLink tag="button">{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  }

  eventChangeHandler = (event) => {
    this.setState({ pg: 1, selectAction: event.target.value });
    this.props.onGetEvent(1, event.target.value);
  }

  modalToggler = (event) => {
    this.setState({ isOpen: !this.state.isOpen, participatedStudents: event });
  }

  uploadFileModalToggler = (eventId) => {
    this.setState({ upload: !this.state.upload, eventId });
  }

  closeModal = () => {
    this.setState({ upload: false });
  }

  render() {
    const { events, } = this.props;
    const { isOpen, participatedStudents, pg, upload, eventId } = this.state;
    return (
      <div className="row clearfix">
        <div className="col-lg-12">
          <UploadFileModal isOpen={upload} isClose={this.closeModal} event={eventId} />
          <Modal
            isOpen={isOpen}
            participatedStudents={participatedStudents.eventStatuses}
            isClose={this.modalToggler} />

          <ConfirmationDialog
            isOpen={!!this.state.deleteEvent}
            deleteHandler={() => this.deleteHandler(this.state.deleteEvent)}
            closeHandler={() => this.confirmationDialogBox(null)} />
          <Card className="c-style">
            <CardHeader className="h-style">
              <div className="row m-r-0 m-l-0">
                <div className="c-title-style">Event</div>
                <div className="ml-auto btn-style">
                  <Link class="btn btn-outline-dark" to={{ pathname: '/admin/event/add' }}>Add</Link>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="row">
                <div class="col-lg-3 col-md-6 d-flex mb-2">
                  <select
                    class="form-control show-tick ms select2"
                    data-placeholder="Select"
                    onChange={this.eventChangeHandler}
                    value={this.state.selectAction}>
                    <option value=''>Select Event</option>
                    <option value='past'>PAST</option>
                    <option value='upcoming'>UPCOMING</option>
                  </select>
                </div>
              </div>
              <Table className="table-responsive-sm table-responsive-md " hover>
                <thead className='table-header-wrapper'>
                  <tr>
                    <th>#</th>
                    <th>Event Date</th>
                    <th>Event Time</th>
                    <th>Event Name</th>
                    <th>Event Poster</th>
                    <th>Event Description</th>
                    <th>Place</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.props.loading && !this.props.error &&
                    events.data.map((p, index) => {
                      return (
                        <tr>
                          <td><strong>{(pg - 1) * 10 + index + 1}</strong></td>
                          <td>{moment(p.eventDate).format('DD/MM/YY')}</td>
                          <td>{moment(p.eventTime, ["HH:mm"]).format("hh:mm a")}</td>
                          <td>{p.title}</td>
                          <td><ImageModal alt="." className="image img-fluid" src={p?.eventPoster
} /></td>
                          <td className="text-wrap w-25">{p.description}</td>
                          <td>{p.location}</td>
                          <td><div className="ml-auto btn-style">
                            <Button onClick={() => this.modalToggler(p)}>View ({p.eventStatuses ? p.eventStatuses.length : 0})</Button>
                          </div></td>
                          <td>
                            <div className="row align-items-center">
                              <Link className="btn-style" to={{ pathname: "/admin/Event/add", state: { eventName: p } }}><i class="icon-pencil" style={{ color: 'black' }} aria-hidden="true"></i></Link>
                              <Button className="ml-3" outline onClick={() => this.uploadFileModalToggler(p.id)}><i class="fa fa-picture-o" style={{ color: 'black' }} aria-hidden="true"></i></Button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>

              {this.props.loading
                ?
                <div className="loader-wrapper">
                  <ClipLoader
                    size={35}
                    color={"#123abc"}
                    loading={true}
                  />
                </div>
                :
                <div className="d-flex justify-content-end p-2">
                  {events.data.length > 0 && events.pagination &&
                    <Pagination className="pull-right">
                      <PaginationItem>
                        <PaginationLink
                          onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()} previous tag="button" />
                      </PaginationItem>
                      {this.renderPagination(events.pagination.pages)}
                      <PaginationItem>
                        <PaginationLink
                          onClick={() => events.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()} next tag="button" />
                      </PaginationItem>
                    </Pagination>
                  }
                </div>
              }
              {!this.props.loading && (this.props.error || !events.data.length) &&
                <div className="error-style">
                  <Alert color="danger">{this.props.error ? this.props.error : events.message}</Alert>
                </div>}
            </CardBody>
          </Card>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => {
  return {
    events: state.event.events,
    currentPage: state.event.currentPage,
    error: state.event.getError,
    loading: state.event.isLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetEvent: (pg, action) => dispatch(getEvent(pg, action)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Event)
