import * as actionTypes from '../action/DailyActivityTimetable';
import { groupBy } from "../../utils/utils";

const initialState = {
	dailyActivityTimeTable: {
		data: [],
		pagination: null
	},
	timetable: [],
	addError: '',
	getError: '',
	currentPage: 1,
	isLoading: false,
	isExportActivityLoading:false
};

const dailyActivityTimeTableReducer = (state = initialState, action) => {
	switch (action.type) {

		case actionTypes.UPDATE_DAILY_ACTIVITY_TIME_TABLE:
			const timeTableIndex = state.dailyActivityTimeTable.data.findIndex(a => a.id === action.timeTable.data.id);
			return {
				...state,
				dailyActivityTimeTable: { ...state.dailyActivityTimeTable, data: Object.assign(state.dailyActivityTimeTable.data, { [timeTableIndex]: action.timeTable.data }) },
				isLoading: false,
				addError: '',
			}

			case actionTypes.SET_LOADING_EXORT_ACTITY_TIME_TABLE:
				return {
						...state,
						isExportActivityLoading:true,
						addError: '',
						getError: ''
				}



		case actionTypes.UPDATE_DAILY_ACTIVITY_TIME_TABLE_ERROR:
			return {
				...state,
				isLoading: false,
				addError: action.message
			}


		case actionTypes.DELETE_DAILY_ACTIVITY_TIME_TABLE:
			const delId = action.dailyTimeTableId;
			return {
				...state,
				isLoading: false,
				getError: '',
				timetable: rearrangeTimetable(state.dailyActivityTimeTable.data.filter(del => del.id !== delId)),
				dailyActivityTimeTable: {
					...state.dailyActivityTimeTable,
					data: state.dailyActivityTimeTable.data.filter(del => del.id !== delId)
				}
			}

		case actionTypes.DELETE_DAILY_ACTIVITY_TIME_TABLE_ERROR:
			return {
				...state,
				isLoading: false,
				getError: action.message
			}


		case actionTypes.ADD_DAILY_ACTIVITY_TIME_TABLE:
			const total = state.dailyActivityTimeTable.pagination && state.dailyActivityTimeTable.pagination.total + 1;
			let pages = state.dailyActivityTimeTable.pagination && state.dailyActivityTimeTable.pagination.pages;
			if (total / 10 > pages) {
				pages += 1;
			}
			return {
				...state,
				isLoading: false,
				addError: '',
				dailyActivityTimeTable: {
					...state.dailyActivityTimeTable,
					// pagination: { ...state.dailyActivityTimeTable.pagination, total, pages },
					data: [...state.dailyActivityTimeTable.data, action.timeTable.data]
				},
			};

		case actionTypes.ADD_DAILY_ACTIVITY_TIME_TABLE_ERROR:
			return {
				...state,
				isLoading: false,
				addError: action.message
			}

		case actionTypes.GET_DAILY_ACTIVITY_TIME_TABLE:
			return {
				...state,
				timetable: rearrangeTimetable(action.data.data),
				dailyActivityTimeTable: action.data,
				isLoading: false,
				isExportActivityLoading:false,
				getError: ''
			}

		case actionTypes.GET_DAILY_ACTIVITY_TIME_TABLE_ERROR:
			return {
				...state,
				isLoading: false,
				getError: action.message,
			}

		case actionTypes.SET_LOADING_DAILY_ACTIVITY_TIME_TABLE:
			return {
				...state,
				isLoading: true, addError :'', getError: ''
			}


		case actionTypes.SET_LOADING_DAILY_ACTIVITY_TIME_TABLE_DELETE:
			return {
				...state,
				isLoading: true, addError :'', getError: ''
			}
		default:
			return state;
	}
}

const rearrangeTimetable = (data) => {
	const timetable = groupBy(data, 'day');
	for (let t in timetable) {
		t = timetable[t].sort((a, b) => a.duration > b.duration ? -1 : 1);
	}
	const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	let available = true;
	const dayWise = [];
	while (available) {
		available = false;
		const row = [];
		for (let d of days) {
			const dArry = timetable[d];
			if (dArry && dArry.length) {
				row.push(dArry.pop());
				if (dArry.length) {
					available = true;
				}
			} else {
				row.push(false);
			}
		}
		dayWise.push(row);
	}
	return dayWise;
}

export default dailyActivityTimeTableReducer
