import React, { Component } from 'react'
import {
 FormGroup, Label, Input, Card, CardHeader, CardBody,
FormFeedback
} from 'reactstrap';
import { addHoliday, updateHolidays } from '../../../Store/action/holiday'
import { connect } from 'react-redux';
import { ClipLoader } from "react-spinners";
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as action from '../../../Store/action/holiday';


const SignupSchema = Yup.object().shape({
  holidayName: Yup.string()
    .required('Holiday name is required'),

});

class AddHoliday extends Component {
  input = React.createRef();
  state = {
    holidayName: '',
    holidayDate: new Date(),
    holidayId: ''
  }


  componentDidMount() {
    const { holiday } = this.props.location.state || {};
    if (holiday) {
      this.setState({ ...holiday, holidayName: holiday.holidayName, holidayDate: new Date(holiday.holidayDate), holidayId: holiday.id });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push('/admin/holiday')
      this.props.onGetHoliday(1);
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      // this.setErrors({ subjectName: nextProps.error });
    }
  }

  submitHandler = (values) => {
    if (this.state.holidayId) {
      this.props.onUpdateHoliday(values)
    }
    else {
      this.props.onAddHoliday(values);
    }
  }


  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{ holidayName: this.state.holidayName, holidayDate: this.state.holidayDate, holidayId: this.state.holidayId }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          status,
          isSubmitting, }) => (
            <Card className="c-style">
              <CardHeader className="h-style title-style">{this.state.holidayName === '' ? 'Add Holiday' : 'Update Holiday'}</CardHeader>
              <CardBody>

                <FormGroup>
                  <Label >Holiday<span className="required">*</span></Label>
                  <Input type="holidayName"
                    invalid={errors.holidayName && touched.holidayName}
                    name="holidayName" onChange={handleChange} onBlur={handleBlur} value={values.holidayName} placeholder="Holiday Name" />
                  <FormFeedback>
                    {errors.holidayName && touched.holidayName && errors.holidayName}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label >Date</Label>
                  <div>
                    <DatePicker
                   isClearable   className="form-control"
                      wrapperClassName="w-100"
                      selected={values.holidayDate}
                      onChange={date => setFieldValue('holidayDate', date)}
                    />
                  </div>
                </FormGroup>

                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary mr-2" onClick={handleSubmit} disabled={this.props.loading}>
                    {this.props.loading ?
                      <ClipLoader
                        size={15}
                        color={"#123abc"}
                        loading={true}
                      /> : 'Save'
                    }
                  </button>
                  <Link className="btn btn-secondary" to={{ pathname: '/admin/holiday' }}>Cancel</Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div >
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.holiday.addError,
    loading: state.holiday.isLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAddHoliday: (holiday) => dispatch(addHoliday(holiday)),
    onUpdateHoliday: (holiday) => dispatch(updateHolidays(holiday)),
    onGetHoliday: (pg) => dispatch(action.getHolidays(pg)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddHoliday)