import { toast } from 'react-toastify';
import * as api from '../../api/Subject';
import { getErrorMessage } from '../../utils/utils';
export const GET_SUBJECTS = 'GET_SUBJECTS'
export const GET_SUBJECTS_ERROR = ' GET_SUBJECTS_ERROR'
export const ADD_SUBJECT = 'ADD_SUBJECT'
export const ADD_SUBJECT_ERROR = 'ADD_SUBJECT_ERROR'
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT'
export const SET_LOADING_SUBJECT = 'SET_LOADING_SUBJECT'
export const DELETE_SUBJECT = 'DELETE_SUBJECT'
export const DELETE_SUBJECT_ERROR = 'DELETE_SUBJECT_ERROR'
export const UPDATE_SUBJECT_ERROR = 'UPDATE_SUBJECT_ERROR'
export const SUBJECT_STATUS_CHANGE = ' SUBJECT_STATUS_CHANGE'
export const SUBJECT_STATUS_CHANGE_ERROR = ' SUBJECT_STATUS_CHANGE_ERROR'
export const SET_SUBJECT_STATUS_IN_PROCESS = 'SET_SUBJECT_STATUS_IN_PROCESS';

export const getSubjectSuccess = (subject, pg) => {
  return {
    type: GET_SUBJECTS,
    subject, pg
  }
}

export const getSubjectError = (message) => {
  return {
    type: GET_SUBJECTS_ERROR,
    message
  }
}

export const getSubject = (pg, searchName, status) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_SUBJECT });
    api.getSubject({ pg, searchName, status })
      .then(response => {
        dispatch(getSubjectSuccess(response.data, pg))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(getSubjectError(getErrorMessage(err)))
      })
  }
}

export const addSubjectSuccess = (subject) => {
  return {
    type: ADD_SUBJECT,
    subject
  }
}

export const addSubjectError = (message) => {
  return {
    type: ADD_SUBJECT_ERROR,
    message
  }
}

export const addSubject = (subject) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_SUBJECT });
    api.addSubject(subject)
      .then(response => {
        dispatch(addSubjectSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(addSubjectError(getErrorMessage(err)))
      })
  }
}

export const updateSubjectError = (message) => {
  return {
    type: UPDATE_SUBJECT_ERROR,
    message
  }
}

export const updateSubjectSuccess = (subject) => {
  return {
    type: UPDATE_SUBJECT,
    subject
  }
}

export const updateSubject = (subject) => {
  return dispatch => {
    dispatch({ type: SET_LOADING_SUBJECT });
    api.updateSubject(subject)
      .then(response => {
        dispatch(updateSubjectSuccess(response.data))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(updateSubjectError(getErrorMessage(err)))
      })
  }
}

export const deleteSubjectSuccess = () => {
  return {
    type: DELETE_SUBJECT,
  }
}

export const deleteSubjectError = (message) => {
  return {
    type: DELETE_SUBJECT_ERROR,
    message
  }
}

export const deleteSubject = () => {
  return dispatch => {

  }
}

export const subjectStatusChangeSuccess = (subjectId, status) => {
  return {
    type: SUBJECT_STATUS_CHANGE,
    subjectId, status
  }
}

export const subjectStatusChangeError = (message) => {
  return {
    type: SUBJECT_STATUS_CHANGE_ERROR,
    message
  }
}

export const subjectStatusChange = (subjectId, status) => {
  return dispatch => {
    dispatch({ type: SET_SUBJECT_STATUS_IN_PROCESS });
    api.subjectStatusChange({ subjectId, status })
      .then(response => {
        dispatch(subjectStatusChangeSuccess(subjectId, status))
      })
      .catch(err => {
        toast.error(err.response ? err.response.data.message : 'Something Went Wrong. Please try again', {
					position: toast.POSITION.TOP_RIGHT,
				});
        dispatch(subjectStatusChangeError(getErrorMessage(err)))
      })
  }
}
