import * as authActions from '../../action/Auth/login'
import * as actionTypes from "../../action/userProfile";
import * as profileTypes from "../../action/Profile";

const initialState = {
	user: null,
	error: '',
	isLoading: false,
	forgotLoading: false
}

const loginReducer = (state = initialState, action) => {

	switch (action.type) {
		case authActions.LOGIN:
			return {
				isLoading: false,
				user: action.user,
			};
		case authActions.LOGOUT:
			return {
				user: null,
			}

		case authActions.LOGIN_ERROR:
			return {
				isLoading: false,
				error: action.message
			}
		case authActions.SET_LOADING_LOGIN:
			return {
				...state,
				isLoading: true,
				error: ''
			};
		case actionTypes.GET_USER_PROFILE:
			return {
				...state,
				isLoading: false,
				user: action.userProfile.data[0],
				error: '',
			};

		case profileTypes.UPDATE_PROFILE:
			return {
				...state,
				user: action.values,
				isLoading: false,
				error: ''
			}

		default:
			return state;

	}
}

export default loginReducer
