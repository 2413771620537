import * as actionTypes from '../action/Leave';

const initialState = {
  leave: {
    data: [],
    pagination: null
  },
  addError: '',
  getError: '',
  isLoading: false
};

const leaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRINCIPAL_GET_TEACHER_LEAVE:
      return {
        ...state,
        leave: action.leave,
        isLoading: false,
        getError: ''
      }

    case actionTypes.PRINCIPAL_GET_TEACHER_LEAVE_ERROR:
      return {
        ...state,
        isLoading: false,
        getError: action.message,
      }

    case actionTypes.PRINCIPAL_LEAVE_SET_LOADING:
      return {
        ...state,
        isLoading: true,
        getError: '',
        addError: ''
      }
    case actionTypes.PRINCIPAL_UPDATE_TEACHER_LEAVE:
      const leaveIndex = state.leave.data.findIndex(a => a.id === action.id);
      const leave = state.leave.data[leaveIndex];
      leave.status = action.status;
      return {
        ...state,
        leave: Object.assign(state.leave, { [leaveIndex]: leave }),
        isLoading: false,
        addError: ''
      }
      case actionTypes.PRINCIPAL_UPDATE_TEACHER_LEAVE_ERROR:
        return{
          ...state,
          isLoading: false,
          addError: action.message
        }
    default:
      return state;
  }
}

export default leaveReducer