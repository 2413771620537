import React, { Component } from 'react';
import {Table, Card, CardHeader, CardBody } from 'reactstrap';
// import * as action from '../../../Store/action/Homework';
import moment from 'moment';
import { trim } from '../../../utils/utils';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { ClipLoader } from "react-spinners";

class HomeworkDetails extends Component {
	state = {
		subject: '',
		title: '',
		startDate: new Date(),
		deadlineDate: new Date(),
		src: null,
		document: [],
		imageUrls: [],
		homeworkId: '',
		classes: '',
		description: '',
		homeworkDocuments: [],
		homeworkStatuses: [],
		studentData: [],
		loading: false
	}

async	componentDidMount() {
		this.setState({loading: true});
		// let studentData = [];
		const { homework } = this.props.location.state || {};
		console.log("Home Work data",homework)
		homework && this.setState({ ...homework, classes: homework.class, homeworkDocuments: homework.homeworkdocuments, homeworkStatuses: homework.homeworkstatuses });
		// console.log("statuses====", homework)
		try{
			const res= await axios.post(`https://schooldiarybackend-development.up.railway.app/api/v1/parent/homework/list`, {homeworkId: homework.id});
			this.setState({studentData: res.data.data})
			this.setState({loading: false})
		}catch(error){
			console.log("Error of data")
			this.setState({loading: false})
		}
		
	}
 downloadImage = async (image) => {
	console.log("Image of data",image)
    try {
			const imageUrl = image
      const response = await fetch(imageUrl);
      const blob = await response.blob();
   //   setImageBlob(blob);

      // If you want to open the image in a new tab
       const imageUrlDownload = URL.createObjectURL(blob);
       window.open(imageUrlDownload);

    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

	render() {
		const { title, subject, classes, startDate, deadlineDate, description, homeworkDocuments, studentData } = this.state;
		console.log("Home work document",homeworkDocuments.length)
		return (
			<>
				<Card className="c-style">
					<CardHeader className="h-style">
						<div className="row align-items-center">
							<Link to={{ pathname: "/admin/homeWork" }}><i  style={{marginLeft:25,marginRight:15}} className="fa fa-arrow-left"></i></Link>
							<div className="c-title-style">
								HomeWork Details
			                </div>
						</div>
					</CardHeader>
					<CardBody>
						<Table className="borderless" hover>
							<tr>
								<th>Class</th>
								<td>{`${classes && classes.standard.standardName}-${classes.className}, ${classes.medium}`}</td>
							</tr>
							<tr>
								<th>Medium</th>
								<td>{classes.medium}</td>
							</tr>
							<tr>
								<th>Subject</th>
								<td>{subject.subjectName}</td>
							</tr>
							<tr>
								<th>Title</th>
								<td>{title}</td>
							</tr>
							<tr>
								<th>Description</th>
								<td>
									<div dangerouslySetInnerHTML={{ __html: description }} />
								</td>
							</tr>
							<tr>
								<th>Start date</th>
								<td>{moment(startDate).format('DD-MMM-YYYY')}</td>
							</tr>
							<tr>
								<th>Deadline date</th>
								<td>{moment(deadlineDate).format('DD-MMM-YYYY')}</td>
							</tr>
							<tr>
								<th>Document </th>
								<table class="table table-responsive ">
									<tr>
										<th scope="col">Document File</th>
										<th scope="col">Name</th>
										<th scope="col">Mimetype</th>
										<th scope="col">Size</th>
									</tr>
									<tbody>
									{homeworkDocuments.length===0?<tr>
										<th style={{textAlign:'center'}} scope="col">-</th>
										<th  style={{textAlign:'center'}} scope="col">-</th>
										<th style={{textAlign:'center'}} scope="col">-</th>
										<th style={{textAlign:'center'}} scope="col">-</th>
									</tr>:homeworkDocuments.map((x) => {
										console.log("X of name",x.name)
											return (
												<tr>
													<td><span  onClick={()=>this.downloadImage(x.name)} style={{cursor:'pointer'}} href={x.documentFile} download><i class="fa fa-download" style={{ color: "black" }} aria-hidden="true"></i></span></td>
													<td>{trim(x.name, 13)}</td>
													<td>{x.mimetype}</td>
													<td>{(x.size / 1024).toFixed(1)}KB</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</tr>
							<tr>
								<th>Status </th>
								<table class="table table-responsive">
									<thead>
										<tr>
											<th scope="col">First Name</th>
											<th scope="col">Last Name</th>
											<th scope="col">Roll NUmber</th>
											<th scope="col">Status</th>
											<th scope="col">Document File</th>
											<th scope="col">Size</th>
										</tr>
									</thead>
									<tbody>
										{this.state.loading && 
									<div style={{display:"flex", justifyContent: "center", width: "100%"}}>
									<ClipLoader
                                        sizeUnit={"px"}
                                        size={20}
                                        color={"#000"}
                                        loading={true}
                                    />
									</div>
		 }
										{studentData.map((p) => {
											return (
												<tr>
													<td>{p.firstName}</td>
													<td>{p.lastName}</td>
													<td>{p.rollNumber}</td>
													<td>{p.status}</td>
													<td>{p.documentFiles.length > 0 && p.documentFiles.map(file => {
														return (
															<div>
															<span href={file.documentFile} download><i class="fa fa-download" style={{ color: "black" }} aria-hidden="true"></i></span>
															<span>{file.name}</span>
															</div>
														)
													})}</td>
													<td>{p.documentFiles.length > 0 && p.documentFiles.map(file => {
														return (
															<div>
															<span>{(file.size / 1024).toFixed(1)}KB</span>
															</div>
														)
													})}</td>
												</tr>

											)
										})}
									</tbody>
								</table>
							</tr>
						</Table>
						{/* <div className="d-flex justify-content-center">
							<Link className="btn btn-secondary align-self-center" to={{ pathname: '/admin/homeWork' }}>Cancel</Link>
						</div> */}
					</CardBody>
				</Card>
			</>
		);
	}
}

export default HomeworkDetails;
