import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Table, CardBody, CardHeader, Alert, Pagination, PaginationItem, PaginationLink, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import * as action from '../../../Store/action/Homework';
import { getClass } from '../../../Store/action/Classes';
import { getSubject } from '../../../Store/action/Subjects';
import moment from 'moment';
import { ClipLoader } from "react-spinners";
import ConfirmationDialog from '../../ConfirmDialog/ConfirmDialog';
import DatePicker from "react-datepicker";

class HomeWork extends Component {
  state = {
    pg: this.props.currentPage,
    deadlineDate: new Date(),
    searchName: '',
    selectedClass: '',
    selectedSubject: '',
    homeworkDetails: []
  }

  componentDidMount() {
    if (!this.props.homeworks.data.length) {
      this.props.onGetHomeworks(1, '', '', '', moment(new Date()).format('YYYY-MM-DDT00:00:00.000[Z]'), '', this.props.user.id);
    }
    this.props.onGetClass(-1);
    this.props.onGetSubject(-1);
  }

  paginationChangeHandler = (pg, value) => () => {
    this.props.onGetHomeworks(value, this.state.searchName);
    this.setState({ pg: value });
  }

  renderPagination(pagination) {
    const pages = [];
    for (let i = 1; i <= pagination; i++) {
      pages.push(
        <PaginationItem active={this.state.pg === i} key={i} onClick={this.paginationChangeHandler('pg', i)}>
          <PaginationLink tag="button">{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  }

  confirmationDialogBox = (deleteTeacher) => {
    this.setState({
      deleteTeacher,
    });
  }

  deleteHandler = (ec) => {
    // this.setState({isDelete: true});
    this.setState({ deleteTeacher: null });
    this.props.onDeleteTeachers(ec);
    // this.setState({isDelete: false});

  }

  searchHomeworkHandler = (event) => {
    this.setState({ pg: 1, searchName: event.target.value })
    this.props.onGetHomeworks(1, event.target.value, this.state.selectedClass, this.state.selectedSubject);
  }

  classChangeHandle = (event) => {
    this.setState({ pg: 1, selectedClass: event.target.value });
    this.props.onGetHomeworks(1, this.state.searchName, event.target.value, this.state.selectedSubject);
  }

  subjectChangeHandle = (event) => {
    this.setState({ pg: 1, selectedSubject: event.target.value });
    this.props.onGetHomeworks(1, this.state.searchName, this.state.selectedClass, event.target.value);
  }

  ModalToggler = (homework) => {
    this.setState({ isOpen: !this.state.isOpen, homeworkDetails: homework });
  }

  endDateHandleChange = (date, event) => {
    this.setState({ pg: 1, deadlineDate: date });
    const {
      selectedClass,
      selectedSubject,
      searchName
    } = this.state;
    this.props.onGetHomeworks(1, searchName, selectedClass, selectedSubject, '', moment(date).format('YYYY-MM-DDT00:00:00.000[Z]'),);
  };

  render() {
    const { homeworks, classes, subjects,user } = this.props;
    const { pg } = this.state;
    const classOption = classes.data.map(a => a);
    const subjectOption = user.userSubjects.map(a => a);
    return (
      <div>
        <ConfirmationDialog
          isOpen={!!this.state.deleteTeacher}
          deleteHandler={() => this.deleteHandler(this.state.deleteTeacher)}
          closeHandler={() => this.confirmationDialogBox(null)} />

        <Card className="c-style">
          <CardHeader className="h-style">
            <div className="row m-r-0 m-l-0">
              <div className="c-title-style">
                HomeWork
              </div>
              <div className="ml-auto btn-style">
                <Link class="btn btn-outline-dark" to={{ pathname: '/admin/homeWork/add' }}>Add</Link>
              </div>
            </div>
          </CardHeader>
          <CardBody >

            <div className="row mb-2">
              <div class="col-lg-2 col-md-3 d-flex">
                <select
                  class="form-control show-tick ms select2"
                  data-placeholder="Select"
                  onChange={this.classChangeHandle}
                  value={this.state.selectedClass}>
                  <option value="">Select Class</option>
                  {classOption.map(c => {
                    return (
                      <option value={c.id}>{`${c.standard.standardName}-${c.className}, ${c.medium}`}</option>
                    )
                  })}

                </select>
              </div>
              <div >
                <FormGroup>
                  <DatePicker
                    isClearable
                    placeholderText="Deadline Date"
                    className="form-control"
                    selected={this.state.deadlineDate}
                    onChange={this.endDateHandleChange}
                  />
                </FormGroup>
              </div>
              <div class="col-lg-2 col-md-6 d-flex">
                <select
                  class="form-control show-tick ms select2"
                  data-placeholder="Select"
                  onChange={this.subjectChangeHandle}
                  value={this.state.selectedSubject}>
                  <option value="">Select Subject</option>
                  {subjectOption.map(s => {
                    return (
                      <option value={s.subject.id}>{s.subject.subjectName}</option>
                    )
                  })}
                </select>
              </div>

              <div className="col-lg-3 col-md-6 d-flex" >
                {/* <div style={{ marginTop: 7, marginRight: 5 }}>Search:</div> */}
                <label><input
                  type="search"
                  className="form-control"
                  placeholder="Search here..."
                  aria-controls="example"
                  value={this.state.searchName}
                  onChange={this.searchHomeworkHandler} /></label>
              </div>
            </div>
            <Table hover>
              <thead className='table-header-wrapper'>
                <tr>
                  <th>#</th>
                  <th width="20%">Class</th>
                  <th>Subject</th>
                  <th>Title</th>
                  <th>Deadline date</th>
                  {/* <th>Status</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!this.props.loading && !this.props.error &&
                  homeworks.data.map((h, index) => {
                    return (
                      <tr>
                        <td><strong>{(pg - 1) * 10 + index + 1}</strong></td>
                        <td>{`${h.class.standard.standardName}-${h.class.className}, ${h.class.medium}`}</td>
                        <td>{h.subject.subjectName}</td>
                        <td>{h.title}</td>
                        <td>{moment(h.deadlineDate).format('DD-MMM-YYYY')}</td>
                        {/* <td>{h.status}</td> */}
                        <td>
                          <div className="row">
                            <Link className="p-2" to={{ pathname: "/admin/homeWork/add", state: { homework: h } }}><i class="icon-pencil" style={{ color: 'black' }} aria-hidden="true"></i></Link>
                            <Link outline to={{ pathname: "/admin/homeWork/details", state: { homework: h } }}><i class="fa fa-info-circle" style={{ color: 'black', fontSize: '20px', paddingTop: '8px', paddingLeft: '10px' }} aria-hidden="true"></i></Link>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
            {this.props.loading ?
              <div className="loader-wrapper">
                <ClipLoader
                  size={35}
                  color={"#123abc"}
                  loading={true}
                />
              </div>
              :
              <div className="d-flex justify-content-end p-2">
                {homeworks.data.length > 0 && homeworks.pagination &&
                  <Pagination className="pull-right">
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => this.state.pg > 1 && this.paginationChangeHandler('pg', this.state.pg - 1)()} previous tag="button" />
                    </PaginationItem>
                    {this.renderPagination(homeworks.pagination.pages)}
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => homeworks.pagination.pages > this.state.pg && this.paginationChangeHandler('pg', this.state.pg + 1)()} next tag="button" />
                    </PaginationItem>
                  </Pagination>
                }
              </div>
            }
            {!this.props.loading && (this.props.error || !homeworks.data.length) &&
              <div className="error-style">
                <Alert color="danger">{this.props.error ? this.props.error : homeworks.message}</Alert>
              </div>}
          </CardBody>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    homeworks: state.homework.homeworks,
    currentPage: state.homework.currentPage,
    classes: state.class.classes,
    subjects: state.subject.subjects,
    error: state.homework.getError,
    classError: state.class.error,
    subjectError: state.subject.error,
    loading: state.homework.isLoading,
    user: state.login.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetHomeworks: (pg, searchName, selectedClass, selectedSubject, startDate, deadlineDate, teacherId) => dispatch(action.getHomeworks(pg, searchName, selectedClass, selectedSubject, startDate, deadlineDate, teacherId)),
    onGetClass: (pg) => dispatch(getClass(pg)),
    onGetSubject: (pg) => dispatch(getSubject(pg)),
    onDeleteHomework: () => dispatch(action.deleteHomeworks()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeWork);
