import React, { Component } from "react";
import { Col, Modal, ModalFooter, ModalHeader, ModalBody, Row, Button } from 'reactstrap';
import { ClipLoader } from "react-spinners";

class ConfirmDialog extends Component {
    render() {
        const { deleteHandler, closeHandler, isDelete } = this.props;
        return (
            <div>
                <Row>
                    <Col>
                        <Modal isOpen={this.props.isOpen}>
                            <ModalHeader>Confirmation Dialog</ModalHeader>
                            <ModalBody style={{ textAlign: "center" }}>
                                <div>
                                    Do you want to Delete ?
                                </div>
                            </ModalBody>
                            <ModalFooter className="justify-content-center">
                                <Button color="danger" onClick={deleteHandler}>
                                    {isDelete ? <ClipLoader
                                        sizeUnit={"px"}
                                        size={20}
                                        color={"#fff"}
                                        loading={true}
                                    /> : 'Yes'}</Button>
                                <Button color="secondary" onClick={closeHandler}>No</Button>
                            </ModalFooter>
                        </Modal>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ConfirmDialog;