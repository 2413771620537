import React, { Component } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import * as action from '../../../Store/action/Students';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Select from 'react-select';
import { getStandard } from '../../../Store/action/Standard';
import { getSubject } from '../../../Store/action/Subjects';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { getClass } from '../../../Store/action/Classes';
import { getSchool } from '../../../Store/action/School';
import Crop from '../../Crop/Crop';
import './Stdents.css';
import Profile from '../../../assets/images/profile-avatar.png';
import moment from 'moment';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, 'Enter Only Alphabetic')
    .required('First Name is required'),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, 'Enter Only Alphabetic')
    .required('Last Name is required'),
  classId: Yup.string().required('Class is required'),
  grNumber: Yup.string().matches(/^\d+$/,'Enter only numeric value').required('GRNumber is required'),
  rollNumber: Yup.string().required('Roll Number is required'),
  parentFirstName: Yup.string()
    .matches(/^[A-Za-z]+$/, 'Enter Only Alphabetic')
    .required('Parent First Name is required'),
  parentLastName: Yup.string()
    .matches(/^[A-Za-z]+$/, 'Enter Only Alphabetic')
    .required('Parent Last Name is Required'),
  parentEmail: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  parentMobileNo: Yup.string()
    .min(10, 'Enter valid phone number')
    .max(10, 'Enter valid phone number')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Enter valid phone number'
    )
    .required('Mobile Number is required'),
  parentAddress: Yup.string().required('Address is required'),
  gender:Yup.string().required("Gender is Required"),
});

const GenderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

class AddStudents extends Component {
  input = React.createRef();
  state = {
    joiningDate: new Date(),
    firstName: '',
    lastName: '',
    classId: '',
    stdId: '',
    medium: '',
    grNumber: '',
    rollNumber: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentMobileNo: '',
    gender: '',
    parentAddress: '',
    subjectId: '',
    oldSubjectId: [],
    schoolId: '',
    profilePic: '',
    src: null,
  };

  componentDidMount() {
    this.props.onGetClass();
    this.props.onGetStandard();
    this.props.onGetSubjects();
    this.props.getSchools();
    const { students } = this.props.location.state || {};
    if (students) {
      const classId = students.classId;
      const subjectId = students.studentSubjects.map((s) => s.subjectId);
      this.setState({
        ...students,
        parentFirstName: students.parent.firstName,
        parentLastName: students.parent.lastName,
        parentEmail: students.parent.email,
        parentMobileNo: students.parent.mobileNo,
        parentAddress: students.parent.address,
        studentId: students.id,
        joiningDate: new Date(students.joiningDate),
        schoolId: this.props.user.id,
        subjectId,
        classId,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading && !nextProps.error) {
      this.props.history.push('/admin/students');
      this.props.onGetStudents()
    } else if (nextProps.error && !this.props.error) {
      // toast.error(nextProps.error, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  }

  submitHandler = (values) => {
    const data = {
      ...values,
      joiningDate: moment(values.joiningDate).format(
        'YYYY-MM-DDT00:00:00.000[Z]'
      ),
    };
    if (this.state.studentId) {
      this.props.onStudentUpdate(data);
    } else {
      this.props.onAddStudents(data);
    }
  };

  onSelectFile = (key) => (e) => {
    this.setState({ image: e.target.files[0] });
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ [key]: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  render() {
    const { subject, user, schools, classes } = this.props;
    const { src, croppedImageUrl } = this.state;

    const studentClassRole=classes.data.map((c, i) => ({
      label: `${c.standard.standardName}-${c.className}, ${c.medium}`,
       value: c.id,
       status: c.status,
    }));

    const studentClassRoleOptions = studentClassRole.filter((op) => op.status === "ACTIVE");

    //console.log("Student role data",studentuserRole)

    // const studentClassOption=schools
    const classOptions = classes.data.map((c, i) => ({
      label: `${c.standard.standardName}-${c.className}, ${c.medium}`,
       value: c.id,
      // status: c.status,
    }));

console.log("classes of data",classes.data)
   // const classOptions = classOption.filter((op) => op.status === "ACTIVE");

   
    const subjectOptions = user.userSubjects.map((c, i) => ({
      label: c.subject.subjectName,
      value: c.subject.id,
      //status: c.status,
    }));

    const subjectUserOption=subject.data.map((c, i) => ({
      label: c.subjectName,
      value: c.id,
      status: c.status,
    }));

     const subjectUserOptions = subjectUserOption.filter((op) => op.status === "ACTIVE");
    const schoolOption = schools.data.map((s) => ({
      label: s.schoolName,
      value: s.id,
    }));

    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            classId: this.state.classId,
            stdId: this.state.stdId,
            // medium: this.state.medium,
            grNumber: this.state.grNumber,
            rollNumber: this.state.rollNumber,
            parentFirstName: this.state.parentFirstName,
            parentLastName: this.state.parentLastName,
            parentEmail: this.state.parentEmail,
            parentMobileNo: this.state.parentMobileNo,
            parentAddress: this.state.parentAddress,
            subjectId: this.state.subjectId,
            oldSubjectId: this.state.subjectId,
            joiningDate: this.state.joiningDate,
            studentId: this.state.studentId,
            parentId: this.state.parentId,
            gender: this.state.gender,
            // schoolId: this.state.schoolId,
            profilePic: this.state.profilePic,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting,
          }) => (
            <Card className="c-style">
              <Crop
                src={src}
                name={this.state.image && this.state.image.name}
                onCrop={(imageUrl, blob) => {
                  this.setState({ croppedImageUrl: imageUrl, src: null });
                  setFieldValue('profilePic', blob);
                }}
                onCancel={() => this.setState({ src: null })}
              />
              <CardHeader className="h-style title-style">
                {this.state.firstName === ''
                  ? 'Add Students'
                  : 'Update Students'}
              </CardHeader>
              <CardBody>
                <Label>Student's Details</Label>
                <hr className="mx-0  mt-0" />

                <FormGroup>
                  <Label className="form-control-label">Profile Picture</Label>
                  <FormGroup>
                    <input
                      accept="image/jpg, image/jpeg, image/png"
                      hidden
                      ref={this.input}
                      type="file"
                      onChange={this.onSelectFile('src')}
                    />
                    <FormFeedback>
                      {errors.profilePic &&
                        touched.profilePic &&
                        errors.profilePic}
                    </FormFeedback>
                    {!croppedImageUrl && !this.state.profilePic && (
                      <img
                        alt="profile"
                        style={{ height: 100 }}
                        src={Profile}
                        onClick={(e) =>
                          this.input.current && this.input.current.click()
                        }
                      />
                    )}
                  </FormGroup>
                  <div>
                    {(croppedImageUrl || this.state.profilePic) && (
                      <img
                        alt="Crop"
                        style={{ maxWidth: '100%' }}
                        onClick={(e) =>
                          this.input.current && this.input.current.click()
                        }
                        src={croppedImageUrl || this.state.profilePic}
                      />
                    )}
                  </div>
                </FormGroup>

                <Row>
                  <Col xs="5">
                    <Label>Student's Details</Label>
                    <hr className="mx-0 mt-0" />
                    <FormGroup>
                      <Label>First Name<span className="required">*</span></Label>
                      <Input
                        type="text"
                        invalid={errors.firstName && touched.firstName}
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        placeholder="First Name"
                      />
                      <FormFeedback>
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Last Name<span className="required">*</span></Label>
                      <Input
                        type="text"
                        invalid={errors.lastName && touched.lastName}
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        placeholder="Last Name"
                      />
                      <FormFeedback>
                        {errors.lastName && touched.lastName && errors.lastName}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Gender</Label>
                      <Select
                        name="GenderOptions"
                        onChange={(option) =>
                          setFieldValue('gender', option.value)
                        }
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={GenderOptions.find(
                          (option) => option.value === values.gender
                        )}
                        options={GenderOptions}
                      />
                      <FormFeedback>
                        {errors.gender && touched.gender && errors.gender}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                    <Label>Class Name <span className="required">*</span></Label>
                      <Select
                        name="classId"
                       // isMulti={true}
                        onChange={(option) =>
                          setFieldValue(
                            "classId",
                           option.value
                          )
                        }
                        onBlur={handleBlur}
                        className="is-invalid"
                        value={classOptions.find(
                          (option) => option.value === values.classId
                        )}
                        options={classOptions}
                      />
                      <FormFeedback>
                        {errors.classId && touched.classId && errors.classId}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Gr Number<span className="required">*</span></Label>
                      <Input
                        type="text"
                        invalid={errors.grNumber && touched.grNumber}
                        name="grNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.grNumber}
                        placeholder="GR Number"
                      />
                      <FormFeedback>
                        {errors.grNumber && touched.grNumber && errors.grNumber}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Roll Number<span className="required">*</span></Label>
                      <Input
                        type="lastName"
                        invalid={errors.rollNumber && touched.rollNumber}
                        name="rollNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.rollNumber}
                        placeholder="Roll Number"
                      />
                      <FormFeedback>
                        {errors.rollNumber &&
                          touched.rollNumber &&
                          errors.rollNumber}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Joining Date</Label>
                      <div>
                        <DatePicker
                          isClearable
                          maxDate={new Date()}
                          className="form-control"
                          wrapperClassName="w-100"
                          selected={values.joiningDate}
                          onChange={(date) =>
                            setFieldValue('joiningDate', date)
                          }
                        />
                      </div>
                    </FormGroup>

                    {!user.schoolId && (
                      <div className="col-6">
                        <FormGroup>
                          <Label>School Name<span className="required">*</span></Label>
                          <Select
                            name="schoolId"
                            onChange={(value) =>
                              setFieldValue(
                                'schoolId',
                                value ? value.value : ''
                              )
                            }
                            onBlur={handleBlur}
                            className="is-invalid"
                            value={schoolOption.find(
                              (option) => option.value === values.schoolId
                            )}
                            options={schoolOption}
                          />
                          <FormFeedback>
                            {errors.schoolId &&
                              touched.schoolId &&
                              errors.schoolId}
                          </FormFeedback>
                        </FormGroup>
                      </div>
                    )}

                    <FormGroup>
                    <Label>Subject Name</Label>
                        <Select
                          name="subjectId"
                          isMulti={true}
                          onChange={(option) =>
                            setFieldValue(
                              "subjectId",
                              option && option.map((p) => p.value)
                            )
                          }
                          onBlur={handleBlur}
                          className="is-invalid"
                          value={
                            values.subjectId &&
                           subjectUserOptions.filter((option) =>
                              values.subjectId.includes(option.value)
                            )
                          }
                          options={ subjectUserOptions}
                          menuPlacement="auto"
                        />
                        <FormFeedback>
                          {errors.subjectId &&
                            touched.subjectId &&
                            errors.subjectId}
                        </FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col xs={{ offset: 1, size: 5 }}>
                    <Label>Parent's Details</Label>
                    <hr className="mx-0 mt-0" />

                    <FormGroup>
                      <Label>Parent FirstName <span className="required">*</span></Label>
                      <Input
                        type="parentFirstName"
                        invalid={
                          errors.parentFirstName && touched.parentFirstName
                        }
                        name="parentFirstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.parentFirstName}
                        placeholder="Parent First Name"
                      />
                      <FormFeedback>
                        {errors.parentFirstName &&
                          touched.parentFirstName &&
                          errors.parentFirstName}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Parent LastName <span className="required">*</span></Label>
                      <Input
                        type="parentLastName"
                        invalid={
                          errors.parentLastName && touched.parentLastName
                        }
                        name="parentLastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.parentLastName}
                        placeholder="parent Last Name"
                      />
                      <FormFeedback>
                        {errors.parentLastName &&
                          touched.parentLastName &&
                          errors.parentLastName}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Parent Email <span className="required">*</span></Label>
                      <Input
                        type="email"
                        invalid={errors.parentEmail && touched.parentEmail}
                        name="parentEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.parentEmail}
                        placeholder="Parent Email"
                      />
                      <FormFeedback>
                        {errors.parentEmail &&
                          touched.parentEmail &&
                          errors.parentEmail}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Parent MobileNo <span className="required">*</span></Label>
                      <Input
                        type="number"
                        invalid={
                          errors.parentMobileNo && touched.parentMobileNo
                        }
                        name="parentMobileNo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.parentMobileNo}
                        placeholder="Parent MobileNo"
                      />
                      <FormFeedback>
                        {errors.parentMobileNo &&
                          touched.parentMobileNo &&
                          errors.parentMobileNo}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label>Parent Address <span className="required">*</span></Label>
                      <Input
                        type="textarea"
                        invalid={errors.parentAddress && touched.parentAddress}
                        name="parentAddress"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows={3}
                        value={values.parentAddress}
                        placeholder="Parent Address"
                      />
                      <FormFeedback>
                        {errors.parentAddress &&
                          touched.parentAddress &&
                          errors.parentAddress}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="d-flex justify-content-start">
                  <button
                    disabled={this.props.loading}
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                  >
                    {this.props.loading ? (
                      <ClipLoader size={15} color={'#123abc'} loading={true} />
                    ) : (
                        'Save'
                      )}
                  </button>
                  <Link
                    className="btn btn-secondary"
                    to={{ pathname: '/admin/students' }}
                  >
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.students.isLoading,
    error: state.students.addError,
    standard: state.standard.standard,
    subject: state.subject.subjects,
    classes: state.class.classes,
    user: state.login.user,
    schools: state.school.schools,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddStudents: (students) => dispatch(action.addStudents(students)),
    onStudentUpdate: (students) => dispatch(action.updateStudent(students)),
    onGetStandard: () => dispatch(getStandard()),
    onGetSubjects: () => dispatch(getSubject()),
    onGetClass: () => dispatch(getClass()),
    getSchools: () => dispatch(getSchool()),
    onGetStudents: (pg, classId, searchName, status) =>
      dispatch(action.getStudents(pg, classId, searchName, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStudents);
